const colorMargin = () => {

  function colorMargin(element) {
    var val = parseFloat(element.innerText.replace(',', '.')) / 100
    if (val > 0.75) { return "#1c7c71" } // vert
    if (val > 0.70 && val <= 0.75) { return "#167FFB" } // bleu
    if (val > 0.65 && val <= 0.70) { return "#d0a555" } // orange
    if (val <= 0.65 && val != 0) { return "#fe0101" } // rouge
  }

  const percent = document.querySelectorAll('.percent-margin')
  percent.forEach((element)=>{
    element.style.color = colorMargin(element)
  })

}


export {colorMargin};
