import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import { filterTableStockInventory, filterTableMinStockIndex } from "../components/filterTableFunction.js"

function totalSum(total, data) {
    return total + data;
}

export default class extends Controller {
    static targets = ["category", "zone", "mvt", "input", "minQtyTotalValue", "minQtyTotalValueModal", "gapQty", "gapValue", "checkBoxRounded", "convertToCartItem"];

    connect() {
        $(this.typeTarget).on('select2:select', function () {
            let event = new Event('change', { bubbles: true }) // fire a native event
            this.dispatchEvent(event);
        });

        $(this.categoryTarget).on('select2:select', function () {
            let event = new Event('change', { bubbles: true }) // fire a native event
            this.dispatchEvent(event);
        });

        $(this.zoneTarget).on('select2:select', function () {
            let event = new Event('change', { bubbles: true }) // fire a native event
            this.dispatchEvent(event);
        });


        const gapTotal = this.minQtyTotalValueTarget;
        const gapTotalModal = this.minQtyTotalValueModalTarget
        const gapItems = this.gapValueTargets;
        const values = Array.from(gapItems, el => parseFloat(el.innerHTML.replace(',', '.')));
        const totalValue = values.reduce(totalSum, 0).toFixed(2) + " €";

        gapTotal.innerHTML = totalValue;
        gapTotalModal.innerHTML = totalValue;
    }

    filterTable(e) {
        var table = document.getElementById("index-min-stock");
        var elementType = this.typeTarget.options[this.typeTarget.selectedIndex].value.toLowerCase();
        var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
        var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
        filterTableStockInventory(table, elementType, elementCat, elementZone, 0, false)
    }

    filterTableIndex(e) {
        var table = document.getElementById("index-min-stock");
        var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
        var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
        filterTableMinStockIndex(table, elementCat, elementZone, 0, false)

        var values = document.querySelectorAll('.value-gap')
        var sum = []
        values.forEach((el) => {
            if (el.closest('tr').style.display != 'none') {
                sum.push(parseFloat(el.innerHTML.replace(',', '.')))
            }
        })

        const gapTotal = this.minQtyTotalValueTarget;
        gapTotal.innerHTML = sum.reduce((sum, v) => sum + v, 0).toFixed(2) + " €";
    }

    filterTableMvts(e) {
        var table = document.getElementById("table");
        var elementType = this.typeTarget.options[this.typeTarget.selectedIndex].value.toLowerCase();
        var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
        var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
        var elementMvt = this.mvtTarget.options[this.mvtTarget.selectedIndex].innerHTML.toLowerCase();
        filterTableStockInventory(table, elementType, elementCat, elementZone, elementMvt, 0, false)
    }

    changeValue(e) {
        const valueInput = e.currentTarget.value
        const row = e.target.closest("tr")
        const stockUbRow = row.querySelector('.stock-ub')
        const qtyGapInRow = row.querySelector('.qty-gap')
        const qtyGapBasisUnitRow = qtyGapInRow.dataset.basisUnit
        const valueGapInRow = row.querySelector('.value-gap')
        const costInRow = parseFloat(row.querySelector('.value-gap').dataset.costUnit)
        const gapTotal = this.minQtyTotalValueTarget;
        const gapTotalModal = this.minQtyTotalValueModalTarget

        // Recalcul de l'écart en qté et en valeur
        qtyGapInRow.innerHTML = (valueInput - parseFloat(stockUbRow.innerHTML.replace(',', '.'))).toFixed(2) + ' ' + qtyGapBasisUnitRow
        valueGapInRow.innerHTML = ((valueInput - parseFloat(stockUbRow.innerHTML.replace(',', '.'))) * costInRow).toFixed(1)

        // Recalcul de l'écart total
        const gapItems = this.gapValueTargets;
        const values = Array.from(gapItems, el => parseFloat(el.innerHTML.replace(',', '.')));
        const totalValue = values.reduce(totalSum, 0).toFixed(2) + " €";
        gapTotal.innerHTML = totalValue;
        gapTotalModal.innerHTML = totalValue
    }

    convertInCartItems(e) {
        const convertRoundedUnities = this.checkBoxRoundedTarget;
        window.location = `/index_min_stock?round_units=${convertRoundedUnities.checked}&min_qty_to_used=true`
    }
}
