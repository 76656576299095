import $ from 'jquery';

const productShowMarginUnit = () => {
    // 1. submit on change pour le select
  $('#margin-unit-select').change(function(){
    let productIdPath = window.location.pathname;
    sessionStorage.setItem("productidpath", productIdPath);
    sessionStorage.removeItem('marginunitclass');
    sessionStorage.removeItem('marginunitid');

    $('#maginunit-formsubmit').submit();
    // console.log('submit');
  });
  // Voir plus tard pour eviter que la page se rafraichisse sur update d'un élément dans le select.

  // 2. Permet de réinitialiser le select si suppression et que l'on garde l'url ça pète donc vérifier que c'est dans les options.
  if ( window.location.href.includes("?") && window.location.pathname == sessionStorage.getItem("productidpath")) {
    let urlBasis = window.location.href.split("?")[0];
    let marginUnitUrlElements = window.location.search.split("?")[1].split("=")[1].split("%26");
    let unitClass = marginUnitUrlElements[0];
    let unitId = marginUnitUrlElements[marginUnitUrlElements.length - 1];

    if (unitClass && unitId) {
      if (unitClass == "ProductSellingUnit") {
        sessionStorage.setItem("marginunitclass", unitClass);
        sessionStorage.setItem("marginunitid", parseFloat(unitId));
      }
    }
   }

    if ( window.location.href.includes("?") == false && window.location.pathname == sessionStorage.getItem("productidpath")){
      let unitClassStored = sessionStorage.getItem("marginunitclass");
      let unitIdStored = sessionStorage.getItem("marginunitid");
      if( unitClassStored && unitIdStored){
        let marginunitid = sessionStorage.getItem("marginunitid");
        window.location = window.location.pathname + "?margin_unit=ProductSellingUnit%26%26%26" + marginunitid;
        $('#margin-unit-select').value = `ProductSellingUnit&&&${marginunitid}`;
        //console.log(sessionStorage);
        //console.log(sessionStorage.getItem("productidpath"));

      }
    }
    if (window.location.pathname != sessionStorage.getItem("productidpath")) {
      sessionStorage.removeItem('productidpath');
      sessionStorage.removeItem('marginunitclass');
      sessionStorage.removeItem('marginunitid');

    }



      const deleteSellingUnits = document.querySelectorAll('.deleteprodsellunit');
      deleteSellingUnits.forEach((deleteSellingUnit) => {
        deleteSellingUnit.addEventListener('click', (event) => {
          //console.log("delete");
          const sellingUnitId = deleteSellingUnit.dataset.itemid
          let unitClassStored = sessionStorage.getItem("marginunitclass");
          let unitIdStored = sessionStorage.getItem("marginunitid");
          if (unitIdStored && unitClassStored) {
            if (unitClassStored == "ProductSellingUnit" && unitIdStored == sellingUnitId ) {
              //console.log('delete right item');
              // event.preventDefault();
              sessionStorage.removeItem('productidpath');
              sessionStorage.removeItem('marginunitclass');
              sessionStorage.removeItem('marginunitid');
              // deleteSellingUnit.dispatchEvent(event);
            }
          }
        });
      });

}

export {productShowMarginUnit}
