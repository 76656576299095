const toggleValidation = (element, boolean) => {
	if (boolean) {
	  if (element.style.border = "1px solid #1EDD88") {
	  	element.style.border = "1px solid red"
	  }
	  element.classList.add("is-invalid");
	  return true;
	} else {
	  element.classList.remove("is-invalid");
	  element.classList.add('is-valid');
	  return false;
	}
}

const formMappingValidates = () => {
	const formEdit = document.querySelector(".edit_sales_mapping");

	if ( formEdit) {
    formEdit.addEventListener('submit', (event) => {
    	var selection = document.querySelectorAll('#_sale_element_ref_pdv').length
    	var totalcount = 0;
    	var ref = []
    	var description = []
		  var concat = []
		  var concatWithId = []
		  var doublons = [];
		  
    	for (var i = 0; i < selection; i++ ) {
		     ref.push(document.querySelector(`.sale_element-${i}-ref_pdv`));
		     description.push(document.querySelector(`.sale_element-${i}-description_pdv`))
		  }
		  
		  for (var i = 0; i < (ref.length); i++ ) {
				if ((ref[i].value + description[i].value) != '') {
					concat.push(ref[i].value + description[i].value)
		    	concatWithId.push([ref[i].value + description[i].value,i])
		    }
		  }
		  
		  var sorted_arr = concat.slice().sort();
		  
		  for (var i = 0; i < sorted_arr.length - 1; i++ ) {
		     if (sorted_arr[i + 1] == sorted_arr[i]) {
			      doublons.push(sorted_arr[i]);
			      totalcount += 1; 
			    }
		  }
		  
		  concatWithId.forEach((item)=>{
		  	for (var i = 0; i < (doublons.length); i++ ) {
		  		if(item[0] == doublons[i]){
		  			toggleValidation(ref[item[1]], true)
		  			toggleValidation(description[item[1]], true)
		  		} else {
		  			console.log("pas doublon")
		  			toggleValidation(ref[item[1]], false)
		  			toggleValidation(description[item[1]], false)
		  		}
		  	}
		  })

   		if (totalcount > 0) {
      	event.preventDefault();
      	event.stopPropagation();
      }
		});
	}
}


export { formMappingValidates };