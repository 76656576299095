
const ComputeRecipePortionsInForm = () => {

  const recipeMultiple = document.getElementById('recipe_restaurant_recipes_attributes_0_plate');
  const portionsNumber = document.getElementById('recipe_recipe_portions_attributes_0_quantity');
  const countorderportions = document.getElementById("orderportions");
  // udapteRecipePortionsCountForOrders(recipeMultiple, portionsNumber, countorderportions);

  // Partie pour recipe form initiale factorisée
  const udapteRecipePortionsCountForOrders = (recipeMultiple, portionsNumber, countorderportions) => {

    if (recipeMultiple && portionsNumber && countorderportions){

      recipeMultiple.addEventListener('change', (event) => {
        event.preventDefault();
        // console.log(countorderportions);
        if (recipeMultiple.value > 0 && portionsNumber.value > 0){
          countorderportions.innerHTML = `( ${ ( recipeMultiple.value * portionsNumber.value ).toFixed(0) } ${ "portions" } )`;
        }
        else{
          countorderportions.innerHTML = `(- portions)`;
        }
      });

      portionsNumber.addEventListener('change', (event) => {
        event.preventDefault();
        // console.log(recipeMultiple.value);
        // console.log(portionsNumber.value);

        // const countorderportions = document.getElementById("orderportions");
        // console.log(countorderportions);
        if (recipeMultiple.value > 0 && portionsNumber.value > 0){
          countorderportions.innerHTML = `( ${ ( recipeMultiple.value * portionsNumber.value ).toFixed(0) } ${ "portions" } )`;
        }
        else{
          countorderportions.innerHTML = `(- portions)`;
        }
      });

      // const countorderportions = document.getElementById("orderportions");
      if (recipeMultiple.value > 0 && portionsNumber.value > 0){
        countorderportions.innerHTML = `( ${ ( recipeMultiple.value * portionsNumber.value ).toFixed(0) } ${ "portions" } )`;
      } else{
        countorderportions.innerHTML = `(- portions)`;
      }


    }
  }

  // Partie pour recipe form initiale factorisée
  udapteRecipePortionsCountForOrders(recipeMultiple, portionsNumber, countorderportions);

  // Partie pour recipe form multi-établissements
  const multiRestContentForm = document.getElementById('display-multi-restaurants-recipe-form');

  if (multiRestContentForm && portionsNumber) {

    const recipeRestaurantInputs = document.querySelectorAll('.recipe_multi_restaurants_id');
    recipeRestaurantInputs.forEach((input) => {
      let itemIndex = input.dataset.itemindex;
      let recipeMultiple = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_plate`);
      let countorderportions = document.getElementById(`orderportions-${itemIndex}`);
      // console.log(linkedInput);
      // console.log(input.checked);

      if (recipeMultiple && countorderportions) {
        if (input.checked == true) {
          udapteRecipePortionsCountForOrders(recipeMultiple, portionsNumber, countorderportions);
        } else {
          countorderportions.innerHTML = `(- portions)`
        }
        udapteRecipePortionsCountForOrders(recipeMultiple, portionsNumber, countorderportions);
      }
      // console.log(linkedInput);
    });
    // Partie pour mise à jour de l'input uniforme en multi-établissements
    const recipeUniformMultiple = document.getElementById("recipe_uniform_plate");
    const countUniformOrderPortions = document.getElementById("uniformorderportionscompute");

    udapteRecipePortionsCountForOrders(recipeUniformMultiple, portionsNumber, countUniformOrderPortions);

  }



}

export {ComputeRecipePortionsInForm};
