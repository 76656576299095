// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
import flatpickr from "flatpickr";
import "controllers" // Stimulus
import $ from 'jquery';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
global.$ = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------
import "bootstrap";

import {autoSubmit} from "../components/autosubmitform";

import {chartTopVentes, comparisonCart, kpis, productMargin, chartOrderStatus} from "../components/charts";

import {displayOrderAndItems} from "../components/shoppingCartDisplay";

import {initSelect2} from "../plugins/init_select2";

import {minusPlusButton} from "../components/minusplusbutton";

import {minusPlusButtonOrder} from "../components/minusplusbuttonorder";

import {move} from "../plugins/move";

import {orderCollap} from "../components/orderCollapse";

import {toggleNavbarButton} from "../components/toggleNavbarButton";

import {toggleShoppingCart} from "../components/toggleShoppingCart";

import {totalshopcart} from "../components/compute_total_shopcart.js";

import {totalorder} from "../components/compute_total_order.js";

import {addRemoveProductUnit} from "../components/addRemoveProductUnit.js"

import {addRemoveSupplierCond} from "../components/addRemoveSupplierCond.js"

import {addCond} from "../components/addCond.js"

import {autocompleteSearch} from  "../components/autocomplete.js";

import {displayRecipeUnitInForm} from "../components/addRecipeQuantityToForm.js";

import {ComputeRecipeTvaInForm} from "../components/computeTVARecipeForm.js";

import {ComputeRecipePortionsInForm} from "../components/computePortionsRecipeForm.js";

import {addPortionSizeInForm} from "../components/addRecipePortionsToForm.js";

import {addRecipeDefOrderInForm} from "../components/addRecipeDefOrdersToForm.js";

// import {addIngredientUnitOnField} from "../components/ingredientAutofield.js"

import {recipeActiveSaleSubmit} from "../components/submitActivesaleOnShow.js"

import {productDirectSaleSubmit} from "../components/submitDirectSaleOnShow.js"

// import {ingredientUnit} from "../components/ingredientUnit.js";

import {addIngredient} from "../components/addIngredient.js";

import {categoryCollapse} from "../components/collapsecategory.js";

import {ingredientElement} from "../components/ingredientElement.js";

import {addRecipeCategory} from "../components/addRecipeCategory.js";

import {ingredientUnitSelect} from "../components/ingredientUnitSelect.js";

import {addActiveToRecipeType} from "../components/addRecipeTypeInForm.js";

import {addRecipeCaliberProtect} from "../components/addRecipeCaliberProtect.js";

import {checkDoubleProductUnit} from "../components/checkDoubleProductUnit.js";

import {removeProductUnitVisible} from "../components/removeProductUnitVisible.js";

import {removeSupplierItemsVisible} from "../components/removeSupplierItemsVisible.js";

import {resizeColumns} from "../components/resizeColumns.js";

import {addCreateCartItems} from "../components/addCreateCartItems.js";

import {minusPlusButtonCartForm} from "../components/minusplusbuttoncartform.js";

import {updateSupplierCartForm} from "../components/updatesuppliercartform.js";

import {updateSupplierCartIndex} from "../components/updatesuppliercartindex.js";

import {FilterProductList} from "../components/filterProductList.js"

import {cartItemFromSupplierFilter} from "../components/cartItemFromSupplierFilter.js";

import {productSellingUnitElement} from "../components/productSellingUnitElement.js"

import {clearSearchInputWithQuery} from "../components/clearSearchInputWithQuery.js";

import {clearSearchInputWoQuery} from "../components/clearSearchInputWoQuery.js";

import {restaurantRecipesSpecUpdate} from "../components/restaurantRecipesSpecUpdate.js";

import {computeTotalRecipesCost} from "../components/compute_total_recipes_cost.js";

import {minusPlusButtonRestaurantRecipe} from "../components/minusplusbuttonrestaurantrecipe.js";

import {addCreateOrders} from "../components/addCreateOrders.js";

import {ProductFormValidates} from "../components/formProductValidates.js"

import {addValidationsToRecipeForm} from "../components/addValidationsToRecipeForm.js"

import {productShowMarginUnit} from "../components/productShowMarginUnit.js"

import {pillsNavOrders} from "../components/pillsNavOrders.js"

import {pillsNavOrderApprovals} from "../components/pillsNavOrderApprovals.js"

import {pillsNavStocks} from "../components/pillsNavStocks.js"

import {convertRestRecipesInCartItems} from "../components/convertRestRecipesInCartItems.js"

import {restaurantSelectFromUser} from "../components/restaurantSelectFromUser.js"

import {pillsNavUsers} from "../components/pillsNavUsers.js"

import {addRecipeMultipleOrdersToForm} from "../components/addRecipeMultipleOrdersToForm.js"

import {dashboard} from "../components/dashboard.js"

import {sortCartItem, sortTable} from "../functions/sort.js"

import {addRestProdMultiplePricesToForm} from "../components/addRestProdMultiplePricesToForm.js"

import {negativeInputs} from "../components/negativeInputs.js"

import {computeTvaRestProdForm} from "../components/computeTVARestProdForm.js"

import {autocompleteSearchSubmit} from "../components/autocompleteSearchSubmit.js"

import {OrderCardResponsive} from "../components/OrderCardResponsive.js"

import {quaggaLoad} from "../plugins/quagga_load.js"

import {addBeginPayingSubscription} from "../components/addBeginPayingSubscription.js"

import {initFlatpickr} from "../plugins/flatpickr.js"

import {addSaleItems, selectSaleElements} from "../components/addSaleItems.js"

import { formOrderValidates } from "../components/formOrderValidates.js"

import { buttonPhoto, buttonUploadFile } from "../components/buttons.js"

import { colorMargin } from "../components/colorMargin.js"

import { formSalesValidates } from "../components/formSalesValidates.js"

import { formMappingValidates } from "../components/formMappingValidates.js"

document.addEventListener('turbolinks:load', () => {
  colorMargin();
  OrderCardResponsive();
  ProductFormValidates();
  // FilterProductList();
  resizeColumns();
  // addIngredientUnitOnField()
  addRemoveProductUnit();
  addCond();
  addRemoveSupplierCond();
  orderCollap();
  toggleNavbarButton();
  toggleShoppingCart();
  displayOrderAndItems();
  minusPlusButton();
  minusPlusButtonOrder();
  move();
  if (document.getElementById("bar-chart-grouped")) {
    comparisonCart();
  };
  if (document.getElementById("doughnut-chart")) {
    chartTopVentes();
  };
  if (document.getElementById("doughnut-chart-order")) {
    chartOrderStatus();
  };
  if (document.getElementById("kpis")) {
    kpis();
  }
  if (document.getElementById("half-doughnut-margin")){
    productMargin();
  }

  autoSubmit();
  displayOrderAndItems();
  initSelect2();

  if (document.getElementById("total-shop-cart")) {
    totalshopcart();
  }
  // totalorder(); // Désactivé car uniquement lié aux modifications méthode appelée dans minmusplusbuttonorder
  buttonPhoto();
  buttonUploadFile();
  if(event.data){
    autocompleteSearch();
  }

  displayRecipeUnitInForm();
  ComputeRecipeTvaInForm();
  ComputeRecipePortionsInForm();
  addPortionSizeInForm();
  addRecipeDefOrderInForm();
  recipeActiveSaleSubmit();
  productDirectSaleSubmit();
  // ingredientUnit();
  addIngredient();
  categoryCollapse();
  ingredientElement();
  productSellingUnitElement();
  addRecipeCategory();
  ingredientUnitSelect();
  addActiveToRecipeType();
  addRecipeCaliberProtect();
  checkDoubleProductUnit();
  removeProductUnitVisible();
  removeSupplierItemsVisible();
  addCreateCartItems();
  minusPlusButtonCartForm();
  updateSupplierCartForm();
  updateSupplierCartIndex();
  cartItemFromSupplierFilter();
  clearSearchInputWithQuery();
  clearSearchInputWoQuery();
  restaurantRecipesSpecUpdate();
  minusPlusButtonRestaurantRecipe();
  computeTotalRecipesCost();
  addCreateOrders();
  addValidationsToRecipeForm();
  productShowMarginUnit();
  pillsNavOrders();
  pillsNavOrderApprovals();
  pillsNavStocks();
  convertRestRecipesInCartItems();
  restaurantSelectFromUser();
  pillsNavUsers();
  addRecipeMultipleOrdersToForm();
  dashboard();
  sortTable();
  sortCartItem();
  computeTvaRestProdForm();
  addRestProdMultiplePricesToForm();
  negativeInputs();
  autocompleteSearchSubmit();
  if(event.data){
    quaggaLoad();
  }
  addBeginPayingSubscription();
  initFlatpickr();
  addSaleItems();
  selectSaleElements();
  formOrderValidates();
  formSalesValidates();
  formMappingValidates();
  // error 401 on remote true
  jQuery(function($) {
    $(document).bind("ajax:error", function(event, data, status, xhr) {
       window.location.replace(this); //"<%= new_user_session_path %>"
    });
  });

});


$(document).on("turbolinks:before-cache", function() {
  if(document.querySelector('.prod-cat.select2-hidden-accessible')) {
    $('.prod-cat').select2('destroy');
  }
  if(document.querySelector('.prod-cat-stock.select2-hidden-accessible')) {
    $('.prod-cat-stock').select2('destroy');
  }
  if(document.querySelector('.inventory_place_filter.select2-hidden-accessible')) {
    $('.inventory_place_filter').select2('destroy');
  }
  if(document.querySelector('.product_recipe_type.select2-hidden-accessible')) {
    $('.product_recipe_type').select2('destroy');
  }
  if(document.querySelector('.restaurant_stock_choice.select2-hidden-accessible')) {
    $('.restaurant_stock_choice').select2('destroy');
  }
  if($(".flatpickr-input")) {
    $(".flatpickr-input").flatpickr('destroy');
  }
  if(document.querySelector('.movement_type.select2-hidden-accessible')) {
    $('.movement_type').select2('destroy');
  }
  if(document.querySelector('.categorywithclear.select2-hidden-accessible')) {
    $('.categorywithclear').select2('destroy');
  }
  if(document.querySelector('.supplierwithclear.select2-hidden-accessible')) {
    $('.supplierwithclear').select2('destroy');
  }
  if(document.querySelector('.productwithclear.select2-hidden-accessible')) {
    $('.productwithclear').select2('destroy');
  }
  if(document.querySelector('.allergenes.select2-hidden-accessible')) {
    $('.allergenes').select2('destroy');
  }
});

