import $ from 'jquery';

const addCreateOrders = () => {
  const ordersForm = document.getElementById('new_order');
  // console.log(ordersForm);
  if (ordersForm) {
    const inOrdersCheckForm = document.getElementById('order_add_in_existing_orders');
    const deleteCartItemsCheckForm = document.getElementById('order_delete_cart_items_after_order');
    const inOrdersCheckModal = document.getElementById('order_validation_add_to_draft_orders');
    const deleteCartItemsCheckModal = document.getElementById('order_validation_remove_cart_items');
    const ordersCreateConfirmModal = document.getElementById('confirm_orders_create');

    inOrdersCheckModal.addEventListener('change', (event) => {
     if(event.target.checked) {
      inOrdersCheckForm.checked = true;
      inOrdersCheckForm.value = 1;
     } else {
      inOrdersCheckForm.checked = false;
      inOrdersCheckForm.value = 0;
     }
    });

    deleteCartItemsCheckModal.addEventListener('change', (event) => {
     if(event.target.checked) {
      deleteCartItemsCheckForm.checked = true;
      deleteCartItemsCheckForm.value = 1;
     } else {
      deleteCartItemsCheckForm.checked = false;
      deleteCartItemsCheckForm.value = 0;
     }
    });

    ordersForm.addEventListener('submit', (event) => {
          event.preventDefault();
    });
    ordersCreateConfirmModal.addEventListener('click', (event) => {
      ordersForm.submit();
    });
  }

}
export {addCreateOrders};
