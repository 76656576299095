import $ from 'jquery';

const sortCartItem = () => {

	$('.product-table-header').on('click', function(e) {
    sortDivByColumn(e.target)
  })

	$('.myshopping-cart-header').on('click', function(e) {
    sortDivByColumn(e.target)
  })

	function sortDivByColumn(divHeader) {
    console.log("sortcartitem from functions")
		// extract all the relevant details
    //let table = tableHeader.closest('table')
    var table = document.getElementById('style-15').children;
		var items = Array.prototype.slice.call(table, 3);

   	var index = 1
    let sortType = divHeader.dataset.sortType
    let sortDirection = divHeader.dataset.sortDir || 'asc' // default sort to ascending

    // sort the table rows
    let sortFunction = getSortFunction(sortType, index, sortDirection, divHeader)
    let sorted = items.sort(sortFunction)

    // remove and re-add rows to table
    for (let row of sorted) {
      let parent = row.parentNode
      let detatchedItem = parent.removeChild(row)
      parent.appendChild(row)
    }

    divHeader.dataset.sortDir = sortDirection == 'asc' ? 'desc' : 'asc'
  }

  function getSortFunction(sortType, index, sortDirection, divHeader) {
    let dir = sortDirection == 'asc' ? -1 : 1
    switch (sortType) {
      case 'text': return stringRowComparer(index, dir, divHeader);
      case 'textsymbol': return numericSymbolRowComparer(index, dir, divHeader);
      default: return stringRowComparer(index, dir, divHeader);
    }
  }
	
 	function stringRowComparer(index, direction, divHeader) {
    return (a, b) => divHeader.dataset.type == "Article" ? (-1 * direction * a.firstElementChild.children[0].textContent.localeCompare(b.firstElementChild.children[0].textContent)) : divHeader.dataset.type == "Category" ? (-1 * direction * a.firstElementChild.children[1].textContent.localeCompare(b.firstElementChild.children[1].textContent) ) : ""
  }
	
	var convert = function(element) { return parseFloat(element.lastElementChild.children[1].lastElementChild.textContent.replace(/[^0-9\r\n.-]/g,'')) }

 	function numericSymbolRowComparer(index, direction, divHeader) {
    return (a, b) => direction * (Number(convert(a)) - Number(convert(b)))
  }

}

const sortTable = () => {
  const columnAsc = document.querySelector('.sort-asc')
  if (columnAsc) {
    sortTableByColumn(columnAsc)
  }

  $('table.table-sortable th').on('click', function(e) {
    sortTableByColumn(this)
  })

  function sortTableByColumn(tableHeader) {

    // extract all the relevant details
    let table = tableHeader.closest('table')
    let index = tableHeader.cellIndex
    let sortType = tableHeader.dataset.sortType
    let sortDirection = tableHeader.dataset.sortDir || 'asc' // default sort to ascending

    // sort the table rows
    let items = Array.prototype.slice.call(table.rows).slice(1);
    let sortFunction = getSortFunction(sortType, index, sortDirection)
    let sorted = items.sort(sortFunction)

    // remove and re-add rows to table
    for (let row of sorted) {
      let parent = row.parentNode
      let detatchedItem = parent.removeChild(row)
      parent.appendChild(row)
    }

    // reset heading values and styles
    for (let header of tableHeader.parentNode.children) {
      header.classList.remove('currently-sorted')
      delete header.dataset.sortDir
    }

    // update this headers's values and styles
    tableHeader.dataset.sortDir = sortDirection == 'asc' ? 'desc' : 'asc'
    tableHeader.classList.add('currently-sorted')
  }

  function getSortFunction(sortType, index, sortDirection) {
    let dir = sortDirection == 'asc' ? -1 : 1
    switch (sortType) {
      case 'text': return stringRowComparer(index, dir);
      case 'specifictext': return stringSpecificRowComparer(index, dir);
      case 'textinput': return stringSpecificInputRowComparer(index, dir);
      case 'textmix': return stringSpecificMixRowComparer(index, dir);
      case 'numeric': return numericRowComparer(index, dir);
      case 'numericsymbol': return numericSymbolRowComparer(index, dir);
      case 'numericsymbolplus': return numericSymbolPlusRowComparer(index, dir);
      case 'numericsymbolsize': return numericSymbolSizeRowComparer(index, dir);
      case 'numericinput': return numericInputRowComparer(index, dir);
      case 'numericmix': return numericMixRowComparer(index, dir);
      case 'date': return dateRowComparer(index, dir);
      case 'datelonglink': return dateLongOnLinkRowComparer(index, dir);
      case 'datelong': return dateLongRowComparer(index, dir);
      default: return stringRowComparer(index, dir);
    }
  }
  var getCellValue = function(tr, idx){ if(tr.children[idx].lastElementChild == null || tr.children[idx].lastElementChild.value === undefined) { return tr.children[idx].innerText} else{ return tr.children[idx].lastElementChild.value;} }
  // asc = alphanumeric order - eg 0->9->a->z
  // desc = reverse alphanumeric order - eg z->a->9->0
  var convert = function(tr, idx) { return parseFloat(tr.children[idx].textContent.replace('€','').replace('%','').replace(' ','')) }
  var convertnumeric = function(tr, idx) { return parseFloat(tr.children[idx].textContent.replace('+','')) }
  var convertsize = function(tr, idx) { return parseFloat(tr.children[idx].textContent.replace(/[^0-9\r\n.-]/g,'')) }

  function stringRowComparer(index, direction) {
    return (a, b) => -1 * direction * a.children[index].textContent.localeCompare(b.children[index].textContent)
  }

  function stringSpecificRowComparer(index, direction) {
    return (a, b) => -1 * direction * a.children[index].innerText.localeCompare(b.children[index].innerText)
  }

  function stringSpecificInputRowComparer(index, direction) {
    return (a, b) => -1 * direction * a.children[index].lastElementChild.value.localeCompare(b.children[index].lastElementChild.value)
  }

  function stringSpecificMixRowComparer(index, direction) {
    return (a, b) => -1 * direction * getCellValue(a,index).localeCompare(getCellValue(b,index))
  }

  function dateRowComparer(index, direction) {
    return (a, b) => -1 * direction * a.children[index].dataset.yearmonth.localeCompare(b.children[index].dataset.yearmonth)
  }

  function dateLongOnLinkRowComparer(index, direction) {
    return (a, b) => -1 * direction * a.getElementsByClassName('date-converted')[index].dataset.date.localeCompare(b.getElementsByClassName('date-converted')[index].dataset.date)
  }

  function dateLongRowComparer(index, direction) {
    return (a, b) => -1 * direction * a.children[index].getElementsByClassName('date-converted')[0].dataset.date.localeCompare(b.children[index].getElementsByClassName('date-converted')[0].dataset.date)
  }
  // asc = higest to lowest - eg 999->0
  // desc = lowest to highest - eg 0->999

  function numericRowComparer(index, direction) {
    return (a, b) => direction * (Number(a.children[index].textContent) - Number(b.children[index].textContent))
  }

  function numericSymbolRowComparer(index, direction) {
    return (a, b) => direction * (Number(convert(a, index)) - Number(convert(b, index)))
  }

  function numericSymbolPlusRowComparer(index, direction) {
    return (a, b) => direction * (Number(convertnumeric(a, index)) - Number(convertnumeric(b, index)))
  }

  function numericSymbolSizeRowComparer(index, direction) {
    return (a, b) => direction * (Number(convertsize(a, index)) - Number(convertsize(b, index)))
  }

  function numericInputRowComparer(index, direction) {
    return (a, b) => direction * (Number(a.children[index].lastElementChild.value) - Number(b.children[index].lastElementChild.value))
  }
  
  function numericMixRowComparer(index, direction) {
    return (a, b) =>  direction * (Number(getCellValue(a,index)) - Number(getCellValue(b,index)))
  }
}

export {sortCartItem, sortTable};
