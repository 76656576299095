

const totalshopcart = () => {
  var minOrderListContent = document.querySelector('.min-order-list')

  const redurecArrayBySuppliers = (array) => {
    var datasets = [];
    array.reduce(function(res, value) {
      if (!res[value.supplier]) {
        res[value.supplier] = { supplier: value.supplier, min: value.min, order: 0, gap: 0 };
        datasets.push(res[value.supplier])
      }
      res[value.supplier].order += value.order;
      res[value.supplier].gap = (res[value.supplier].order - value.min);
      return res;
    }, {});
    datasets = datasets.filter(i => i.min > 0);
    return datasets
  }

  const createTableMinOrder = (data) => {
    var html = "<tr class='productrows'>" +
        "<td class='padding-left ref-item'>" +
          `<span style=''><strong>${data.supplier}</strong></span>` +
        "</td>" +
        `<td class='text-center other-item'>${parseFloat(data.min).toFixed(2)}</td>` +
        `<td class='text-center other-item'>${parseFloat(data.order).toFixed(2)}</td>` +
        `<td class='text-center other-item' style='${data.gap < 0 ? 'color: #ff6d4d;' : ''}'>${parseFloat(data.gap).toFixed(2)}</td>` +
      "</tr>"
    return minOrderListContent.insertAdjacentHTML('beforeend', html)
  }

  const totalcart = document.getElementById(`total-shop-cart`);
  const subtotals = document.querySelectorAll(".sous-total");
  // const soustotalht = document.getElementById(`totalht-${input.dataset.itemid}`);
  let total = 0;
  let arr = [];
  var negativeGapCount = 0;
  subtotals.forEach((subtotal) => {
    if (subtotal.innerHTML != "n.d" && subtotal.innerHTML != "-"){
      total += parseFloat(subtotal.innerHTML);

      // ajout d'un dataset Supplier name + min order dans chaque .sout-total
      var supplier = subtotal.dataset.supplierName
      var minOrder = subtotal.dataset.minOrder
      // var supplier = subtotal.closest('.shopping-cart-part2').getElementsByClassName('cart-supplier-name')[0].innerText
      // var minOrder = subtotal.closest('.shopping-cart-box').getElementsByClassName('min-order-supplier')[0].innerText

      arr.push({supplier: supplier, min: parseFloat(minOrder), order: parseFloat(subtotal.innerHTML), gap: 0 })

    }
  });

  // Ajout + Suppression du badge min order
  const divButtonOrder = document.querySelector('.basket-form-bottom.total-part-2 > span')
  const divModalValidationOrder = document.querySelector('#showGapMinOrder .confirm-orders')
  const badge = '<span class="btn btn-to_valid badge-min-order min-order" data-toggle="modal" data-target="#showGapMinOrder" type="button">MC</span>'
  const badgeValidation = '<div class="badge-order-validation mt-4"> <span class="ml-0 btn btn-to_valid badge-min-order" data-toggle="modal" data-target="#showGapMinOrder" type="button">MC</span><span class"ml-1" style="color:red;"> Minimum de commandes non atteint pour au moins 1 fournisseur</span> </div>'
  minOrderListContent.innerHTML = "";

  redurecArrayBySuppliers(arr).forEach((el)=>{
    if (el.gap < 0){
      negativeGapCount += 1
    }
    if (!document.querySelector('.min-order')){
      divButtonOrder.insertAdjacentHTML('beforeEnd', badge)
     }
    if (!document.querySelector('.badge-order-validation')){
      divModalValidationOrder.insertAdjacentHTML('beforeEnd', badgeValidation)
    }
    createTableMinOrder(el)
  })

  if (negativeGapCount == 0 && document.querySelector('.min-order')) {
    document.querySelector('.min-order').remove()
    document.querySelector('.badge-order-validation').remove()
    // Supprimer badge + message dans la modale de validation de commande
  }
  // Calcul du total du panier
  totalcart.innerHTML = total.toFixed(2);
}


export {totalshopcart};
