

const minusPlusButtonCartForm = () => {
  const cart_form = document.getElementById('create_cart_items');
  if (cart_form) {
  //1. Changement en direct dans l'input par le user
    const cartInputs = cart_form.querySelectorAll('.cartformquantityinput');
    cartInputs.forEach((input) => {
      if (input.dataset.addEvent) return
      input.dataset.addEvent = true;
      input.addEventListener('change', (event) => {
        let currentValue = parseFloat(event.target.value);
        input.value = currentValue;
        updateQuantityandPrices(input, currentValue);
      });
    });

    const minusButtons = cart_form.querySelectorAll('#cartformminusbutton');
    minusButtons.forEach((minusButton) => {
      if (minusButton.dataset.addEvent) return
      minusButton.dataset.addEvent = true;
      minusButton.addEventListener('click', (event) => {
        const input = minusButton.nextElementSibling;

        let currentValue = parseFloat(input.value);
        if (currentValue > 0) {
          currentValue = currentValue - 1;
        } else {
          currentValue = 0;
        }
        input.value = currentValue;
        updateQuantityandPrices(input, currentValue);
        input.dataset.eventchanged = "true";
      });
    });

    const plusButtons = cart_form.querySelectorAll('#cartformplusbutton');
    plusButtons.forEach((plusButton) => {
      if (plusButton.dataset.addEvent) return
        plusButton.dataset.addEvent = true;
      plusButton.addEventListener('click', (event) => {

        const input = plusButton.previousElementSibling;
        let currentValue = parseFloat(input.value);
        currentValue = currentValue + 1;
        input.value = currentValue;
        updateQuantityandPrices(input, currentValue);
        input.dataset.eventchanged = "true";
      });
    });
  }

  const updateQuantityandPrices = (input, currentValue) => {
    const cart_form = document.getElementById('create_cart_items');
    if (cart_form) {
      // 1. Mise à jour des quantités de conditionnement commandés et order_item.quantity en hidden
      const itemquantity = document.getElementById(`cartformitem-quantity-${input.dataset.itemid}`);
      itemquantity.innerHTML = currentValue;
      const hiddenquantity = document.getElementById(`cartformhidden-quantity-${input.dataset.itemid}`);
      hiddenquantity.value = currentValue;

      // 2.Mise à jour de la quantité unitaire (au L / au kg)
      const quantityPerUnit = document.getElementById(`cartformitem-quantity-per-unit-${input.dataset.itemid}`);
      const supplieritemsize = parseFloat(document.getElementById(`cartformsupplieritemsize-${input.dataset.itemid}`).innerHTML);
      // console.log(document.getElementById(`supplieritemselect-${input.dataset.itemid}`)); // NE FONCTIONNA PAS - création d'un supplieritem size en hidden
      if (supplieritemsize) {
        const quantityPerUnitValue = ( supplieritemsize * currentValue ).toFixed(3);
        quantityPerUnit.innerHTML = quantityPerUnitValue
        const refprixunitaireht = document.getElementById(`cartformrefprixunitaireht-${input.dataset.itemid}`);

        // 3.Mise à jour du sous-total (recalcul en fonction du prix au kg / L pour éviter les problèmes de conversion entre prix et conditionnement)
        if (refprixunitaireht && refprixunitaireht.innerHTML != " " && refprixunitaireht.innerHTML != "n.d" && refprixunitaireht.dataset.refcost != " " ) {
          const prixtotalht = document.getElementById(`cartformtotalht-${input.dataset.itemid}`);
          const valeurunitaireht = parseFloat(refprixunitaireht.dataset.refcost);
          //COMMENTAIRE : Voir si on maintient le round 2 ou round 1 pour limiter les écart liés au js (quantité unitaire * prux au kg etc.)
          const valeurtotalht = ( valeurunitaireht * quantityPerUnitValue ).toFixed(2);
          prixtotalht.innerHTML = valeurtotalht;
        }
      }
    }
  }

}


export {minusPlusButtonCartForm};
