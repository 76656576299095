const addCond = () => {

  var list_cond_value
  var list_cond_text
  var list_cond_id
  var condId = {} // liste des ids des conditionning units
  //console.log("refresh me")

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  const createname = () => {
    var product_unit_names = document.querySelectorAll('.product-refunit input, .product-refunit select')
    var array = [] // liste des noms des différents champs pour product unist & conditioning units
    var perChunk = 5 // items per chunk
    // var condId = {} // liste des ids des conditionning units
    condId["A0"] = ""
    list_cond_id = []
    list_cond_id.push("0")

    product_unit_names.forEach((name) => {
      if (name.tagName == "SELECT"){
        //array.push(name.innerText.split('\n')[name.selectedIndex])
        array.push(name.options[name.selectedIndex].innerText);
      } else if(name.type == "hidden" || name.type == "checkbox") {

      } else if (name.value == "" && name.type == "text"){
        array.push("Unité");
      } else if (name.value == "" && name.type == "number"){
        array.push("1");
      } else {
        array.push(name.value);
      }

      if (name.name.indexOf("[id]") != -1 && name.id.indexOf("conditioning_unit_id") != -1){
        if(name.value == ""){
          condId["A99"] = "";
          list_cond_id.push("99")
        } else {
          condId[`A${name.value}`] = "";
          list_cond_id.push(name.value);
        }
      }
    });

    // Séparation de l'array en x items (en fonction du perchunk choisi)
    // si array = 10 valeurs, alors le nouvel array sera de 10 / 5(perchunk) soit un array de 2 valeurs
    var result = array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/perChunk)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    // Création du libellé
    list_cond_value = []
    list_cond_text = []
    list_cond_text.push("Indiquer le conditionnement")
    list_cond_value.push("Indiquer le conditionnement")

    result.forEach((item)=>{
      // exemple boite (x20 barquette - 200g)
      if (parseFloat(item[4]).toString().indexOf(".") == -1){
        item[4] = parseFloat(item[4]) + ".0"
      }
      if (parseFloat(item[1]).toString().indexOf(".") == -1){
        item[1] = parseFloat(item[1]) + ".0"
      }
      list_cond_text.push(`${item[3][0].toUpperCase() + item[3].substring(1)} (x${item[4]} ${item[0][0].toUpperCase() + item[0].substring(1)} - ${item[1]}${item[2]})`);
      // list_cond_value.push(`${item[3]}${parseFloat(item[4]).toFixed(1)}`);
      list_cond_value.push(`${item[3][0].toUpperCase() + item[3].substring(1)} (x${item[4]} ${item[0][0].toUpperCase() + item[0].substring(1)} - ${item[1]}${item[2]})`);
    })

    // Ajout des libellés au hash de condId
    var i = 0
    for (var key in condId){
      condId[key] = list_cond_text[i]
      i++
    }

    // Ajout des libellés au hash avec ids
    for (var key in hashIds){
      if (key in condId){
        hashIds[key] = condId[key];
      }
    }
  }

  // Création d'une liste des ids sélectionnés des conditionings units SELECTED en supplier items
  const itembysupplier = document.querySelectorAll('.product_supplier_item_conditioning_unit select');
  const hashIds = {}
  if(itembysupplier){
    itembysupplier.forEach((item) => {
      hashIds[`A${item.value}`] = "";
      item.title = item.value;
    });
  }

  var productUnitInput = document.getElementById('product_product_units_attributes_0_basis_unit_id');
  if(productUnitInput){}
  else{
    var productUnitInput = document.getElementById('product_product_unit_basis_unit_id')
  }

  const matchBasisUnits = (productUnitInput) => {
    var unitId = {}
    Object.values(productUnitInput).forEach((item)=>
      unitId[item.text] = item.value
    )
    const unitValuePU = productUnitInput.options[productUnitInput.selectedIndex].innerText
    const unitValueAllSU = document.querySelectorAll('.product_supplier_items_cost_unit select');
    const lastRowUnitValueSU = unitValueAllSU[unitValueAllSU.length - 1];
    // Modification des cost units selon le choix de la 1ere ligne du product unit
    if(lastRowUnitValueSU){
      unitValueAllSU.forEach((valueSu)=>{
       const active = unitValuePU
        var unitsListSU = []
        if (unitValuePU == "kg" || unitValuePU == "g" || unitValuePU == "mg"){
          unitsListSU.push("kg", "g", "pièce", "condit.", "L", "cL", "mL");
        } else if (unitValuePU == "pièce") {
          unitsListSU.push("pièce", "condit.");
        } else {
          unitsListSU.push("L", "cL", "mL", "pièce", "condit.", "kg", "g");
        }

        valueSu.options.length = 0;
        Object.values(unitsListSU).forEach((item) => {
          var opt = document.createElement('option');
          // Ajout des options dans le sélecteur
          opt.value = item;
          opt.text =  item;
          opt.innerHTML = item;
          valueSu.appendChild(opt);
          if (window.location.href.split("/").includes("products") && window.location.href.split("/").includes("edit")){
            if (opt.text == active){
              opt.selected = true;
            } else {
              opt.selected = false;
            }
          } else {
            if (opt.text == unitValuePU){
              opt.selected = true;
            } else {
              opt.selected = false;
            }
          }
        });
      })
    }
  }

  const list = () => {
    matchBasisUnits(productUnitInput);
    createname();

    // Insertion pour la partie fournisseurs de la liste des conditionnements disponible
    var supplier_unit = document.querySelectorAll('.product_supplier_items_conditioning_unit_id select');
      if (supplier_unit.length != 0){
      } else {
        var supplier_unit = document.querySelectorAll('.product_supplier_item_conditioning_unit select');
      }

    var x = 0
    supplier_unit.forEach((item) => {
      // Identification de l'option selectionnée
      const test = item.selectedIndex;
      if (item.options.length == 0){
        var selected_value = 0;
        var active = "";
      } else {
        var selected_value = item.selectedIndex;
        var selected_id = item[selected_value].value;
        var active = hashIds[`A${selected_id}`];
        if(active === undefined){
          var i = 0
          list_cond_value.forEach((item)=>{
            if(item == selected_id){
              active = list_cond_text[i]
            }
            i++
          })
        }
      }
      item.options.length = 0
      var i=0
      list_cond_text.forEach((cond) => {
        var opt = document.createElement('option');
        // Ajout des options dans le sélecteur
        opt.value = list_cond_value[i];
        opt.text = getKeyByValue(hashIds,cond);
        opt.innerHTML = cond;
        opt.title = list_cond_id[i];
        item.appendChild(opt);
        if (cond == active){
          opt.selected = true;
        } else {
          opt.selected = false;
        }
        i++
      });
    });
  }

  // Vérification si changement dans l'unité standard article en Product New
  const form = document.getElementById("new_product");
  if(form){
    const product_refunit= document.querySelector('.product-refunit');
    if(product_refunit){
      product_refunit.addEventListener('change', (event) => {
        event.preventDefault();
        var itembysupplier = document.querySelectorAll('.product_supplier_item_conditioning_unit select');
        const hashIds = {}
        if(itembysupplier){
          itembysupplier.forEach((item) => {
            hashIds[`B${item.value}`] = "";
          });
        }
        list();
      });
    };
  }

  const changename = () => {

    var itembysuppliers = document.querySelectorAll('.product_supplier_item_conditioning_unit select');
    var productunitrows = document.querySelectorAll('.product-units-rows');
    var produnitConds = document.querySelectorAll('#product_product_unit_conditioning_unit_product_unit_id');

    if (itembysuppliers.length == 0) {
      var itembysuppliers = document.querySelectorAll('.product_supplier_items_conditioning_unit_id select');
    }

    productunitrows.forEach((condunit) => {
      condunit.addEventListener('change', (event) => {
        event.preventDefault();
        var condid =  condunit.childNodes[15].lastChild.value.toString(); // id du conditioning unit lié au product unit
        if (condid == ""){
          condid = "A99"
          list();
        }
        var condname = condunit.childNodes[19].lastChild.value + parseFloat(condunit.childNodes[21].lastChild.value).toFixed(1);
        if (hashIds[`A${condid}`]){
          createname();
          itembysuppliers.forEach((supplieritem) => {
            for (var i = 0; i < supplieritem.length; i++) {
              var optionvalue = supplieritem.options[i].value;
              var optionid = supplieritem.options[i].title;
              if(optionid == condid){
                supplieritem.options[i].textContent = hashIds[`A${condid}`];
                supplieritem.options[i].innerHTML = hashIds[`A${condid}`];
                supplieritem.options[i].textContent = hashIds[`A${condid}`];
                supplieritem.options[i].value = hashIds[`A${condid}`];
                supplieritem.style.width = "auto";
              }
            }
          });
        } else if(condId[`A${condid}`]){
          createname();
          itembysuppliers.forEach((supplieritem) => {
            for (var i = 0; i < supplieritem.length; i++) {
              var optionvalue = supplieritem.options[i].value;
              var optionid = supplieritem.options[i].title;
              if(optionid == condid){
                supplieritem.options[i].textContent = condId[`A${condid}`];
                supplieritem.options[i].innerHTML = condId[`A${condid}`];
                supplieritem.options[i].textContent = condId[`A${condid}`];
                supplieritem.options[i].value = condId[`A${condid}`];
                supplieritem.style.width = "auto";
              }
            }
          });
        }
      });
    });
  }

  var edit = document.getElementById("product-edit")
  if (edit){

    const addButton = document.querySelector('.fa-plus-circle.circle-first');
    addButton.addEventListener('click', (event) => {
      hashIds[`A99`] = "";
      changename();
      list();
    });

    const basisUnitFirstRow = document.getElementById('product_product_unit_basis_unit_id');
    if(basisUnitFirstRow.selectedOptions[0].innerText == "pièce") {
      const listSelects = document.querySelectorAll('.product-units-rows select');
      listSelects.forEach((select)=>{
        var options = select.getElementsByTagName('OPTION');
        for(var x=0; x < select.length; x++) {
          if(options[x].text != 'pièce'){
            options[x].remove();
            x--;
          };
        }
      });
    } else {
      const listSelects = document.querySelectorAll('.product-units-rows select');
      listSelects.forEach((select)=>{
        var options = select.getElementsByTagName('OPTION');
        for(var x=0; x < select.length; x++) {
          if(options[x].text == 'pièce'){
            options[x].remove();
            x--;
          };
        }
      });
    }
    changename();
    document.addEventListener("turbolinks:load", list());
  }
}

export { addCond };
