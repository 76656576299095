const addRemoveProductUnit = () => {

  const removeButton = document.querySelectorAll('.product-units-rows');
    removeButton.forEach((item) => {
      item.lastElementChild.addEventListener('click', (event) => {
        event.preventDefault();
          item.childNodes.forEach((child) => {
            if (child.className=="fas fa-minus-circle") {
              item.remove();
            }
          });
      });
    });

  const addButton = document.querySelector('.fa-plus-circle.circle-first');
  if (addButton){

    var nbLignes = document.querySelectorAll(".product_product_unit_name")

    if(nbLignes.length > 1){
      var i = nbLignes.length
    }
    else {
      var i = 1
    }

    var productUnitInput = document.getElementById('product_product_units_attributes_0_basis_unit_id');
    if(productUnitInput){
    }
    else{
      var productUnitInput = document.getElementById('product_product_unit_basis_unit_id')
    }


    // const matchBasisUnits = (productUnitInput) => {

    //   var unitId = {}

    //   Object.values(productUnitInput).forEach((item)=>
    //     unitId[item.text] = item.value
    //   )

    //   const unitValueAllPU = document.querySelectorAll('.product-units-rows select');
    //   const lastRowUnitValuePU = unitValueAllPU[unitValueAllPU.length - 1];
    //   const unitValuePU = productUnitInput.options[productUnitInput.selectedIndex].textContent

    //   const unitValueAllSU = document.querySelectorAll('.supplier-units-rows select');
    //   const lastRowUnitValueSU = unitValueAllSU[unitValueAllSU.length - 1];
    //   const active = lastRowUnitValueSU.options[lastRowUnitValueSU.selectedIndex].value

    //   var unitsListPU = []
    //   var unitsListSU = []

    //   if (unitValuePU == "kg" || unitValuePU == "g" || unitValuePU == "mg"){
    //     unitsListPU.push("kg", "g", "mg", "L", "cL", "mL");
    //     unitsListSU.push("kg", "g", "mg", "pièce", "condit.", "L", "cL", "mL");

    //   } else {
    //     unitsListPU.push("L", "cL", "mL", "kg", "g", "mg");
    //     unitsListSU.push("L", "cL", "mL", "pièce", "condit.", "kg", "g", "mg");
    //   }

    //   lastRowUnitValuePU.options.length = 0;
    //   Object.values(unitsListPU).forEach((item) => {
    //     var opt = document.createElement('option');
    //     // Ajout des options dans le sélecteur
    //     opt.value = unitId[item];
    //     opt.text =  item;
    //     opt.innerHTML = item;
    //     lastRowUnitValuePU.appendChild(opt);
    //   });

    //   lastRowUnitValueSU.options.length = 0;
    //   Object.values(unitsListSU).forEach((item) => {
    //     var opt = document.createElement('option');
    //     // Ajout des options dans le sélecteur
    //     opt.value = item;
    //     opt.text =  item;
    //     opt.innerHTML = item;
    //     lastRowUnitValueSU.appendChild(opt);
    //     // console.log(opt.value);
    //     if (opt.text == active){
    //       opt.selected = true;
    //     } else {
    //       opt.selected = false;
    //     }
    //   });
    // }


    var selectedBasisUnit = document.getElementById('product_product_units_attributes_0_basis_unit_id')
    if (selectedBasisUnit == null) {
      var selectedBasisUnit = document.getElementById('product_product_unit_basis_unit_id')
    }
    const pieceMessage =
    "Nous recommandons de mettre une unité en poids (kg, g) ou en volume (L, cL, mL), même approximative, pour avoir une comparaison plus pertinente de vos références fournisseurs et une meilleure estimation par la suite du calibre de vos recettes. \n\n Si vous gardez l'unité 'pièce', les unités en poids et volumes ne seront plus disponibles pour les autres conditionnement pour ce produit. Nous recommandons alors de mettre des pièces comparables.\n\n Ex: Pour les oeufs, nous recommandons de mettre approximativement 50g. Si le choix d'unité est finalement 'pièce', alors nous recommandons de renseigner des calibres d'oeufs comparables. Le poids des oeufs sera considéré comme nul dans les recettes."


    selectedBasisUnit.addEventListener('change', () => {

      if(selectedBasisUnit.selectedOptions[0].innerText == "pièce") {
        var category = document.getElementById('product_prod_cat_id');
        var categorySelected = category.selectedOptions[0].innerText.replace(/\s+/g, " ")
        if(categorySelected != " Consommables " && categorySelected != " Divers "){
          alert(`Êtes- vous certain de vouloir choisir une unité en pièce (pour la catégorie ${categorySelected}) ?\n\n`
            + `${pieceMessage}`);
        }
        // Mise à jour de tous les basis units avec "pièce"
        const listitems = document.querySelectorAll('.product-units-rows');
        if(listitems[listitems.length-2] !== undefined) {
          const listSelects = document.querySelectorAll('.product-units-rows select');
          var selectinput = listSelects[0]
          listSelects.forEach((select)=>{
            if (select.name != 'product[product_units_attributes][0][basis_unit_id]'){
              var options = select.getElementsByTagName('OPTION');
              for(var i=0; i<options.length; i++) {
                if(options[i].innerHTML == 'pièce'){
                  options[i].disabled = false;
                  select.selectedIndex = 0;
                };
                if(options[i].innerHTML != 'pièce') {
                    select.removeChild(options[i]);
                    i--;
                }
              }
            }
          });
        }
      } else {
        // Mise à jour de tous les basis units avec toutes les unités
        const listSelects = document.querySelectorAll('.product-units-rows select');
        var selectinput = listSelects[0]
        listSelects.forEach((select)=>{
          if (select.name != 'product[product_units_attributes][0][basis_unit_id]'){
            var options = select.getElementsByTagName('OPTION');
            var optionsFirstRow = selectinput[0].options;
            if (options.length == 1) {
              select.options[0].remove()
              for(var x=0; x < selectinput.length; x++) {
                var opt = document.createElement("option");
                opt.text = selectinput[x].innerHTML;
                opt.value = selectinput[x].value;
                select.appendChild(opt);
                if(opt.text == 'pièce'){
                  opt.disabled = true;
                };
              }

              select.selectedIndex = selectinput.selectedIndex
            }
          }
        });
      }
    })

    addButton.addEventListener('click', (event) => {
      event.preventDefault();
      const formProductUnit = document.querySelector('.product-refunit');
      const productUnit = document.querySelector('.product-units-rows');

      formProductUnit.insertAdjacentHTML('beforeend',
        '<div class="product-units-rows">'
        +
        productUnit.innerHTML.split('_0_').join(`_${i}_`).split('[0]').join(`[${i}]`)
        // productUnit.innerHTML.split('[0]').join(`${i}`)
        +
        '<i class="fas fa-minus-circle"></i>'
        +
        '</div>');

      // recopie des informations du product unit précédent
      const listitems = document.querySelectorAll('.product-units-rows');
      var listoutputs = listitems[listitems.length-2].querySelectorAll('input')
      var listinputs = listitems[listitems.length-1].querySelectorAll('input')
      var max = listoutputs.length
      for(var j=0 ; j < max ; j++){
        listinputs[j].value = listoutputs[j].value;
      }
      var selectoutputs = listitems[listitems.length-2].querySelectorAll('select')
      var selectinputs = listitems[listitems.length-1].querySelectorAll('select')
      selectinputs[0].selectedIndex = selectoutputs[0].selectedIndex

      // Si select 1ere ligne = "pièce" alors la nouvelle aura pour selecteur uniquement "pièce"
      if(selectoutputs[0].selectedOptions[0].innerText == "pièce") {
        selectinputs.forEach((select)=>{
          var options = select.getElementsByTagName('OPTION');
          for(var i=0; i<options.length; i++) {
            if(options[i].innerHTML != 'pièce') {
                select.removeChild(options[i]);
                i--;
            }
          }
        });
      }

      // Vide tous les inputs d'un nouveau product_unit lorsque l'on est en edits
      if(window.location.href.split("/").includes("edit")){
        const newitem = document.querySelectorAll('.product-units-rows');
        const listinputs = newitem[newitem.length-1].querySelectorAll('input')
        listinputs.forEach(input => input.value="")
      }

      i++

      // Supprimer le delete () qui apparait dans l'edit sur les lignes ajoutées précédemment via le "insertAdjacentHTML"
      const productUnitAll = document.querySelectorAll('.product-units-rows');
      const lastRow = productUnitAll[productUnitAll.length - 1];
      for (var j = 0; j < lastRow.childNodes.length; j++) {
        if (lastRow.childNodes[j].className == "remove-prod-unit") {
          lastRow.childNodes[j].style.display = "none";
        }
      }


      const removeButton = document.querySelectorAll('.product-units-rows');

      removeButton.forEach((item) => {
        item.lastChild.addEventListener('click', (event) => {
          event.preventDefault();
            item.childNodes.forEach((child) => {
              if (child.className=="fas fa-minus-circle") {
                item.remove();
              }
            });
        });
      });
    });
  }
}

export {addRemoveProductUnit};
