import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['checkbox', 'button'];

  connect() {
  }

  toggleButtonState(e) {
    if (this.checkboxTarget.checked) {
      this.buttonTarget.classList.remove('disabled')
    } else {
      this.buttonTarget.classList.add('disabled')
    }
  }
}
