// Version 1 - Exemple à reprendre

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const comparisonCart = () => {
  new Chart(document.getElementById("bar-chart-grouped"), {
    type: 'bar',
    data: {
      labels: ["Lundi", "Mardi", "Mercerdi", "Jeudi", "Vendredi"],
      datasets: [
        {
          label: "N-1",
          backgroundColor: "rgba(54, 162, 235, 0.6)",
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          barPercentage: 0.8,
          barThickness: "flex",
          data: [531,563,649,728, 634]
        }, {
          label: "N",
          backgroundColor: "rgba(153, 102, 255, 0.6)",
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
          barPercentage: 0.8,
          barThickness: "flex",
          data: [408,547,675,734, 651]
        }
      ]
    },
    options: {
      scales: {
        xAxes: [{
            gridLines: {
                display:false
            }
        }],
        yAxes: [{
            gridLines: {
                display:false
            }
        }]
      },
      legend: {
            position: 'right',
        },
      title: {
        display: true,
        text: 'Comparaison journalière du CA',
        fontSize: 14
      },
    }
  });
}

const chartTopVentes = () => {
  new Chart(document.getElementById("doughnut-chart"), {
      type: 'doughnut',
      data: {
        labels: ["Boeuf Bourguignon", "Falafel", "Rillettes de saumon", "Riz au Lait", "Côte de Boeuf Aubrac", "Autres"],
        datasets: [
          {
            label: "Proportions des ventes par recettes",
            backgroundColor: ["#3e95cd", "#8e5ea2","#3cba9f","#e8c3b9","#c45850"],
            data: [421,375,328,254,178,567]
          }
        ]
      },
      options: {
        title: {
          display: true,
          text: 'Ventes par produits'
        },
        legend: {
            position: 'right',
        }
      }
  });
}

const chartOrderStatus = () => {

  const doughnutChart = document.getElementById("doughnut-chart-order");
  if (doughnutChart) {
    const nbToValidate = Math.round(document.querySelector(".nb-to-receive.selected").dataset.amount);
    const nbPartialReceived = Math.round(document.querySelector(".nb-partiallyreceived.selected").dataset.amount);
    const nbReceived = Math.round(document.querySelector(".nb-received.selected").dataset.amount);
    const nbCanceled = Math.round(document.querySelector(".nb-canceled.selected").dataset.amount);
    const noChart = document.querySelector(".chart-none");
    var myLegendContainer = document.getElementById("legend");

    const array = []

    if (nbToValidate) {
      array.push(nbToValidate);
    } else {
      array.push(0)
    }
    if (nbPartialReceived) {
      array.push(nbPartialReceived);
    } else {
      array.push(0)
    }
    if (nbReceived) {
      array.push(nbReceived);
    } else {
      array.push(0)
    }
    if (nbCanceled) {
      array.push(nbCanceled);
    } else {
      array.push(0)
    }
    // console.log(array)
    if (doughnutChart) {
      if (array.every(item => item === 0)){
        doughnutChart.style.display = "none";
        noChart.style = "display: flex; align-items: center; justify-content: center;";
        myLegendContainer.style.display = "none";
      } else {
        noChart.style.display = "none";
        myLegendContainer.style.display = "block";
        doughnutChart.style.display = "block";
        var chart = new Chart(document.getElementById("doughnut-chart-order"), {
          type: 'doughnut',
          data: {
            labels: ["A réceptionner", "Reçu Partiel", "Reçu", "Annulé"],
            datasets: [
              {
                label: "Proportions des ventes par recettes",
                backgroundColor: [
                  '#2f4cdd',
                  '#ffeae6',
                  '#e7faec',
                  '#b8bbbd'
                ],
                borderWidth: 1,
                data: array
              }
            ]
          },
          options: {
            tooltips: {
              enabled: false
            },
            plugins: {
              datalabels: {
                display: true,
                backgroundColor: '#ffff',
                borderRadius: 3,
                font: {
                  color: 'red',
                  weight: 'bold',
                },
                 formatter: (value) => {
                    return (value/1000).toFixed(1) + 'K€';
                  }
              },
              doughnutlabel: {
                labels: [{
                  text: '550',
                  font: {
                    size: 50,
                    weight: 'bold'
                  }
                }, {
                  text: 'total'
                }]
              }
            }, legend: {
                display: false
            },
            legendCallback: function(chart) {
              var text = [];
              text.push('<ul class="0-legend">');
              var ds = chart.data.datasets[0];
              var sum = ds.data.reduce(function add(a, b) { return a + b; }, 0);
              for (var i=0; i<ds.data.length; i++) {
                text.push('<li>');
                var perc = Math.round(100*ds.data[i]/sum,0);
                text.push('<span style="background-color:' + ds.backgroundColor[i] + '">' + '</span>' + chart.data.labels[i] + ' ('+(ds.data[i]/1000).toFixed(1)+'K€) ('+perc+'%)');
                text.push('</li>');
              }
              text.push('</ul>');
              return text.join("");
            }
          }
        });
        myLegendContainer.innerHTML = chart.generateLegend();
      }
    }
  }
}



const kpis = () => {

  new Chart(document.getElementById("kpis"), {
    type: 'horizontalBar',
    data: {
      labels: "",
      datasets: [
        {
          label: "Valeur des commandes (€ - en cumul)",
          backgroundColor: "#574E8C",
          barPercentage: 0.5,
          data: [931]
        }, {
          label: "Commandes à réceptionner (€ - en cumul)",
          backgroundColor: "##F78888",
          barPercentage: 0.5,
          data: [708]
        },
      ]
    },
    options: {
      legend: {
        position: 'top',
        },
      scales: {
        xAxes: [{
            categoryPercentage: 0.5,
            barPercentage: 0.5,
        }]
      },
      title: {
        display: true,
        text: 'Commandes de la semaine (€)'
      },
    }
  });
}

const productMargin = () => {


  const margin = document.querySelector(".kpi-margin");
  const value = parseFloat(margin.innerHTML)
  const remaining = 100 - value
  const c = document.getElementById("half-doughnut-margin");
  const ctx = c.getContext("2d");

  var greenPart = ctx.createLinearGradient(0,0,0,100);
  greenPart.addColorStop(0, 'green');
  greenPart.addColorStop(1, 'palegreen');


  var redPart = ctx.createLinearGradient(0,0,0,100);
  redPart.addColorStop(0, 'red');
  redPart.addColorStop(1, 'tomato');

  var whitePart = ctx.createLinearGradient(0,0,0,100);
  whitePart.addColorStop(0, 'white');
  whitePart.addColorStop(1, 'lightgray');

  if(value < 0){
    var color = redPart;
  } else {
    var color = greenPart;
  }

  new Chart(c, {
      type: 'doughnut',
      data: {
        labels: ["% Marge", "% Marge"],
        datasets: [
          {
            label: "% Marge",
            data: [value, remaining],
            backgroundColor: [color, whitePart],
            borderColor: [color],
            borderWidth: 2
          }
        ]
      },
      options: {
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        title: {
          display: true,
          text: 'Marge %',
          fontSize: 16
        },
        legend: {
          display: false
        },
        cutoutPercentage : 80,
        tooltip: {
          enabled: false
        },
        plugins: {
          datalabels: {
            backgroundColor: '#f3f6f8',
            borderColor: '#ffffff',
            color: function(context) {
              return context.dataset.backgroundColor;
            },
            font: function(context) {
              var w = context.chart.width;
              return {
                size: w < 220 ? 12 : 20
              }
            },
            align: 'start',
            anchor: 'start',
            offset: 16,
            borderRadius: 4,
            borderWidth: 1,
            formatter: function(value, context) {
              var i = context.dataIndex;
              var len = context.dataset.data.length - 1;
              if(i == len){
                return null;
              }
              return value +' %';
            }
          }
        },

      }
  });
}

export {chartTopVentes, comparisonCart, kpis, productMargin, chartOrderStatus};
