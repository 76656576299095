import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

const textToFloat = (subtotal) => {
  return parseFloat(subtotal.lastElementChild.innerText);
}

export default class extends Controller {
  static targets = ['modal_order'];

  connect() {
  }

  show(e) {
    e.preventDefault();
    const orderSelected = this.modal_orderTarget 
    const order_id = orderSelected.dataset.orderid
  	const totalOrderGap = document.getElementById(`total-order-gap-validate-${order_id}`);
	  if (textToFloat(totalOrderGap)!=0){
	    totalOrderGap.style.display = "block"
	  }
  }
}
