import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['namesinput','namesoutput', 'elementtype'];

  connect() {
  }

  copy(e) {
    var inputs = this.namesinputTargets
    var outputs = this.namesoutputTargets
    var type = this.elementtypeTargets

    for(var i = 0; i < inputs.length; i++){
    	if (outputs[i].value == ""){
        if (inputs[i].innerText.split('\n')[0].length < 20){
          var truncate = inputs[i].innerText.split('\n')[0].slice(0,20)
        } else {
          var truncate = inputs[i].innerText.split('\n')[0].slice(0,40) + '...'
        }
        if (type[i].innerText == "Recette") {
          outputs[i].value = truncate
        } else {
    		  outputs[i].value = truncate + " - " + inputs[i].innerText.split('\n')[1]
        }
    	}
    }
  }
}
