import $ from 'jquery';

const addActiveToRecipeType = () => {



  $(".recipe-type-choice").click(function(){
    $(this).toggleClass("active");
  });

  // const saleButtonLabel = document.getElementById('recipe_for_sale_label');
  // const saleButton = document.getElementById('recipe_for_sale');
  // const recipettcprice = document.getElementById('recipe_restaurant_recipes_attributes_0_price_per_plate');
  // if (saleButtonLabel && saleButton && recipettcprice){
  //   if (recipettcprice.value > 0){
  //     $(saleButtonLabel).toggleClass("active");
  //     saleButton.checked = true;
  //   }
  //   console.log(recipettcprice.value, saleButton.checked);
  // }

  // const saleButton = document.getElementById('recipe_for_sale');
  // const saleButtonLabel = document.getElementById('recipe_for_sale_label');
  // $(saleButtonLabel).click(function(){
  //   console.log("clicked");
  //   // saleButton.checked = true;
  //   console.log(saleButton.checked);
  // });



  // const Buttons = document.querySelectorAll(".recipe-type-choices");



  // if (saleButton){
  //   console.log(saleButton.value);
  //   console.log(saleButton.checked);
  // }
}

export {addActiveToRecipeType};
