const productDirectSaleSubmit = () => {

  const product_direct_sale_form = document.getElementById('edit_directsale');

  if (product_direct_sale_form){

    const product_direct_sale = product_direct_sale_form.querySelector(".form-check");
    const product_direct_sale_input = product_direct_sale.children;

    if (product_direct_sale, product_direct_sale_input){
      product_direct_sale.addEventListener("click", (event) => {
        product_direct_sale_form.submit();
      });
    }
  }
}

export {productDirectSaleSubmit};
