import {totalorder} from "../components/compute_total_order.js";

// ATTENTION A BIEN ENVOYER 2 CHIFFRES APRES LA VIRGULE POUR LES MONTANTS EN EURO SINON MAUVAIS CALCUL SUR LE REF COST
const minusPlusButtonOrder = () => {

  const color = (inputorder) => {
    const qty_ordered = document.getElementById(`qty_ordered-${inputorder.dataset.orderitemid}`);
    if(qty_ordered){
      var qty_orderedValue = parseFloat(qty_ordered.innerHTML);
      var qtyPieceInput = document.getElementById(`supplier_item_piece_nb-${inputorder.dataset.orderitemid}`);
      var orderItemTotalSize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`);
      if (inputorder.value != qty_orderedValue ) {
        inputorder.style.backgroundColor = "#fd101521";
        qtyPieceInput.style.backgroundColor = "#fd101521";
        if (orderItemTotalSize.value) {
          orderItemTotalSize.style.backgroundColor = "#fd101521";
        }
      } else {
        inputorder.style.backgroundColor = "#FCFCFC";
        qtyPieceInput.style.backgroundColor = "#FCFCFC";
        if (orderItemTotalSize.value) {
          orderItemTotalSize.style.backgroundColor = "#FCFCFC";
        }
      }
    }
  };

  const computeGapOrder = (element, gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, sizebefore = 0, sizeinput = 0, supplieritemsize = 0) => {
    var priceEffect, volEffect, gapTotal
    // var priceEffect = (parseFloat(condpricemodal.innerHTML) - condpricebefore) * parseFloat(qty)
    // var volEffect = (qty - qty_ordered ) * condpricebefore
    // var gapTotal = volEffect + priceEffect
    gapOrder.innerHTML = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML)).toFixed(2);

    if(qty == 0) {
      priceEffect = 0
      volEffect = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML))
    } else {
      priceEffect = ((parseFloat(condpricemodal.innerHTML) / supplieritemsize) - (condpricebefore / supplieritemsize)) * sizebefore
      volEffect = (parseFloat(condpricemodal.innerHTML) / supplieritemsize) * (sizeinput - sizebefore )
    }
    gapTotal = (volEffect + priceEffect).toFixed(2)

    if (negociatedPrice.dataset.negociatedPrice == "true") {
      console.log("Supplier with negociatedPrice")
      priceEffect = ((condpricebefore / supplieritemsize) - (parseFloat(condpricemodal.innerHTML) / supplieritemsize)) * sizebefore
      volEffect = (condpricebefore / supplieritemsize) * (sizeinput - sizebefore )
      gapTotal = (volEffect + priceEffect).toFixed(2)
      gapOrder.innerHTML = gapTotal
    }

    var table = []
    table["element"] = element;
    table["negociatedPrice"] = negociatedPrice.innerHTML;
    table["gapOrder"] = gapOrder.innerHTML;
    table["qty_ordered"] = qty_ordered
    table["qty"] = qty;
    table["sizebefore"] = sizebefore
    table["sizeinput"] = sizeinput
    table["supplieritemsize"] = supplieritemsize
    table["priceCondBefore"] = condpricebefore;
    table["priceCondAfter"] = parseFloat(condpricemodal.innerHTML);
    table["volEffect"] = volEffect.toFixed(2);
    table["priceEffect"] = priceEffect.toFixed(2);
    table["Total"] = (volEffect + priceEffect).toFixed(2);
    console.table(table)

    gapOrderInput.value = gapTotal
    priceEffectInput.value = priceEffect.toFixed(2)
    qtyEffectInput.value = volEffect.toFixed(2)
  }

  const valueQtyOrders = document.querySelectorAll('.quantityinputorder');
  valueQtyOrders.forEach((valueQtyOrder) => {
    var inputorder = valueQtyOrder
    const itemprice = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`);
    const itempricevalue = parseFloat(itemprice.innerHTML);
    const subtotalorder = document.getElementById(`product-price-${inputorder.dataset.orderitemid}`);
    let currentValueOrder = parseFloat(inputorder.value);
    var qtyPieceInput = document.getElementById(`supplier_item_piece_nb-${inputorder.dataset.orderitemid}`);
    var qty_received = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
    var orderItemTotalSize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`);

    if (orderItemTotalSize) {
      if (orderItemTotalSize.innerHTML){
        var qtyTotalSizeBefore = parseFloat(orderItemTotalSize.innerHTML)
      }
      else {
        var qtyTotalSizeBefore = orderItemTotalSize.value
      }
    }
    if (qty_received){
      if (qty_received.innerHTML) {
        var qty_receivedBefore = parseFloat(qty_received.innerHTML);
      }
      else {
        var qty_receivedBefore = parseFloat(qty_received.value);
      }
    } else {
      var qty_received = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
      var qty_receivedBefore = parseFloat(qty_received.value);
    }
    if (qtyPieceInput) {
      if (qtyPieceInput.innerHTML){
        var qtyPieceInputBefore = parseFloat(qtyPieceInput.innerHTML)
      }
      else {
        var qtyPieceInputBefore = parseFloat(qtyPieceInput.value)
      }
    }
    //const coefTotalSize = qtyTotalSizeBefore / qtyPieceInputBefore

    inputorder.value = currentValueOrder;
    const valeursubtotal = itempricevalue * currentValueOrder;
    //subtotalorder.innerHTML = valeursubtotal.toFixed(2);
    const totalprice = document.getElementById(`totalprice-${inputorder.dataset.orderitemid}`);
    if(totalprice){
      //totalprice.value = valeursubtotal.toFixed(2);
    }
    const order_id = inputorder.dataset.orderid;
    totalorder(order_id);
    color(inputorder);

    valueQtyOrder.addEventListener('keyup', (event) => {
      var inputorder = valueQtyOrder
      const itemprice = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`);
      const itempricevalue = parseFloat(itemprice.innerHTML);
      const subtotalorder = document.getElementById(`product-price-${inputorder.dataset.orderitemid}`);
      const subtotalOrderDefault = document.getElementById(`subtotal-order-default-${inputorder.dataset.orderitemid}`);
      const gapOrder = document.getElementById(`product-gap-${inputorder.dataset.orderitemid}`);
      const negociatedPrice = document.getElementById(`total-order-gap-validate-${inputorder.dataset.orderid}`);
      const gapOrderInput = document.getElementById(`gap-order-item-${inputorder.dataset.orderitemid}`);
      const priceEffectInput = document.getElementById(`price-effect-${inputorder.dataset.orderitemid}`);
      const qtyEffectInput = document.getElementById(`qty-effect-${inputorder.dataset.orderitemid}`);
      let currentValueOrder = parseFloat(inputorder.value);
      const supplieritemsize = parseFloat(document.getElementById(`supplier_item_size-${inputorder.dataset.orderitemid}`).innerHTML);
      const supplier_item_piece_nb_initial = parseFloat(document.getElementById(`supplier_item_piece_nb-initial-${inputorder.dataset.orderitemid}`).innerHTML)
      const qty_ordered_element = document.getElementById(`qty_ordered-${inputorder.dataset.orderitemid}`);
      if (qty_ordered_element) {var qty_ordered = parseFloat(qty_ordered_element.innerHTML);}
      const supplier_item_piece_nb_initial_element = document.getElementById(`supplier_item_piece_nb-initial-${inputorder.dataset.orderitemid}`)
      const supplier_item_piece_nb_initialqty_ordered_zero = supplier_item_piece_nb_initial_element.dataset.computepiece
      const itempriceInitial = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`).dataset.initialPrice;
      const quantity = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
      const supplieritemcoef = document.getElementById(`supplier_item_coef-${inputorder.dataset.orderitemid}`);
      const condpricebefore = itempriceInitial * supplieritemsize * parseFloat(supplieritemcoef.innerHTML);
      const condprice = itempricevalue;
      const condpricemodal = itemprice;
      //const qtysizebefore = parseFloat(document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`).dataset.sizeOrder);
      var qtyitemsize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`)
      var qtysizebefore = qty_ordered * supplieritemsize

      if (inputorder.value == "") {
      } else {
        qtyPieceInput.value = (qtyPieceInputBefore / qty_receivedBefore * currentValueOrder).toFixed(1)
      }

      if(qtyPieceInputBefore == "" && qty_ordered != 0){
        qtyPieceInput.value = supplier_item_piece_nb_initial / qty_ordered * currentValueOrder
      } else if (qtyPieceInputBefore == "" && qty_ordered == 0) {
        qtyPieceInput.value = supplier_item_piece_nb_initialqty_ordered_zero * currentValueOrder
      } else {
        qtyPieceInput.value = qtyPieceInputBefore / qty_receivedBefore * currentValueOrder.toFixed(1)
      }

      if (orderItemTotalSize.innerHTML) {
        orderItemTotalSize.innerHTML = ((supplieritemsize * currentValueOrder)).toFixed(3) + " " + orderItemTotalSize.innerText.split(" ").pop()
      } else {
        orderItemTotalSize.value = (supplieritemsize * currentValueOrder).toFixed(3);
      }
      const orderItemTotalSizeInput = document.getElementById(`order_item_total_size_input-${inputorder.dataset.orderitemid}`);

      if (orderItemTotalSizeInput) {
        orderItemTotalSizeInput.value = (supplieritemsize * currentValueOrder).toFixed(3);
      }
      //console.log(orderItemTotalSizeInput.value)
      inputorder.value = currentValueOrder;

/*      var table = []
      console.table("qty order input")
      table["order item id"] = inputorder.dataset.orderitemid
      table["qtyTotalSizeBefore"] = qtyTotalSizeBefore
      table["qty_orderedBefore"] = qty_orderedBefore
      table["coefTotalSize"] = coefTotalSize
      table["input order value"] = inputorder.value
      table["orderItemTotalSize value"] = orderItemTotalSize.value
      console.table(table)
      console.log(`----------------`)*/

      const valeursubtotal = itempricevalue * currentValueOrder;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);

      if (quantity.value) {
        var qty = quantity.value
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }

      var qtyitemsizeDivHtml = document.getElementById(`order_item_total_size_no_input-${inputorder.dataset.orderitemid}`)

      if(qtyitemsizeDivHtml) {
        qtyitemsize = parseFloat(qtyitemsizeDivHtml.innerHTML)
      } else if(qtyitemsize.value){
        qtyitemsize = parseFloat(qtyitemsize.value)
      } else {
        qtyitemsize = parseFloat(qtyitemsize.innerHTML)
      }

      var element = "valueQtyOrder"
      if (gapOrder) {
        computeGapOrder(element, gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, qtysizebefore, qtyitemsize, supplieritemsize)
        // gapOrder.innerHTML = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML)).toFixed(2);
        // if (negociatedPrice.dataset.negociatedPrice == "true") {
        //   gapOrder.innerHTML = - gapOrder.innerHTML
        // }
      }
      const totalprice = document.getElementById(`totalprice-${inputorder.dataset.orderitemid}`);
      if(totalprice){
        totalprice.value = valeursubtotal.toFixed(2);
      }
      const order_id = inputorder.dataset.orderid;
      totalorder(order_id);
      color(inputorder);
    });
  });

  const minusButtonsOrders = document.querySelectorAll('#minusbuttonorder');
  minusButtonsOrders.forEach((minusButtonOrder) => {
    var inputorder = minusButtonOrder.nextElementSibling.firstChild;
    var qty_received = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
    var qtyPieceInput = document.getElementById(`supplier_item_piece_nb-${inputorder.dataset.orderitemid}`);
    var orderItemTotalSize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`);
    if (orderItemTotalSize) {
      if (orderItemTotalSize.innerHTML){
        var qtyTotalSizeBefore = parseFloat(orderItemTotalSize.innerHTML)
      }
      else {
        var qtyTotalSizeBefore = orderItemTotalSize.value
      }
    }
    if (qty_received){
      if (qty_received.innerHTML) {
        var qty_receivedBefore = parseFloat(qty_received.innerHTML);
      }
      else {
        var qty_receivedBefore = parseFloat(qty_received.value);
      }
    } else {
      var qty_received = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
      var qty_receivedBefore = parseFloat(qty_received.value);
    }
    if (qtyPieceInput) {
      if (qtyPieceInput.innerHTML){
        var qtyPieceInputBefore = parseFloat(qtyPieceInput.innerHTML)
      }
      else {
        var qtyPieceInputBefore = parseFloat(qtyPieceInput.value)
      }
    }
    //const coefTotalSize = qtyTotalSizeBefore / qtyPieceInputBefore

    minusButtonOrder.addEventListener('click', (event) => {
      var inputorder = minusButtonOrder.nextElementSibling.firstChild;
      if (inputorder.dataset.orderitemid == null){
        var inputorder = minusButtonOrder.nextElementSibling[2];
      }
      const itemprice = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`);
      const itempricevalue = parseFloat(itemprice.innerHTML);
      const subtotalorder = document.getElementById(`product-price-${inputorder.dataset.orderitemid}`);
      const subtotalOrderDefault = document.getElementById(`subtotal-order-default-${inputorder.dataset.orderitemid}`);
      const gapOrder = document.getElementById(`product-gap-${inputorder.dataset.orderitemid}`);
      const negociatedPrice = document.getElementById(`total-order-gap-validate-${inputorder.dataset.orderid}`);
      const gapOrderInput = document.getElementById(`gap-order-item-${inputorder.dataset.orderitemid}`);
      const priceEffectInput = document.getElementById(`price-effect-${inputorder.dataset.orderitemid}`);
      const qtyEffectInput = document.getElementById(`qty-effect-${inputorder.dataset.orderitemid}`);
      let currentValueOrder = parseFloat(inputorder.value);
      const supplieritemsize = parseFloat(document.getElementById(`supplier_item_size-${inputorder.dataset.orderitemid}`).innerHTML);
      const supplier_item_piece_nb_initial = parseFloat(document.getElementById(`supplier_item_piece_nb-initial-${inputorder.dataset.orderitemid}`).innerHTML)
      const qty_ordered_element = document.getElementById(`qty_ordered-${inputorder.dataset.orderitemid}`);
      if (qty_ordered_element) {var qty_ordered = parseFloat(qty_ordered_element.innerHTML);}
      const supplier_item_piece_nb_initial_element = document.getElementById(`supplier_item_piece_nb-initial-${inputorder.dataset.orderitemid}`)
      const supplier_item_piece_nb_initialqty_ordered_zero = supplier_item_piece_nb_initial_element.dataset.computepiece
      const itempriceInitial = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`).dataset.initialPrice;
      const quantity = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
      const supplieritemcoef = document.getElementById(`supplier_item_coef-${inputorder.dataset.orderitemid}`);
      const condpricebefore = itempriceInitial * supplieritemsize * parseFloat(supplieritemcoef.innerHTML);
      const condprice = itempricevalue;
      const condpricemodal = itemprice;
      //const qtysizebefore = parseFloat(document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`).dataset.sizeOrder);
      var qtyitemsize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`)
      var qtysizebefore = qty_ordered * supplieritemsize

      if (Number.isInteger(currentValueOrder)) {
        if (currentValueOrder > 0) {
          currentValueOrder = currentValueOrder - 1;
        } else {
          currentValueOrder = 0;
        }
      } else {
        currentValueOrder = Math.floor(currentValueOrder);
      }

      if(qtyPieceInputBefore == "" && qty_ordered != 0){
        qtyPieceInput.value = supplier_item_piece_nb_initial / qty_ordered * currentValueOrder
      } else if (qtyPieceInputBefore == "" && qty_ordered == 0) {
        qtyPieceInput.value = supplier_item_piece_nb_initialqty_ordered_zero * currentValueOrder
      } else {
        qtyPieceInput.value = qtyPieceInputBefore / qty_receivedBefore * currentValueOrder
      }

      if (orderItemTotalSize.innerHTML) {
        orderItemTotalSize.innerHTML = ((supplieritemsize * currentValueOrder)).toFixed(3) + " " + orderItemTotalSize.innerText.split(" ").pop()
      } else {
        orderItemTotalSize.value = (supplieritemsize * currentValueOrder).toFixed(3)
      }

      const orderItemTotalSizeInput = document.getElementById(`order_item_total_size_input-${inputorder.dataset.orderitemid}`);

      if (orderItemTotalSizeInput) {
        orderItemTotalSizeInput.value = (supplieritemsize * currentValueOrder).toFixed(3);
      }
      inputorder.value = currentValueOrder;
      // console.log(inputorder.value)
      const valeursubtotal = itempricevalue * currentValueOrder;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);


      if (quantity.value) {
        var qty = quantity.value
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }

      var qtyitemsizeDivHtml = document.getElementById(`order_item_total_size_no_input-${inputorder.dataset.orderitemid}`)

      if(qtyitemsizeDivHtml) {
        qtyitemsize = parseFloat(qtyitemsizeDivHtml.innerHTML)
      } else if(qtyitemsize.value){
        qtyitemsize = parseFloat(qtyitemsize.value)
      } else {
        qtyitemsize = parseFloat(qtyitemsize.innerHTML)
      }

      var element = "minusButtonOrder"
      if (gapOrder) {
        computeGapOrder(element, gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, qtysizebefore, qtyitemsize, supplieritemsize)
        // gapOrder.innerHTML = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML)).toFixed(2);
        // if (negociatedPrice.dataset.negociatedPrice == "true") {
        //   //gapOrder.innerHTML = - gapOrder.innerHTML
        // }
      }
      const totalprice = document.getElementById(`totalprice-${inputorder.dataset.orderitemid}`);
      if(totalprice){
        totalprice.value = valeursubtotal.toFixed(2);
      }
      const order_id = inputorder.dataset.orderid;
      totalorder(order_id);
      color(inputorder);
    });
  });

  const plusButtonsOrders = document.querySelectorAll('#plusbuttonorder');
  plusButtonsOrders.forEach((plusButtonOrder) => {
    var inputorder = plusButtonOrder.previousElementSibling.firstChild;
    var qty_received = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
    var qtyPieceInput = document.getElementById(`supplier_item_piece_nb-${inputorder.dataset.orderitemid}`);
    var orderItemTotalSize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`);
    if (orderItemTotalSize) {
    if (orderItemTotalSize.innerHTML){
        var qtyTotalSizeBefore = parseFloat(orderItemTotalSize.innerHTML)
      }
      else {
        var qtyTotalSizeBefore = orderItemTotalSize.value
      }
    }
    if (qty_received){
      if (qty_received.innerHTML) {
        var qty_receivedBefore = parseFloat(qty_received.innerHTML);
      }
      else {
        var qty_receivedBefore = parseFloat(qty_received.value);
      }
    } else {
      var qty_received = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
      var qty_receivedBefore = parseFloat(qty_received.value);
    }
    if (qtyPieceInput) {
      if (qtyPieceInput.innerHTML){
        var qtyPieceInputBefore = parseFloat(qtyPieceInput.innerHTML)
      }
      else {
        var qtyPieceInputBefore = parseFloat(qtyPieceInput.value)
      }
    }
    // const coefTotalSize = qtyTotalSizeBefore / qtyPieceInputBefore

    plusButtonOrder.addEventListener('click', (event) => {
      var inputorder = plusButtonOrder.previousElementSibling.firstChild;
      if (inputorder.dataset.orderitemid == null){
        var inputorder = plusButtonOrder.previousElementSibling[2];
      }
      const itemprice = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`);
      const itempricevalue = parseFloat(itemprice.innerHTML);
      const subtotalorder = document.getElementById(`product-price-${inputorder.dataset.orderitemid}`);
      const subtotalOrderDefault = document.getElementById(`subtotal-order-default-${inputorder.dataset.orderitemid}`);
      const gapOrder = document.getElementById(`product-gap-${inputorder.dataset.orderitemid}`);
      const negociatedPrice = document.getElementById(`total-order-gap-validate-${inputorder.dataset.orderid}`);
      const gapOrderInput = document.getElementById(`gap-order-item-${inputorder.dataset.orderitemid}`);
      const priceEffectInput = document.getElementById(`price-effect-${inputorder.dataset.orderitemid}`);
      const qtyEffectInput = document.getElementById(`qty-effect-${inputorder.dataset.orderitemid}`);
      let currentValueOrder = parseFloat(inputorder.value);
      const supplieritemsize = parseFloat(document.getElementById(`supplier_item_size-${inputorder.dataset.orderitemid}`).innerHTML)
      const supplier_item_piece_nb_initial = parseFloat(document.getElementById(`supplier_item_piece_nb-initial-${inputorder.dataset.orderitemid}`).innerHTML)
      const qty_ordered_element = document.getElementById(`qty_ordered-${inputorder.dataset.orderitemid}`);
      if (qty_ordered_element) {var qty_ordered = parseFloat(qty_ordered_element.innerHTML);}
      const supplier_item_piece_nb_initial_element = document.getElementById(`supplier_item_piece_nb-initial-${inputorder.dataset.orderitemid}`)
      const supplier_item_piece_nb_initialqty_ordered_zero = supplier_item_piece_nb_initial_element.dataset.computepiece
      const itempriceInitial = document.getElementById(`itemprice-${inputorder.dataset.orderitemid}`).dataset.initialPrice;
      const quantity = document.getElementById(`quantityinputorder-${inputorder.dataset.orderitemid}`);
      const supplieritemcoef = document.getElementById(`supplier_item_coef-${inputorder.dataset.orderitemid}`);
      const condpricebefore = itempriceInitial * supplieritemsize * parseFloat(supplieritemcoef.innerHTML);
      const condprice = itempricevalue;
      const condpricemodal = itemprice;
      //const qtysizebefore = parseFloat(document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`).dataset.sizeOrder);
      var qtyitemsize = document.getElementById(`order_item_total_size-${inputorder.dataset.orderitemid}`)
      var qtysizebefore = qty_ordered * supplieritemsize

      if (Number.isInteger(currentValueOrder)) {
        currentValueOrder = currentValueOrder + 1;
      } else {
        currentValueOrder = Math.ceil(currentValueOrder);
      }

      if(qtyPieceInputBefore == "" && qty_ordered != 0){
        qtyPieceInput.value = supplier_item_piece_nb_initial / qty_ordered * currentValueOrder
      } else if (qtyPieceInputBefore == "" && qty_ordered == 0) {
        qtyPieceInput.value = supplier_item_piece_nb_initialqty_ordered_zero * currentValueOrder
      } else {
        qtyPieceInput.value = qtyPieceInputBefore / qty_receivedBefore * currentValueOrder
      }

      if (orderItemTotalSize.innerHTML) {
        orderItemTotalSize.innerHTML = ((supplieritemsize * currentValueOrder)).toFixed(3) + " " + orderItemTotalSize.innerText.split(" ").pop()
      } else {
        orderItemTotalSize.value = (supplieritemsize * currentValueOrder).toFixed(3)
      }

      const orderItemTotalSizeInput = document.getElementById(`order_item_total_size_input-${inputorder.dataset.orderitemid}`);

      if (orderItemTotalSizeInput) {
        orderItemTotalSizeInput.value = (supplieritemsize * currentValueOrder).toFixed(3);
      }

      inputorder.value = currentValueOrder;

      // console.log(inputorder.value)
      const valeursubtotal = itempricevalue * currentValueOrder;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);

      if (quantity.value) {
        var qty = quantity.value
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }

      var qtyitemsizeDivHtml = document.getElementById(`order_item_total_size_no_input-${inputorder.dataset.orderitemid}`)

      if(qtyitemsizeDivHtml) {
        qtyitemsize = parseFloat(qtyitemsizeDivHtml.innerHTML)
      } else if(qtyitemsize.value){
        qtyitemsize = parseFloat(qtyitemsize.value)
      } else {
        qtyitemsize = parseFloat(qtyitemsize.innerHTML)
      }

      var element = "plusButtonOrder"
      if (gapOrder) {
        computeGapOrder(element, gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, qtysizebefore, qtyitemsize, supplieritemsize)
        // gapOrder.innerHTML = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML)).toFixed(2);
        // if (negociatedPrice.dataset.negociatedPrice == "true") {
        //   gapOrder.innerHTML = - gapOrder.innerHTML
        // }
      }
      const totalprice = document.getElementById(`totalprice-${inputorder.dataset.orderitemid}`);
      if(totalprice){
        totalprice.value = valeursubtotal.toFixed(2);
      }
      const order_id = inputorder.dataset.orderid;
      totalorder(order_id);
      color(inputorder);
    });
  });

  const supplierPriceInputs = document.querySelectorAll('.order-item-supplier-price-input');
  supplierPriceInputs.forEach((supplierPriceInput) => {

    supplierPriceInput.addEventListener('keyup', (event) => {
      const orderitemid = supplierPriceInput.dataset.orderitemid
      const orderid = supplierPriceInput.dataset.orderid
      const price = supplierPriceInput.dataset.price
      const inputprice = supplierPriceInput.value;
      const qty_ordered_for_reception = document.getElementById(`qty_ordered-${orderitemid}`)
      if (qty_ordered_for_reception != null) {
        var qty_ordered = parseFloat(document.getElementById(`qty_ordered-${orderitemid}`).innerHTML);
      } else {
        var qty_ordered = parseFloat(document.getElementById(`quantityinputorder-${orderitemid}`).value);
      }
      const supplieritemsize = document.getElementById(`supplier_item_size-${orderitemid}`);
      const supplieritemcoef = document.getElementById(`supplier_item_coef-${orderitemid}`);
      const condprice = inputprice * parseFloat(supplieritemsize.innerHTML) * parseFloat(supplieritemcoef.innerHTML);
      const condpricemodal = document.getElementById(`itemprice-${orderitemid}`)
      condpricemodal.innerHTML = condprice.toFixed(3) + " €";
      const quantity = document.getElementById(`quantityinputorder-${orderitemid}`);
      const subtotalorder = document.getElementById(`product-price-${orderitemid}`);
      const subtotalOrderDefault = document.getElementById(`subtotal-order-default-${orderitemid}`);
      const gapOrder = document.getElementById(`product-gap-${orderitemid}`);
      const negociatedPrice = document.getElementById(`total-order-gap-validate-${orderid}`);
      const gapOrderInput = document.getElementById(`gap-order-item-${orderitemid}`);
      const priceEffectInput = document.getElementById(`price-effect-${orderitemid}`);
      const qtyEffectInput = document.getElementById(`qty-effect-${orderitemid}`);
      var orderItemTotalSize = document.getElementById(`order_item_total_size-${orderitemid}`);
      const pricebysupplieritemsize = condprice / parseFloat(supplieritemsize.innerHTML);
      const supplierPriceUpdateAuto = document.getElementById(`supplier-price-update-${orderid}`)
      const verticalLine = document.getElementById(`vertical-line-${orderid}`)
      const itemprice = document.getElementById(`itemprice-${orderitemid}`);
      const itempricevalue = parseFloat(itemprice.innerHTML);
      const itempriceInitial = document.getElementById(`itemprice-${orderitemid}`).dataset.initialPrice;
      const condpricebefore = itempriceInitial * parseFloat(supplieritemsize.innerHTML) * parseFloat(supplieritemcoef.innerHTML);
      //if (qty_ordered_for_reception) {
        var qtysizebefore = qty_ordered * parseFloat(supplieritemsize.innerHTML)
       //}
      var qtyitemsize = document.getElementById(`order_item_total_size-${orderitemid}`)


      if (parseFloat(condpricemodal.innerHTML) != parseFloat(condpricebefore)) {
        supplierPriceUpdateAuto.style.display = 'flex'
        verticalLine ? verticalLine.style.display = 'inline-block' : ""
      } else {
        supplierPriceUpdateAuto.style.display = 'none'
        verticalLine ? verticalLine.style.display ='none' : ""
      }

      if (quantity.value) {
        var qty = quantity.value
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }
      if (orderItemTotalSize.innerText) {
        var orderItemTotalSizeConverted = parseFloat(orderItemTotalSize.innerText);
      } else {
        var orderItemTotalSizeConverted = orderItemTotalSize.value;
      }
      // var table = []
      // table["order item id"] = orderitemid;
      // table["price"] = price;
      // table["inputprice"] = inputprice;
      // table["condpricebefore"] = condpricebefore;
      // table["supplieritemsize"] = parseFloat(supplieritemsize.innerHTML);
      // table["condpricemodal"] = parseFloat(condpricemodal.innerHTML);
      // table["pricebysupplieritemsize"] = pricebysupplieritemsize;
      // table["orderItemTotalSize"] = orderItemTotalSizeConverted;
      // table["itempricevalue"] = itempricevalue;
      // console.table(table)
      // console.log(`----------------`)

      const valeursubtotal = pricebysupplieritemsize * orderItemTotalSizeConverted;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);

      var qtyitemsizeDivHtml = document.getElementById(`order_item_total_size_no_input-${orderitemid}`)

      if(qtyitemsizeDivHtml) {
        qtyitemsize = parseFloat(qtyitemsizeDivHtml.innerHTML)
      } else if(qtyitemsize.value){
        qtyitemsize = parseFloat(qtyitemsize.value)
      } else {
        qtyitemsize = parseFloat(qtyitemsize.innerHTML)
      }

      var element = "supplierPriceInput"
      if (gapOrder) {
        computeGapOrder(element, gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, qtysizebefore, qtyitemsize, parseFloat(supplieritemsize.innerHTML))
      }

      const totalprice = document.getElementById(`totalprice-${orderitemid}`);

      if(totalprice){
        totalprice.value = valeursubtotal.toFixed(2);
      }

      const order_id = supplierPriceInput.dataset.orderid;
      totalorder(order_id);
    });
  });

  const removeOrderItems = document.querySelectorAll('.removeitemorder');
  removeOrderItems.forEach((removeOrderItem) => {
    removeOrderItem.addEventListener('click', (event) => {
      const orderitemid = removeOrderItem.dataset.orderitemid
      const orderid = removeOrderItem.dataset.orderid
      const itemprice = document.getElementById(`itemprice-${removeOrderItem.dataset.orderitemid}`);
      const itempricevalue = parseFloat(itemprice.innerHTML);
      const supplieritemsize = document.getElementById(`supplier_item_size-${orderitemid}`);
      const supplieritemcoef = document.getElementById(`supplier_item_coef-${orderitemid}`);
      const condprice = parseFloat(supplieritemsize.innerHTML) * parseFloat(supplieritemcoef.innerHTML);
      const quantity = document.getElementById(`quantityinputorder-${orderitemid}`);
      const subtotalorder = document.getElementById(`product-price-${orderitemid}`);

      if (quantity.value) {
        var qty = quantity.value
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }

      const valeursubtotal = itempricevalue * qty;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);
      const order_id = removeOrderItem.dataset.orderid;
      totalorder(order_id);

      const total = document.getElementById(`total-order-validate-${orderid}`);
      if(parseFloat(total.lastChild.innerText.split(" ").join("")) == 0){
        alert("Etes vous sûr de vouloir supprimer toutes les lignes de la commande ? Si oui, veuillez plutôt supprimer la commande avec le bouton du haut.");
        removeOrderItem.checked = false;
        totalorder(order_id);
      }
    });
  });


  const qtyComputeSizeInputs = document.querySelectorAll('.order-item-order_item_total_size');
  qtyComputeSizeInputs.forEach((qtyComputeSizeInput) => {
    const qtysizebefore = qtyComputeSizeInput.value
    const orderitemid = qtyComputeSizeInput.dataset.orderitemid
    const qty_ordered = parseFloat(document.getElementById(`qty_ordered-${orderitemid}`).innerHTML);
    const quantityReceived = document.getElementById(`quantityinputorder-${orderitemid}`)
    const quantityReceivedBefore = quantityReceived.value

    qtyComputeSizeInput.addEventListener('keyup', (event) => {
      const orderitemid = qtyComputeSizeInput.dataset.orderitemid
      const inputprice = document.getElementById(`order_order_item_[${orderitemid}]_supplier_item_price`);
      const inputpricevalue = inputprice.value;
      const qtyitemsize = qtyComputeSizeInput.value;
      const supplieritemsize = parseFloat(document.getElementById(`supplier_item_size-${orderitemid}`).innerHTML);
      const supplieritemcoef = parseFloat(document.getElementById(`supplier_item_coef-${orderitemid}`).innerHTML);
      const condprice = inputpricevalue * supplieritemsize * supplieritemcoef;
      const condpricemodal = document.getElementById(`itemprice-${orderitemid}`)
      const qtyTransformed = parseFloat(quantityReceivedBefore) / (qtysizebefore / qtyitemsize);
      const pricebysupplieritemsize = condprice / supplieritemsize
      const order_id = qtyComputeSizeInput.dataset.orderid;
      const subtotalorder = document.getElementById(`product-price-${orderitemid}`);
      const subtotalOrderDefault = document.getElementById(`subtotal-order-default-${orderitemid}`);
      const gapOrder = document.getElementById(`product-gap-${orderitemid}`);
      const negociatedPrice = document.getElementById(`total-order-gap-validate-${order_id}`);
      const gapOrderInput = document.getElementById(`gap-order-item-${orderitemid}`);
      const priceEffectInput = document.getElementById(`price-effect-${orderitemid}`);
      const qtyEffectInput = document.getElementById(`qty-effect-${orderitemid}`);
      const itempriceInitial = document.getElementById(`itemprice-${orderitemid}`).dataset.initialPrice;
      const quantity = document.getElementById(`quantityinputorder-${orderitemid}`);
      const condpricebefore = itempriceInitial * supplieritemsize * supplieritemcoef;

      const qty_ordered = parseFloat(document.getElementById(`qty_ordered-${orderitemid}`).innerHTML);
      var sizebefore = qty_ordered * supplieritemsize
      //console.log(qtyitemsize) // poids saisie
      //console.log(qtysizebefore) // Poids cde
      //console.log(qty_ordered) // qté commandé
      //console.log(parseFloat(condpricemodal.innerHTML)) // Prix conditionnement apres changement de prix
      //console.log(supplieritemsize) // poids du supplier item
      //console.log((supplieritemsize * qty_ordered))
      //console.log(pricebysupplieritemsize) // Prix cond divisé par poids saisie
      //console.log(itempriceInitial) // Prix ref initial
      //console.log(condpricebefore) // Prix conditionnement avant changement de prix
      //console.log((inputpricevalue * (qtyitemsize - qtysizebefore )))

/*      var table = []
      console.table("order item total size")
      table["order item id"] = orderitemid
      table["Qty before"] = quantityReceivedBefore
      table["price"] = inputpricevalue
      table["cond price"] = parseFloat(condpricemodal.innerHTML)
      table["Qty size before"] = qtysizebefore
      table["Qty size"] = qtyitemsize
      table["supplier item coef"] = supplieritemcoef
      table["coef"] = qtysizebefore / qtyitemsize
      table["Size before"] = qtysizebefore
      table["Qty Transformed"] = qtyTransformed
      console.table(table)
      console.log(`----------------`)*/

      //quantityReceived.value = qtyTransformed.toFixed(2);


      const valeursubtotal = pricebysupplieritemsize * qtyitemsize;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);

      if (quantity.value) {
        var qty = parseFloat(quantity.value)
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }

      var element = "qtyComputeSizeInput"
      if (gapOrder) {
        computeGapOrder(element,gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, sizebefore, qtyitemsize, supplieritemsize)
        //gapOrder.innerHTML = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML)).toFixed(2);
        // if (negociatedPrice.dataset.negociatedPrice == "true") {
        //   gapOrder.innerHTML = - gapOrder.innerHTML
        // }
      }

      const totalprice = document.getElementById(`totalprice-${orderitemid}`);
      if(totalprice){
        totalprice.value = valeursubtotal.toFixed(2);
      }

      //totalorder(order_id);
      const valueQtyOrders = document.querySelectorAll('.quantityinputorder');
      valueQtyOrders.forEach((valueQtyOrder) => {
        var inputorder = valueQtyOrder
        color(inputorder);
        valueQtyOrder
      });
    });
  });


  const qtyPieceInputs = document.querySelectorAll('.order-item-supplier-item-piece-nb');
  qtyPieceInputs.forEach((qtyPieceInput) => {
    const orderitemid = qtyPieceInput.dataset.orderitemid

    const qtysizebefore = qtyPieceInput.value
    //const qtyComputeSizeInput = document.getElementById(`order_item_total_size-${orderitemid}`);
    const quantityReceived = document.getElementById(`quantityinputorder-${orderitemid}`)
    const quantityReceivedBefore = quantityReceived.value
    const supplierItemSize = document.getElementById(`supplier_item_size-${orderitemid}`);
    var orderItemTotalSize = document.getElementById(`order_item_total_size-${orderitemid}`);
    /*var qtyTotalSizeBefore = parseFloat(supplierItemSize.innerHTML) * parseFloat( qty_ordered.innerHTML)
    const coefTotalSize = qtyTotalSizeBefore / qtyPieceInput.value*/

    qtyPieceInput.addEventListener('keyup', (event) => {
      const orderitemid = qtyPieceInput.dataset.orderitemid
      const inputprice = document.getElementById(`order_order_item_[${orderitemid}]_supplier_item_price`);
      const inputpricevalue = inputprice.value;
      const qtyitemsize = qtyPieceInput.value;
      const supplieritemsize = parseFloat(document.getElementById(`supplier_item_size-${orderitemid}`).innerHTML);
      const supplieritemcoef = parseFloat(document.getElementById(`supplier_item_coef-${orderitemid}`).innerHTML);
      const condpricemodal = document.getElementById(`itemprice-${orderitemid}`)
      const supplier_item_piece_nb_initial = parseFloat(document.getElementById(`supplier_item_piece_nb-initial-${orderitemid}`).innerHTML)
      const qty_ordered = parseFloat(document.getElementById(`qty_ordered-${orderitemid}`).innerHTML);  
      const supplier_item_piece_nb_initial_element = document.getElementById(`supplier_item_piece_nb-initial-${orderitemid}`)
      const supplier_item_piece_nb_initialqty_ordered_zero = supplier_item_piece_nb_initial_element.dataset.computepiece

      if (quantityReceivedBefore == 0 && qty_ordered != 0){
        var qtyTransformed = qty_ordered / supplier_item_piece_nb_initial * qtyitemsize;
      } else if (quantityReceivedBefore == 0 && qty_ordered == 0) {
        var qtyTransformed = 1 / supplier_item_piece_nb_initialqty_ordered_zero * qtyitemsize;
      } else {
        var qtyTransformed = parseFloat(quantityReceivedBefore) / (qtysizebefore / qtyitemsize);
      }
     
      const orderItemTotalSizeInput = document.getElementById(`order_item_total_size_input-${orderitemid}`);
      if (orderItemTotalSizeInput) {
        orderItemTotalSizeInput.value = (supplieritemsize * qtyTransformed).toFixed(3);
      }

     /* var table = []
      table["order item id"] = orderitemid
      table["price"] = inputpricevalue
      table["Qty pieces"] = qtyitemsize
      table["Qty cde before"] = quantityReceivedBefore
      table["supplier item coef"] = supplieritemcoef
      table["coef"] = qtysizebefore / qtyitemsize
      table["Qty pièces before"] = qtysizebefore
      table["Qty cde Transformed"] = qtyTransformed
      console.table(table)
      console.log(`----------------`)*/

      quantityReceived.value = qtyTransformed;
      if (orderItemTotalSize.innerHTML) {
        orderItemTotalSize.innerHTML = ((supplieritemsize * qtyTransformed).toFixed(3)) + " " + orderItemTotalSize.innerText.split(" ").pop()
      } else {
        orderItemTotalSize.value = (supplieritemsize * qtyTransformed).toFixed(3);
      }
      const order_id = qtyPieceInput.dataset.orderid;
      const subtotalorder = document.getElementById(`product-price-${orderitemid}`);
      const subtotalOrderDefault = document.getElementById(`subtotal-order-default-${orderitemid}`);
      const gapOrder = document.getElementById(`product-gap-${orderitemid}`);
      const negociatedPrice = document.getElementById(`total-order-gap-validate-${order_id}`);
      const gapOrderInput = document.getElementById(`gap-order-item-${orderitemid}`);
      const priceEffectInput = document.getElementById(`price-effect-${orderitemid}`);
      const qtyEffectInput = document.getElementById(`qty-effect-${orderitemid}`);
      const itempriceInitial = document.getElementById(`itemprice-${orderitemid}`).dataset.initialPrice;
      const condpricebefore = itempriceInitial * supplieritemsize * supplieritemcoef;
      const quantity = document.getElementById(`quantityinputorder-${orderitemid}`);
      //const qtysizebeforeNew = parseFloat(document.getElementById(`order_item_total_size-${orderitemid}`).dataset.sizeOrder);
      var qtysizebeforeNew = qty_ordered * supplieritemsize

      const valeursubtotal = parseFloat(condpricemodal.innerHTML) * qtyTransformed;
      subtotalorder.innerHTML = valeursubtotal.toFixed(2);

      if (quantity.value) {
        var qty = parseFloat(quantity.value)
      } else {
        var qty = parseFloat(quantity.innerHTML)
      }

      var qtyitemsizeNew = document.getElementById(`order_item_total_size-${orderitemid}`)
      var qtyitemsizeDivHtml = document.getElementById(`order_item_total_size_no_input-${orderitemid}`)

      if(qtyitemsizeDivHtml) {
        console.log("first")
        qtyitemsizeNew = parseFloat(qtyitemsizeDivHtml.innerHTML)
      } else if(qtyitemsizeNew.value){
        console.log("Second")
        qtyitemsizeNew = parseFloat(qtyitemsizeNew.value)
      } else {
        console.log("third")
        qtyitemsizeNew = parseFloat(qtyitemsizeNew.innerHTML)
      }

      var element = "qtyPieceInput"
      if (gapOrder) {
        computeGapOrder(element,gapOrder, gapOrderInput, priceEffectInput, qtyEffectInput, negociatedPrice, condpricemodal, condpricebefore, qty, qty_ordered, subtotalorder, subtotalOrderDefault, qtysizebeforeNew, qtyitemsizeNew, supplieritemsize)

        // gapOrder.innerHTML = (subtotalorder.innerHTML - parseFloat(subtotalOrderDefault.innerHTML)).toFixed(2);
        // if (negociatedPrice.dataset.negociatedPrice == "true") {
        //   gapOrder.innerHTML = - gapOrder.innerHTML
        // }
      }
      const totalprice = document.getElementById(`totalprice-${orderitemid}`);
      if(totalprice){
        totalprice.value = valeursubtotal.toFixed(2);
      }

      totalorder(order_id);
      const valueQtyOrders = document.querySelectorAll('.quantityinputorder');
      valueQtyOrders.forEach((valueQtyOrder) => {
        var inputorder = valueQtyOrder
        color(inputorder);
        valueQtyOrder
      });
    });
  });


  function fadeIn(element, duration = 600) {
    element.style.display = '';
    element.style.opacity = 0;
    var last = +new Date();
    var tick = function() {
      element.style.opacity = +element.style.opacity + (new Date() - last) / duration;
      last = +new Date();
      if (+element.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      }
    };
    tick();
  }

  const orderNotes = document.querySelectorAll('.button-notes-view');
  if(orderNotes) {
    orderNotes.forEach((note) => {
      note.addEventListener('click', (event) => {
        const orderid = note.dataset.orderid
        const notesCardToDisplay = document.getElementById(`${orderid}-notes-card`);
        const buttonOrderNotesToDisplay = document.getElementById(`${orderid}-button-order-notes`);
        const buttonDisplayOrderNotes = document.getElementById(`${orderid}-order-button-display-notes`);

        if (notesCardToDisplay.style.display == "none"){
          fadeIn(notesCardToDisplay,600);
          fadeIn(buttonOrderNotesToDisplay,600);
          notesCardToDisplay.style.display = "block";
          buttonOrderNotesToDisplay.style.display = "block";
        } else {
          notesCardToDisplay.style.display = "none";
          buttonOrderNotesToDisplay.style.display = "none";
        }

        const buttonSentOrderNotes = document.getElementById(`${orderid}-button-order-notes`)

        buttonSentOrderNotes.addEventListener('mousedown', (event) => {
          const notes = document.getElementById(`order_order_notes_trix_input_order_${orderid}`)
          const valueQtyOrders = document.querySelectorAll('.quantityinputorder');
          var hash = {}
          var i = 0
          valueQtyOrders.forEach((valueQtyOrder) => {
            if (valueQtyOrder.dataset.orderid == orderid) {
              const inputorder = valueQtyOrder.value
              const itemprice = document.getElementById(`order_order_item_[${valueQtyOrder.dataset.orderitemid}]_supplier_item_price`);
              hash[i] = {}
              hash[i]["id"] = valueQtyOrder.dataset.orderitemid;
              hash[i]["qty_received"] = inputorder;
              hash[i]["supplier_item_price"] = itemprice.value;
              i++
            };
          });

          var link = document.getElementById(`${orderid}-button-order-notes`).children[0].href
          document.getElementById(`${orderid}-button-order-notes`).children[0].href = link + "&info_order=" + encodeURIComponent(notes.value) + "&order_items_attributes=" + JSON.stringify(hash)
        });

      });
    });
  }

}

export {minusPlusButtonOrder};
