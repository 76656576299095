import $ from 'jquery';

const pillsNavOrders = () => {

  // console.log(sessionStorage);
  if (sessionStorage.tabOuvert) {
    const url = window.location.href;
    const pillouvert = sessionStorage.getItem("tabOuvert");
    const pills = document.querySelectorAll(".tabs");

    pills.forEach((pill)=> {
      if (url.split("/")[url.split("/").length-1] == "orders") {
        if (pill.id == "pills-tab-to-validate"){
          pill.classList.add("active");
        } else {
        pill.classList.remove("active");
        }
      } else if(pill.id == pillouvert){
        pill.classList.add("active");
      } else {
        pill.classList.remove("active");
      }
    });
    $("#"+sessionStorage.tabOuvert).find("a").trigger("click");
  }

  $(".orders-icon").on("click", function() {
    sessionStorage.setItem("tabOuvert",'pills-tab-to-validate');
    const pills = document.querySelectorAll(".tabs");
    pills.forEach((pill)=> {
      if(pill.id == 'orders_to_validate'){
        pill.classList.add("active");
      } else {
        pill.classList.remove("active");
      }
    });
  });

  $(".orderstab").on("click", function() {
    //window.location.href = url
    //console.log("2")
    sessionStorage.setItem("tabOuvert",$(this).attr("id"));
    sessionStorage.setItem("url",window.location.href);
    // console.log(sessionStorage);
  });

  const ordersCreateConfirmModal = document.getElementById('confirm_orders_create');
  if(ordersCreateConfirmModal){
    ordersCreateConfirmModal.addEventListener('click', (event) => {
      sessionStorage.removeItem('tabOuvert');
    });
  }

}


export {pillsNavOrders};
