import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

const addBeginPayingSubscription = () => {

  const computeDate = (nb, date) => {
    var transform = new Date(date.value)
    var newDate = new Date(transform.setMonth(transform.getMonth()+parseInt(nb)))
    return newDate
  }

  const companyForm = document.querySelector(".container-company")

  if (companyForm) {

    var config = {
      allowInput: true,
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      "locale": French,
    }

    flatpickr("#company_subscribed_on", config);
    flatpickr("#company_paying_subscription_on", config);

    const subscriptionOn = document.getElementById('company_subscribed_on');
    const monthNb = document.getElementById('company_free_trial_months');
    const DatePayingOn = document.getElementById('company_paying_subscription_on');

    monthNb.addEventListener('change', (event) => {
      DatePayingOn._flatpickr.setDate(computeDate(monthNb.value, subscriptionOn))
    });

    subscriptionOn.addEventListener('change', (event) => {
      if (parseInt(monthNb.value) != 0) {
        DatePayingOn._flatpickr.setDate(computeDate(monthNb.value, subscriptionOn))
      }
    });
  }
}
export {addBeginPayingSubscription};
