import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import $ from 'jquery';
import 'select2';
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { French } from "flatpickr/dist/l10n/fr.js"
require('flatpickr/dist/plugins/monthSelect/style.css')
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau.js';
import {colorMargin} from "../components/colorMargin.js";
import {sortTable} from "../functions/sort.js";

Chart.defaults.global.plugins.colorschemes = {
  scheme: Tableau20,
  fillAlpha: 1,
  reverse: false,
  override: false
};

var chartStacked, chartLines, datasets, cost, qty, dates, start_date, end_date

function isMobileDevice(){
  return window.screen.width < 569;
}


const redurecArrayBySuppliers = (array) => {
  datasets = [];
   array.reduce(function(res, value) {
    if (!res[value.supplier]) {
      res[value.supplier] = { label: value.supplier, data: new Array(12) };
      datasets.push(res[value.supplier])
    }
    res[value.supplier].data[value.month-1] = value.amount.toFixed(1);
    return res;
  }, {});
  return datasets
}

const redurecArrayBySuppliersByMonthYear = (array) => {
  var arrayUniq = [...new Set(array.map( item => { return item.monthyear }))]
  datasets = [];
   array.reduce(function(res, value) {
    if (!res[value.supplier]) {
      res[value.supplier] = { label: value.supplier, data: new Array(arrayUniq.length) };
      datasets.push(res[value.supplier])
    }
    res[value.supplier].data[arrayUniq.indexOf(value.monthyear)] = value.amount.toFixed(1);
    return res;
  }, {});
  return datasets
}


const splitDataForLineChart = (array) => {
  cost = [];
  qty = [];
  dates = [];
  var options = {year: '2-digit', month: 'short', day: 'numeric' };
  array.forEach((element)=> {
    cost.push(parseFloat(element.cost).toFixed(2))
    qty.push(parseFloat(element.qty).toFixed(2))
    dates.push(new Date(Date.parse(element.day)).toLocaleDateString('fr-FR',options))
  })
  return cost, qty, dates
}

const splitPeriod = (period) => {
  start_date = ""
  end_date = ""
  if (period.includes('au')) {
    start_date = period.toString().split(' au ')[0];
    end_date = period.toString().split(' au ')[1];
  } else {
    start_date = period
    end_date = period
  }
  return [ new Date(start_date), new Date(end_date) ]
}

const initFlatpickr = () => {
  const month = document.querySelector('#orderpicker')
  if (month) {
    var dateCurrent = new Date();
    var dateConverted = dateCurrent.toISOString().split('T')[0]

    flatpickr('#orderpicker', {
      altInput: true,
      defaultDate: dateConverted,
      "locale": French,
      plugins: [ new monthSelectPlugin({
        shorthand: true,
        altFormat: "F Y",
        dateFormat: "Y-m-d"
      })],
      disableMobile: "true",
      onChange : function() {
        var monthSelected = month.value.split("-")
        var dateSelected = new Date(monthSelected[0], monthSelected[1]-1, monthSelected[2])
        const categories = document.querySelector('.select-category')
        var category = categories.options[categories.selectedIndex].value
        var elementType = categories.options[categories.selectedIndex].parentNode.label
        if (category == "Toutes Catégories") {
          category = ""
          elementType = ""
        }
      }
    });
  }

  const period = document.querySelector('#orderpicker2')
  if (period) {
    const orderAnalysisDiv = document.getElementById('order-analysis-div');

    flatpickr("#orderpicker2", {
      altInput: true,
      "locale": French,
      dateFormat: "Y-m-d",
      mode: "range",
      altFormat: "d/m/Y",
      onOpen : function() {
         period.classList.remove("calendar-close");
      },
      onClose : function(selectedDates, dateStr, instance) {
        const categories = document.querySelector('.select-category')
        var category = categories.options[categories.selectedIndex].value
        var elementType = categories.options[categories.selectedIndex].parentNode.label
        if (category == "Toutes Catégories") {
          category = ""
          elementType = ""
        }
        period.classList.add("calendar-close");
      },
    });
  }
}

const initChartStackedBar = (elements, start) => {
  const chart = document.getElementById("orders-stacked-bar")
  var ordersData = JSON.parse(chart.dataset.orders);
  var salesData = JSON.parse(chart.dataset.sales);

  if (elements) { var ordersData = elements }

  if (start instanceof Date) {
    var year = start.getFullYear();
  } else if (start === undefined){
    var year = new Date().getFullYear();
  } else if(start.includes("-")){
    var year = start.split('-')[0];
  }

  redurecArrayBySuppliers(ordersData)

  const data = {
    labels: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aout", "Sep", "Oct", "Nov", "Dec"],
    datasets: datasets
  };

  chartStacked = new Chart(chart, {
    type: 'bar',
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false,
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            callback: (value, index, values)=>{
              return value/1000+'k';
            }
          }
       }]
      },
      title: {
        display: true,
        text: `ACHATS PAR FOURNISSEUR (€HT) - ${year}`,
        padding: 20
      },
      layout: { padding: 20 },
      legend: { position: 'bottom' },
      tooltips: {
        displayColors: false,
        callbacks: {
          title: function(tooltipItem, all) {
            return [
              all.datasets[tooltipItem[0].datasetIndex].label
            ]
          },
          label: function(tooltipItem, all) {
            const datasetArray = [];
            all.datasets.forEach((dataset)=> {
              if (dataset.data[tooltipItem.index] != undefined) {
                datasetArray.push(parseFloat(dataset.data[tooltipItem.index]))
              } else {
                datasetArray.push(0)
              }
            })
            let sum = datasetArray.reduce((partialSum, a) => partialSum + a, 0);
            return ["Montant" + ': ' + all.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + "€ " + "(" + (tooltipItem.value * 100 / sum).toFixed(0) + "%" + ")",
            "% CA" + ": " + (salesData[tooltipItem.index] === undefined ? 0 : all.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] * 100 / salesData[tooltipItem.index].amount).toFixed(1) + "%"
            ]
          }
        }
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold'
          },
          formatter: (value, context) => {
            const datasetArray = [];
            context.chart.data.datasets.forEach((dataset)=> {
              if (dataset.data[context.dataIndex] != undefined) {
                datasetArray.push(parseFloat(dataset.data[context.dataIndex]))
              }
            })
            function totalSum(total, datapoint) {
              return total + datapoint;
            }
            var sum = datasetArray.reduce(totalSum, 0).toFixed(1);
            if(context.datasetIndex === (datasetArray.length - 1)) {
              return sum
            } else {
              return ''
            }
          }
        }
      }
    },
    plugins: [ChartDataLabels]
  });
}

const initChartLines = (elements, start) => {
  const chart = document.getElementById("orders-lines")
  var ordersData = JSON.parse(chart.dataset.orders);

  if (elements) { var ordersData = elements }

  if (start instanceof Date) {
    var year = start.getFullYear();
  } else if (start === undefined){
    var year = new Date().getFullYear();
  } else if(start.includes("-")){
    var year = start.split('-')[0];
  }

  splitDataForLineChart(ordersData)

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Coût moyen',
        type: 'line',
        data: cost,
        backgroundColor: 'rgba(255,26,104,0.2)',
        borderColor: 'rgba(255,26,104,1)',
        tension: 0.4,
        pointRadius: 5,
        pointBackgroundColor: 'rgba(255,255,255,1)',
        pointBorderColor: 'rgba(255,26,104,1)',
        borderWidth: 5,
        fill: false,
        yAxisID: 'A',
      },
      {
        label: 'Qté réceptionnée',
        type: 'bar',
        data: qty,
        backgroundColor: 'rgba(0,26,104,0.4)',
        borderColor: 'rgba(0,26,104,1)',
        tension: 0.4,
        maxBarThickness: 100,
        fill: false,
        yAxisID: 'B',
        datalabels: {
          color: 'blue',
          anchor: 'center',
          align: 'center',
          clip: true,
        },
      }
    ]
  };

  chartLines = new Chart(chart, {
    type: 'line',
    data: data,
    options: {
      maintainAspectRatio: false,
      onResize: function(chart, size) {
        if (size.width < 768) {
          chart.scales['A'].options.scaleLabel.display = false
          chart.scales['B'].options.scaleLabel.display = false
          chart.options.legend.display = true;
          //chart.options.legend.position = 'bottom'
        }
        chart.update();
      },
      title: {
        display: true,
        text: `COUT MOYEN / QTÉ RECEPTIONNÉE - ${year} (unités multiples)`,
        padding: 20
      },
      legend: {
        display: true
      },
      plugins: {
        datalabels: {
          align: 'top',
          offset: 10,
          backgroundColor: function(context) {
            if (context.dataset.backgroundColor == 'rgba(255,26,104,0.2)') {
              return 'rgba(255,26,104,0.4)';
            }
          },
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          formatter: (value, context) => {
            if (context.datasetIndex == 0) {
              return `${value} €`
            } else {
              return Math.round(value,1)
            }
          },
        },
      },
      scales: {
        xAxes: [{
          gridLines: { display: false },
          scaleLabel: { display: true, labelString: "Date de réception" },
          offset: true
        }],
        yAxes: [
          {
            id: 'A',
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: !isMobileDevice(),
              labelString: 'Coût moyen',
              fontColor: 'rgba(255,26,104,1)',
              fontSize: 16,

            },
          },
          {
            id: 'B',
            type: 'linear',
            position: 'right',
            scaleLabel: {
              display: !isMobileDevice(),
              labelString: 'Qté réceptionnée',
              fontColor: 'rgba(0,26,104,1)',
              fontSize: 16
            },
            gridLines: {
              display: true,
              drawOnChartArea: false,
            },
            ticks: {beginAtZero:true}
          }
        ]
      }
    },
  });
}

export default class extends Controller {
  static targets = ['category', 'restaurant', 'select', 'month', 'period', 'cards'];

  connect() {
    //initChartStackedBar();
    //initChartLines();
    $(this.categoryTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
    $(this.restaurantTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
    $(this.categoryTarget).on('select2:unselecting', function(e) {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
    $(this.restaurantTarget).on('select2:unselecting', function(e) {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
    initFlatpickr();
  }

  choice(e) {
    const linkActiveText = e.target.dataset.choice;
    const inputByMonth = document.querySelector('.input+.input')
    const inputByImport = document.querySelector('.input2+input')
    inputByMonth.classList.toggle("date-input-none");
    this.monthTarget.classList.toggle("active");
    inputByImport.classList.toggle("date-input-none");
    this.periodTarget.classList.toggle("active");
    this.get_data();
  }

  get_data() {
    var category, restaurant, start_date, end_date, choice, url
    category = this.categoryTarget.options[this.categoryTarget.selectedIndex].value
    restaurant = this.restaurantTarget.options[this.restaurantTarget.selectedIndex].value
    var close = document.querySelector("#orderpicker2").classList.contains('calendar-close');
    if (this.monthTarget.classList.contains('active')) {
      var monthSelected = this.monthTarget.value.split("-")
      var dateSelected = new Date(monthSelected[0], monthSelected[1]-1, 1)
      start_date = dateSelected
      end_date = dateSelected;
      choice = "month";
      close = true;
    } else {
      var periodSelected = this.periodTarget.value
      start_date = splitPeriod(periodSelected)[0]
      end_date = splitPeriod(periodSelected)[1]
      choice = "period"
    }
    
    if(!isNaN(start_date) && close){
      url = `/margin_analysis?margin[category]=${encodeURIComponent(category)}&margin[restaurant]=${encodeURIComponent(restaurant)}&margin[start_date]=${start_date}&margin[end_date]=${end_date}&margin[choice]=${choice}`

      fetch(url, {
        method: 'GET',
        headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
      })
      .then(response => response.json())
      .then((data) => {

        ////////// Update Cards ////////
        const cards = document.getElementById('sales-card-infos')
        cards.innerHTML = data[0]
        ////////// Update Table Conso ////////
        // const table = document.getElementById('margin-analysis-table')
        // table.innerHTML = data[1]
        ////////// Update Table By Restaurant ////////
        const table_restaurants = document.getElementById('margin-analysis-table-restaurants')
        table_restaurants.innerHTML = data[data.length - 1]
        colorMargin();
        sortTable();

      });
    }
  }
}
