//import $ from 'jquery';

const ComputeRecipeTvaInForm = () => {

  const recipeTva = document.getElementById('recipe_restaurant_recipes_attributes_0_tva_plate');
  const recipettcprice = document.getElementById('recipe_restaurant_recipes_attributes_0_price_per_plate');
  const recipehtprice = document.getElementById("recipehtprice");
  let prepsentence = ', soit';
  let postsentence = ' ';

  // Partie pour recipe form initiale factorisée
  const udapteRecipePriceWoTva = (recipeTva, recipettcprice, recipehtprice, prepsentence, postsentence) => {

    if (recipeTva && recipettcprice && recipehtprice){
      // console.log(prepsentence.value);
      // console.log(postsentence.value);


      recipeTva.addEventListener('change', (event) => {
        event.preventDefault();
        // console.log(recipeTva.value);
        // console.log(recipettcprice.value);

        // const recipehtprice = document.getElementById("recipehtprice");
        // console.log(recipehtprice);
        if (recipeTva.value >= 0 && recipettcprice.value >= 0){
          recipehtprice.innerHTML = `${prepsentence} ${ ( recipettcprice.value / (1 + (recipeTva.value / 100 )) ).toFixed(1) } ${ "€HT" } ${postsentence}`;
        }
        else{
          recipehtprice.innerHTML = `(- €HT )`;
        }
      });

      recipettcprice.addEventListener('change', (event) => {
        event.preventDefault();
        // console.log(recipeTva.value);
        // console.log(recipettcprice.value);

        // const recipehtprice = document.getElementById("recipehtprice");
        // console.log(recipehtprice);
        if (recipeTva.value >= 0 && recipettcprice.value >= 0){
          recipehtprice.innerHTML = `${ prepsentence} ${ ( recipettcprice.value / (1 + (recipeTva.value / 100 )) ).toFixed(1) } ${ "€HT" } ${postsentence}`;
        }
        else{
          recipehtprice.innerHTML = `(- €HT )`;
        }
      });

      if (recipeTva.value >= 0 && recipettcprice.value >= 0){
        recipehtprice.innerHTML = `${prepsentence} ${ ( recipettcprice.value / (1 + (recipeTva.value / 100 )) ).toFixed(1) } ${ "€HT" } ${postsentence}`;
      }
      else{
        recipehtprice.innerHTML = `( - €HT )`;
      }
    }
  }

  // Partie pour recipe form initiale factorisée
  udapteRecipePriceWoTva(recipeTva, recipettcprice, recipehtprice, prepsentence, postsentence);

  // Partie pour recipe form multi-établissements 136
  const multiRestContentForm = document.getElementById('display-multi-restaurants-recipe-form');

  if (multiRestContentForm) {

    const recipeRestaurantInputs = document.querySelectorAll('.recipe_multi_restaurants_id');
    recipeRestaurantInputs.forEach((input) => {
      let itemIndex = input.dataset.itemindex;
      let recipeTva = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_tva_plate`);
      let recipettcprice = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_price_per_plate`);
      let recipehtprice = document.getElementById(`recipehtprice-${itemIndex}`);
      let prepsentence = "(";
      let postsentence = ")";

      // console.log(linkedInput);
      // console.log(input.checked);
      // console.log(itemIndex);
      if (recipeTva && recipettcprice && recipehtprice) {
        if (input.checked == true) {
          udapteRecipePriceWoTva(recipeTva, recipettcprice, recipehtprice, prepsentence, postsentence);
        } else {
          recipehtprice.innerHTML = `( - €HT )`;
        }
        udapteRecipePriceWoTva(recipeTva, recipettcprice, recipehtprice, prepsentence, postsentence);
      }
      // console.log(linkedInput);
    });
    // Partie pour mise à jour de l'input uniforme en multi-établissements
    const recipeUniformTva = document.getElementById(`recipe_uniform_tva`);
    const recipeTtcUniformPrice = document.getElementById(`recipe_uniform_price`);
    const recipeUniformHtPrice = document.getElementById(`uniformrecipehtpricecompute`);
    let prepsentence = "(";
    let postsentence = ")";
    udapteRecipePriceWoTva(recipeUniformTva, recipeTtcUniformPrice, recipeUniformHtPrice, prepsentence, postsentence);

  }


}

export {ComputeRecipeTvaInForm};
