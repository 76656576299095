import $ from 'jquery';


const toggleValidation = (element) => {
	if (element.value == "" || element.value == 0) {
	  element.classList.add("is-invalid");
	  return true;
	} else {
	  element.classList.remove("is-invalid");
	  return false
	}
}

const toggleValidationEmpty = (element) => {
	if (element.value == "") {
	  element.classList.add("is-invalid");
	  return true;
	} else {
	  element.classList.remove("is-invalid");
	  return false
	}
}


const formOrderValidates = () => {
	const formAll = document.querySelectorAll(".edit_order");

	if (formAll) {
		formAll.forEach((form) => { 
		    form.addEventListener('submit', (event) => {
		    	// event.preventDefault();
		    	const id = form.id.split('_')[2]
		    	const supplier_item_cost = document.querySelectorAll(`.order-item-supplier-price-input[data-orderid='${id}']`);
		    	const quantities = document.querySelectorAll(`.quantityinputorder[data-orderid='${id}']`);
		    	const qtyPieces = document.querySelectorAll(`.order-item-supplier-item-piece-nb[data-orderid='${id}']`);
		    	const totalSize = document.querySelectorAll(`.order-item-order_item_total_size[data-orderid='${id}']`);

		    	var totalcount = 0;
				supplier_item_cost.forEach( (cost) => {
					if (toggleValidation(cost)) {
						totalcount += 1; 
					}
				});
				quantities.forEach( (qty) => {
					if (toggleValidationEmpty(qty)) {
						totalcount += 1; 
					}
				});
				qtyPieces.forEach( (qtyPiece) => {
					if (toggleValidationEmpty(qtyPiece)) {
						totalcount += 1; 
					}
				});
				totalSize.forEach( (qtyPiece) => {
					if (toggleValidationEmpty(qtyPiece)) {
						totalcount += 1; 
					}
				});
				
	   		if (totalcount > 0) {
	       	event.preventDefault();
	       	event.stopPropagation();
	      }

			});
			
		});
	}
}


export { formOrderValidates };
