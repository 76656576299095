import $ from 'jquery';

const pillsNavStocks = () => {
  const url = window.location.href;
  const pills = document.querySelectorAll(".tabs_stock");
  const pillouvert = sessionStorage.getItem("tabStockOuvert");
  
  if (sessionStorage.tabStockOuvert != undefined) {
    pills.forEach((pill)=> {
      if (url.split("/")[url.split("/").length-1] == "other_movements") {
      	//console.log("ok")
        if (pill.id == "pills-tab-others"){
          pill.classList.add("active");
        } else {
        pill.classList.remove("active");
        }
      } else if(pill.id == pillouvert){
        pill.classList.add("active");
      } else {
        pill.classList.remove("active");
      }
    });
  }

  if (window.location.href.indexOf('/inventory_list/productions') > -1) {
    document.getElementById('pills-tab-productions').classList.add("active")
    document.getElementById('pills-tab-others').classList.remove("active")
  }

  $(".tabs_stock").on("click", function() {
    sessionStorage.setItem("tabStockOuvert",$(this).attr("id"));
    sessionStorage.setItem("url",window.location.href);
  });

}

export {pillsNavStocks};
