import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['submit', 'orderBudget', 'budgetAmount', 'budgetPeriod', 'usersValidation'];

  connect() {
    if(this.hasUsersValidationTarget) {
      $(this.usersValidationTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });
    }
  }

  submit(e){
    if (this.orderBudgetTarget.checked) {
      var amount = parseFloat(this.budgetAmountTarget.value);
      var period = this.budgetPeriodTarget.value;
      var users = $(this.usersValidationTarget).val();
      if (isNaN(amount) || amount == 0.0 ) {
        this.budgetAmountTarget.classList.add('is-invalid')
        e.preventDefault();
      }
      if (period == "") {
        this.budgetPeriodTarget.classList.add('is-invalid')
        e.preventDefault();
      }
      if ($(this.usersValidationTarget).val().length < 1) {
        document.querySelector('.select2-selection--multiple').classList.add('has-error')
        e.preventDefault();
      }
    }
  }

  change(e) {
    var amount = parseFloat(this.budgetAmountTarget.value);
    var period = this.budgetPeriodTarget.value;
    var users = $(this.usersValidationTarget).val();

    if (isNaN(amount) || amount == 0 ) {
      this.budgetAmountTarget.classList.add('is-invalid')
    } else {
      this.budgetAmountTarget.classList.remove('is-invalid')
      this.budgetAmountTarget.classList.add('is-valid')
    }

    if(period == ""){
      this.budgetPeriodTarget.classList.add('is-invalid')
    } else {
      this.budgetPeriodTarget.classList.remove('is-invalid')
      this.budgetPeriodTarget.classList.add('is-valid')
    }

    if (users.length < 1) {
      document.querySelector('.select2-selection--multiple').classList.add('has-error')
      document.querySelector('.select2-selection--multiple').classList.remove('no-error')
    } else {
      document.querySelector('.select2-selection--multiple').classList.remove('has-error')
      document.querySelector('.select2-selection--multiple').classList.add('no-error')
    }
  }
}
