import $ from 'jquery';

const addValidationsToRecipeForm = () => {

  // Validation pour form new
  const recipeFormNew = document.querySelector('.simple_form.new_recipe');
  if (recipeFormNew) {
    validationsToRecipeForm(recipeFormNew);
  }

  // Validation pour form edit
  const recipeFormEdit = document.querySelector('.simple_form.edit_recipe');
  if (recipeFormEdit) {
    validationsToRecipeForm(recipeFormEdit);
  }
}

const validationsToRecipeForm = (recipeForm) => {
  if (recipeForm) {
    recipeForm.addEventListener('submit', (event) => {

      let countValidations = 0
      //1
      const recipeNameInput = document.getElementById('recipe_name');
      //2
      const recipeWithPortionInput = document.getElementById('recipe_with_portion');
      //3
      const portionNumberInput = document.getElementById('recipe_recipe_portions_attributes_0_quantity');
      const recipeCaliberFirstLine = document.getElementById('recipeportionssize');
      const withPortionSizeInput = document.getElementById('with_portion_size');
      const withNetSizeInput = document.getElementById('recipe_recipe_caliber_with_net_size');
      const withVarRateInput = document.getElementById('with_var_rate_field');
      const withGrossSizeInput = document.getElementById('recipe_recipe_caliber_with_gross_size');
      // 4
      const withPortionSizeNumberInput = document.getElementById('recipe_recipe_portions_attributes_0_size');
      const withNetSizeNumberInput = document.getElementById('recipe_size');
      const withVarRateNumberInput = document.getElementById('recipe_var_rate');
      //5
      const recipeSaleButton = document.getElementById('recipe_for_sale');
      const sellingPriceInput = document.getElementById('recipe_restaurant_recipes_attributes_0_price_per_plate');
      const sellingTvaInput = document.getElementById('recipe_restaurant_recipes_attributes_0_tva_plate');

      // 1. Name input
      //1.1 Validation Name input
      if(recipeNameInput.value == ""){
        recipeNameInput.classList.add("is-invalid");
        if (recipeNameInput.nextElementSibling == null || recipeNameInput.nextElementSibling.classList[0] != "invalid-feedback") {
         recipeNameInput.insertAdjacentHTML('afterend',
            '<div class="invalid-feedback">'
            +
            'Nom à renseigner'
            +
            '</div>')
        }
        countValidations += 1;
      }

      // 1.2Récupération après saisie
      if (recipeNameInput.classList[recipeNameInput.classList.length -1] == "is-invalid"){
        recipeNameInput.addEventListener("keyup", function() {
          if (recipeNameInput.value != ""){
            recipeNameInput.classList.remove("is-invalid");
          }
        });
      }

      // 2. Portion number input
      //2.1 Validation input
      if(recipeWithPortionInput.checked == true && ( portionNumberInput.value == "" || portionNumberInput.value <= 0 ) && portionNumberInput.readonly == null){
        portionNumberInput.classList.add("is-invalid");
        if (portionNumberInput.nextElementSibling == null || portionNumberInput.nextElementSibling.classList[0] != "invalid-feedback") {
         portionNumberInput.insertAdjacentHTML('afterend',
            '<div class="invalid-feedback">'
            +
            'Nombre de portions positif'
            +
            '</div>')
        }
        countValidations += 1;
      }

      //2.2 Récupération après saisie
      if (portionNumberInput.classList[portionNumberInput.classList.length -1] == "is-invalid"){
        portionNumberInput.addEventListener("keyup", function() {
          if (portionNumberInput.value != "" && portionNumberInput.value > 0){
            portionNumberInput.classList.remove("is-invalid");
          }
        });
      }

      // 3. Choisir au moins 1 option pour le calibre de la recette
      //3.1 Validations input
      if (withPortionSizeInput.checked == false && withNetSizeInput.checked == false && withVarRateInput.checked == false && withGrossSizeInput.checked == false){
        // console.log('inputs false')
        if (recipeCaliberFirstLine.previousElementSibling == null || recipeCaliberFirstLine.previousElementSibling.classList[0] != "invalid-feedback") {
          recipeCaliberFirstLine.insertAdjacentHTML('beforebegin',
            '<div id="recipe-caliber-validates-message" class="invalid-feedback" style="display: block; margin-left: 2em;">'
            +
            'Sélectionnez une option pour le calibre ci-dessous'
            +
            '</div>')
        }
        countValidations += 1;
      }
      //3.2 Validations input
      const caliberInputsArray = [withPortionSizeInput, withNetSizeInput, withVarRateInput, withGrossSizeInput]
      caliberInputsArray.forEach((input) => {
        input.addEventListener("change", (event) => {
          if(event.target.checked){
            const recipeCaliberMessage = document.getElementById('recipe-caliber-validates-message');
            if (recipeCaliberMessage){
              recipeCaliberMessage.remove();
            }
          }
        });
      });


      //4. Validates sur les options pour le calibre de la recette : si je choisis 1 option (ex: with_net_size), l'input ne doit pas rester vide
      // 4.1 Méthode applicable à chaque input

      const recipeCaliberinputValidates = (radiobutton, following_input, all_caliber_check_input) => {
        // 4.1.1 Validation input
        if (radiobutton.checked == true && ( following_input.value == "" || parseFloat(following_input.value) == 0)){
          following_input.classList.add("is-invalid");
          countValidations += 1;
        }
        // 4.1.2 Récupération après saisie
        if (following_input.classList[following_input.classList.length -1] == "is-invalid"){
          following_input.addEventListener("keyup", function() {
            if (following_input.value != "" || parseFloat(following_input.value) != 0){
              following_input.classList.remove("is-invalid");
            }
          });
          // 4.1.3 Retirer validates en cas de saisie d'un autre input (button) pour le calibre
          // // 4.1.3.1 Méthode pour retirer l'input sur lequel on est de la lsite de tous les caliber_check_inputs
          for (var i = all_caliber_check_input.length - 1; i >= 0; i--) {
            if(all_caliber_check_input[i] == radiobutton) {
                all_caliber_check_input.splice(i, 1);
            }
          }
          // 4.1.3.2 Retirer le validates quand autre input checké
          all_caliber_check_input.forEach((input) => {
            input.addEventListener("change", (event) => {
              if (event.target.checked){
                following_input.classList.remove("is-invalid");
              }
            });
          });
        }
      }

      let all_caliber_check_input = caliberInputsArray
      recipeCaliberinputValidates(withPortionSizeInput, withPortionSizeNumberInput, all_caliber_check_input);
      recipeCaliberinputValidates(withNetSizeInput, withNetSizeNumberInput, all_caliber_check_input);
      recipeCaliberinputValidates(withVarRateInput, withVarRateNumberInput, all_caliber_check_input);


      // 5. Si sélection avec vente, renseignement du prix ou de la TVA
      // 5.1 Factorisation méthode pour multi-établissements

      const recipeSellingInputValidates = (recipeSaleButton, sellingPriceInput, sellingTvaInput, style_price, placeholder, style_tva, tva_message, style_tva_feedback) => {

        if (recipeSaleButton.checked == true && ( (sellingPriceInput.value == "" || sellingPriceInput.value < 0)|| sellingTvaInput.value == "")){
            if (sellingPriceInput.value == "") {
              sellingPriceInput.classList.add("is-invalid");
              if (sellingPriceInput.nextElementSibling == null || sellingPriceInput.nextElementSibling.classList[0] != "invalid-feedback") {
               sellingPriceInput.style = style_price;
               sellingPriceInput.classList.remove("sale_price_field");
               sellingPriceInput.placeholder = placeholder;
               // const urlImageValidates = "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FD1015' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FD1015' stroke='none'/%3e%3c/svg%3e";
               // sellingPriceInput.style.backgroundImage = `url(${urlImageValidates}) !important`;
              }
            }

            if (sellingTvaInput.value == "") {
              sellingTvaInput.classList.add("is-invalid");
              if (sellingTvaInput.nextElementSibling == null || sellingTvaInput.nextElementSibling.classList[0] != "invalid-feedback") {
                sellingTvaInput.style = style_tva;
                sellingTvaInput.insertAdjacentHTML('afterend',
                  `<div class="invalid-feedback" style="${style_tva_feedback}">`//136
                  +
                  `${tva_message}`
                  +
                  '</div>')
              }
            }

            const SellingInputsArray = [[sellingPriceInput,"keyup"], [sellingTvaInput, "change"]]

            SellingInputsArray.forEach(function(input, index) {
              if (input[0].classList[input[0].classList.length -1] == "is-invalid"){
                input[0].addEventListener(input[1], function() {
                  if (input[0].value != ""){
                    input[0].classList.remove("is-invalid");
                    if (index == 0){
                      input[0].classList.add("sale_price_field");
                    }
                  }
                });
              }
            });

            countValidations += 1;
          }
        }


      //5.1 Validations input initiale
      const multiRestContent = document.getElementById('display-multi-restaurants-recipe-form');
      if (multiRestContent == null){
        let style_price = "width: 8.8em; margin: 4px; padding: 4px;";
        let placeholder = "Prix TTC positif";
        let style_tva = "";
        let tva_message = "TVA";
        let style_tva_feedback = "width: 2em;";
        recipeSellingInputValidates(recipeSaleButton, sellingPriceInput, sellingTvaInput, style_price, placeholder, style_tva, tva_message, style_tva_feedback);

      //5.2 Partie multi-établissements
      } else {
        // Partie pour recipe form multi-établissements 136
        const recipeRestaurantInputs = document.querySelectorAll('.recipe_multi_restaurants_id');
        recipeRestaurantInputs.forEach((input) => {
          let itemIndex = input.dataset.itemindex;
          let multiSellingPriceInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_price_per_plate`);
          let multiTvaPriceInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_tva_plate`);
          let style_price = "width: 7em; margin: 4px; padding: 2px;"
          let placeholder = "Prix TTC > 0";
          let style_tva = "margin-top: 4px; width: 4.2em;";
          let tva_message = "";
          let style_tva_feedback = "";

          if (multiSellingPriceInput && multiTvaPriceInput) {
            if (input.checked == true) {
              recipeSellingInputValidates(recipeSaleButton, multiSellingPriceInput, multiTvaPriceInput, style_price, placeholder, style_tva, tva_message);
            }
          }

        });

      }


      console.log(countValidations);

      if (countValidations > 0){
        event.preventDefault();
      }


    });
  }
}


export {addValidationsToRecipeForm};
