
const recipeActiveSaleSubmit = () => {

  const recipe_active_sale_form = document.getElementById('edit_active_sale');
  // const recipe_active_sale_form = recipe_active_sale_div.children.first;
  // console.log(recipe_active_sale_div);
  // console.log(recipe_active_sale_form);
  // console.log(recipe_active_sale);
  // console.log(recipe_active_sale_input);

  if (recipe_active_sale_form){
    const recipe_active_sale = recipe_active_sale_form.querySelector(".form-check");
      const recipe_active_sale_input = recipe_active_sale.children;

    if (recipe_active_sale, recipe_active_sale_input){


    recipe_active_sale.addEventListener("click", (event) => {

      console.log(recipe_active_sale);
      console.log(recipe_active_sale_form);
      console.log(recipe_active_sale_input);
      recipe_active_sale_form.submit();
    });
    }
  }
}

export {recipeActiveSaleSubmit};
