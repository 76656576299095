import $ from 'jquery';
import {clearSearchInputWoQuery} from "../components/clearSearchInputWoQuery.js";
import {htmlLoadingAnimation} from "../components/loadingAnimationHtml";

const nameProductSearch = document.getElementById('searchProductNameCartForm');
const categoryProductSearch = document.getElementById('searchProductCategoryCreate2');

if (nameProductSearch && categoryProductSearch) {
  const nameSearchInput = nameProductSearch.firstChild.nextElementSibling;
  const categorySearchInput = categoryProductSearch.firstChild.nextElementSibling;
}

const SearchFetchCartItem = (nameSearchInput, categorySearchInput, supplierId) => {
  if (isNaN(supplierId)) {
    supplierId = ""
  }
  const cartItemsContent = document.getElementById('cart-item-content')
  let product = encodeURIComponent(nameSearchInput.value);
  let category = categorySearchInput.value;
  if (categorySearchInput.value == "Catégorie"){
    category = ""
  }
  let url = `/cart_items?cart_items[name]=${product}&cart_items[prod_cat]=${category}&cart_items[supplier_id]=${supplierId}`
  if (product == "" && category != "") {
    // console.log("product = vide")
    url = `/cart_items?cart_items[name]=&cart_items[prod_cat]=${category}&cart_items[supplier_id]=${supplierId}`
  }
  if (product != "" && category == "") {
    // console.log("category = vide")
   url = `/cart_items?cart_items[name]=${product}&cart_items[prod_cat]=&cart_items[supplier_id]=${supplierId}`
  }
  if (product == "" && category == "" && supplierId == "") {
    // console.log("product&category&supplierid = vide")
   url = `/cart_items?cart_items[name]=''&cart_items[prod_cat]=''&cart_items[supplier_id]=''`
  }
  const loadingAnimation = document.querySelector('.loading-animation')
  if(loadingAnimation) {
    loadingAnimation.remove();
  } 
  const token = document.querySelector('meta[name="csrf-token"]').content;
  const headerCartItem = document.querySelector('.header-cart-item')
  const cartItemRows = document.querySelector('.cart-item-rows')
  if(cartItemRows) {
    cartItemRows.remove();
  }
  headerCartItem.insertAdjacentHTML('afterEnd', htmlLoadingAnimation());
  const emptyMessage = document.querySelector('.empty-cart-form-search');
  if (emptyMessage) {
    emptyMessage.remove();
  }
  if (product == "" && category == "" && supplierId == "") {
    const loadingAnimation = document.querySelector('.loading-animation')
    if(loadingAnimation) {
      loadingAnimation.remove();
    } 
  }

  fetch(url, {
     headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Accept: "*/*;q=0.5, application/javascript", "X-CSRF-Token": token,
    },
  })
  .then(
    function(response) {
      //console.log(response.status);
      if (!response.ok) {
         window.location = "/cart_items";
      }
      return response.text();
    }
  )
  .then((html) => {
    const loadingAnimation = document.querySelector('.loading-animation')
    if(loadingAnimation) {
      headerCartItem.lastChild.remove()
    }
  
    cartItemsContent.outerHTML = html;
    const event = new CustomEvent('turbolinks:load');
    document.dispatchEvent(event);
    clearSearchInputWoQuery();
  });
}

const cartItemFromSupplierFilter = () => {

  const cartItemsForm = document.getElementById('create_cart_items');

  if (cartItemsForm) {
    const supplierFilterButton = document.getElementById('cart-item-supplier-filter');
    const supplierFilterFormDiv = document.getElementById('supplier-filter-form');
    const supplierFilterSelect = document.getElementById('supplier-filter-select');
    const supplierFilterForm = document.getElementById('supplier-filter-formsubmit');
    const supplierFilterSubmitButton = document.getElementById('supplier-filter-submit');
    const supplierFilterLockedButton = document.getElementById('supplier-filter-locked');

    //1. Définition placeholder et croix pour vider selecteur
    $(supplierFilterSelect).select2({
      placeholder: "> Fournisseur",
      allowClear: true
    });

    //2. Appui sur bouton filtre avec apparition select2 et bouton filtre
    supplierFilterButton.addEventListener('click', (event) => {
      // event.preventDefault();
      if (event.target){
        supplierFilterButton.style.display = "none";
        supplierFilterFormDiv.style.display = "flex";
      }
    });

    //3. Appui sur le bouton close pour revenir au bouton initial
    const closeSupplierFilter = document.getElementById('close-supplier-filter');

    closeSupplierFilter.addEventListener('click', (event) => {
      event.preventDefault();
      if (event.target){
        supplierFilterSelect.value = "";
        supplierFilterFormDiv.style.display = "none";
        supplierFilterButton.style.display = "flex";
      }
    });

    //4. Appui sur le bouton défiltrer : submit d'un input vide et le bouton filtrer revient
    supplierFilterLockedButton.addEventListener('click', (event) => {
      if (event.target){
        supplierFilterSelect.value = "";
        supplierFilterLockedButton.style.display = "none";
        supplierFilterSubmitButton.style.display = "flex";
      }
    });

     //5. Changement sur le sélecteur: autosubmit ne marche pas rafraichit la page. du coup affichage du bouton filtrer.
     //Tentative pour l'autosubmit de le placer ci-dessous dans le "if (supplierFilterForm.dataset.addEvent) return" mais ne fonctionne pas
    $(supplierFilterSelect).on('select2:select', function (e) {
      supplierFilterLockedButton.style.display = "none";
      supplierFilterSubmitButton.style.display = "flex";
    });

    //6. Changement sur le sélecteur: autosubmit ne marche pas rafraichit la page. du coup affichage du bouton filtrer.
    if (supplierFilterForm.dataset.addEvent) return
    supplierFilterForm.dataset.addEvent = true;

    supplierFilterForm.addEventListener('submit', (event) => {
      event.preventDefault();
      supplierFilterSubmitButton.style.display = "none";
      supplierFilterLockedButton.style.display = "flex";

      // 1. Récupération des informations pour l'ajax
      const supplierIdParam = supplierFilterSelect.value

      if (supplierIdParam != null){
        const cartItemsContent = document.getElementById('cart-item-content')
        let supplierId = parseFloat(supplierIdParam);
        const nameProductSearch = document.getElementById('searchProductNameCartForm');
       // const categoryProductSearch = document.getElementById('searchProductCategoryCreate');
        const categoryProductSearch = document.getElementById('searchProductCategoryCreate2');
        const nameSearchInput = nameProductSearch.firstChild.nextElementSibling;
        //const categorySearchInput = categoryProductSearch.firstChild.nextElementSibling;
         const categorySearchInput = categoryProductSearch;

        SearchFetchCartItem(nameSearchInput,categorySearchInput,supplierIdParam);


        if (isNaN(supplierId)) {
            supplierFilterLockedButton.style.display = "none";
            supplierFilterSubmitButton.style.display = "flex";
        }
      }

    });
  }
}

export {cartItemFromSupplierFilter, SearchFetchCartItem};
