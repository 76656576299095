import { Controller } from "stimulus";

function toggleValidation(element, boolean) {
  if (boolean) {
    element.classList.add("is-invalid");
    return true
  } else {
    element.classList.remove("is-invalid");
    return false
  }
}

function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

function invalidInput(input){
  input.insertAdjacentHTML('afterend','<div class="invalid-feedback">A renseigner</div>')
}

function invalidMail(input){
  input.insertAdjacentHTML('afterend','<div class="invalid-feedback">Mail non valide</div>')
}

function checkRestaurant(e) {

  // Fonction pour obtenir les delivery_days d'un supplier_contact
  function getDeliveryDays(contact) {
    const deliveryDaysCheckboxes = contact.querySelectorAll('.deliveryday');
    const selectedDeliveryDays = [];
    deliveryDaysCheckboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedDeliveryDays.push(checkbox.value);
      }
    });
    return selectedDeliveryDays;
  }

  // Fonction pour vérifier si les delivery_days sont identiques pour les restaurants sélectionnés
  function areDeliveryDaysIdentical(selectedRestaurants, supplierContacts) {
    const selectedDeliveryDaysMap = new Map();
    for (var i = 0; i < supplierContacts.length; i++) {
      const contact = supplierContacts[i];
      const contactSelectedRestaurants = getSelectedRestaurants(contact);
      const contactDeliveryDays = getDeliveryDays(contact);

      // Vérifier si les restaurants sélectionnés du contact actuel sont inclus dans les restaurants sélectionnés
      const commonRestaurants = contactSelectedRestaurants.filter((restaurantId) =>
        selectedRestaurants.includes(restaurantId)
      );

      for (var j = 0; j < commonRestaurants.length; j++) {
        const restaurantId = commonRestaurants[j];
        if (!selectedDeliveryDaysMap.has(restaurantId)) {
          // Ajouter les delivery_days du restaurant
          selectedDeliveryDaysMap.set(restaurantId, contactDeliveryDays);
        } else {
          // Vérifier si les delivery_days sont identiques
          const existingDeliveryDays = selectedDeliveryDaysMap.get(restaurantId);
          if (!arraysAreEqual(existingDeliveryDays, contactDeliveryDays)) {
            return false; // Les delivery_days sont différents
          }
        }
      }
    }

    return true; // Tous les restaurants sélectionnés ont les mêmes delivery_days
  }

  // Fonction pour vérifier si deux tableaux sont identiques
  function arraysAreEqual(array1, array2) {
    // Vérifier si les tableaux ont la même longueur
    if (array1.length !== array2.length) {
      return false;
    }
    // Vérifier si chaque élément est identique dans les deux tableaux
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    // Si tous les éléments sont identiques, les tableaux sont identiques
    return true;
  }

  // Fonction pour obtenir les restaurants sélectionnés d'un supplier_contact
  function getSelectedRestaurants(contact) {
    const selectedRestaurants = [];
    const contactRestaurants = contact.querySelectorAll('.supplier_restaurants input[type="checkbox"]:checked');
    contactRestaurants.forEach((checkbox) => {
      selectedRestaurants.push(checkbox.value);
    });
    return selectedRestaurants;
  }

  // Fonction pour obtenir les delivery_delay d'un supplier_contact
  function getDeliveryDelay(contact) {
    const deliveryDelaySelect = contact.querySelector('.supplier-contact-row select[name*="delivery_delay"]');
    return deliveryDelaySelect.value;
  }

  // Sélection de tous les supplier_contacts
  const supplierContacts = document.querySelectorAll('.supplier-contact-row');

  // Tableau pour stocker les restaurants identiques avec des délais et jours de livraison différents
  const restaurantsWithDifferentDelay = [];
  const restaurantsWithDifferentDeliveryDays = [];

  // Reinitialise les supplier restaurants en enlevant les marqueurs
  supplierContacts.forEach((contact) => {
    const contactRestaurants = contact.querySelectorAll('.supplier_restaurants input[type="checkbox"]');
    contactRestaurants.forEach((checkbox) => {
      checkbox.parentElement.classList.remove('different-delivery-delay');
      checkbox.parentElement.classList.remove('different-delivery-days');
    });
  });

  // Boucle pour parcourir tous les supplier_contacts
  for (let i = 0; i < supplierContacts.length; i++) {
    const contact = supplierContacts[i];

    // Récupérer les restaurants sélectionnés du contact actuel
    const contactSelectedRestaurants = getSelectedRestaurants(contact);
    // Récupérer le délai de livraison du contact actuel
    const contactDeliveryDelay = getDeliveryDelay(contact);

    // Vérifier s'il existe des restaurants sélectionnés identiques dans d'autres supplier_contacts
    for (let j = 0; j < supplierContacts.length; j++) {
      if (j !== i) {
        const otherContact = supplierContacts[j];
        const otherSelectedRestaurants = getSelectedRestaurants(otherContact);

        // Vérifier si les restaurants sélectionnés sont identiques
        const commonRestaurants = contactSelectedRestaurants.filter((restaurantId) =>
          otherSelectedRestaurants.includes(restaurantId)
        );

        // Vérifier si les restaurants identiques ont des delivery_delay différents
        if (commonRestaurants.length > 0 && contactDeliveryDelay !== getDeliveryDelay(otherContact)) {
          // Ajouter les restaurants à la liste des restaurants avec des délais de livraison différents
          restaurantsWithDifferentDelay.push(...commonRestaurants);
        }

        // Vérifier si les restaurants identiques ont des delivery_days différents
        if ( commonRestaurants.length > 0 && !areDeliveryDaysIdentical(commonRestaurants,supplierContacts)
        ) {
          // Ajouter les restaurants à la liste des restaurants avec des délais de livraison différents
          restaurantsWithDifferentDeliveryDays.push(...commonRestaurants);
        }
      }
    }
  }

  // Boucle pour parcourir tous les supplier_contacts et entourer les restaurants identiques avec des délais ou des jours de livraison différents
  supplierContacts.forEach((contact) => {
    const contactRestaurants = contact.querySelectorAll('.supplier_restaurants input[type="checkbox"]');
    contactRestaurants.forEach((checkbox) => {
      const restaurantId = checkbox.value;
      if (restaurantsWithDifferentDelay.includes(restaurantId)) {
        checkbox.parentElement.classList.add('different-delivery-delay');
      }
      if (restaurantsWithDifferentDeliveryDays.includes(restaurantId)) {
        checkbox.parentElement.classList.add('different-delivery-days');
      }
    });
  });

  // Vérifier si des restaurants avec des délais de livraison différents ont été trouvés
  if (restaurantsWithDifferentDelay.length > 0 || restaurantsWithDifferentDeliveryDays.length > 0) {
    e.preventDefault();
    e.stopPropagation();
    alert('Les restaurants sélectionnés doivent avoir le même délai de livraison ainsi que les mêmes jours de livraison');
  }
}

export default class extends Controller {
  static targets = ["name", "companyName", "contactName", "contactMail", "category", "supplierContactRow", "supplierContactInputs", "newdeliveryday", "formEdit"];

  connect() {
    var listCheckbox = []
    this.newdeliverydayTargets.forEach((day)=>{
      listCheckbox.push(day.checked)
    })
    let unique = [...new Set(listCheckbox)];

    if(!unique[0] && unique.length == 1) {
      this.newdeliverydayTargets.forEach((day)=>{
        listCheckbox.push(day.checked)
        day.checked = true
      })
    }
  }

  addContact(e){

    // Div contenant tous les inputs
    var divInputs = this.supplierContactInputsTarget;
    var countChilds = divInputs.childElementCount

    // Ligne référence à copier
    var inputRow = divInputs.lastElementChild;

    // Clonez de la ligne référence
    var nouvelleLigne = inputRow.cloneNode(true);

    // Check si form edit, si oui alors ne pas afficher la corbeille
    var formEdit = this.hasFormEditTarget
    if (formEdit) {
      if (nouvelleLigne.getElementsByClassName("remove-prod-unit")[0])
      { nouvelleLigne.getElementsByClassName("remove-prod-unit")[0].remove() }
    }

    // Changement de l'id dans le name et dans les ids pour les params
    var names = nouvelleLigne.querySelectorAll('input[name*=supplier_contacts_attributes]' + ',select[name*=supplier_contacts_attributes]')
    names.forEach((input)=>{
      var initial = countChilds - 1
      input.name = input.name.replace('supplier[supplier_contacts_attributes]['+ initial +']', 'supplier[supplier_contacts_attributes]['+ countChilds +']')
    })

    var ids = nouvelleLigne.querySelectorAll('input[id*=supplier_contacts_attributes]')
    ids.forEach((input)=>{
      var initial = countChilds - 1
      input.id = input.id.replace('supplier_supplier_contacts_attributes_'+ initial +'_', 'supplier_supplier_contacts_attributes_'+ countChilds +'_')
    })

    // Ajout du bouton de suppression
    // if(nouvelleLigne.getElementsByClassName("supplier_supplier_contact_contact_name") != "") {
    //   var divParent = Array.prototype.slice.call(nouvelleLigne.getElementsByClassName("supplier_supplier_contact_contact_name"))
    // } else {
    //   var divParent = Array.prototype.slice.call(nouvelleLigne.getElementsByClassName("supplier_supplier_contacts_contact_name"))
    // }
    //  divParent[0].insertAdjacentHTML('beforeend','<i class="fas fa-minus-circle" data-action="click->supplier-contacts#deleteContact"></i>')
    nouvelleLigne.insertAdjacentHTML('beforeend','<i class="fas fa-minus-circle" data-action="click->supplier-contacts#deleteContact"></i>')


    // Réinitialisation des valeurs des inputs de la nouvelle ligne
    var inputs = nouvelleLigne.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      if (!inputs[i].classList.contains('deliveryday') && !inputs[i].classList.contains('supplier_restaurant')) {
        inputs[i].value = '';
      }
    }

    var deliveryDelay = nouvelleLigne.querySelector('select');
    if (deliveryDelay.options[0].innerHTML != "Délai livraison") {
      const opt1 = document.createElement("option");
      opt1.value = "";
      opt1.text = "Délai livraison";
      deliveryDelay.add(opt1, deliveryDelay.options[0]);
      deliveryDelay.selectedIndex = 0;
    }

    var deliveryDays = nouvelleLigne.querySelectorAll('.deliveryday')
    for (var i = 0; i < deliveryDays.length; i++) {
      deliveryDays[i].checked = true;
    }

    var restaurants = nouvelleLigne.querySelectorAll('.supplier_restaurant')
    for (var i = 0; i < restaurants.length; i++) {
      restaurants[i].checked = false;
    }

    // Ajout de la nouvelle ligne à la div contenant tous les inputs
    divInputs.append(nouvelleLigne);

  }

  deleteContact(e) {
    e.target.parentElement.remove()
  }

  destroyContact(e) {
    this.supplierContactRowTarget.remove()
    // ajour de l'attribut _destroy = 1 au supplier contact
  }

  validation(e) {


    var inputs = []
    inputs.push(this.nameTarget)
    inputs.push(this.companyNameTarget)
    inputs.push(this.categoryTarget)
    this.contactNameTargets.forEach((element)=>{
      inputs.push(element)
    })
    this.contactMailTargets.forEach((element)=>{
      inputs.push(element)
    })

    // Vérification que les inputs et le select2 sont bien renseignés

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].nodeName == "INPUT") {

        if (inputs[i].value == "") {
          toggleValidation(inputs[i], true)
          if (inputs[i].nextSibling === null && !inputs[i].classList.contains('contact-mail')) {
            invalidInput(inputs[i])
          } else if (inputs[i].nextSibling === null && inputs[i].classList.contains('contact-mail')) {
            invalidMail(inputs[i])
          }
          e.preventDefault()
          e.stopPropagation();
        } else if (inputs[i].classList.contains('contact-mail')){
          if(!validateEmail(inputs[i].value)){
            toggleValidation(inputs[i], true)
            if (inputs[i].nextSibling === null) {  invalidMail(inputs[i]) }
            e.preventDefault()
            e.stopPropagation();
          } else {
            toggleValidation(inputs[i], false)
          }
        } else {
          toggleValidation(inputs[i], false)
        }

      } else if (inputs[i].nodeName == "SELECT") {
        if (inputs[i].options[inputs[i].selectedIndex].value == "") {
          toggleValidation(inputs[i], true)
          if (inputs[i].nextSibling === null ) { invalidInput(inputs[i]) }
          e.preventDefault()
          e.stopPropagation();
        } else {
          toggleValidation(inputs[i], false)
        }
      }
    }

    // Vérification qu'il y a 1 jours de livraison sélectionné au minimum

    var deliveryDaysInputs = document.querySelectorAll('.deliverydays')
    deliveryDaysInputs.forEach((deliveryDays)=>{
      var inputs_checked = deliveryDays.querySelectorAll('input:checked')
      if(inputs_checked.length == 0){
        var removeMessage = deliveryDays.getElementsByClassName('invalid-feedback')
        if(removeMessage.length > 0){
          removeMessage[0].remove()
        }
        deliveryDays.insertAdjacentHTML('beforeend','<div class="invalid-feedback d-block">A renseigner</div>')
        e.preventDefault()
        e.stopPropagation();
      } else {
        var removeMessage = deliveryDays.getElementsByClassName('invalid-feedback')
        if(removeMessage.length > 0){
          removeMessage[0].remove()
        }
      }
    })

    // Vérification qu'il y a 1 restaurant de selectionné au minimum

    var restaurantsInputs = document.querySelectorAll('.supplier_restaurants')
    restaurantsInputs.forEach((restaurants)=>{
      var inputs_checked = restaurants.querySelectorAll('input:checked')
      if(inputs_checked.length == 0){
        var removeMessage = restaurants.parentNode.getElementsByClassName('invalid-feedback')
        if(removeMessage.length > 0){
          removeMessage[0].remove()
        }
        restaurants.insertAdjacentHTML('afterend','<div class="invalid-feedback d-block">A renseigner</div>')
        e.preventDefault()
        e.stopPropagation();
      } else {
        var removeMessage = restaurants.parentNode.getElementsByClassName('invalid-feedback')
        if(removeMessage.length > 0){
          removeMessage[0].remove()
        }
      }
    })

    checkRestaurant(e)

  }
}
