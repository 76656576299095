import $ from 'jquery';

const ProductFormValidates = () => {
 // Validation pour form new
  const formNew = document.getElementById("new_product");
  if (formNew) {
    validatesProductForm(formNew);
  }

  // Validation pour form edit
  const formEdit = document.querySelector(".simple_form.edit_product");
  if (formEdit) {
    validatesProductForm(formEdit);
  }
}

const validatesProductForm = (productForm) => {
  if (productForm) {
    productForm.addEventListener('submit', (event) => {
      const button = document.querySelector("input[name='commit']");
      const name = document.getElementById("product_name");
      const category = document.querySelector(".select2-selection__placeholder");
      const category_container = document.querySelector(".select2-container");
      const category_selector = document.getElementById("product_prod_cat_id");

      // Ajout validates sur product_units
      if (productForm.classList[1] == "edit_product") {

        var supplier_id = document.querySelectorAll("#product_supplier_item_supplier_id");
        if(supplier_id.length == 0){
          var supplier_id = document.querySelectorAll("#product_supplier_items_supplier_id");
        }
        var supplier_item_cost = document.querySelectorAll("#product_supplier_item_price_per_unit_cents");
        if(supplier_item_cost.length == 0){
          var supplier_item_cost = document.querySelectorAll("#product_supplier_items_price_per_unit_cents");
        }
        var supplier_item_cond_id = document.querySelectorAll("#product_supplier_item_conditioning_unit_id");
        if(supplier_item_cond_id.length == 0){
          var supplier_item_cond_id = document.querySelectorAll("#product_supplier_items_conditioning_unit_id");
        }
        var productUnitNames = document.querySelectorAll(".product_product_unit_name");
        var productUnitQuantities = document.querySelectorAll(".product_product_unit_quantity");

      } else {
        var supplier_id = document.querySelectorAll(".supplier_id");
        var supplier_item_cost = document.querySelectorAll(".supplier_item_price");
        var supplier_item_cond_id = document.querySelectorAll("#product_supplier_items_conditioning_unit_id");
        var productUnitNames = document.querySelectorAll(".product_product_units_name");
        var productUnitQuantities = document.querySelectorAll(".product_product_units_quantity");
      }

      var totalcount = 0;

      // la bordure rouge ne tient pas
      if (category) {
        category_container.style.border = "1px solid red";
        if (category_container.nextElementSibling == null || category_container.style.border != "1px solid red") {
          category_container.insertAdjacentHTML('afterend',
            '<div class="invalid-feedback" style="display: flex;">'
            +
            'Catégorie à renseigner'
            +
            '</div>')
        }
        totalcount += 1;
      } else {
        category_container.style.border = "none";
      }


      if (name.value == "") {
        name.classList.add("is-invalid");
        if (name.nextElementSibling == null || name.nextElementSibling.classList[0] != "invalid-feedback") {
          name.insertAdjacentHTML('afterend',
            '<div class="invalid-feedback" >'
            +
            'Nom à renseigner'
            +
            '</div>')
        }
        totalcount += 1;
      } else {
        name.classList.remove("is-invalid");
      }


      // Ajout product_unit validated
      productUnitNames.forEach( (productUnitName) => {
        if (productUnitName.firstChild.value == "") {
          productUnitName.firstChild.classList.add("is-invalid");
          totalcount += 1;
        } else {
          productUnitName.firstChild.classList.remove("is-invalid");
        }
      });

      productUnitQuantities.forEach( (productUnitQuantity) => {
        if (productUnitQuantity.firstChild.value == "" || productUnitQuantity.firstChild.value == 0) {
          productUnitQuantity.firstChild.classList.add("is-invalid");
          totalcount += 1;
        } else {
          productUnitQuantity.firstChild.classList.remove("is-invalid");
        }
      });

      supplier_id.forEach( (supplier) => {
        if (supplier.options[supplier.selectedIndex].innerText == "Indiquer le fournisseur") {
          supplier.classList.add("is-invalid");
          totalcount += 1;
        } else {
          supplier.classList.remove("is-invalid");
        }
      });

      supplier_item_cond_id.forEach( (condid) => {
        if (condid.options[condid.selectedIndex] != null){
          if (condid.options[condid.selectedIndex].innerText == "Indiquer le conditionnement") {
            condid.classList.add("is-invalid");
            totalcount += 1;
          } else {
            condid.classList.remove("is-invalid");
          }
        }
      });

      supplier_item_cost.forEach( (cost) => {
        if (cost.value == "" || cost.value == 0) {
          cost.classList.add("is-invalid");
          totalcount += 1;
        } else {
          cost.classList.remove("is-invalid");
        }
      });

      const supplier_item_row = document.querySelectorAll('.supplier-units-rows');

        supplier_item_row.forEach( (row) => {
            if ( row.childNodes[1].childNodes[0].options[row.childNodes[1].childNodes[0].selectedIndex] != null &&
            row.childNodes[3].childNodes[0].options[row.childNodes[3].childNodes[0].selectedIndex] != null &&
            row.childNodes[9].childNodes[0] != null){
              if (row.childNodes[1].childNodes[0].options[row.childNodes[1].childNodes[0].selectedIndex].innerText == "Indiquer le fournisseur" &&
              row.childNodes[3].childNodes[0].options[row.childNodes[3].childNodes[0].selectedIndex].innerText == "Indiquer le conditionnement" &&
              row.childNodes[9].childNodes[0].value == "") {

                row.childNodes[1].childNodes[0].classList.remove("is-invalid");
                row.childNodes[3].childNodes[0].classList.remove("is-invalid");
                row.childNodes[9].childNodes[0].classList.remove("is-invalid");
                totalcount -= 3;
              }
            }
        });


      //Remove des validations
      //1. Name
      removeIsInvalidForInput(name);

      //2. supplier_item cost
      supplier_item_cost.forEach( (cost) => {
        removeIsInvalidForInput(cost);
      });

      //3. product_unit_names
      productUnitNames.forEach( (productUnitName) => {
        removeIsInvalidForInput(productUnitName.firstChild);
      });

      //4. product_unit_quantities
      productUnitQuantities.forEach( (productUnitQuantity) => {
        removeIsInvalidForInput(productUnitQuantity.firstChild);
      });


      //3. selector supplier_id
      removeIsInvalidForSelector(supplier_id);

      //4. selector supplier_item_cond
      removeIsInvalidForSelector(supplier_item_cond_id);

      //5. selecteur catégorie
      $(category_selector).on('select2:select', function (e) {
        if (e.target.value != ""){
          if (category_container.nextElementSibling != null && category_container.style.border == "1px solid red"){
          category_container.style.border = "none"
          category_container.nextElementSibling.remove()
          }
        }
      });

      if (totalcount > 0) {
        event.preventDefault();
      }

    });

    // Fonction pour remove les invalid class pour sélecteur
    const removeIsInvalidForSelector = (selectorinputs) => {
      selectorinputs.forEach(function(input) {
        if (input.classList.value.includes("is-invalid")){
          input.addEventListener("change", function() {
            if (input.value != ""){
              input.classList.remove("is-invalid");
            }
          });
        }
      });
    }

    // Fonction pour remove les invalid class pour sélecteur
    const removeIsInvalidForInput = (input) => {
      if (input.classList.value.includes("is-invalid")){
        input.addEventListener("keyup", function() {
          if (input.value != ""){
            input.classList.remove("is-invalid");
          }
        });
      }
    }
  }
}


export { ProductFormValidates };
