import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {

  static targets = ['recipes', 'ingredients', 'btn'];

  connect() {
  }

  showHide(e) {
    const tableRecipes = this.recipesTarget;
    const tableIngredients = this.ingredientsTarget;
	  tableIngredients.classList.toggle('d-none');
	  tableRecipes.classList.toggle('d-none');
	  if (tableIngredients.classList.contains('d-none')) {
			this.btnTarget.textContent = '> Ingredients';
	  } else {
	  	this.btnTarget.textContent = '< Recettes';
	  }
  }

}         
