import {computeTotalRecipesCost} from "../components/compute_total_recipes_cost.js";

const minusPlusButtonRestaurantRecipe = () => {

  const restaurantRecipesContent = document.getElementById('restaurant_recipes_content');
  if (restaurantRecipesContent) {
  //1. Changement en direct dans l'input par le user
    const recipeQuantityInputs = restaurantRecipesContent.querySelectorAll('.restaurantrecipequantityinput');
    recipeQuantityInputs.forEach((input) => {
      input.addEventListener('change', (event) => {
          // console.log('change value');
          // console.log(event.target.value);
          // BLOQUER LES INPUTS ne devant accepter que des float/integer
          // if (event.target.value == "NaN"){
          //   let currentValue = 0;
          // } else {
          let currentValue = parseFloat(event.target.value);
          input.value = currentValue;
          updateQuantityInput(input, currentValue);
          input.dataset.eventchanged = "true";
          // }
        });
      });

    const minusButtons = restaurantRecipesContent.querySelectorAll('#restaurantrecipeminusbutton');
    minusButtons.forEach((minusButton) => {
      minusButton.addEventListener('click', (event) => {
        const input = minusButton.nextElementSibling;

        let currentValue = parseFloat(input.value);
        if (currentValue > 0) {
          currentValue = currentValue - 1;
        } else {
          currentValue = 0;
        }
        input.value = currentValue;
        updateQuantityInput(input, currentValue);
        input.dataset.eventchanged = "true";
      });

    });

    const plusButtons = restaurantRecipesContent.querySelectorAll('#restaurantrecipeplusbutton');
    plusButtons.forEach((plusButton) => {
        plusButton.dataset.addEvent = true;
      plusButton.addEventListener('click', (event) => {
        const input = plusButton.previousElementSibling;
        let currentValue = parseFloat(input.value);
        // console.log(currentValue);
        currentValue = currentValue + 1;
        input.value = currentValue;
        // console.log(input.value);
        updateQuantityInput(input, currentValue);
        input.dataset.eventchanged = "true";
      });
    });
  }






    const updateQuantityInput = (input, currentValue) => {
      const RestaurantRecipesContent = document.getElementById('restaurant_recipes_content');
      if (RestaurantRecipesContent) {
        // 1. Mise à jour de l'unité / nombre de portions
        const withPortion = input.dataset.withportion;
        if (withPortion == "true") {
            const recipePortionCount = document.getElementById(`portion-count-${input.dataset.itemid}`);
            const recipePortionUnit = document.getElementById(`portion-${input.dataset.itemid}`);
            const recipePortionQuantity = document.getElementById(`portion-quantity-${input.dataset.itemid}`);
            const recipePortionCountValue = ( currentValue * parseFloat(recipePortionQuantity.innerHTML) ).toFixed(1);
          if (currentValue >= 1) {
            recipePortionCount.innerHTML = recipePortionCountValue;
            recipePortionUnit.innerHTML = "portions";
            recipePortionCount.previousElementSibling.innerHTML = "|";
          } else {
            recipePortionCount.innerHTML = "";
            recipePortionUnit.innerHTML = "";
            recipePortionCount.previousElementSibling.innerHTML = "";
          }
        } else {
          const recipeWoPortionUnit = document.getElementById(`recette-${input.dataset.itemid}`);
          if (currentValue > 1) {
            recipeWoPortionUnit.innerHTML = "recettes";
          } else {
            if (currentValue == 0) {
              recipeWoPortionUnit.innerHTML = "-";
            } else {
              recipeWoPortionUnit.innerHTML = "recette";
            }
          }
        }

        // 2. Mise à jour du coût total de la restrecipe pour commande
        const restRecipeTotalCost = document.getElementById(`totalcostht-${input.dataset.itemid}`);
        const recipeCostValue = parseFloat(input.dataset.refrecipecostht);
        let restRecipeTotalCostValue = (currentValue * recipeCostValue);
        restRecipeTotalCost.innerHTML = restRecipeTotalCostValue.toFixed(2) ;

        // 3. Mise à jour du coût total
        computeTotalRecipesCost();

        // 4. Mise à jour de la marge moyenne

        //5. ajax pour update des cart_items
        $.ajax(`/restaurant_recipes/${input.dataset.itemid}`, {
          method: "PATCH",
          data: {restaurant_recipe: {order_quantity: currentValue }}
        })
      }
    }




































}

export {minusPlusButtonRestaurantRecipe};
