


const addPortionSizeInForm = () => {

  const recipeWithPortion = document.getElementById('recipe_with_portion');
  const portionsNumber = document.getElementById('recipe_recipe_portions_attributes_0_quantity');
  const recipePortionSize = document.getElementById('recipeportionssize');
  const portionsSizeInput = document.getElementById('recipe_recipe_portions_attributes_0_size');
  const recipeUnitInput = document.getElementById('recipe_recipe_unit');
  const portionUnitInput = document.getElementById('recipe_recipe_portions_attributes_0_portion_unit');
  const withPortionSizeRadio = document.getElementById('with_portion_size');

  if (recipeWithPortion && recipePortionSize && portionsNumber && recipeUnitInput){

    recipeWithPortion.addEventListener('change', (event) => {
      event.preventDefault();

      if (event.target.checked){
        portionsNumber.readOnly = false;
        recipePortionSize.style.display = "block";
        matchRecipePortionUnits(recipeUnitInput);
        recipePortionSizeReadOnly(portionsSizeInput);
        recipePortionSizeReadOnly(portionUnitInput);


        recipeUnitInput.addEventListener('change', (event) => {
          matchRecipePortionUnits(recipeUnitInput);
        });

      } else{
        recipePortionSize.style.display = "none";
        portionsNumber.readOnly = true;
        withPortionSizeRadio.checked = false;
      }
    });

    if (recipeWithPortion.checked == false ){
      recipePortionSize.style.display = "none";
      portionsNumber.readOnly = true;
    } else {
      recipePortionSize.style.display = "block";
      portionsNumber.readOnly = false;
    }
  }
}

const recipePortionSizeReadOnly = (recipePortionElement) => {
  const withPortionSizeRadio = document.getElementById('with_portion_size');
  // const portionsNumber = document.getElementById('recipe_recipe_portions_attributes_0_quantity');
  // console.log(withPortionSizeRadio.checked);

  if (withPortionSizeRadio) {
    if (withPortionSizeRadio.checked == false ){
      recipePortionElement.disabled = true;
    }
    withPortionSizeRadio.addEventListener('change', (event) => {
      event.preventDefault();
      // console.log("change radio");
      // console.log(withPortionSizeRadio.checked);
      if (event.target.checked){
        recipePortionElement.disabled = false;
      } else {
        recipePortionElement.disabled = true;
      }
    });
  }
}

const matchRecipePortionUnits = (recipeUnitInput) => {
  const recipePortionUnit = document.getElementById('recipe_recipe_portions_attributes_0_portion_unit');
  const recipePortionUnitOptions = document.querySelectorAll('.portion_units option');

  var unitsList = []
  if (recipeUnitInput.value == "kg" || recipeUnitInput.value == "g"){
    unitsList.push("kg", "g");
  } else {
    unitsList.push("L", "cL", "mL");
  }
  var i=0;
  recipePortionUnit.options.length = 0;
  Object.values(unitsList).forEach((item) => {
    recipePortionUnit.options[recipePortionUnit.length] = new Option(item, item);
    i++;
  });
}


export {addPortionSizeInForm};
