import $ from 'jquery';

const clearSearchInputWithQuery = () => {

  const searchs = document.querySelectorAll(".search form");
  // console.log(searchs);

  if(searchs.length > 0) {
    searchClearButton(searchs)
  } else {
    const combinedSearchForm = document.querySelector(".form-combined-search");
    if (combinedSearchForm) {
      const searchs = combinedSearchForm.querySelectorAll(".search");
      // console.log(searchs);
      if(searchs.length > 0) {
        searchClearButton(searchs);
      }
    }
  }
}


const searchClearButton = (searchs) => {
  searchs.forEach((search) => {
    const searchInput = search.querySelector(".form-control.rounded");
    const searchSubmitButton = search.querySelector(".search-button");
    const searchClearButton = search.querySelector(".clear-search-button");
    var initial_placeholder = search.firstElementChild.dataset.query;
    // console.log(search.firstElementChild.dataset.query);
    //console.log(searchInput.name);


    if(searchInput.placeholder != initial_placeholder){
      searchSubmitButton.style.visibility = 'hidden'; // Attention hidden et pas display none important sinon ne fonctionne pas sur safari / tablette
      searchClearButton.style.display = 'block';
    }

      searchInput.addEventListener("keyup", function() {
        if (searchInput.value.length == 1) {
          // console.log(searchClearButton);
          searchSubmitButton.style.visibility = 'hidden'; // Attention hidden et pas display none important sinon ne fonctionne pas sur safari / tablette
          searchClearButton.style.display = 'block';

        } else {
          if (searchInput.value.length == 0) {
            searchSubmitButton.style.display = 'block';
            searchClearButton.style.display = 'none';
            // searchClearButton.remove();
          }
        }
      });
      if (searchClearButton && searchInput) {
        emptySearchInputFromQuery(searchClearButton, searchInput);
      }
  });

}


// voir si on peut empêcher de recharger pour effacer un input quand une query est déja présente dans l'url (window.location)
const emptySearchInputFromQuery = (searchClearButton, searchInput) => {
  // méthode pour disinguer recherches simples de combinées
  if(searchClearButton && searchInput){
    // console.log(searchClearButton);
    searchClearButton.addEventListener('click', (event) => {
      // clearing de l'iunput pour effacer les données
      searchInput.value = "";
      // si display block, déja des données rentrées quand la page s'est chargée : effacer les params dans l'url et relancer la query
      if (searchClearButton.style.display == 'block') {
        //event.preventDefault();
        let searchUrlArray = []
        if (window.location.search != "") {
          searchUrlArray = window.location.search.split("?")[1].split("&")
        }
        // console.log(searchInput.value);
        if (searchUrlArray.length > 1) {
          searchUrlArray.forEach((params, index) => {
            // console.log(params);
            if (params.search(searchInput.name) > - 1 && params.split('=')[1] != ""){
              searchUrlArray[index] = `${searchInput.name}=`;
              // console.log(params);
            }
          });
          let finalSearchUrl = searchUrlArray.join('&');
          window.location = window.location.pathname + "?" + finalSearchUrl;

        } else {
          if(searchUrlArray.length > 0){
            window.location = window.location.href.split("?")[0];
          }
        }
      }
      searchClearButton.style.display = 'none';
      searchClearButton.previousElementSibling.style.visibility = 'visible';
    });
  }
}

// const autocompleteClick = () => {
// {


export {clearSearchInputWithQuery};
