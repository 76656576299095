import $ from 'jquery';
import {totalshopcart} from "../components/compute_total_shopcart.js";


  function formatDesc (opt) {
    var optdescsize = $(opt.element).attr('data-descsize');
    var optdescprice = $(opt.element).attr('data-descprice');
    var optdescunitcost = $(opt.element).attr('data-descunitcost');
    // 1. Laisser url masqué sur ces 3 images et récupérer par id pour chemin
    // 2. Insérer ces images en forme de background en css sur un rectangle TO DO
    const srcImage1 = "/assets/supp_item_size-ebc6e291aeb90c58c137d96fd4de614bff662cbba1c845e63c10a79b71eef68f.png";
    const srcImage2 = "/assets/supp_item_price-5e93ab5a5caac6aa278528607e1be56fc7d6f71c531ae7ecb4ca17f157d9a39f.png";
    const srcImage3 = "/assets/supp_item_unit_cost-92602b78f4aadf453531ab0e70eb286fd2c0664608442123739e270b45f68d2e.png"

    if ( optdescunitcost == " " ){
      var $opt = $(
        '<div class="select-custom-title">' + opt.text + `</div><div class="select-data-desc"><span><image src="${srcImage1}">` + optdescsize
        + `<image src="${srcImage2}">` + optdescprice + '</span></div>'
      );
    } else {
      var $opt = $(
        '<div class="select-custom-title">' + opt.text + `</div><div class="select-data-desc"><span><image src="${srcImage1}">` + optdescsize
        + `<image src="${srcImage2}">` + optdescprice + `<image src="${srcImage3}">` + optdescunitcost + '</span></div>'
      );
    }
    return $opt;
  };


const updateSupplierCartIndex = () => {

 $('.select2.supplier-select').select2({
    templateResult: formatDesc
  });
  // A Vérifier fonctionnement avec cart_item
  const supplierItemsSelectors = document.querySelectorAll('.select2.supplier-select');
  // console.log(SupplierItemsSelectors);
  if (supplierItemsSelectors){
    supplierItemsSelectors.forEach(function(supplierItemsSelector){
      // console.log(SupplierItemsSelector.dataset.itemId);
      // console.log(supplierItemsSelector.value);
      const referentSupplierItem = document.getElementById(`referentsupplieritem-${supplierItemsSelector.dataset.itemId}`)
      if (referentSupplierItem && supplierItemsSelector){
        supplierItemsSelector.value = referentSupplierItem.innerText;
        // $(supplierItemsSelector).val(referentSupplierItem.innerText).trigger('change');
        // console.log(supplierItemsSelector.value);
      }
      // console.log(supplierItemsSelector.value);
    });
  }

  $('.select2.supplier-select').on('select2:select', function (e) {
    // console.log(document.getElementById(e.target.value).dataset.prix);
    // $('.select2.supplier-select').select2("data",e.choice);

    // 1. Partie prix du supplier_item : x€/pièce, x€/kg : prix =x / unit = "pièce / kg"
    // const prixhtupdate = document.getElementById(e.target.value).dataset.prix / 100.0;
    //console.log(e.target.dataset)
    const prixht = document.getElementById(e.target.dataset.prixht);
    const optionId = `cart_index-${e.target.dataset.index}-${e.target.value}`
    const prixhtupdate = document.getElementById(optionId).dataset.prix / 100.0;
    prixht.innerText = prixhtupdate.toFixed(1);

    const unitht = document.getElementById(e.target.dataset.unitht);
    const unithtupdate = document.getElementById(optionId).dataset.unit;
    unitht.innerText = unithtupdate;

    // 2. Prix unitaire sur la base d'une unité de comparaison commune des références fournisseurs ("kg" ou "L"): "pièce" n'existe plus car est convertie.
    // console.log(document.getElementById(e.target.value).dataset.refprix);

    const refprixht = document.getElementById(e.target.dataset.refprixht);
    const refprixhtupdate = document.getElementById(optionId).dataset.refprix;
    // console.log(refprixhtupdate);

    const refunitht = document.getElementById(e.target.dataset.refunitht);
    const refunithtupdate = document.getElementById(optionId).dataset.refunit;
    const refpereuro = document.getElementById(`refpereuro-${e.target.dataset.itemId}`);
    // console.log(refunithtupdate);
    refprixht.innerText = parseFloat(refprixhtupdate).toFixed(2);
    refprixht.dataset.refcost = parseFloat(refprixhtupdate);
    // console.log(refprixht.dataset.refcost);
    refunitht.innerText = refunithtupdate;
    refpereuro.innerText = "€/";
    if ( parseFloat(refprixhtupdate) == parseFloat(prixht.innerText) && refunithtupdate == unitht.innerText ){
      refprixht.style.display = "none";
      refunitht.style.display = "none";
      refpereuro.style.display = "none";
    } else {
      refprixht.style.display = "";
      refunitht.style.display = "";
      refpereuro.style.display = "";

    }

    // 3. Affichage nom fournisseur
    const suppliername = document.getElementById(e.target.dataset.suppliernameselected);
    const suppliernameupdate = document.getElementById(optionId).dataset.suppliername;
    suppliername.innerText = suppliernameupdate;

    // 3. Affichage min order par founisseur sélectionné
    //const minOrder = document.getElementById(e.target.dataset.minordersupplier);
    const minOrder = document.getElementById(`totalht-${e.target.dataset.itemId}`);
    const minOrderUpdate = document.getElementById(optionId).dataset.minorder;
    minOrder.dataset.minOrder = minOrderUpdate;
    minOrder.dataset.supplierName = suppliernameupdate;


    // 3. Affichage nom conditionnement
    const conditioningunit = document.getElementById(e.target.dataset.conditioningunitselected);
    const conditioningunitupdate = document.getElementById(optionId).dataset.conditioningname;
    conditioningunit.innerText = conditioningunitupdate;

    // 4.1 Affichage des quantités par unité (au kg / L) en hidden pour mise à jour des quantités en kg / L dans le panier (quantité du supplieritem)
    const supplieritemsize = document.getElementById(`supplieritemsize-${e.target.dataset.itemId}`);
    const supplieritemsizeupdate = document.getElementById(optionId).dataset.size;
    supplieritemsize.innerHTML = supplieritemsizeupdate;
    // console.log(supplieritemsize);

    //4.2.1 Mise à jour des quantités unitaires commandées (quantité du supplieritem * nbre commandé)
    // Grosse valueur différentiante par rapport à la concurrence : on recalcule les quantités par défaut sur la base des valeurs en L ou kg initialement dans le pannier
    // Cela signifie: je change de supplier_item : j'ai la quantité la plus proche arrondie au supérieure automatiquement calculée pas besoin de réajuster les quantités
    // Plus tard: ajout de la densité en fonction que le supplieritem soit au litre ou au kg pour un même article
    const quantityPerUnit = document.getElementById(`item-quantity-per-unit-${e.target.dataset.itemId}`);
    // console.log(quantityPerUnit.innerHTML);
    const quantityPerUnitValue = parseFloat(quantityPerUnit.innerHTML);


    const quantityInput = document.getElementById(`quantityinput-${e.target.dataset.itemId}`);

    let quantityValueUpdate = parseFloat(quantityInput.value);

    // 4.2.2. Récupération des quantités optimales et unités liées


    // // console.log(optimalQuantityValue);
    // console.log(quantityInput.dataset.eventchanged);


    if (supplieritemsizeupdate == 0 || supplieritemsizeupdate == null) {
      quantityInput.value = 0;
      quantityPerUnit.innerHTML = 0;
    } else {
        const optimalQuantity = document.getElementById(`optimal-quantity-value-${e.target.dataset.itemId}`);
        let optimalQuantityValue = 0;
        let optimalQuantityunit = null;

        if (optimalQuantity != null && optimalQuantity.innerHTML != "-") {
          console.log("not null");
          optimalQuantityValue = parseFloat(optimalQuantity.innerHTML);
          optimalQuantityunit = document.getElementById(`optimal-quantity-unit-${e.target.dataset.itemId}`);
        }
        // console.log(optimalQuantityValue);
        // console.log(optimalQuantityunit);
        // console.log(quantityInput.dataset.eventchanged);

        if (optimalQuantityValue > 0 && optimalQuantityunit != null && quantityInput.dataset.eventchanged == "false") {
          const optimalQuantityUnitValue = optimalQuantityunit.innerText;
          let coeffUnitConversion = 1;
          if (optimalQuantityUnitValue == "cL"  && refunithtupdate == "kg") {
            coeffUnitConversion = 10 / 1000.0;
          // Plus tard: ajout de la densité (et options pour kg et L avec densité)
          } else {
            // Plus tard: ajout de la densité
            if (optimalQuantityUnitValue == "g"  && refunithtupdate == "L") {
              coeffUnitConversion = ( 1 / 10.0 ) / 100.0;
            } else {
              if (optimalQuantityUnitValue == "g"  && refunithtupdate == "kg") {
                coeffUnitConversion = 1 / 1000.0;
              } else {
                if (optimalQuantityUnitValue == "cL"  && refunithtupdate == "L") {
                  coeffUnitConversion = 1 / 100.0;
                }
              }
            }
          }
          quantityValueUpdate = Math.ceil( ( optimalQuantityValue / supplieritemsizeupdate ) * coeffUnitConversion );
          // console.log(coeffUnitConversion);
          // console.log(quantityValueUpdate);
        } else {
          quantityValueUpdate = Math.ceil( ( quantityPerUnitValue / supplieritemsizeupdate ) );
        }

       // ajout densité si besoin entre L et kg
        quantityInput.value = quantityValueUpdate.toFixed(1);
        if (quantityInput.style.backgroundColor == "rgba(253, 16, 21, 0.13)") {
          quantityInput.style.backgroundColor = "#FCFCFC";
        }
        quantityPerUnit.innerHTML = ( quantityValueUpdate * supplieritemsizeupdate ).toFixed(3);
      }


    //4.3 Mise à jour de l'unité dans le panier (kg / L) en fonction de l'unité de base du supplieritem
    const quantityUnitInput = document.getElementById(`itemquantity-cost-unit-${e.target.dataset.itemId}`);
    quantityUnitInput.innerHTML = refunithtupdate;

    //4.4 Mise à jour de la quantité de conditionnement dans le panier
    const itemquantity = document.getElementById(`item-quantity-${e.target.dataset.itemId}`);
    itemquantity.innerHTML = quantityValueUpdate;
    const hiddenquantity = document.getElementById(`hidden-quantity-${e.target.dataset.itemId}`);
    hiddenquantity.value = quantityValueUpdate.toFixed(2);


    //5 Mise à jour du prix sous-total (pour éviter le problème prix par unité * nombre d'unités de base (prix au kg * nb. de kg))
    const subtotal = refprixhtupdate * parseFloat(quantityPerUnit.innerText);
    const subtotalhtml = document.getElementById(`totalht-${e.target.dataset.itemId}`);
    subtotalhtml.innerText = subtotal.toFixed(2);
    // console.log(refprixhtupdate);
    // console.log(subtotal);
    // console.log(subtotal.toFixed(2));
    // console.log(subtotalhtml);

    //6 Mise à jour du total du panier
    totalshopcart();
    const containerIndex = document.querySelector('.content-order');

    // 7. ajax pour update des cart_items
    $.ajax(`/cart_items/${e.target.dataset.itemId}`, {
      type: "update",
      action: "update",
      method: "PATCH",
      dataType: 'JSON',
      data: {cart_item: {quantity: quantityValueUpdate, supplier_item_id: e.target.value }},
      statusCode: {
        401: function() {
          window.location = "/cart_items";
        },
      },
    })//.then(response => response.text())
    //   .then((data) => {
    //     containerIndex.innerHTML = data;
    //     const event = new CustomEvent('turbolinks:load');
    //     // récupérer event chargement turblinks
    //     document.dispatchEvent(event);
    //     // Poster un event
    //   });
  });

};



export {updateSupplierCartIndex}
