import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
  
export default class extends Controller {
  static targets = ['result_response', 'pos_name', 'api_cash', 'key1', 'key2', 'call_api'];

  connect() {
  }

  show(e) {
  	if (e.target.value !== "") {
	  	this.api_cashTargets.forEach((element)=>{
	  		element.classList.remove('d-none');
	  	})
		} else {
			this.api_cashTargets.forEach((element)=>{
		  		element.classList.add('d-none');
		  	})
		}
  }

  response(e) {
    e.preventDefault();
    const pos_name = this.pos_nameTarget.value
    const key1 = this.key1Target.value
    const key2 = this.key2Target.value
    const url = `/test_url_sales_api?pos_name=${pos_name}&key1=${key1}&key2=${key2}`

    fetch(url, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
    })
    .then(response => response.json())
    .then((data) => {
      if (data == '200') {
        this.result_responseTarget.innerHTML = '<span data-api-target="api_cash" class="badge badge-success text-white">Connecté !</span>'
      } else {
        this.result_responseTarget.innerHTML = '<span data-api-target="api_cash" class="badge badge-danger">Echec</span>'
      }
    });
  }

  get(e){
    const url = `/upload_via_api`
    fetch(url, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
    })
    .then(response => response.json())
    .then((data) => {
      //console.log(data)
      window.location.href = '/sales'
      // if (data == '200') {
      //   this.result_responseTarget.innerHTML = '<span data-api-target="element_show" class="badge badge-success text-white">Réussi</span>'
      // } else {
      //   this.result_responseTarget.innerHTML = '<span data-api-target="element_show" class="badge badge-danger">Ko</span>'
      // }
    });
  }
}         
