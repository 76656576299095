import $ from 'jquery';

const restaurantSelectFromUser = () => {
    // 1. submit on change pour le select
  const submitFormSelect = (select, form) => {
    select.change(function(){
      form.submit();
    });
  }
  submitFormSelect($('#user-restaurant-select'), $('#userrestaurant-formsubmit'));
  submitFormSelect($('#user-company-select'), $('#usercompany-formsubmit'));
}

export {restaurantSelectFromUser}
