
const addIngredient = () => {

  const addIngredientItem = document.getElementById('add-ingredient');
  const newIngredient = document.getElementById('new_ingredient');
  const cancelIngredient = document.getElementById('add_ingredient-cancel-button');
  // console.log(addIngredientItem);
  // console.log(newIngredient);
  // console.log(cancelIngredient);

  if (addIngredientItem && newIngredient && cancelIngredient){
  if (addIngredientItem, newIngredient){
    newIngredient.style.display = "none";

    addIngredientItem.style.display = "block";

    addIngredientItem.addEventListener('click', (event) => {
      event.preventDefault();


      if (event.target){
        newIngredient.style.display = "block";
        addIngredientItem.style.display = "none";

      }
    });

    cancelIngredient.addEventListener('click', (event) => {
      event.preventDefault();
      const elementSelect = document.getElementById('ingredient_element_id');


      if (event.target){
        //Réinitialisation selecteur ingrédients
        elementSelect.selectedIndex = elementSelect.options[0];
        const elementSelectContainer = document.getElementById('select2-ingredient_element_id-container');
        elementSelectContainer.innerHTML = elementSelect.options[0].innerHTML;

        //Réinitialisation quantité d'ingrédients
        const ingredientQuantityInput = document.getElementById('ingredient_quantity');
        ingredientQuantityInput.value = 0;

        //Réinitialisation ingredient_units
        let ingredientUnitSelect = document.getElementById('ingredientUnitsList');
        const basisUnitArray = ["kg", "g", "L", "cL", "mL"]
        ingredientUnitSelect.options.length = 0

        let i = 0
        basisUnitArray.forEach((basisUnit) => {
          ingredientUnitSelect.options[ingredientUnitSelect.length] = new Option(basisUnit, basisUnit)
          Option.value =
          i++;
        });

        newIngredient.style.display = "none";
        addIngredientItem.style.display = "block";

      }
    });
 }
 }
}

export {addIngredient};

// effacer la création de l'ingrédient par un click en dehors du formulaire

// TEST 1

//     newIngredient.addEventListener('click', (event) => {
//     // var flyoutElement = document.getElementById('flyout-example'),
//     event.preventDefault();


//     do {
//         if (event.target) {
//             // This is a click inside. Do nothing, just return.
//             newIngredient.style.display = "block";
//             addIngredientItem.style.display = "none";
//             return;
//         }
//         // Go up the DOM
//         // targetElement = targetElement.parentNode;
//     } while (targetElement);

//     // This is a click outside.
//     newIngredient.style.display = "block";
//     addIngredientItem.style.display = "none";
// });

// TEST 2



//     document.addEventListener('click', (event) => {
//     // var flyoutElement = document.getElementById('flyout-example'),
//     event.preventDefault();
//         targetElement = event.target;  // clicked element

//     do {
//         if (targetElement == newIngredient) {
//             // This is a click inside. Do nothing, just return.
//             newIngredient.style.display = "block";
//             addIngredientItem.style.display = "none";
//             return;
//         }
//         // Go up the DOM
//         targetElement = targetElement.parentNode;
//     } while (targetElement);

//     // This is a click outside.
//     newIngredient.style.display = "block";
//     addIngredientItem.style.display = "none";
// });

// TEST 3

// var isClickInside = newIngredient.contains(event.target);

      // if (!isClickInside) {
      // newIngredient.style.display = "none";
      // addIngredientItem.style.display = "block";
      // }


