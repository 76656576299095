import $ from 'jquery';
import 'select2';

const formatSupplier = (state) => {
  if(state.id) {
    return state.element.dataset.label;
  }
}

function categories (cat) {
  var img = $(cat.element).attr("data-thumbnail");
  //console.log(img);
  if (!cat.id || cat.id == "Toutes catégories" || cat.id == "Catégorie") {
    return cat.text;
  }
  var $cat = $(
    '<span><img src="' + img + '"  width="20px" /> ' + cat.text + '</span>'
  );
  return $cat;
};

function recipes (recipe) {
  var type = $(recipe.element).attr("data-type");
  const img = "/assets/cooking-6ec19aa588ebc918506e734ea48ea9b752e90ebcc0a5c12ca232b476d070be7d.svg";
  if (type == "Recipe") {
  return $('<span class="recipe-ingredient-wrapper">' + recipe.text + `<span class="recipe-ingredient"><image class="recipe-ingredient-img" src="${img}"></span> </span>`);
  } else {
    return recipe.text
  }
};

function formatState (state) {
  // if (!state.id) { return state.text; }
  if (state.text == "Créer une zone" || state.text == "Créer une unité"){
    var $state = $('<span class="add-inventory-place"><i class="fas fa-plus-circle pr-2"></i>' + state.text + '</span>');
    return $state;
  } else {
    return state.text;
  }
};

const initSelect2 = () => {
  $('.select2').select2({
    templateResult: formatSupplier
  })
  $('.categorywithclear').select2({
    placeholder: "Toutes catégories",
    allowClear: true,
    "language": {
      "noResults": function(){
         return "Aucune commande";
      }
    },
  })
  $('.restaurantwithclear').select2({
    placeholder: "Tous restaurants",
    allowClear: true,
  })
  $('.select2-selection__clear').click(function(){
    $(".categorywithclear").select2('val', 'All');
  });
  $('.supplierwithclear').select2({
    placeholder: "Fournisseurs",
    allowClear: true,
    "language": {
      "noResults": function(){
         return "Aucune commande";
      }
    },
  })
  $('.productwithclear').select2({
    placeholder: "Produits",
    allowClear: true,
    "language": {
      "noResults": function(){
         return "Aucune commande";
      }
    },
  })
  $('.allergenes').select2({
    width: '100%',
    theme: 'classic'
  });
  $('.approval_list').select2({
    placeholder: "Indiquer les approbateurs"
  })
  $('.product_recipe_type').select2({
    width: '100%',
    theme: 'classic',
    placeholder: "Selectionner un type"
  });
  $('.restaurant_stock_choice').select2({
    width: '100%',
    theme: 'classic',
    placeholder: "Avec/Sans unités de stock"
  });
  $('.inventory_place').select2({
    templateResult: formatState,
    placeholder: "Selectionner une zone",
    theme: 'classic',
    //allowClear: true, //Permet d'afficher la croix pour annuler la sélection
  });
  $('.inventory_place_filter').select2({
    placeholder: "Selectionner une zone",
    theme: 'classic',
    //allowClear: true, //Permet d'afficher la croix pour annuler la sélection
  });
  $('.movement_type').select2({
    placeholder: "Selectionner un mouvement",
    width: '100%',
    theme: 'classic'
  });
  $(".prod-cat").select2({
    templateResult: categories,
    placeholder: "Selectionner une catégorie",
      dropdownAutoWidth : true,
 
  });
  $(".prod-cat-stock").select2({
    templateResult: categories,
    placeholder: "Selectionner une catégorie",
    width: '100%',
    theme: 'classic'
  });
  $(".recipe_list").select2({
    width: '100%',
    templateResult: recipes,
    templateSelection: recipes
  });



  
 //  $('.portion_units').select2({
 //    width: '100%',
 //    theme: "classic"
 // });
 //  $('.recipe_units').select2({
 //    width: '100%',
 //    theme: "classic"
 // });
};
export { initSelect2, formatState };

