import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import $ from 'jquery';
import 'select2';
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { French } from "flatpickr/dist/l10n/fr.js"
require('flatpickr/dist/plugins/monthSelect/style.css')
import { colorMargin } from "../components/colorMargin.js";
import { sortTable } from "../functions/sort.js";

var start_date, end_date

const splitPeriod = (period) => {
    start_date = ""
    end_date = ""
    if (period.includes('au')) {
        start_date = period.toString().split(' au ')[0];
        end_date = period.toString().split(' au ')[1];
    } else {
        start_date = period
        end_date = period
    }
    return [new Date(start_date), new Date(end_date)]
}

const initFlatpickr = () => {
    const month = document.querySelector('#orderpicker')
    if (month) {
        var dateCurrent = new Date();
        var dateConverted = dateCurrent.toISOString().split('T')[0]

        flatpickr('#orderpicker', {
            altInput: true,
            defaultDate: dateConverted,
            "locale": French,
            plugins: [new monthSelectPlugin({
                shorthand: true,
                altFormat: "F Y",
                dateFormat: "Y-m-d"
            })],
            disableMobile: "true",
            onChange: function () {
                var monthSelected = month.value.split("-")
                var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, monthSelected[2])
                const categories = document.querySelector('.select-category')
                var category = categories.options[categories.selectedIndex].value
                var elementType = categories.options[categories.selectedIndex].parentNode.label
                if (category == "Toutes Catégories") {
                    category = ""
                    elementType = ""
                }
            }
        });
    }

    const period = document.querySelector('#orderpicker2')
    if (period) {
        const orderAnalysisDiv = document.getElementById('order-analysis-div');

        flatpickr("#orderpicker2", {
            altInput: true,
            "locale": French,
            dateFormat: "Y-m-d",
            mode: "range",
            altFormat: "d/m/Y",
            onOpen: function () {
                period.classList.remove("calendar-close");
            },
            onClose: function (selectedDates, dateStr, instance) {
                const categories = document.querySelector('.select-category')
                var category = categories.options[categories.selectedIndex].value
                var elementType = categories.options[categories.selectedIndex].parentNode.label
                if (category == "Toutes Catégories") {
                    category = ""
                    elementType = ""
                }
                period.classList.add("calendar-close");
            },
        });
    }
}

export default class extends Controller {
    static targets = ['category', 'select', 'month', 'period', 'cards'];

    connect() {
        console.log('connecté')
        $(this.categoryTarget).on('select2:select', function () {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
        });
        $(this.categoryTarget).on('select2:unselecting', function (e) {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
        });
        initFlatpickr();
    }

    choice(e) {
        const linkActiveText = e.target.dataset.choice;
        const inputByMonth = document.querySelector('.input+.input')
        const inputByImport = document.querySelector('.input2+input')
        inputByMonth.classList.toggle("date-input-none");
        this.monthTarget.classList.toggle("active");
        inputByImport.classList.toggle("date-input-none");
        this.periodTarget.classList.toggle("active");
        this.get_data();
    }

    get_data() {
        var category, restaurant, start_date, end_date, choice, url
        category = this.categoryTarget.options[this.categoryTarget.selectedIndex].value
        var close = document.querySelector("#orderpicker2").classList.contains('calendar-close');
        if (this.monthTarget.classList.contains('active')) {
            var monthSelected = this.monthTarget.value.split("-")
            var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, 1)
            start_date = dateSelected
            end_date = dateSelected;
            choice = "month";
            close = true;
        } else {
            var periodSelected = this.periodTarget.value
            start_date = splitPeriod(periodSelected)[0]
            end_date = splitPeriod(periodSelected)[1]
            choice = "period"
        }

        if (!isNaN(start_date) && close) {
            url = `/loss_analysis?margin[category]=${encodeURIComponent(category)}&margin[start_date]=${start_date}&margin[end_date]=${end_date}&margin[choice]=${choice}`

            fetch(url, {
                method: 'GET',
                headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
            })
                .then(response => response.json())
                .then((data) => {

                    ////////// Update Cards ////////
                    const cards = document.getElementById('sales-card-infos')
                    cards.innerHTML = data[0]

                    ////////// Update Table By Product ////////
                    const table_products = document.getElementById('margin-analysis-table-products')
                    table_products.innerHTML = data[data.length - 1]
                    colorMargin();
                    sortTable();

                });
        }
    }
}
