const computeTvaRestProdForm = () => {

  // Partie pour recipe form initiale factorisée
  const udapteProdPriceWoTva = (recipeTva, recipettcprice, recipehtprice, prepsentence, postsentence) => {

    if (recipeTva && recipettcprice && recipehtprice){

      recipeTva.addEventListener('change', (event) => {
        event.preventDefault();

        if (recipeTva.value >= 0 && recipettcprice.value >= 0){
          recipehtprice.innerHTML = `${prepsentence} ${ ( recipettcprice.value / (1 + (recipeTva.value / 100 )) ).toFixed(1) } ${ "€HT" } ${postsentence}`;
        }
        else{
          recipehtprice.innerHTML = `(- €HT )`;
        }
      });

      recipettcprice.addEventListener('change', (event) => {
        event.preventDefault();

        if (recipeTva.value >= 0 && recipettcprice.value >= 0){
          recipehtprice.innerHTML = `${ prepsentence} ${ ( recipettcprice.value / (1 + (recipeTva.value / 100 )) ).toFixed(1) } ${ "€HT" } ${postsentence}`;
        }
        else{
          recipehtprice.innerHTML = `(- €HT )`;
        }
      });

      if (recipeTva.value >= 0 && recipettcprice.value >= 0){
        recipehtprice.innerHTML = `${prepsentence} ${ ( recipettcprice.value / (1 + (recipeTva.value / 100 )) ).toFixed(1) } ${ "€HT" } ${postsentence}`;
      }
      else{
        recipehtprice.innerHTML = `( - €HT )`;
      }
    }
  }

  // Partie multi-établissements
  //Tout part des switch buttons s'il y a plusieurs restaurants. Un dataset est fixé pour retrouver le bon id (new pour create) et psu id pour edit
  const switchButtonmultiRestProds = document.querySelectorAll('.rest_product-uniform-multi-restaurants');
  // console.log(switchButtonmultiRestProds);
  if (switchButtonmultiRestProds && switchButtonmultiRestProds.length > 0) {

    switchButtonmultiRestProds.forEach((switchButtonmultiRestProd) => {
      // Récupération du psuid
      let psuIdIndex = switchButtonmultiRestProd.dataset.itempsuid;
      const multiRestContentForm = document.getElementById(`${psuIdIndex}_display-multi-restaurants-product-form`);

       if (multiRestContentForm) {

        const restaurantInputsProd = multiRestContentForm.querySelectorAll('.rest_prod_multi_restaurants_id');
        restaurantInputsProd.forEach((input) => {
          let itemIndex = input.dataset.itemindex;
          let restProdTva = document.getElementById(`product_selling_unit_${psuIdIndex}_restaurant_products_attributes_${itemIndex}_tva_item`);
          let restProdttcprice = document.getElementById(`product_selling_unit_${psuIdIndex}_restaurant_products_attributes_${itemIndex}_price_per_item`);
          let restProdhtprice = document.getElementById(`psu_${psuIdIndex}_restprodhtprice-${itemIndex}`);
          let prepsentence = "(";
          let postsentence = ")";

          if (restProdTva && restProdttcprice && restProdhtprice) {
            if (input.checked == true) {
              udapteProdPriceWoTva(restProdTva, restProdttcprice, restProdhtprice, prepsentence, postsentence);
            } else {
              restProdhtprice.innerHTML = `( - €HT )`;
            }
            udapteProdPriceWoTva(restProdTva, restProdttcprice, restProdhtprice, prepsentence, postsentence);
          }
          // console.log(linkedInput);
        });

        // Partie pour mise à jour de l'input uniforme en multi-établissements
        const restProdUniformTva = document.getElementById(`${psuIdIndex}-product_uniform_tva`);
        const restProdTtcUniformPrice = document.getElementById(`${psuIdIndex}-product_uniform_price`);
        const restProdUniformHtPrice = document.getElementById(`${psuIdIndex}-uniformproducthtpricecompute`);
        let prepsentence = "(";
        let postsentence = ")";
        udapteProdPriceWoTva(restProdUniformTva, restProdTtcUniformPrice, restProdUniformHtPrice, prepsentence, postsentence);

      }

    });
  }
  // Partie pour mise à jour si restaurant unique
  const uniquerestprodinformations = document.querySelectorAll('.restproduniquedisplay');
  uniquerestprodinformations.forEach((uniquerestprodinformations) => {
    // Récupération du psuid
    let psuId = uniquerestprodinformations.dataset.itempsuid;

     if (uniquerestprodinformations != null) {
      if (psuId == "new") {
        const restProdUniqueTva = document.getElementById(`product_selling_unit_restaurant_products_attributes_0_tva_item`);
        const restProdUniquettcprice = document.getElementById(`product_selling_unit_restaurant_products_attributes_0_price_per_item`);
        const restProdUniquehtprice = document.getElementById(`${psuId}-initialproducthtpricecompute`);
        let prepsentence = ', soit';
        let postsentence = ' ';
        udapteProdPriceWoTva(restProdUniqueTva, restProdUniquettcprice, restProdUniquehtprice, prepsentence, postsentence);

      } else {
        const restProdUniqueTva = document.getElementById(`product_selling_unit_${psuId}_restaurant_products_attributes_0_tva_item`);
        const restProdUniquettcprice = document.getElementById(`product_selling_unit_${psuId}_restaurant_products_attributes_0_price_per_item`);
        const restProdUniquehtprice = document.getElementById(`${psuId}-initialproducthtpricecompute`);
        let prepsentence = ', soit';
        let postsentence = ' ';
        udapteProdPriceWoTva(restProdUniqueTva, restProdUniquettcprice, restProdUniquehtprice, prepsentence, postsentence);
      }
    }
   });

}

export {computeTvaRestProdForm};
