import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

const refreshData = (data, referent) => {
  if (data.inserted_gross_size) {
    referent.gross_sizeTarget.innerHTML = ""
    referent.gross_sizeTarget.insertAdjacentHTML("beforeend", data.inserted_gross_size);
  }
  if (data.inserted_recipe_composition) {
    referent.recipe_compositionTarget.innerHTML = ""
    referent.recipe_compositionTarget.insertAdjacentHTML("beforeend", data.inserted_recipe_composition);
  }
  if (data.inserted_recipe_kpis) {
    referent.recipe_kpisTarget.innerHTML = ""
    referent.recipe_kpisTarget.insertAdjacentHTML("beforeend", data.inserted_recipe_kpis);
  }
  if (data.inserted_kpis_recap) {
    referent.kpis_recapTarget.innerHTML = ""
    referent.kpis_recapTarget.insertAdjacentHTML("beforeend", data.inserted_kpis_recap);
  }
}
////
const changeStockUnits = (element_for_message, recipeUnit, recipeComputedSize, recipePortionsQuantity) => {
  var recipeId = window.location.href.split('/')[window.location.href.split('/').length-1]

  fetch(`/change_stock_units?recipe_id=${recipeId}&recipe_unit=${recipeUnit}&recipe_compute_size=${recipeComputedSize}&recipe_portions_quantity=${recipePortionsQuantity}`, {
    method: 'GET',
    headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
  })
  .then(response => response.json())
  .then((data) => {
    var stock_unit_row = document.querySelector('.stock_unit-rows-show')
    if (stock_unit_row) {
      stock_unit_row.innerHTML = data.inserted_item_for_recipe
    }
    element_for_message.innerHTML = data.inserted_item_for_ingredients
  });
}

export default class extends Controller {
  static targets = ['items', 'form', 'element', 'gross_size', 'recipe_composition', 'recipe_kpis', 'delete', 'kpis_recap', 'stockMessage', 'recipe_unit', 'recipe_computed_size', 'recipe_portions_qty'];


  connect() {
  }

  send(e) {
    e.preventDefault();
    if (this.hasFormTarget) {
      fetch(this.formTarget.action, {
        method: 'POST',
        headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
        body: new FormData(this.formTarget)
      })
      .then(response => response.json())
      .then((data) => {
        var recipeUnit, recipeComputedSize, recipePortionsQuantity
        recipeUnit = this.recipe_unitTarget.innerHTML
        recipeComputedSize = this.recipe_computed_sizeTarget.innerHTML
        recipePortionsQuantity = this.recipe_portions_qtyTarget.innerHTML
        if (data.inserted_item) {
          this.itemsTarget.insertAdjacentHTML("beforeend", data.inserted_item);
        }
        this.formTarget.outerHTML = data.form;
        refreshData(data, this)
        const event = new CustomEvent('turbolinks:load');
        document.dispatchEvent(event);
        var message = document.querySelector('.stock-message')
        if (message) {
          changeStockUnits(message, recipeUnit, recipeComputedSize, recipePortionsQuantity);
        }

        // Récupération du nouveau recipe_computed_size après ajout de l'ingrédient
        const parser = new DOMParser();
        const html = parser.parseFromString(data.inserted_item, 'text/html');
        const recipeComputedSizeNew = html.querySelector('.recipe_computed_size').innerHTML
        const ingredientsWithRecipeComputedSize = document.querySelectorAll('.recipe_computed_size')

        ingredientsWithRecipeComputedSize.forEach((computedSize)=>{
          computedSize.innerHTML = recipeComputedSizeNew
        })

      });

    }
  }

  destroy(e) {
    if(confirm('Etes vous certain de vouloir supprimer cet ingrédient ?')) {
      var path_recipe = e.path || (e.composedPath && e.composedPath()) || composedPath(e.target);
      this.currentDelete =  path_recipe[3]
      this.ingredientId = e.target.getAttribute('data-id');
      const recipeId = parseInt(this.deleteTarget.baseURI.split('/').slice(-1));
      e.preventDefault();
      if (this.hasDeleteTarget) {
        fetch(this.deleteTarget.baseURI + '/ingredients/' + this.ingredientId, {
          method: 'DELETE',
          headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
          body: JSON.stringify({recipe_id: recipeId})
        })
        .then(response => response.json())
        .then((data) => {
          var recipeUnit, recipeComputedSize, recipePortionsQuantity
          recipeUnit = this.recipe_unitTarget.innerHTML
          recipeComputedSize = this.recipe_computed_sizeTarget.innerHTML
          recipePortionsQuantity = this.recipe_portions_qtyTarget.innerHTML
          if (data.title == "error_avoid_delete") {
            window.location.href = this.deleteTarget.baseURI
          } else {
            this.currentDelete.remove();
            refreshData(data, this);
            $(".select-ingredient-element").select2("destroy");
            const event = new CustomEvent('turbolinks:load');
            document.dispatchEvent(event);
          }
          var message = document.querySelector('.stock-message')
          if (message) {
            changeStockUnits(message, recipeUnit, recipeComputedSize, recipePortionsQuantity);
          }

          // Récupération du nouveau recipe_computed_size après suppression de l'ingrédient
          const recipeComputedSizeNew = data.recipe_computed_size
          const ingredientsWithRecipeComputedSize = document.querySelectorAll('.recipe_computed_size')

          ingredientsWithRecipeComputedSize.forEach((computedSize)=>{
            computedSize.innerHTML = recipeComputedSizeNew
          })

        });
      }

    } else {
      e.preventDefault();
    }
  }

}
