
const autocompleteSearchSubmit = () => {
  const searchs = document.querySelectorAll(".search form");

  if(searchs.length > 0) {
    searchSubmitDataSkills(searchs)
  } else {
    const combinedSearchForm = document.querySelector(".form-combined-search");
    //console.log(searchs);
    if (combinedSearchForm) {
      const searchs = [combinedSearchForm];
      // console.log(searchs);
      searchSubmitDataSkills(searchs);
    }
  }
}

const searchSubmitDataSkills = (searchs) => {
  searchs.forEach((search) => {
    const searchInputs = search.querySelectorAll(".form-control.rounded");
    searchInputs.forEach((searchInput) => {
      searchInput.addEventListener("focus", function() {
        // console.log("change searchinput");
        if (searchInput.value.length > 0) {
          let autocompleteSelected = document.querySelector(".autocomplete-suggestion.selected");
          // console.log(autocompleteSelected);
          if(autocompleteSelected != null) {
            search.submit();
          }
        }
      });
    });
  });
}

export {autocompleteSearchSubmit};
