import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import $ from 'jquery';
import 'select2';

const createInventoryPlace = () => {
  $('#inventory_place_name_principal').change(function() {
    if($('#inventory_place_name_principal option:selected').val() == "0") {
      $("#modal_inventory_places").modal('show');
      $('#modal_inventory_places').modal({backdrop: 'static', keyboard: false})  
      var modal = document.getElementById('modal_inventory_places')
      $("#modal_inventory_places").on('hidden.bs.modal', function(){
        $('#inventory_place_name_principal').prop('selectedIndex', 0).change();
      });
    }
    // Ajout de l'id de l'inventory place à tous les inventory items.
    var items = document.querySelectorAll('.inventory_movement_inventory_movement_items_inventory_place_id input')
    items.forEach((item)=>{
      item.value = $('#inventory_place_name_principal option:selected').val();
    })
  });
}

export default class extends Controller {
  static targets = ['principal','secondary', 'input','form','options','modal', 'addPlace'];

  connect() {
    //createInventoryPlace(); 
  }

  post(e) {
    e.preventDefault();
    console.log("inventory place controller")
    var name_principal = this.principalTarget.value
    var name_secondary = this.secondaryTarget.value
    const restaurantParam = document.getElementById(`inventory_place_restaurant_id`);
    let restaurantId = restaurantParam.value;

    fetch('/inventory_places', {
      method: 'POST',
      headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
      body: new FormData(this.formTarget)    
    })
    .then(response => response.json())
    .then((data) => {
      // Suppression de la modal pour éviter d'être en conflit avec l'event sur le close modal
      $("#modal_inventory_places").remove();
      $(".modal-backdrop").remove();

      if (data.inserted_options) {
        this.optionsTarget.innerHTML = data.inserted_options;
        this.modalTarget.innerHTML = data.inserted_modal;
        
        // Changement du selectedIndex suite à l'ajout de la nouvelle zone de stockage
        var select = document.querySelector('.inventory_place')
        Array.apply(null, select.options).forEach((option)=>{
          if(option.innerText == `${name_principal} > ${name_secondary}`){
            $('#inventory_place_name_principal').prop('selectedIndex', option.index).change();     
          }
        })
        
        // Réinitialisation du select2
        function formatState (state) {
          if (!state.id) { return state.text; }
          if (state.text == "Créer une zone"){
            var $state = $('<span class="add-inventory-place"><i class="fas fa-plus-circle pr-2"></i>' + state.text + '</span>');
          } else {
            var $state = state.text;
          }
         return $state;
        };         
        
        $('.inventory_place').select2({
          templateResult: formatState,
          width: '100%',
          theme: 'classic'
        });
        createInventoryPlace();
      }

    });
  }
}         
