import $ from 'jquery';
import {SearchFetchCartItem} from "../components/cartItemFromSupplierFilter.js";
import {clearSearchInputWoQuery} from "../components/clearSearchInputWoQuery.js";
import {htmlLoadingAnimation} from "../components/loadingAnimationHtml";

 // Pour déclencher la fonction avec un certain laps de temps
function debounce(fn, duration) {
  var timer;
  return function(){
    clearTimeout(timer);
    timer = setTimeout(fn, duration);
  }
}

function insertHtml (html) {
  html.insertAdjacentHTML('beforeend',
    '<div class="empty-cart-form-search" style="margin-top: 5px">'
    +'  <div class="d-flex justify-content-around">'
    +    '<span class="index-empty-cart-text" style="padding: 15px;">'
    +     ' Veuillez sélectionner un article et/ou une catégorie et/ou un fournisseur avec la recherche au-dessus.'
    +    ' <strong>(minimum 2 caractères)</strong>'
    +   ' </span>'
    +  '</div>'
    + '</div>')
}

const submitFormCartItems = () => {
  const submitForms = document.querySelectorAll('.simple_form.new_cart_item');
  const containerIndex = document.querySelector('.content-order');
  submitForms.forEach((submitForm) => {
    if (submitForm.dataset.addEvent) return
    submitForm.dataset.addEvent = true;
    submitForm.addEventListener('submit', (event) => {
      event.preventDefault();
      const itemId = submitForm.dataset.itemid;
      // 1. Récupération des informations pour l'ajax
      // Eléments pour déclencher alerte si déja dans le panier
      const quantityPerUnit = document.getElementById(`cartformitem-quantity-per-unit-${itemId}`);
      const selectSupplierItem = document.getElementById(`cartsupplieritemselect-${itemId}`);
      localStorage.setItem("badgequantityPerUnit", quantityPerUnit.innerHTML);

      let supplierItemId = "n.d";
      // Validation qu'il n'y a pas de problème sur le supplier_item sinon cela créé un bug dans l'html après ajax
      if (selectSupplierItem != null) {
        supplierItemId = parseFloat(selectSupplierItem.value).toFixed(1);
      } else {
        const uniqueSupplierItem = document.getElementById(`uniquecartsupplieritem-${itemId}`);
        supplierItemId = parseFloat(uniqueSupplierItem.dataset.value).toFixed(1);
      }

      if (supplierItemId != null) {
        //console.log(supplierItemId)
        const addInCartButton = document.getElementById(`submit-cartitem-${itemId}`);
        const hiddenquantity = document.getElementById(`cartformhidden-quantity-${itemId}`);
        let quantityValue = hiddenquantity.value;
        const restaurantParam = document.getElementById(`cart_item_${itemId}_restaurant_id`);
        let restaurantId = restaurantParam.value;

        const badgeInCart = document.getElementById(`badgeincart-${itemId}`);
        const badgeInCartQuantity = badgeInCart.querySelector('.in_cart_item_quantity');

        // ConfirmationResult: si l'élément est déja dans le panier, résultat du message de confirmation
        let confirmationResult = true
        if (badgeInCartQuantity.innerHTML > 0){
          let confirmation = confirm("L’article est déja dans le panier. Voulez-vous l’ajouter à nouveau ?");
          if (confirmation == true) {
            confirmationResult = true;
          } else {
            confirmationResult = false;
          }
        }
        addInCartButton.removeAttribute("disabled");
        if (confirmationResult == true) {
         const token = document.querySelector('meta[name="csrf-token"]').content;
          fetch(`/cart_items`, {
            headers: {
              "Content-Type": "application/json",
              // "X-Requested-With": "XMLHttpRequest",
              "X-CSRF-Token": token,
            },
            method: "POST",
            body: JSON.stringify({cart_item: {restaurant_id: restaurantId, supplier_item_id: supplierItemId, quantity: quantityValue}})

          }).then(response => response.text())
            .then((data) => {
              containerIndex.innerHTML = data;
              const event = new CustomEvent('turbolinks:load');
              // récupérer event chargement turblinks
              document.dispatchEvent(event);
              // Poster un event
              addInCartButton.removeAttribute("disabled");
              clearSearchInputWoQuery();
            });

          containerIndex.style.opacity = "0.5";

          // 3. Effet de transition sur le badge pour cart_in et sur le bouton // Mis à la fin car un timeout sur mis à jour des quantités
          if(badgeInCartQuantity && quantityPerUnit){
            setTimeout(udaptequantityinbadge, 800, badgeInCartQuantity, quantityPerUnit);
          }
          if (badgeInCart.className == "already-in-cart_item hidden-badge"){
            $(badgeInCart).toggleClass("active-badge");
          }
          if (addInCartButton.className == "btn button-plus-cart_item"){
            addInCartButton.classList.remove("button-plus-cart_item");
            $(addInCartButton).toggleClass("button-plus-cart_item_already_in");
          }

          // Réinitialisation valeur des quantités (display and hidden) et prix sous-total
          const itemquantity = document.getElementById(`cartformitem-quantity-${itemId}`);
          const itemquantityinput = document.getElementById(`cartformquantityinput-${itemId}`);
          itemquantity.innerHTML = 1;
          hiddenquantity.value = 1;
          itemquantityinput.value = 1;
          const supplieritemsize = parseFloat(document.getElementById(`cartformsupplieritemsize-${itemId}`).innerHTML);
          // const quantityPerUnit = document.getElementById(`cartformitem-quantity-per-unit-${e.target.dataset.itemId}`);
          quantityPerUnit.innerHTML = (supplieritemsize * 1).toFixed(3);
          const refprixunitaireht = document.getElementById(`cartformrefprixunitaireht-${itemId}`);
          if (refprixunitaireht && refprixunitaireht.innerHTML != " " && refprixunitaireht.innerHTML != "n.d") {
            const valeurunitaireht = parseFloat(refprixunitaireht.innerHTML);
            const valeurtotalht = (valeurunitaireht * (supplieritemsize * 1)).toFixed(2);
            const prixtotalht = document.getElementById(`cartformtotalht-${itemId}`);
            prixtotalht.innerHTML = valeurtotalht;
          }
        }
      } else {
         alert("Veuillez compléter la référence fournisseur sélectionnée.");
      }
    });
  });
}

const addCreateCartItems = () => {

  const addCartItem = document.getElementById('add_cart_items');
  const cartItemsForm = document.getElementById('create_cart_items');
  const CloseCarItemForm = document.getElementById('close-form');
  const containerIndex = document.querySelector('.content-order');

  if (!addCartItem || !cartItemsForm || !CloseCarItemForm|| !containerIndex) return
  const nameProductSearch = document.getElementById('searchProductNameCartForm');
  const nameSearchInput = document.getElementById('searchProductNameCartForm').firstChild.nextElementSibling;
  //const categoryProductSearch = document.getElementById('searchProductCategoryCreate');
  //const categorySearchInput = document.getElementById('searchProductCategoryCreate').firstChild.nextElementSibling;
  const categorySearchInput2 = document.getElementById('searchProductCategoryCreate2');

  submitFormCartItems();

  addCartItem.addEventListener('click', (event) => {
    if (event.target){
      cartItemsForm.style.display = "block";
      addCartItem.style.display = "none";
      containerIndex.style.opacity = "0.5";
    }
  });

  CloseCarItemForm.addEventListener('click', (event) => {
    event.preventDefault();
    if (event.target){
      nameSearchInput.value = "";
      //  categorySearchInput.value= "";
      cartItemsForm.style.display = "none";
      addCartItem.style.display = "flex";
      containerIndex.style.opacity = "1";
    }
  });

  if (cartItemsForm.dataset.addEvent) return
  cartItemsForm.dataset.addEvent = true;

  // Partie clearsearch
  const searchNameSubmitButton = nameProductSearch.querySelector(".search-button");
  const searchNameClearButton = nameProductSearch.querySelector(".clear-search-button");
  //const searchCategorySubmitButton = categoryProductSearch.querySelector(".search-button");
  //const searchCategoryClearButton = categoryProductSearch.querySelector(".clear-search-button");

  const ClearSearchButtonCartItemForm = (searchClearButton, searchSubmitButton , searchInput) => {
    searchInput.addEventListener("keyup", function() {
      if (searchInput.value.length > 1) {
        searchSubmitButton.style.display = 'none';
        searchClearButton.style.display = 'block';
      } else {
        if (searchInput.value.length == 0) {
          searchClearButton.style.display = 'none';
          searchSubmitButton.style.display = 'block';
        }
      }
    });
  }

  ClearSearchButtonCartItemForm(searchNameClearButton, searchNameSubmitButton , nameSearchInput);
 // ClearSearchButtonCartItemForm(searchCategoryClearButton, searchCategorySubmitButton , categorySearchInput);

  const emptyCartFormSearchInputWoQuery = (searchClearButton, searchInput, searchSubmitButton, type) => {
    if(searchClearButton && searchInput){
      searchClearButton.addEventListener('click', (event) => {
        const supplierFilterSelect = document.getElementById('supplier-filter-select');
        const supplierIdParam = supplierFilterSelect.value

        if(searchClearButton.style.display == 'none') {
          searchSubmitButton.style.display = 'block'
        } else {
          searchSubmitButton.style.display = 'block'
          searchClearButton.style.display = 'none'
        }

        event.preventDefault();
        searchInput.value = "";
        let result = "";

        if ( type == "product") {
           SearchFetchCartItem(searchInput,categorySearchInput2,supplierIdParam);
        } else if (type == "category") {
          SearchFetchCartItem(nameSearchInput,searchInput,supplierIdParam);
        }
      });
    }
  }

  emptyCartFormSearchInputWoQuery(searchNameClearButton, nameSearchInput, searchNameSubmitButton, "product");
  //emptyCartFormSearchInputWoQuery(searchCategoryClearButton, categorySearchInput, searchCategorySubmitButton, "category");


  let productLines = document.querySelectorAll(".cartform-grid-template");
  const noResultSearch = (lines) => {
    let linesHiddenCount = 0;
    lines.forEach((productLine) => {
      if(productLine.style.display == 'none'){
        linesHiddenCount += 1;
      }
    });
    const noResultSearch = document.querySelector(".empty-cart-form-search");
    if (linesHiddenCount == productLines.length){
      noResultSearch.style.display = 'flex';
    } else {
      noResultSearch.style.display = 'none';
    }
  }

  nameSearchInput.addEventListener("keyup", debounce(() => {
    const supplierFilterSelect = document.getElementById('supplier-filter-select');
    const supplierIdParam = supplierFilterSelect.value
    let supplierId = parseFloat(supplierIdParam);
    if(nameSearchInput.value.length > 1) {
      //console.log("Caractères > 1")
      SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
    } else if(categorySearchInput2.value != "Catégorie") {
      //console.log("Catégorie sélectionnée")
      SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
    } else if (supplierIdParam != "") {
      //console.log("Fournisseur sélectionné")
      SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
    } else {
      const emptyMessage = document.querySelector('.empty-cart-form-search')
      const cartItemsContent = document.getElementById('cart-item-content')
      const cartItemsRow = document.querySelector('.cart-item-rows')
      //console.log("Aucune sélection")
      if(emptyMessage){
      } else {
        cartItemsRow.outerHTML = "";
        insertHtml(cartItemsContent)
      }
    }
  }, 500));

  nameSearchInput.addEventListener('submit', (event) => {
    event.preventDefault();
  });

  function prodCat (cat) {

    var img = $(cat.element).attr("data-thumbnail");
    if (!cat.id || cat.id == "Toutes catégories" || cat.id == "Catégorie") {
      return cat.text;
    }
    var $cat = $(
      '<span><img src="' + img + '"  width="20px" /> ' + cat.text + '</span>'
    );
    return $cat;
  };

  $(".prodcat").select2({
    templateResult: prodCat,
    dropdownAutoWidth : true
  });

  // Select2 Prod Cat

  $('#searchProductCategoryCreate2').on('select2:select', function (e) {
    e.preventDefault();
    const supplierFilterSelect = document.getElementById('supplier-filter-select');
    const supplierIdParam = supplierFilterSelect.value
    let supplierId = parseFloat(supplierIdParam);
    const closeBtnCategory = document.getElementById('close-category-filter');

    if(categorySearchInput2.value != "Catégorie") {
      closeBtnCategory.style.display = 'flex';
    } else {
      closeBtnCategory.style.display = 'none';
    }

    closeBtnCategory.addEventListener('click', (e) => {
        $("#searchProductCategoryCreate2").val('Catégorie');
        $("#searchProductCategoryCreate2").trigger('change');
        categorySearchInput2.value = "Catégorie";
        closeBtnCategory.style.display = 'none';
        const supplierFilterSelect = document.getElementById('supplier-filter-select');
        const supplierIdParam = supplierFilterSelect.value
        let supplierId = parseFloat(supplierIdParam);
        // console.log(nameSearchInput)
        // console.log(categorySearchInput2)
        // console.log(supplierId)
        SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
     })

    if(categorySearchInput2.value.length > 1) {
      SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
    } else if(nameSearchInput.value.length > 1) {
      SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
    } else if (supplierIdParam != "") {
      SearchFetchCartItem(nameSearchInput,categorySearchInput2,supplierId);
    } else {
      const emptyMessage = document.querySelector('.empty-cart-form-search')
      const cartItemsContent = document.getElementById('cart-item-content')
      const cartItemsRow = document.querySelector('.cart-item-rows')

      if(emptyMessage){
      } else {
        cartItemsRow.outerHTML = "";
        insertHtml(cartItemsContent)
      }
    }
  });


}

const udaptequantityinbadge = (badgeInCartQuantity, quantityPerUnit) => {
  if (badgeInCartQuantity.innerHTML == 0) {
    let initialQuantity = localStorage.getItem("badgequantityPerUnit");
    badgeInCartQuantity.innerHTML = parseFloat(initialQuantity).toFixed(1);
  } else {
    if( parseFloat(quantityPerUnit.innerHTML) != null ){
      let initialQuantity = localStorage.getItem("badgequantityPerUnit");
      badgeInCartQuantity.innerHTML = (parseFloat(badgeInCartQuantity.innerHTML) + parseFloat(initialQuantity)).toFixed(1);
      localStorage.removeItem('badgequantityPerUnit');
    }
  }
}

export {addCreateCartItems};
