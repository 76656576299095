import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['radio', 'submit'];

  connect() {
  }

  submit(e) {
    var radioButtons = this.radioTargets
    var radioParams = ""
    var confirmed = confirm('Etes vous certain de vouloir créer toutes les unités de stock ?')
    var modalBody = document.querySelector('.confirm-orders')
    if (confirmed) {
      e.preventDefault();

      for(var i = 0; i < radioButtons.length; i++ ) {
        if (radioButtons[i].checked) {
          radioParams = radioButtons[i].dataset.type;
          break;
        }
      }

      var text =
      "<span class='jumping-dots'>Création en cours" +
      "<span class='dot-1'>.</span>" +
      "<span class='dot-2'>.</span>" +
      "<span class='dot-3'>.</span>" +
      "</span>"

      modalBody.insertAdjacentHTML("beforeend", text)

      fetch(`/create_all_stock_units?type=${radioParams}`, {
        method: 'GET',
        headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
        //body: JSON.stringify({sales_errors: checkboxResult})
      })
      .then(response => response)
      .then((data) => {
        //this.containerTarget.insertAdjacentHTML('beforeend', data.inserted_item);
        //$('#modal_create_all_stock_units').modal('show');
        $('#modal_create_all_stock_units').modal('hide');
        window.location.href = "/inventory_movements"
      });

    }
  }
}
