import $ from 'jquery';

const clearSearchInputWoQuery = () => {

  const searchs = document.querySelectorAll(".searchwoquery form");

  if(searchs){
    searchs.forEach((search) => {
      const searchInput = search.querySelector(".form-control.rounded");
      const searchSubmitButton = search.querySelector(".search-button");
      const searchClearButton = search.querySelector(".clear-search-button");

        searchInput.addEventListener("keyup", function() {
          if (searchInput.value.length == 1) {
            searchSubmitButton.style.display = 'none';
            searchClearButton.style.display = 'block';
          } else {
            if (searchInput.value.length == 0) {
              searchClearButton.style.display = 'none';
              searchSubmitButton.style.display = 'block';
            }
          }
        });

        searchClearButton.addEventListener('click', (event) => {
          searchClearButton.style.display = 'none';
          searchSubmitButton.style.display = 'block';
        });
    });
  }

  function fadeIn(element, duration = 600) {
    element.style.display = '';
    element.style.opacity = 0;
    var last = +new Date();
    var tick = function() {
      element.style.opacity = +element.style.opacity + (new Date() - last) / duration;
      last = +new Date();
      if (+element.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      }
    };
    tick();
  }

  const searchButtonFilter = document.querySelector('.button-filter');
    if(searchButtonFilter) {
      searchButtonFilter.addEventListener('click', (event) => {
        const classHidden = document.querySelector(".is-hidden-on-mobile")
        const listElements = document.querySelectorAll('.select-to-show')
        const searchprodcatToDisplay = document.querySelector(".searchprodcat");
        const searchsupplierToDisplay = document.querySelector(".searchsupplier");
        const searchcategoryToDisplay = document.querySelector(".search-recipe-category");
        const inventoryPlaceToDisplay = document.querySelector('.inventory-places');
        const stockUnitsToDisplay = document.querySelector('.stock-units')
        const stockCatToDisplay = document.querySelector('.stock-cat')
        const stockTypeToDisplay = document.querySelector('.stock-type')

        if(classHidden) {
          if (listElements.length > 0) {
            listElements.forEach((elem)=>{
              fadeIn(elem, 600)
              elem.classList.remove('is-hidden-on-mobile')
            })
          } else if (inventoryPlaceToDisplay && stockUnitsToDisplay && stockCatToDisplay && stockTypeToDisplay) {
            fadeIn(inventoryPlaceToDisplay,600);
            fadeIn(stockUnitsToDisplay, 600);
            fadeIn(stockCatToDisplay, 600);
            fadeIn(stockTypeToDisplay, 600);
            inventoryPlaceToDisplay.classList.remove("is-hidden-on-mobile");
            stockUnitsToDisplay.classList.remove("is-hidden-on-mobile");
            stockCatToDisplay.classList.remove("is-hidden-on-mobile");
            stockTypeToDisplay.classList.remove("is-hidden-on-mobile");
          } else if(searchprodcatToDisplay && searchsupplierToDisplay) {
              fadeIn(searchprodcatToDisplay,600);
              fadeIn(searchsupplierToDisplay,600);
              searchprodcatToDisplay.classList.remove("is-hidden-on-mobile");
              searchsupplierToDisplay.classList.remove("is-hidden-on-mobile");
          } else {
            fadeIn(searchcategoryToDisplay,600);
            searchcategoryToDisplay.classList.remove("is-hidden-on-mobile");
          }

        } else {
          if (listElements.length > 0) {
            listElements.forEach((elem)=>{
              fadeIn(elem, 600)
              elem.classList.add('is-hidden-on-mobile')
            })
          } else if (inventoryPlaceToDisplay && stockUnitsToDisplay && stockCatToDisplay && stockTypeToDisplay) {
            inventoryPlaceToDisplay.classList.add("is-hidden-on-mobile");
            stockUnitsToDisplay.classList.add("is-hidden-on-mobile");
            stockCatToDisplay.classList.add("is-hidden-on-mobile");
            stockTypeToDisplay.classList.add("is-hidden-on-mobile");
          } else if(searchprodcatToDisplay && searchsupplierToDisplay) {
            searchprodcatToDisplay.classList.add("is-hidden-on-mobile");
            searchsupplierToDisplay.classList.add("is-hidden-on-mobile");
          } else {
            searchcategoryToDisplay.classList.add("is-hidden-on-mobile");
          }
        }

      });
    }
}

export {clearSearchInputWoQuery};
