import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ['showModal', 'container', 'cancelButton'];

  connect() {
   console.log('modal controller')
  }

  show(e) {
  	e.preventDefault();
    var product_id = this.showModalTarget.dataset.id
    
    fetch(`/inventory_modal?id=${product_id}`, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
      //body: JSON.stringify({sales_errors: checkboxResult})
    })
    .then(response => response.json())
    .then((data) => {
      this.containerTarget.insertAdjacentHTML('beforeend', data.inserted_item);
      $('#create_zone_stock_unit').modal('show'); 

      $('#create_zone_stock_unit').on('hidden.bs.modal', function () {
			  $(this).remove();
			});
 		


    });
  }

  test() {
  	console.log("ça marche")
  }
}         
