import $ from 'jquery';

const pillsNavOrderApprovals = () => {
  const url = window.location.href;
  const pills = document.querySelectorAll(".tabs_order_approval");
  const pillouvert = sessionStorage.getItem("tabOrderApprovalOuvert");

  if (sessionStorage.tabOrderApprovalOuvert != undefined) {
    pills.forEach((pill)=> {
      if (url.split("/")[url.split("/").length-1] == "orders_approved") {
        //console.log("ok")
        if (pill.id == "pills-tab-approved"){
          pill.classList.add("active");
        } else {
        pill.classList.remove("active");
        }
      } else if(pill.id == pillouvert){
        pill.classList.add("active");
      } else {
        pill.classList.remove("active");
      }
    });
  }

  if (window.location.href.indexOf('/orders_to_approval') > -1) {
    document.getElementById('pills-tab-to-approval').classList.add("active")
    document.getElementById('pills-tab-approved').classList.remove("active")
  }

  $(".tabs_order_approval").on("click", function() {
    sessionStorage.setItem("tabOrderApprovalOuvert",$(this).attr("id"));
    sessionStorage.setItem("url",window.location.href);
  });

}


export {pillsNavOrderApprovals};
