const negativeInputs = () => {

  const checkNegativeInputs = (numbers) => {
    var patForReqdFld = /^\d+([.,]{1})+\d{0,3}?$/;
    numbers.forEach((number) => {
      if (number.name != "recipe[var_rate]") {
        number.addEventListener("keyup", function(e) {
          var keycode = e.keyCode;
          // console.log(number.value)
          if(patForReqdFld.test(number.value)) {
          } else if(/^(-\d)\d{0,2}|(\d-)\d{0,2}$/.test(number.value)) {
            number.value = number.value.replace("-", "");
          } else if (e.keyCode == "190" || e.keyCode == "188" || e.target.value.charAt(e.target.selectionStart - 1).charCodeAt() == "44" || e.target.value.charAt(e.target.selectionStart - 1).charCodeAt() == "46" ) {
          } else {
            if (number.value == "") {
              // number.value = "";
            }
            return false
          }
        });
      }
    });
  }

  var numbers = document.querySelectorAll("input[type='number']");
  var textnumbers = document.querySelectorAll("input[type='textnumber']");
  if (numbers.length > 0) {
    checkNegativeInputs(numbers);
  }
  if (textnumbers.length > 0) {
    checkNegativeInputs(textnumbers);
  }
}

export {negativeInputs};
