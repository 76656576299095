import $ from 'jquery';

const addRecipeMultipleOrdersToForm = () => {

  // Blocs partie mutli-restaurant : bloc form et swithc button
  const multiRestContent = document.getElementById('display-multi-restaurants-recipe-form');
  const switchButtonmultiRest = document.getElementById('recipe-uniform-multi-restaurants');
  const uniformMultiRest = document.getElementById('recipe-uniform-multi-inputs');
  const switchFormMultiRest = document.getElementById('switch-to-multi-restaurants');

  if (multiRestContent) {
    // Liste de sous-blocs par item
    const multiSaleTitle = multiRestContent.querySelector('.title-vente');
    const multiOrderTitle = multiRestContent.querySelector('.title-order');

    const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');
    const multiOrderForms = multiRestContent.querySelectorAll('.input-multi-orders');
  }

  // console.log(uniformMultiRest);

  // 2. Obligé de mettre des inputs variables initialisées pour qu'il soit reconnus dans les méthodes et addevent
  let uniformMultiSaleForms = null;
  let uniformmultiOrderForms = null;
  let uniformPriceInput = null;
  let uniformTvaInput = null;
  let uniformPlateOrderInput = null;
  // console.log(uniformPlateOrderInput);

  if (uniformMultiRest) {
    // Liste de sous-blocs par item
    uniformMultiSaleForms = uniformMultiRest.querySelector('.input-uniform-multivente');
    uniformmultiOrderForms = uniformMultiRest.querySelector('.input-uniform-multiorders');

    uniformPriceInput = document.getElementById('recipe_uniform_price');
    uniformTvaInput = document.getElementById('recipe_uniform_tva');
    uniformPlateOrderInput = document.getElementById('recipe_uniform_plate');
    // console.log(uniformMultiSaleForms);
    // console.log(uniformPlateOrderInput);
  }
  // console.log(uniformMultiSaleForms);
  // console.log(uniformPlateOrderInput);

  // Partie check-buttons pour calibre
  const directSaleButton = document.getElementById('recipe_for_sale');
  const otherRecipeButton = document.getElementById('recipe_other_recipe');
  const subrecipeButton = document.getElementById('recipe_subrecipe');


  if (directSaleButton && otherRecipeButton && subrecipeButton && switchButtonmultiRest && switchFormMultiRest) {

    // Méthode pour disabled un liste d'inputs
    const disabledListOfInput = (listOfInputs) => {
      listOfInputs.forEach((input) => {
        // console.log(input);
        input.disabled = true;
      });
    }

    // Méthode pour enabled et disabled les inputs liés selon que restaurantinput soit checké
    const disabledRestaurantLinkedInputs = (listOfRestaurantInputs, linkedInputName) => {
      listOfRestaurantInputs.forEach((input) => {
        let itemIndex = input.dataset.itemindex;
        const linkedInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_${linkedInputName}`);
        // console.log(linkedInput);
        // console.log(input.checked);
        // console.log(itemIndex);
        if (linkedInput) {
          if (input.checked == true) {
            linkedInput.disabled = false;
          } else {
            linkedInput.disabled = true;
          }
        }
        // console.log(linkedInput);
      });
    }

    // Méthode pour injecter les valeurs aux linked inputs sur le check uniform
    const injectUniformValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
      if (uniformInput.value != "") {
        let uniformInputValue = uniformInput.value
        if (uniformInputValue >= 0) {
          listOfMultipleLinkedInputs.forEach((input) => {
            input.value = parseFloat(uniformInputValue);
          });
        }
      }
    }

    // Méthode pour désactiver les valeurs aux linked inputs sur le decheck uniform
    const removeformValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
      if (uniformInput.value != "") {
        let uniformInputValue = uniformInput.value
        if (uniformInputValue >= 0) {
          listOfMultipleLinkedInputs.forEach((input) => {
            input.value = "";
          });
        }
      }
    }

    // Méthode pour injecter les valeurs aux linked inputs SELECT sur le check uniform (TVA)
    const injectUniformSelectedValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
      if (uniformInput.value != "") {
        let uniformInputValue = uniformInput.value
        if (uniformInputValue >= 0) {
          listOfMultipleLinkedInputs.forEach((input) => {
            input.selectedIndex = uniformInput.selectedIndex;
          });
        }
      }
    }

    // Méthode pour désactiver les valeurs aux linked inputs SELECT sur le decheck uniform (TVA)
    const removeUniformSelectedValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
      if (uniformInput.value != "") {
        let uniformInputValue = uniformInput.value
        if (uniformInputValue >= 0) {
          listOfMultipleLinkedInputs.forEach((input) => {
            input.selectedIndex = 3;
          });
        }
      }
    }

    // Méthode pour mettre à jour le nombre de portions à partir de l'uniform input
    // update nombre de portions avec méthode issue de computeportionsrecipeform
    const updateMultiPortionsCount = () => {
      const countUniformOrderPortions = document.getElementById("uniformorderportionscompute");
      recipeRestaurantInputs.forEach((input) => {
        let itemIndex = input.dataset.itemindex;
        let recipeMultiple = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_plate`);
        let countorderportions = document.getElementById(`orderportions-${itemIndex}`);

        if (recipeMultiple && countorderportions) {
          if (input.checked == true) {
            countorderportions.innerHTML = countUniformOrderPortions.innerHTML;
          } else {
            countorderportions.innerHTML = `(- portions)`;
          }
        }
      });
    }

    // Méthode pour mettre à jour les prix ht à partir de l'uniform input
    const updateMultihtPrices = () => {
      const recipeUniformHtPrice = document.getElementById(`uniformrecipehtpricecompute`);
      recipeRestaurantInputs.forEach((input) => {
        let itemIndex = input.dataset.itemindex;
        // let recipeTva = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_tva_plate`);
        // let recipettcprice = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_price_per_plate`);
        let recipehtprice = document.getElementById(`recipehtprice-${itemIndex}`);
        // let prepsentence = "(";
        // let postsentence = ")";

        if (recipehtprice) {
          if (input.checked == true) {
            recipehtprice.innerHTML = recipeUniformHtPrice.innerHTML;
          } else {
            recipehtprice.innerHTML = `( - €HT )`;
          }
        }
        // console.log(linkedInput);
      });
    }


    //1. Récupération des check restaurants_id
    const recipeRestaurantInputs = document.querySelectorAll('.recipe_multi_restaurants_id');

    // 2. Obligé de mettre des inputs variables initialisées pour qu'il soit reconnus dans les méthodes et addevent avec la protection restaurantinputs
    let recipeSellingPriceInputs = null;
    let recipeTvaPriceInputs = null;
    let recipeOrderPlateInputs = null;

    // Préparation des inputs disabled ou non selon l'edit
    if (recipeRestaurantInputs) {

      // VOIR SI BESOIN DE LES ACTIVER / DESACTIVER SUR LE CHECK
      // Mise à jour des hidden inputs avec l'id pour activer le destroy
      const restaurantRecipeHiddenids = document.querySelectorAll('.restaurant_recipes_initial_id');
      restaurantRecipeHiddenids.forEach((input) => {
        input.disabled = false;
      });


      //2. Récupération des inputs 2.1 sales price et 2.2 tva et 2.3 orders
      recipeSellingPriceInputs = document.querySelectorAll('.recipe_multi_selling_price');
      recipeTvaPriceInputs = document.querySelectorAll('.recipe_multi_selling_tva');
      recipeOrderPlateInputs = document.querySelectorAll('.recipe_multi_plate');

      // if (switchButtonmultiRest.checked == true) {

        //3. disabled les inputs si pas sur edit ou si ne correspondent pas au cas d'usage
        if ( directSaleButton.checked == true || (directSaleButton.checked == true && otherRecipeButton.checked == true) ) {
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "price_per_plate");
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "tva_plate");
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "plate");

        } else {
          if (otherRecipeButton.checked == true){
            disabledRestaurantLinkedInputs(recipeRestaurantInputs, "plate");

            // total disabled
            disabledListOfInput(recipeSellingPriceInputs);
            disabledListOfInput(recipeTvaPriceInputs);
          } else {
            // total disabled
            // console.log("new");
            disabledListOfInput(recipeSellingPriceInputs);
            disabledListOfInput(recipeTvaPriceInputs);
            disabledListOfInput(recipeOrderPlateInputs);
          }
        }
    }


    // 4. affichage sur sélection switch button selon les cas
    // Partie à compléter sur swith button pour afficher la partie multi-établissements et masquer celle uniforme
    switchButtonmultiRest.addEventListener('change', (event) => {

      // Ne doit fonctionner que si la partie vente directe ou autre checkées
      if (event.target.checked) {

        // checked tous les inputs de restaurant
        recipeRestaurantInputs.forEach((input) => {
          input.checked = true;
        });

        // Mise à jour et disabled selon vente ou otherrecipe
        if ( directSaleButton.checked == true || (directSaleButton.checked == true && otherRecipeButton.checked == true) ) {

          // // 4.2.2 Affichage partie uniforminput
          // const uniformMultiSaleTitle = switchFormMultiRest.querySelector('.title-uniform-vente');
          // uniformMultiSaleTitle.style.display = "flex";

          // Mise à jour des disabled (edit)
          // console.log("input to disable");
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "price_per_plate");
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "tva_plate");
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "plate");

          // Mise à jour des valeurs price
          injectUniformValueinLinkedInputs(uniformPriceInput, recipeSellingPriceInputs);
          // Mise à jour des valeurs tva
          injectUniformSelectedValueinLinkedInputs(uniformTvaInput, recipeTvaPriceInputs);
          // Mise à jour des htprices
          updateMultihtPrices();

          // Mise à jour des valeurs plates
          injectUniformValueinLinkedInputs(uniformPlateOrderInput, recipeOrderPlateInputs);
          // update nombre de portions avec méthode issue de computeportionsrecipeform
          updateMultiPortionsCount();

          // Avec simple_form en :restaurant_recipe et pas restaurant_recipes, besoin de refixer la TVA par défaut 136
          // let recipeTvaPriceInputsToFix = document.querySelectorAll('.recipe_multi_selling_tva');
          // recipeTvaPriceInputsToFix.forEach((input) => {
          //   input.selectedIndex = 3;
          // });


        } else {

          if (otherRecipeButton.checked == true) {

            // Mise à jour des disabled (edit)
            disabledRestaurantLinkedInputs(recipeRestaurantInputs, "plate");

            // total disabled
            disabledListOfInput(recipeSellingPriceInputs);
            disabledListOfInput(recipeTvaPriceInputs);

            // Mise à jour des valeurs plates
            injectUniformValueinLinkedInputs(uniformPlateOrderInput, recipeOrderPlateInputs);
            // const countUniformOrderPortions = document.getElementById("uniformorderportionscompute");

            // update nombre de portions avec méthode issue de computeportionsrecipeform
            updateMultiPortionsCount();

          }
        }
        // console.log(recipeSellingPriceInputs);
      } else {
       // dechecked tous les inputs de restaurant
        recipeRestaurantInputs.forEach((input) => {
          input.checked = false;
        });

        if ( directSaleButton.checked == true || (directSaleButton.checked == true && otherRecipeButton.checked == true) ) {

          // réinitialiser valeur
          removeformValueinLinkedInputs(uniformPriceInput, recipeSellingPriceInputs);
          removeUniformSelectedValueinLinkedInputs(uniformTvaInput, recipeTvaPriceInputs);
          removeformValueinLinkedInputs(uniformPlateOrderInput, recipeOrderPlateInputs);

          // total disabled
          disabledListOfInput(recipeSellingPriceInputs);
          disabledListOfInput(recipeTvaPriceInputs);
          disabledListOfInput(recipeOrderPlateInputs);

          // Mise à jour des htprices
          let multiHtPricesComputed = document.querySelectorAll(".recipehtpricecompute");
          // console.log(multiHtPricesComputed);
          if (multiHtPricesComputed) {
             multiHtPricesComputed.forEach((input) => {
              input.innerHTML = `( - €HT )`;
            });
          }

          // Mise à jour des portioncounts
          let multiPlatePortionsComputed = document.querySelectorAll(".orderportionscompute");
          if (multiPlatePortionsComputed) {
             multiPlatePortionsComputed.forEach((input) => {
              input.innerHTML = `(- portions)`;
            });
          }

        } else {
          if (otherRecipeButton.checked == true) {
            // orderFormUniqueRest.style.display = "block";
            removeformValueinLinkedInputs(uniformPlateOrderInput, recipeOrderPlateInputs);

            // total disabled
            disabledListOfInput(recipeOrderPlateInputs);
          }
        }
      }

    });

    //5. Mise à jour des multiinputs en cas de changement d'inputs uniformes (si switchbutton est checked)
    // Ne pas passer direct par le if (switchButtonmultiRest.checked == true) obligé de le mettre dans l'eventlistener

    //5.1 priceuniforminput and htprice
    uniformPriceInput.addEventListener('change', (event) => {
      if ( switchButtonmultiRest.checked == true && directSaleButton.checked == true ) {

        recipeSellingPriceInputs.forEach((input) => {
          if (uniformPriceInput.value != "") {
            input.value = parseFloat(uniformPriceInput.value);
          } else {
            input.value = "";
          }
        });

        updateMultihtPrices();
      }
    });


    //5.2 Orderuniforminput and portions
    uniformPlateOrderInput.addEventListener('change', (event) => {
      if ( switchButtonmultiRest.checked == true && (directSaleButton.checked == true || otherRecipeButton.checked == true) ) {

        recipeOrderPlateInputs.forEach((input) => {
          if (uniformPlateOrderInput.value != "") {
            input.value = parseFloat(uniformPlateOrderInput.value);
          } else {
            input.value = "";
          }
        });
        updateMultiPortionsCount();

      }
    });

    //5.3 Tvainput and htprice
    uniformTvaInput.addEventListener('change', (event) => {
      if ( switchButtonmultiRest.checked == true && directSaleButton.checked == true ) {
        recipeTvaPriceInputs.forEach((input) => {
          // console.log(uniformInput.value);
          if (uniformTvaInput.value != "") {
            input.selectedIndex = uniformTvaInput.selectedIndex;
          } else {
            input.selectedIndex = 3;
          }
        });
        updateMultihtPrices();
      }
    });


    //6. Mise à jour du check sur l'uniform input à partir des multiinputs en cas de changement d'un des multiinputs (si switchbutton est checked)
    // Ne pas passer direct par le if (switchButtonmultiRest.checked == true) obligé de le mettre dans l'eventlistener


    // 6.0 Méthode pour tracker en cas de changement d'un multi-input avec le uniform check false si tous les inputs price, TVA, orders
    // sont alignés avec les uniform inputs, pour remettre un uniform check true

    const checkUniformWhenAlignedInputs = () => {
      let differentinputcount = 0
      let inputcountforordersuniquely = 0

      // Partie price
      recipeSellingPriceInputs.forEach((input) => {
        if ( directSaleButton.checked == true ) {
          if (input.value == "" || input.value != uniformPriceInput.value ) {
            differentinputcount += 1;
          }
        }
      });

      // Partie tva
      recipeTvaPriceInputs.forEach((input) => {
        if ( directSaleButton.checked == true ) {
          if (input.value == "" || input.selectedIndex != uniformTvaInput.selectedIndex ) {
            differentinputcount += 1;
          }
        }
      });

      // Partie order
      recipeOrderPlateInputs.forEach((input) => {
        if ( directSaleButton.checked == true || otherRecipeButton.checked == true  ) {
          if (input.value == ""  || input.value != uniformPlateOrderInput.value ) {
            differentinputcount += 1;
            inputcountforordersuniquely += 1;
          }
        }
      });

      // conclusion: remettre input ou non en checked
      if ( differentinputcount == 0 && ( directSaleButton.checked == true || (directSaleButton.checked == true  && otherRecipeButton.checked == true) ) ) {
        switchButtonmultiRest.checked = true;
        // console.log(differentinputcount);
      } else {
        switchButtonmultiRest.checked = false;
      }

      if (switchButtonmultiRest.checked == false && inputcountforordersuniquely == 0 && (directSaleButton.checked == false && otherRecipeButton.checked == true)) {
        switchButtonmultiRest.checked = true;
      }

    }



    //6.1 priceuniforminput
    recipeSellingPriceInputs.forEach((input) => {
      input.addEventListener('change', (event) => {
        if ( switchButtonmultiRest.checked == true && directSaleButton.checked == true ) {
          if (input.Value != uniformPriceInput.value ) {
            switchButtonmultiRest.checked = false;
          }
        } else {
          checkUniformWhenAlignedInputs();
        }
      });
    });

    //6.2 Orderuniforminput
    recipeOrderPlateInputs.forEach((input) => {
      input.addEventListener('change', (event) => {
        if ( switchButtonmultiRest.checked == true && ( directSaleButton.checked == true || otherRecipeButton.checked == true ) ) {
          if (input.Value != uniformPlateOrderInput.value ) {
            switchButtonmultiRest.checked = false;
          }
        } else {
          checkUniformWhenAlignedInputs();
        }
      });
    });

    //6.3 tvaforminput
    recipeTvaPriceInputs.forEach((input) => {

      input.addEventListener('change', (event) => {
        if ( switchButtonmultiRest.checked == true && directSaleButton.checked == true ) {
          if (input.selectedIndex != uniformTvaInput.selectedIndex ) {
            switchButtonmultiRest.checked = false;
          }
        } else {
          checkUniformWhenAlignedInputs();
        }
      });

    });

    //6.4 permet de retrouver le bon check après désélection d'un élement et selon les valeurs disponibles.
    // Ex: check sur partie ventes et other recipe, prix différent mais même plate pour chaque restaurant. Lors que je décheck partie vente,
    // il ne reste que la partie orders qui est bonne mais le check avec des prix de vente différents issus de la partie vente est false
    // ATTENTION : beaucoup d'autres actions sont effectuées sur les check dans AddRecipeDefOrdersToForm

    // Passage entre other recipe et sale recipe
    directSaleButton.addEventListener('change', (event) => {
      if (event.target.checked){
        console.log('salebutton');
        // Si check sur directsale et uniform == true recalcul si besoin de checker ou pas le bouton (plate vs. sellingprice)
        // if ( switchButtonmultiRest.checked == false
        checkUniformWhenAlignedInputs();

        // Si check sur directsale et uniform == false réinitialisetion de la tva pour les multi_inputs
        recipeTvaPriceInputs.forEach((input) => {
          if ( switchButtonmultiRest.checked == false) {
            if ( input.selectedIndex == 0 ) {
              input.selectedIndex = 3;
            }
          }
        });

        // Si check sur directsale et uniform == false et reste sur other_recipe recalcul si besoin de checker ou pas le bouton (plate vs. sellingprice)
        // condition supplémentaire : tous les restaurants doivent être checkés (sinon problème si tout est vide)
      } else {
        let countRestaurantsChecked = 0;
         recipeRestaurantInputs.forEach((input) => {
            if (input.checked == true){
              countRestaurantsChecked += 1;
            }
          });

        if ( switchButtonmultiRest.checked == false && countRestaurantsChecked == recipeRestaurantInputs.length ) {
          checkUniformWhenAlignedInputs();
        }
      }
    });



    // 7. Affichage pour l'edit selon le statut sélectionné (sale ou other recipe) et sur checked ou non du switch button selon les cas
    if ( directSaleButton.checked == true || (directSaleButton.checked == true && otherRecipeButton.checked == true) ) {
       //7.1 Partie display sale & other recipe hors check uniform
       multiRestContent.style.display = "block";

        const multiSaleTitle = multiRestContent.querySelector('.title-vente');
        const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');

        // display titre et forms si non display à cause d'actions antérieures
        multiSaleForms.forEach((form) => {
          form.style.display = "flex";
        });
        multiSaleTitle.style.display = "block";

        // 7.2 Partie avec checkbutton
        // si pas uniform check mise à jour de tva par défaut
        if (switchButtonmultiRest.checked == false){

          // Mise à jour de la tva sur uniforme
          uniformTvaInput.selectedIndex = 3;

          // Mise à jour de la tva sur multi-établissements
          recipeTvaPriceInputs.forEach((input) => {
            if ( input.value == "" && input.disabled == true) {
                input.selectedIndex = 3;
              }
          });

        // sinon mise à jour des valeurs
        } else {
          // Mise à jour valeur uniform
          uniformPriceInput.value = recipeSellingPriceInputs[0].value;
          uniformTvaInput.selectedIndex = recipeTvaPriceInputs[0].selectedIndex;
          uniformPlateOrderInput.value = recipeOrderPlateInputs[0].value;

          // Mise à jour countportions
          const countUniformOrderPortions = document.getElementById("uniformorderportionscompute");
          let multiPlatePortionsComputed = document.querySelectorAll(".orderportionscompute");
          countUniformOrderPortions.innerHTML = multiPlatePortionsComputed[0].innerHTML;

          // Mise à jour prix ht
          const recipeUniformHtPrice = document.getElementById(`uniformrecipehtpricecompute`);
          let multiHtPricesComputed = document.querySelectorAll(".recipehtpricecompute");
          recipeUniformHtPrice.innerHTML = multiHtPricesComputed[0].innerHTML;
        }



    } else {
      if (otherRecipeButton.checked == true) {
        //7.3 Partie display other recipe seulement hors check uniform
        multiRestContent.style.display = "block";


        uniformMultiSaleForms.style.display = "none";
        const uniformMultiSaleTitle = switchFormMultiRest.querySelector('.title-uniform-vente');
        uniformMultiSaleTitle.style.display = "none";

        uniformPriceInput.disabled = true;
        uniformTvaInput.disabled = true;
        const multiSaleTitle = multiRestContent.querySelector('.title-vente');
        const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');

        // display none titre et forms
        multiSaleForms.forEach((form) => {
          form.style.display = "none";
        });
        multiSaleTitle.style.display = "none";

        // 7.4 Partie avec checkbutton
        // si pas uniform check mise à jour de tva par défaut
        if (switchButtonmultiRest.checked == true){
          // Mise à jour valeur uniform
          uniformPlateOrderInput.value = recipeOrderPlateInputs[0].value;

          // Mise à jour countportions
          const countUniformOrderPortions = document.getElementById("uniformorderportionscompute");
          let multiPlatePortionsComputed = document.querySelectorAll(".orderportionscompute");
          countUniformOrderPortions.innerHTML = multiPlatePortionsComputed[0].innerHTML;

        }


      }
    }

    //8. Bouton pour dispaly uniform inputs
    const uniforminputsToDisplay = document.getElementById("uniforminputs-to-display");
    const buttonUniformDisplay = document.getElementById("button-displayuniforminputs");
    if (buttonUniformDisplay && uniforminputsToDisplay) {
      //8.1 Partie edit
      if ( (directSaleButton.checked == true || otherRecipeButton.checked == true) && switchButtonmultiRest.checked == true ){
        uniforminputsToDisplay.style.display = "block";
      } else {
        uniforminputsToDisplay.style.display = "none";
      }

      //8.2 Partie envetlistener display
      buttonUniformDisplay.addEventListener('click', (event) => {
        if (uniforminputsToDisplay.style.display == "none"){
          uniforminputsToDisplay.style.display = "block";
        } else {
          uniforminputsToDisplay.style.display = "none";
        }
      });

    }


    //9. Permettre d'enlever le disabled sur la bonne ligne pour le restaurant checké selon les cas
    recipeRestaurantInputs.forEach((input) => {
      input.addEventListener('change', (event) => {
        let itemIndex = input.dataset.itemindex;
        const recipeSellingPriceInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_price_per_plate`);
        const recipeTvaPriceInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_tva_plate`);
        const recipeOrderPlateInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_plate`);

        if (event.target.checked) {

          if ( directSaleButton.checked == true || (directSaleButton.checked == true && otherRecipeButton.checked == true) ) {
            recipeSellingPriceInput.disabled = false;
            recipeTvaPriceInput.disabled = false;
            recipeOrderPlateInput.disabled = false;
          } else {
            if (otherRecipeButton.checked == true){
              recipeOrderPlateInput.disabled = false;
            }
          }

         } else {

          recipeSellingPriceInput.disabled = true;
          recipeTvaPriceInput.disabled = true;
          recipeOrderPlateInput.disabled = true;
        }
      });
    });


  }
}


export {addRecipeMultipleOrdersToForm};
