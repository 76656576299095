import $ from 'jquery';
import 'select2';

function formatDesc (opt) {

  var optdescsize = $(opt.element).attr('data-descsize');
  var optdescprice = $(opt.element).attr('data-descprice');
  var optdescunitcost = $(opt.element).attr('data-descunitcost');
  const srcImage1 = "/assets/supp_item_size-ebc6e291aeb90c58c137d96fd4de614bff662cbba1c845e63c10a79b71eef68f.png";
  const srcImage2 = "/assets/supp_item_price-5e93ab5a5caac6aa278528607e1be56fc7d6f71c531ae7ecb4ca17f157d9a39f.png";
  const srcImage3 = "/assets/supp_item_unit_cost-92602b78f4aadf453531ab0e70eb286fd2c0664608442123739e270b45f68d2e.png"

    if ( optdescunitcost == "-" || optdescprice == "-"  ){
      var $opt = $(
          '<span class="select-custom-title">' + opt.text + `</span><span class="select-data-desc" disabled="true" ><span><image src="${srcImage1}">` + optdescsize
          + "Pas de prix disponible" + '</span></span>'
           );
      } else {
      if ( optdescunitcost == " " ){
        var $opt = $(
          '<span class="select-custom-title">' + opt.text + `</span><span class="select-data-desc"><span><image src="${srcImage1}">` + optdescsize
          + `<image src="${srcImage2}">` + optdescprice + '</span></span>'
        );
      } else {
        var $opt = $(
          '<span class="select-custom-title">' + opt.text + `</span><span class="select-data-desc"><span><image src="${srcImage1}">` + optdescsize
          + `<image src="${srcImage2}">` + optdescprice + `<image src="${srcImage3}">` + optdescunitcost + '</span></span>'
        );
      }
    }
  return $opt;
};



// COMPRENDRE POURQUOI CELA NE MARCHE PAS DANS LA METHODE (NOTE 1)
const cart_form = document.getElementById('create_cart_items');
if (cart_form){
  const cartSupplierItemsSelectors = cart_form.querySelectorAll('.select2.cartformsupplier-select');
  // console.log(cartSupplierItemsSelectors);
  if (cartSupplierItemsSelectors){
    cartSupplierItemsSelectors.forEach(function(cartSupplierItemsSelector){
      const cartreferentSupplierItem = document.getElementById(`cartformreferentsupplieritem-${cartSupplierItemsSelector.dataset.itemId}`)
      if (cartreferentSupplierItem){
        cartSupplierItemsSelector.value = cartreferentSupplierItem.innerText;
        // $(cartSupplierItemsSelector).val(cartreferentSupplierItem.innerText).trigger('change');
      }
    });
  }
}


const updateSupplierCartForm = () => {
  const cart_form = document.getElementById('create_cart_items');

  if (cart_form){

    $('.select2.cartformsupplier-select').select2({
      templateResult: formatDesc
    });

    // TESTS MUTLIPLES DANS LA METHODE (NOTE 1)
    // const cartSupplierItemsSelectors = cart_form.querySelectorAll('.select2.cartformsupplier-select');
    // console.log(cartSupplierItemsSelectors);
    // if (cartSupplierItemsSelectors){
    //   cartSupplierItemsSelectors.forEach(function(SupplierItemsSelector){
    //     const cartreferentSupplierItem = document.getElementById(`cartformreferentsupplieritem-${SupplierItemsSelector.dataset.itemId}`)
    //     if (cartreferentSupplierItem){
    //       SupplierItemsSelector.value = cartreferentSupplierItem.innerText;
    //     }
    //   });
    // }

    // $('.select2.cartformsupplier-select').val(`${document.getElementById(`cartformreferentsupplieritem-${$('.select2.cartformsupplier-select').dataset.itemId}`).innerText}`).trigger('change');


    // const cartSupplierItemsSelectors = cart_form.querySelectorAll('.select2.cartformsupplier-select');
    // // console.log(cartSupplierItemsSelectors);
    // if (cartSupplierItemsSelectors){
    //   cartSupplierItemsSelectors.forEach((supplierItemsSelector) => {
    //     // console.log(supplierItemsSelector);
    //     const cartreferentSupplierItem = document.getElementById(`cartformreferentsupplieritem-${supplierItemsSelector.dataset.itemId}`)
    //     if (cartreferentSupplierItem){
    //       // console.log($(supplierItemsSelector)[0].selectedOptions.value);
    //       console.log(supplierItemsSelector);
    //       console.log(supplierItemsSelector.options);
    //       console.log(supplierItemsSelector.value);
    //       console.log(cartreferentSupplierItem.innerText);
    //       // $(supplierItemsSelector)[0].selectedOptions.value = cartreferentSupplierItem.innerText;

    //       for(var i, j = 0; i = supplierItemsSelector.options[j]; j++) {
    //         if(i.value == cartreferentSupplierItem) {
    //           supplierItemsSelector.val(cartreferentSupplierItem);
    //           break;
    //         }
    //       }

          // $(`#${supplierItemsSelector.id}`).select2().val(`${cartreferentSupplierItem.innerText}`);
          // supplierItemsSelector.value = cartreferentSupplierItem.innerText;
          // JSON.parse(supplierItemsSelector.options).forEach(function(key, value) {
          //   console.log(key);
            // console.log($(supplierItemsSelector).find("option[value='" + cartreferentSupplierItem + "']").index);
            // if (option.value == cartreferentSupplierItem.innerText){
            //   option.selected = true;
            // }
          // });
          // $(supplierItemsSelector).val(cartreferentSupplierItem.innerText);
          // $("#lang").select2().select2('val','asp');
    //     }
    //   });
    // }


  $('.select2.cartformsupplier-select').on('select2:select', function (e) {
    // console.log(document.getElementById(optionId).dataset.prix);
    //console.log(e.target.value)
    const optionId = `cart_form-${e.target.value}`
    // 1. Partie prix du supplier_item : x€/pièce, x€/kg : prix =x / unit = "pièce / kg"
    const prixht = document.getElementById(e.target.dataset.prixht);
    const prixhtupdate = document.getElementById(optionId).dataset.prix / 100.0;
    // console.log(prixht);
    // console.log(prixht);
    // console.log(isNaN(prixhtupdate))
    prixht.innerText = prixhtupdate.toFixed(1);

    const unitht = document.getElementById(e.target.dataset.unitht);
    const unithtupdate = document.getElementById(optionId).dataset.unit;
    unitht.innerText = unithtupdate;

    // 2. Prix unitaire sur la base d'une unité de comparaison commune des références fournisseurs ("kg" ou "L"): "pièce" n'existe plus car est convertie.
    // console.log(document.getElementById(optionId).dataset.refprix);

    const refprixht = document.getElementById(e.target.dataset.refprixht);
    const refprixhtupdate = document.getElementById(optionId).dataset.refprix;
    // console.log(refprixht);
    // console.log(refprixhtupdate);

    const refunitht = document.getElementById(e.target.dataset.refunitht);
    const refunithtupdate = document.getElementById(optionId).dataset.refunit;
    const refpereuro = document.getElementById(`cartformrefpereuro-${e.target.dataset.itemId}`);
    // console.log(refunithtupdate);

    if (isNaN(prixhtupdate)) {
      prixht.innerText = "n.d";
      refprixht.innerText = "n.d";
      refprixht.dataset.refcost = " ";
      unitht.innerText = " ";
      refunitht.innerText = " ";
      refpereuro.innerText = " ";
      const pereuro = document.getElementById(`pereuro-${e.target.dataset.itemId}`);
      pereuro.innerText = " ";
    } else {
      refprixht.innerText = parseFloat(refprixhtupdate).toFixed(2);
      refprixht.dataset.refcost = parseFloat(refprixhtupdate);
      refunitht.innerText = refunithtupdate;
      refpereuro.innerText = "€/";
      refprixht.style.display = "unset";
      refunitht.style.display = "unset";
      refpereuro.style.display = "unset";

      if ( parseFloat(refprixhtupdate) == parseFloat(prixhtupdate) && refunithtupdate == unitht.innerText ){
        refprixht.style.display = "none";
        refunitht.style.display = "none";
        refpereuro.style.display = "none";
      }
    }

    // 3. Affichage nom fournisseur
    const suppliername = document.getElementById(e.target.dataset.suppliernameselected);
    const suppliernameupdate = document.getElementById(optionId).dataset.suppliername;
    suppliername.innerText = suppliernameupdate;

    // 3. Affichage nom conditionnement
    const conditioningunit = document.getElementById(e.target.dataset.conditioningunitselected);
    const conditioningunitupdate = document.getElementById(optionId).dataset.conditioningname;
    conditioningunit.innerText = conditioningunitupdate;

    // 4.1 Affichage des quantités par unité (au kg / L) en hidden pour mise à jour des quantités en kg / L dans le panier (quantité du supplieritem)
    const supplieritemsize = document.getElementById(`cartformsupplieritemsize-${e.target.dataset.itemId}`);
    const supplieritemsizeupdate = document.getElementById(optionId).dataset.size;
    supplieritemsize.innerHTML = supplieritemsizeupdate;
    // console.log(supplieritemsize);

    //4.2.1 Mise à jour des quantités unitaires commandées (quantité du supplieritem * nbre commandé)
    // Grosse valueur différentiante par rapport à la concurrence : on recalcule les quantités par défaut sur la base des valeurs en L ou kg initialement dans le pannier
    // Cela signifie: je change de supplier_item : j'ai la quantité la plus proche arrondie au supérieure automatiquement calculée pas besoin de réajuster les quantités
    // Plus tard: ajout de la densité en fonction que le supplieritem soit au litre ou au kg pour un même article
    const quantityPerUnit = document.getElementById(`cartformitem-quantity-per-unit-${e.target.dataset.itemId}`);
    // console.log(quantityPerUnit.innerHTML);

    let quantityPerUnitValue = 0;
    if (quantityPerUnit.innerHTML == "-" || quantityPerUnit.innerHTML == "n.d"){
      quantityPerUnitValue = 0;
    } else {
      quantityPerUnitValue = parseFloat(quantityPerUnit.innerHTML);
    }

    const quantityInput = document.getElementById(`cartformquantityinput-${e.target.dataset.itemId}`);

    let quantityValueUpdate = parseFloat(quantityInput.value);

    if (supplieritemsizeupdate == 0 || supplieritemsizeupdate == null) {
      quantityInput.value = 0;
      quantityPerUnit.innerHTML = 0;
    } else {
      quantityValueUpdate = Math.ceil( ( quantityPerUnitValue / supplieritemsizeupdate ) );
      // ajout densité si besoin entre L et kg
      quantityInput.value = quantityValueUpdate;
      quantityPerUnit.innerHTML = ( quantityValueUpdate * supplieritemsizeupdate ).toFixed(3);
    }

    //4.3 Mise à jour de l'unité dans le panier (kg / L) en fonction de l'unité de base du supplieritem
    const quantityUnitInput = document.getElementById(`cartformitemquantity-cost-unit-${e.target.dataset.itemId}`);
    quantityUnitInput.innerHTML = refunithtupdate;

    //4.4 Mise à jour de la quantité de conditionnement dans le panier
    const itemquantity = document.getElementById(`cartformitem-quantity-${e.target.dataset.itemId}`);
    itemquantity.innerHTML = quantityValueUpdate;

    const hiddenquantity = document.getElementById(`cartformhidden-quantity-${e.target.dataset.itemId}`);
    hiddenquantity.value = quantityValueUpdate;

    //5 Mise à jour du prix sous-total (pour éviter le problème prix par unité * nombre d'unités de base (prix au kg * nb. de kg))

    const subtotalhtml = document.getElementById(`cartformtotalht-${e.target.dataset.itemId}`);
    if (isNaN(prixhtupdate)) {
      subtotalhtml.innerText = "n.d";
      const subtotaleuro = document.getElementById(`sous-total-euro-${e.target.dataset.itemId}`);

    } else {
    const subtotal = refprixhtupdate * parseFloat(quantityPerUnit.innerText);
    subtotalhtml.innerText = subtotal.toFixed(2);
    // console.log(subtotal);
    }

  });

 }
};



export {updateSupplierCartForm}
