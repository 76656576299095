
const addRecipeCategory = () => {

  const categoryFormsDivList = document.querySelectorAll('.line-recipe-category-form');
  // console.log(categoryFormsDivList);

  if (categoryFormsDivList){
    categoryFormsDivList.forEach((categoryFormDiv) => {
    categoryFormDiv.style.display = "none";
    });

    const mainCategoriesList = document.querySelectorAll('.for-form-insertion');
    // console.log(mainCategoriesList[0]);

    mainCategoriesList.forEach((mainCategory) => {
      const categoryAddButton = mainCategory.querySelector('.fa-plus');
      // console.log(categoryAddButton);


      categoryAddButton.addEventListener('click', (event) => {
        event.preventDefault();

        const categoryFormDiv = mainCategory.querySelector('.line-recipe-category-form');
        categoryFormDiv.style.display = "block";
        if (categoryFormDiv.style.display == "block") {
          console.log(categoryFormDiv);
          console.log(categoryFormDiv.firstElementchild);
          const recipeCategoryForm = categoryFormDiv.firstElementChild;
          recipeCategoryForm.addEventListener('keypress', (event) => {
            if (event.key === 'Enter'){
              recipeCategoryForm.submit();
            }
          });
        }


        // const AddFieldButton = categoryFormDiv.querySelector('.fa-plus-circle');
        // const AddFormName = categoryFormDiv.querySelector('.recipe-categories-form');
        // const AddFormNameInput = AddFormName.querySelector('.recipe_category_name');

        // const AddFormInput = categoryFormDiv.querySelector('.recipe_category_name').querySelector('input');;
        //console.log(AddFormName.innerHTML);
        //console.log(AddFormNameInput.innerHTML);

        // AddFieldButton.addEventListener('click', (event) => {
        //   event.preventDefault();
        //   // console.log("add");
        //   AddFormName.insertAdjacentHTML('beforeend',
        //   '<div class="recipe_category_name">'
        //   +
        //   AddFormNameInput.innerHTML
        //   +
        // '<i class="fas fa-minus-circle"></i>'
        //   +
        //   '</div>');
        // // console.log(AddFormName);
        //   const removeButtons = mainCategory.querySelectorAll('.fa-minus-circle');
        //   // console.log(removeButtons);
        //   removeButtons.forEach((removeButton) => {
        //     removeButton.addEventListener('click', (event) => {
        //       // console.log(removeButton.parentNode);
        //       removeButton.parentNode.remove();
        //     });
        //   });
        // });
        const cancelButton = mainCategory.querySelector('.button-cancel-categories');

        cancelButton.addEventListener('click', (event) => {
        event.preventDefault();
        categoryFormDiv.style.display = "none";
        // Bug: à voir après pour qu'il n'y ait plus qu'un champs d'input afin de ne pas ajouter plus d'un input par la suite.

        });

      });
    });
  }
}

export {addRecipeCategory};
