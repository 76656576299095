import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { French } from "flatpickr/dist/l10n/fr.js"
require('flatpickr/dist/plugins/monthSelect/style.css')
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colorMargin } from "../components/colorMargin.js";
import { sortTable } from "../functions/sort.js"
import 'chartjs-plugin-colorschemes';
//import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau.js';

Chart.defaults.global.plugins.colorschemes = {
  scheme: Tableau20,
  fillAlpha: 1,
  reverse: false,
  override: false
};

var dataPie = ''

function compute(object) {
  var helper = {};
  var result = object.reduce(function (r, o) {
    var key = o.month + '/' + o.type;

    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key].ht_amount += o.ht_amount;
    }
    return r;
  }, []);
  return helper
}

function getRandomColor() {
  var letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function codingColor(object, chartType) {
  var backgroundColor = []
  var borderColor = []
  const colors = {
    "Autres": ["rgba(220,220,200,0.9)", "rgba(220,220,200)"],
    "Boulangerie / pâtisserie": ["rgba(225,161,73,0.9)", "rgba(225,161,73)"],
    "crèmerie / bof": ["rgba(209,226,246,0.9)", "rgba(209,226,246)"],
    "Epicerie sèche": ["rgba(248,231,151,0.9)", "rgba(248,231,151)"],
    "Fruits et légumes": ["rgba(161,205,120,0.9)", "rgba(161,205,120)"],
    "Liquide": ["rgba(51,168,255,0.9)", "rgba(51,168,255)"],
    "Poissons & viandes": ["rgba(220,131,127,0.9)", "rgba(220,131,127)"],
    "Surgelés": ["rgba(226,252,251,0.9)", "rgba(226,252,251)"],
  }


  if (chartType == "pie") {
    object.forEach((item) => {
      if (colors[item["category"]]) {
        backgroundColor.push(colors[item["category"]][0])
        borderColor.push(colors[item["category"]][1])
      } else {
        var colorAlea = getRandomColor() + 60
        backgroundColor.push(colorAlea)
        borderColor.push(colorAlea)
      }
    })
  } else if (chartType == "bubble") {
    if (colors[object.name]) {
      backgroundColor.push(colors[object.name][0])
      borderColor.push(colors[object.name][1])
    } else {
      var colorAlea = getRandomColor() + 80
      backgroundColor.push(colorAlea)
      borderColor.push(colorAlea)
    }
  }

  return [backgroundColor, borderColor]
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

// function fetchList(choice, date, category = "", elementType = "") {
//   // fetch pour réinitialiser la liste des produits/recettes
//   var url = ""

//   if (choice == "period") {
//     if (date.includes('au')) {
//       var start = date.toString().split(' au ')[0];
//       var end = date.toString().split(' au ')[1];
//     } else {
//       var start = date
//       var end = date
//     }
//     if (date.length != 0) {
//       if (date.includes('/')) {
//         start = `${start.split('/')[2]}-${start.split('/')[1]}-${start.split('/')[0]}`
//         end = `${end.split('/')[2]}-${end.split('/')[1]}-${end.split('/')[0]}`
//       }
//       var firstDayMonth = new Date(start);
//       var lastDayMonth = new Date(end);
//       var firstDayMonthConverted = firstDayMonth.toISOString().split('T')[0]
//       var lastDayMonthConverted = lastDayMonth.toISOString().split('T')[0]
//       url = `/sales_analysis?sales[choice]=${choice}&sales[start]=${firstDayMonthConverted}&sales[end]=${lastDayMonthConverted}&sales[date]=${firstDayMonthConverted}&sales[category]=${encodeURIComponent(category)}&sales[elementType]=${elementType}`
//     } else {
//       url = `/sales_analysis`
//     }
//   } else {
//     var firstDayMonth = new Date(date.getFullYear(), date.getMonth(), "1");
//     firstDayMonth.setHours(0, -firstDayMonth.getTimezoneOffset(), 0, 0);
//     var lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//     lastDayMonth.setHours(0, -lastDayMonth.getTimezoneOffset(), 0, 0);
//     var firstDayMonthConverted = firstDayMonth.toISOString().split('T')[0]
//     var lastDayMonthConverted = lastDayMonth.toISOString().split('T')[0]
//     url = `/sales_analysis?sales[choice]=${choice}&sales[start]=${firstDayMonthConverted}&sales[end]=${lastDayMonthConverted}&sales[category]=${encodeURIComponent(category)}&sales[elementType]=${elementType}`
//   }

//   const div = document.querySelector('.sale-item-analysis');
//   const cards = document.getElementById('sales-card-infos');
//   const token = document.querySelector('meta[name="csrf-token"]').content;

//   if (url != "/sales_analysis") {
//     fetch(url, {
//       headers: {
//         "Content-Type": "application/json",
//         "X-Requested-With": "XMLHttpRequest",
//         Accept: "*/*;q=0.5, application/javascript", "X-CSRF-Token": token,
//       },
//     })
//       .then(response => response.text())
//       .then((html) => {
//         cards.innerHTML = "";
//         cards.innerHTML = html.split("html")[1];
//         div.innerHTML = "";
//         div.innerHTML = html.split("html")[2];
//         colorMargin();
//         sortTable();
//       });
//   }
// }

// function fetchDataMonth(choice, dateSelected, category = "", elementType = "") {
//   var url = ""
//   const div = document.querySelector('.sale-item-analysis');
//   const cards = document.getElementById('sales-card-infos');

//   var categories = document.querySelector('.select-category')
//   var category = categories.options[categories.selectedIndex].value
//   if (category == "Toutes Catégories") {
//     category = ""
//   }

//   if (choice == "month") {
//     url = `/montforcharts?sales_cat_chart[choice]=${choice}&sales_cat_chart[dateSelected]=${dateSelected}&sales_cat_chart[category]=${encodeURIComponent(category)}&ales_cat_chart[elementType]=${elementType}`
//   } else if (choice == "period") {
//     if (dateSelected.includes('au')) {
//       var start = dateSelected.toString().split(' au ')[0];
//       var end = dateSelected.toString().split(' au ')[1];
//     } else {
//       var start = dateSelected
//       var end = dateSelected
//     }
//     if (start.includes('/')) {
//       start = `${start.split('/')[2]}-${start.split('/')[1]}-${start.split('/')[0]}`
//       end = `${end.split('/')[2]}-${end.split('/')[1]}-${end.split('/')[0]}`
//     }
//     url = `/selecteddates?sales[start]=${start}&sales[end]=${end}&sales[category]=${encodeURIComponent(category)}&sales[elementType]=${elementType}`
//   }

//   const token = document.querySelector('meta[name="csrf-token"]').content;
//   if (url != "/sales_analysis" && start != "") {
//     fetch(url, {
//       headers: {
//         "Content-Type": "application/json",
//         "X-Requested-With": "XMLHttpRequest",
//         Accept: "*/*;q=0.5, application/javascript", "X-CSRF-Token": token,
//       },
//     })
//       .then(response => response.json())
//       .then((data) => {
//         $('#sales-pie').remove();
//         $('#chart-sales-pie').append('<canvas id="sales-pie"><canvas>');
//         $('#sales-bubble').remove();
//         $('#chart-sales-bubble').append('<canvas id="sales-bubble"><canvas>');

//         if (choice == "period") {
//           var start_date = new Date(start.split('-')[0], start.split('-')[1] - 1, start.split('-')[2])
//           var today = new Date

//           if (start.includes('/')) {
//             start_date = new Date(start.split('/')[2], start.split('/')[1], start.split('/')[0])
//           }

//           if (start_date.getFullYear() != today.getFullYear()) {
//             $('#sales-bar').remove();
//             $('#chart-sales-bar').append('<canvas id="sales-bar"><canvas>');
//             if (start.includes('/')) {
//               start = `${start.split('/')[2]}-${start.split('/')[1]}-${start.split('/')[0]}`
//             }
//             chartBarSalesByMonths(data[0], start)
//           }
//           if (category == "") {
//             chartPieSalesByCategory(data[1], start, end)
//             chartBulbleSalesByCategory(data[1], start, end)
//           } else {
//             chartPieSalesByCategory(data[1], start, end)
//             chartBulbleSalesByCategory(data[2], start, end)
//           }
//         } else {
//           $('#sales-bar').remove();
//           $('#chart-sales-bar').append('<canvas id="sales-bar"><canvas>');

//           chartBarSalesByMonths(data[0], dateSelected, "")

//           if (category == "") {
//             chartPieSalesByCategory(data[1], dateSelected, "")
//             chartBulbleSalesByCategory(data[1], dateSelected, "")
//           } else {
//             chartPieSalesByCategory(data[1], dateSelected, "")
//             chartBulbleSalesByCategory(data[2], dateSelected, "")
//           }
//         }
//       });
//   }
// }

const orderAnalysisDiv = document.getElementById('order-analysis-div');
if (!orderAnalysisDiv) {
  Chart.plugins.register({
    beforeDraw: function (chart) {
      if (chart.width < 400 && chart.chart.config.type == 'bar') {
        chart.scales['y-axis-1'].options.scaleLabel.display = false
        chart.scales['y-axis-2'].options.scaleLabel.display = false
        chart.options.legend.display = true;
        chart.options.legend.position = 'bottom'
        chart.update()
      }
      if (chart.width < 400 && (chart.chart.config.type == 'pie' || chart.chart.config.type == 'bubble')) {
        chart.options.legend.position = 'bottom';
        chart.update();
      }
    }
  });
}

// const calendarByMonth = () => {
//   const periodMonth = document.querySelector('#datepicker')
//   if (periodMonth) {
//     var dateCurrent = new Date();
//     var dateConverted = dateCurrent.toISOString().split('T')[0]
//     chartBarSalesByMonths();
//     chartBulbleSalesByCategory("", dateCurrent, "");
//     chartPieSalesByCategory("", dateCurrent, "");

//     flatpickr("#datepicker", {
//       altInput: true,
//       defaultDate: dateConverted,
//       "locale": French,
//       plugins: [new monthSelectPlugin({
//         shorthand: true,
//         altFormat: "F Y",
//         dateFormat: "Y-m-d"
//       })],
//       onChange: function () {
//         var monthSelected = periodMonth.value.split("-")
//         var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, monthSelected[2])
//         //chartBarSalesByMonths(dateSelected);
//         const categories = document.querySelector('.select-category')
//         var category = categories.options[categories.selectedIndex].value
//         var elementType = categories.options[categories.selectedIndex].parentNode.label
//         if (category == "Toutes Catégories") {
//           category = ""
//           elementType = ""
//         }
//         fetchDataMonth("month", dateSelected, category, elementType)
//         fetchList("month", dateSelected, category, elementType)
//       }
//     });
//   }
// }

// const calendarByPeriod = () => {
//   const periodImport = document.querySelector('#datepicker2')
//   if (periodImport) {
//     const saleAnalysisDiv = document.getElementById('sale-analysis-div');
//     const salesData = JSON.parse(saleAnalysisDiv.dataset.salesbarmonths);
//     const saleAnalysisflatpickrdiv = document.getElementById('sale-analysis-flatpickr-div');
//     var reservedDates = JSON.parse(saleAnalysisflatpickrdiv.dataset.salesreserveddates);

//     flatpickr("#datepicker2", {
//       altInput: true,
//       "locale": French,
//       //defaultDate: dateDefault,
//       dateFormat: "Y-m-d",
//       mode: "range",
//       //maxDate: new Date(),
//       altFormat: "d/m/Y",
//       //enable: enableDatesFrom,
//       onClose: function (selectedDates, dateStr, instance) {
//         const categories = document.querySelector('.select-category')
//         var category = categories.options[categories.selectedIndex].value
//         var elementType = categories.options[categories.selectedIndex].parentNode.label
//         if (category == "Toutes Catégories") {
//           category = ""
//           elementType = ""
//         }
//         fetchList("period", periodImport.value, category, elementType)
//         fetchDataMonth("period", periodImport.value, category, elementType)
//       },
//       onDayCreate: function (dObj, dStr, fp, dayElem) {
//         var d = new Date(dayElem.dateObj);
//         var ts = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
//         var date = new Date(ts).toISOString().substring(0, 10);

//         // Mise en forme des dates d'import dans le calendrier.
//         for (var i = 0; i < reservedDates.length; i++) {
//           if (date === reservedDates[i].from && date === reservedDates[i].to) {
//             dayElem.classList.add("check-day-only"); // jours unique
//           }
//           if (date === reservedDates[i].from) {
//             dayElem.classList.add("check-in"); // début d'intervalle
//           }
//           if (date === reservedDates[i].to) {
//             dayElem.classList.add("check-out"); // fin  d'intervalle
//           }
//           if (new Date(date) < new Date(reservedDates[i].to) && new Date(date) > new Date(reservedDates[i].from)) {
//             dayElem.classList.add("check"); // jours dans l'intervalle
//             dayElem.classList.add("flatpickr-disabled");
//           }
//         }
//       },
//     });
//   }
// }

// const chartBarSalesByMonths = (elements, start) => {
//   const salesChart = document.getElementById("sales-bar")
//   const saleAnalysisDiv = document.getElementById('sale-analysis-div');
//   var salesData = JSON.parse(saleAnalysisDiv.dataset.salesbarmonths);

//   if (elements) {
//     var salesData = elements
//   }

//   if (start instanceof Date) {
//     var year = start.getFullYear();
//   } else if (start === undefined) {
//     var year = new Date().getFullYear();
//   } else if (start.includes("-")) {
//     var year = start.split('-')[0];
//   }

//   var ht_amount_by_month = new Array(12)
//   var margin_by_month = new Array(12)
//   var monthNames = ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aout", "Sep", "Oct", "Nov", "Dec"];
//   var colors = new Array(12).fill('rgba(184, 237, 190, 0.2)')
//   var borders = new Array(12).fill('rgba(184, 237, 190)')
//   salesData.forEach((data) => {
//     ht_amount_by_month[data.month - 1] = Math.round(data.ht_amount)
//   })

//   salesData.forEach((data) => {
//     margin_by_month[data.month - 1] = Math.round(data.margin_amount / data.ht_amount * 100)
//   })

//   var dataset1 = { type: 'bar', label: 'CA', yAxisID: "y-axis-1", data: ht_amount_by_month, backgroundColor: colors, borderColor: borders, borderWidth: 1 }
//   var dataset2 = { type: 'line', label: 'Marge', yAxisID: "y-axis-2", data: margin_by_month, fill: false, pointBackgroundColor: '#ffff', pointBorderColor: 'rgb(255, 166, 0)', borderColor: 'rgb(255, 166, 0)' }

//   const data = {
//     labels: monthNames,
//     datasets: [dataset1, dataset2]
//   };

//   new Chart(salesChart, {
//     type: 'bar',
//     data: data,
//     options: {
//       onResize: function (chart, size) {
//         if (size.height == 400) {
//           chart.scales['y-axis-1'].options.scaleLabel.display = false
//           chart.scales['y-axis-2'].options.scaleLabel.display = false
//           chart.options.legend.display = true;
//           chart.options.legend.position = 'bottom'
//         }
//         chart.update();
//       },
//       responsive: true,
//       maintainAspectRatio: false,
//       plugins: {
//         datalabels: {
//           align: 'top',
//           formatter: (value, context) => {
//             if (context.datasetIndex == 1) {
//               return `${value}%`
//             }
//           },
//         },
//       },
//       scales: {
//         xAxes: [{
//           gridLines: {
//             lineWidth: 0,
//             drawBorder: true,
//           },
//         }],
//         yAxes: [{
//           ticks: { beginAtZero: true },
//           id: "y-axis-1",
//           position: 'left',
//           type: 'linear',
//           ticks: {
//             beginAtZero: true
//           },
//           gridLines: {
//             lineWidth: 0,
//             drawBorder: true,
//           },
//           scaleLabel: {
//             display: true,
//             labelString: "Chiffre d'affaires en €"
//           }
//         },
//         {
//           id: "y-axis-2",
//           position: 'right',
//           type: 'linear',
//           ticks: {
//             beginAtZero: true,
//             callback: function (value, index, values) {
//               return `${value} %`
//             }
//           },
//           gridLines: {
//             lineWidth: 0,
//             drawBorder: true,
//           },
//           scaleLabel: {
//             display: true,
//             labelString: 'Marge en %'
//           }
//         }]
//       },
//       title: {
//         display: true,
//         text: `CA NET (€HT) & MARGE (%) - ${year}`,
//         padding: 30
//       },
//       layout: {
//         padding: 5
//       },
//       legend: { display: false },
//     }
//   });
// }

// const chartPieSalesByCategory = (elements, start, end) => {
//   const salesChartPie = document.getElementById("sales-pie")
//   const saleAnalysisDiv = document.getElementById('sale-analysis-div');
//   var noChart = document.querySelector(".chart-none");
//   var salesData = JSON.parse(saleAnalysisDiv.dataset.salescat);
//   const categories = document.querySelector('.select-category')
//   var category = categories.options[categories.selectedIndex].value
//   var elementType = categories.options[categories.selectedIndex].parentNode.label
//   var typology = "CATEGORIE"
//   if (category != "Toutes Catégories") {
//     if (elementType == "Produits") {
//       typology = "PRODUIT"
//     } else {
//       typology = "RECETTE"
//     }
//   }

//   if (start && end == "") {
//     var indexMonth = start.getMonth();
//   } else {
//     var indexMonth = `${start.split('-')[2] + '/' + start.split('-')[1] + '/' + start.split('-')[0]} au ${end.split('-')[2] + '/' + end.split('-')[1] + '/' + end.split('-')[0]}`
//   }

//   if (start instanceof Date) {
//     var year = start.getFullYear();
//   } else {
//     var year = start.split('-')[0]
//   }

//   if (elements) {
//     var salesData = elements;
//   }

//   if (salesData.length == 0) {
//     //console.log("pas de données")
//     salesChartPie.style.display = "none";
//     $('#chart-sales-pie').css("display", "none")
//     noChart.style = "display: flex; align-items: center; justify-content: center;";
//   } else {
//     //console.log("avec des données")
//     noChart.style.display = "none";
//     salesChartPie.style.display = "block";
//     $('#chart-sales-pie').css("display", "block")

//     var ht_amount_by_cat = [];
//     var categoryNames = [];
//     var margin = [];
//     var monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

//     var result = [];
//     salesData.reduce(function (res, value) {
//       if (!res[value.category]) {
//         res[value.category] = { category: value.category, ht: 0, margin: 0 };
//         result.push(res[value.category])
//       }
//       res[value.category].ht += value.ht;
//       res[value.category].margin += value.margin;
//       return res;
//     }, {});

//     result.forEach((data) => {
//       ht_amount_by_cat.push(Math.round(data.ht));
//       margin.push((((data.margin / data.ht) * 100).toFixed(1)) / 100);
//       categoryNames.push(data.category);
//     })

//     if (ht_amount_by_cat.length == 0) {
//       ht_amount_by_cat = [1]
//     }
//     ht_amount_by_cat.sort(function (a, b) { return b - a });

//     dataPie = {
//       labels: categoryNames,
//       datasets: [{
//         data: ht_amount_by_cat,
//         // backgroundColor: codingColor(result,"pie")[0],
//         // borderColor: codingColor(result,"pie")[1],
//         // borderWidth: 1
//       }]
//     };

//     var test = new Chart(salesChartPie, {
//       type: 'pie',
//       data: dataPie,
//       options: {
//         onResize: function (chart, size) {
//           if (size.height == 400) {
//             chart.options.legend.display = true;
//             chart.options.legend.position = 'bottom'
//           }
//           chart.update();
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//           datalabels: {
//             anchor: 'end',
//             align: 'end',
//             offset: 10,
//             formatter: (value, ctx) => {
//               let sum = ctx.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
//               let percentage = (value * 100 / sum).toFixed(0) + "%";
//               if ((value * 100 / sum).toFixed(0) > 5) {
//                 return [`${value} (${percentage})`]
//               } else {
//                 return ""
//               }
//             }
//           },
//         },
//         title: {
//           display: true,
//           text: `CA PAR CATEGORIE (€HT) : ${end == "" ? monthNames[indexMonth] + ' ' + year : indexMonth}`,
//           padding: 30
//         },
//         layout: {
//           padding: {
//             bottom: 30  //set that fits the best
//           }
//         },
//         legend: { display: true, position: 'right', padding: 20 },
//         tooltips: {
//           displayColors: false,
//           callbacks: {
//             title: function (tooltipItem, all) {
//               return [
//                 all.labels[tooltipItem[0].index],
//               ]
//             },
//             label: function (tooltipItem, all) {
//               return [
//                 "Chiffre d'affaires" + ': ' + all.datasets[0].data[tooltipItem.index].toLocaleString() + "€",
//                 "Taux de marge" + ': ' + (margin[tooltipItem.index] * 100).toLocaleString() + "%",
//               ]
//             }
//           }
//         },
//       }
//     });
//     return dataPie
//   };
// }

// const chartBulbleSalesByCategory = (elements, start, end) => {
//   const salesChart = document.getElementById("sales-bubble")
//   const chartsale = salesChart.getContext('2d')
//   const saleAnalysisDiv = document.getElementById('sale-analysis-div');
//   const noChart = document.querySelector(".chart-none-bubble");
//   var salesData = JSON.parse(saleAnalysisDiv.dataset.salescat);
//   var X_AXIS = "Chiffre d'affaires";
//   var Y_AXIS = 'Taux de marge';
//   const categories = document.querySelector('.select-category')
//   var category = categories.options[categories.selectedIndex].value
//   var elementType = categories.options[categories.selectedIndex].parentNode.label
//   var typology = "CATEGORIE"
//   if (category != "Toutes Catégories") {
//     if (elementType == "Produits") {
//       typology = "PRODUIT"
//     } else {
//       typology = "RECETTE"
//     }
//   }

//   if (start && end == "") {
//     var indexMonth = start.getMonth();
//     var year = start.getFullYear();
//   } else {
//     var indexMonth = `${start.split('-')[2] + '/' + start.split('-')[1] + '/' + start.split('-')[0]} au ${end.split('-')[2] + '/' + end.split('-')[1] + '/' + end.split('-')[0]}`
//   }

//   if (start instanceof Date) {
//     var year = start.getFullYear();
//   } else {
//     var year = start.split('-')[0]
//   }

//   if (elements) {
//     var salesData = elements;
//   }

//   if (salesData.length == 0) {
//     salesChart.style.display = "none";
//     noChart.style = "display: flex; align-items: center; justify-content: center;";
//     $('#chart-sales-bubble').css("display", "none")
//   } else {
//     noChart.style.display = "none";
//     salesChart.style.display = "block";
//     $('#chart-sales-bubble').css("display", "block")

//     var monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];
//     var result = [];

//     salesData.reduce(function (res, value) {
//       if (!res[value.category]) {
//         res[value.category] = { category: value.category, ht: 0, margin: 0 };
//         result.push(res[value.category])
//       }
//       res[value.category].ht += value.ht;
//       res[value.category].margin += value.margin;
//       return res;
//     }, {});

//     var POINT_X_PERCENT = '%'; // prefix for x values, eg '$'
//     var POINT_X_EURO = '€ '; // postfix for x values, eg '%'

//     var bubbleData = result.map(function (row) {
//       return {
//         x: Math.round(row.ht),
//         y: (((row.margin / row.ht) * 100).toFixed(1)) / 100,
//         r: 10,
//         name: row.category
//       }
//     })

//     var labels = result.map(function (row) { return row.category });

//     new Chart(chartsale, {
//       type: 'bubble',
//       data: {
//         datasets:
//           (function (bubbleData) {
//             var out = [];
//             for (var i = 0; i < bubbleData.length; i++) {
//               if (category == "Toutes Catégories") {
//                 out.push({
//                   label: labels[i],
//                   data: [bubbleData[i]],
//                   // backgroundColor: dataPie.datasets[0].backgroundColor[i],
//                   // borders: dataPie.datasets[0].borderColor[i],
//                   // borderWidth: 1,
//                 });
//               } else {
//                 out.push({
//                   label: labels[i],
//                   data: [bubbleData[i]],
//                   // backgroundColor: codingColor(result,"bubble")[0],
//                   // borders: codingColor(result,"bubble")[1],
//                   // borderWidth: 1,
//                 });
//               }
//             }
//             return out;
//           })(bubbleData)
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         onResize: function (chart, size) {
//           if (size.height < 400) {
//             chart.options.legend.display = true;
//             chart.options.legend.position = 'bottom'
//           }
//           chart.update();
//         },
//         title: {
//           display: true,
//           padding: 20,
//           text: `CA (€HT) vs MARGE (%) PAR ${typology} : Top/Flop ${end == "" ? monthNames[indexMonth] + ' ' + year : indexMonth}`
//         },
//         layout: {
//           padding: 20
//         },
//         legend: { display: true, position: 'right' },
//         scales: {
//           xAxes: [{
//             scaleLabel: {
//               display: true,
//               labelString: X_AXIS
//             },
//             ticks: {
//               callback: function (value, index, values) {
//                 return value.toLocaleString() + POINT_X_EURO;
//               }
//             }
//           }],
//           yAxes: [{
//             scaleLabel: {
//               display: true,
//               labelString: Y_AXIS
//             },
//             ticks: {
//               callback: function (value, index, values) {
//                 return (value * 100).toLocaleString() + POINT_X_PERCENT;
//               }
//             }
//           }]
//         },
//         plugins: {
//           datalabels: {
//             display: false,
//           },
//         },
//         tooltips: {
//           displayColors: false,
//           callbacks: {
//             title: function (tooltipItem, all) {
//               return [
//                 all.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].name,
//               ]
//             },
//             label: function (tooltipItem) {
//               return [
//                 X_AXIS + ': ' + tooltipItem.xLabel.toLocaleString() + POINT_X_EURO,
//                 Y_AXIS + ': ' + (tooltipItem.yLabel * 100).toLocaleString() + POINT_X_PERCENT,
//               ]
//             }
//           }
//         },
//       }
//     });

//   };
// }

const initFlatpickr = () => {

  ///////// INITIALISATION DE FLATPICKR POUR SALES FORM /////////

  const saleForm = document.getElementById('sale-form-div');
  if (saleForm) {
    const sales = JSON.parse(saleForm.dataset.sales);
    const fp = flatpickr("#range_start", {
      "locale": French,
      plugins: [new rangePlugin({ input: "#range_end" })],
      dateFormat: "Y-m-d",
      maxDate: new Date(),
      altInput: true,
      altFormat: "d/m/Y",
      disable: sales,
      onChange: function (selectedDates, dateStr, instance) {
        var current = selectedDates[0];
        var lastDayOfMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        instance.set("maxDate", lastDayOfMonth);
      },
    });
  }

  const orders = document.querySelector('.tab-content');
  const sales = document.querySelector('.sales-index-table');
  if (orders || sales) {
    const fp = flatpickr("#datepicker3", {
      "locale": French,
      dateFormat: "Y-m-d",
      maxDate: new Date(),
      mode: "range",
      altInput: true,
      altFormat: "d/m/Y",
    });
  }

  const stockForm = document.querySelector('.new_inventory_movement');
  if (stockForm) {
    const fp = flatpickr("#stock", {
      "locale": French,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "d/m/Y H:i",
      allowInput: true,
    });
  }

  const stockFormEdit = document.querySelector('.edit_inventory_movement');
  if (stockFormEdit) {
    const fp = flatpickr("#stock", {
      "locale": French,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "d/m/Y",
      allowInput: true,
    });
  }

  const productionForm = document.querySelector('.new_production');
  if (productionForm) {
    const fp = flatpickr("#stock", {
      "locale": French,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "d/m/Y",
      allowInput: true,
      minDate: new Date(),
    });
  }

  const productionFormEdit = document.querySelector('.edit_production');
  const closeProduction = document.querySelector('.close-order')
  if (productionFormEdit && !closeProduction) {
    var date = document.getElementById('stock')
    const fp = flatpickr("#stock", {
      "locale": French,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "d/m/Y",
      allowInput: true,
      minDate: $('#stock').attr('value'),
      defaultDate: $('#stock').attr('value')
    });
  }

  const movementsList = document.getElementById('movements-list-flatpickr-div')
  if (movementsList) {
    flatpickr("#datepickermvtlist", {
      altInput: true,
      "locale": French,
      dateFormat: "Y-m-d",
      mode: "range",
      altFormat: "d/m/Y",
      onClose: function (selectedDates, dateStr, instance) {
        // Insérer un fetch pour obtenir la liste des inventory movement items correspondants
        // Prend en compte l'article si celui-ci est mentionné
        // fetchList("period", periodImport.value, category, elementType)
      },
    });
  }

  const orderForm = document.querySelector('.edit_order');
  if (orderForm) {
    const orders = document.querySelectorAll('.order-delivery-actual')
    orders.forEach((order) => {
      const orderId = order.dataset.id
      const inputOrder = document.getElementById(`order_date_${orderId}`)
      var targetDate = JSON.parse(inputOrder.dataset.orders);
      const deliveredDateInDb = JSON.parse(inputOrder.dataset.dbdate);
      if (deliveredDateInDb !== null) {
        targetDate = deliveredDateInDb
      }
      targetDate = new Date(new Date(targetDate).valueOf() + new Date(targetDate).getTimezoneOffset() * 60000)
      console.log(targetDate)
      const fp = flatpickr(inputOrder, {
        "locale": French,
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        altFormat: "d/m/Y H:i",
        altInput: true,
        allowInput: true,
        defaultDate: targetDate, //.getTimezoneOffset()
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 0) {
            fp.setDate(targetDate, false, "Y-m-d H:i")
          }
        },
      });
    })
  }


  ///////// INITIALISATION DE FLATPICKR POUR SALES ANALYSIS /////////
  // calendarByPeriod();
  // calendarByMonth();

  ///////// INITIALISATION CODING COLOR MARGIN /////////
  //colorMargin();

  ///////// MENU CHOIX PAR MOIS OU PAR DATE + MISE A JOUR DES DATA /////////

  // const saleAnalysisDiv = document.getElementById('sale-analysis-div');
  // if (saleAnalysisDiv) {
  //   const links = document.querySelectorAll(".nav-sale-link");
  //   links.forEach((link) => {
  //     link.addEventListener('click', (event) => {
  //       const linkActiveText = link.dataset.choice;  // soit par mois, soit par période d'import
  //       const inputByMonth = document.querySelector('.input+.input')
  //       const inputByImport = document.querySelector('.input2+input')
  //       const periodMonth = document.querySelector('#datepicker')
  //       const categories = document.querySelector('.select-category')
  //       var category = categories.options[categories.selectedIndex].value
  //       var elementType = categories.options[categories.selectedIndex].parentNode.label
  //       if (category == "Toutes Catégories") {
  //         category = ""
  //         elementType = ""
  //       }

  //       if (linkActiveText == "month") {
  //         var monthSelected = periodMonth.value.split("-")
  //         var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, monthSelected[2])
  //         inputByMonth.classList.remove("date-input-none");
  //         inputByImport.classList.add("date-input-none");

  //         fetchDataMonth("month", dateSelected, category, elementType)
  //         fetchList("month", dateSelected, category, elementType)
  //       } else if (linkActiveText == "period") {
  //         inputByMonth.classList.add("date-input-none");
  //         inputByImport.classList.remove("date-input-none");

  //         if (inputByImport.value != "") {
  //           fetchList("period", inputByImport.value, category, elementType)
  //           fetchDataMonth("period", inputByImport.value, category, elementType)
  //         }
  //       }
  //     });
  //   });

  //   const categories = document.querySelector('.select-category')
  //   categories.addEventListener('change', (event) => {
  //     var category = categories.options[categories.selectedIndex].value
  //     var elementType = categories.options[categories.selectedIndex].parentNode.label
  //     const inputByMonth = document.querySelector('.input+.input')
  //     const inputByImport = document.querySelector('.input2+input')
  //     const periodMonth = document.querySelector('#datepicker')
  //     var links = document.querySelectorAll(".nav-sale-link");
  //     if (category == "Toutes Catégories") {
  //       category = ""
  //       elementType = ""
  //     }

  //     if (links[0].classList.contains("active")) {
  //       var monthSelected = periodMonth.value.split("-")
  //       var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, monthSelected[2])
  //       inputByMonth.classList.remove("date-input-none");
  //       inputByImport.classList.add("date-input-none");
  //       fetchDataMonth("month", dateSelected, category, elementType)
  //       fetchList("month", dateSelected, category, elementType)
  //     } else if (links[1].classList.contains("active")) {
  //       inputByMonth.classList.add("date-input-none");
  //       inputByImport.classList.remove("date-input-none");
  //       if (inputByImport.value != "") {
  //         fetchList("period", inputByImport.value, category, elementType)
  //         fetchDataMonth("period", inputByImport.value, category, elementType)
  //       }
  //     }
  //   });
  // }
}

export { initFlatpickr };
