import $ from 'jquery';

const ingredientUnitSelect = () => {
  const elementSelector = document.getElementById('ingredient_element_id');


  if (elementSelector){
    const elementsList = document.querySelectorAll('#ingredient_element option');

    // Syntaxe select_2;
    $(elementSelector).on('change', function (e) {

      const element = elementSelector.options[elementSelector.selectedIndex];

      if (element.dataset.units) {
        const element_units = element.dataset.units;
        const ingredientUnitsList = document.getElementById('ingredientUnitsList');
        const ingredientUnitsJSON = JSON.parse(element_units);
        // const ingredientUnitsListOptions = ingredientUnitsList.querySelectorAll('option');

        var i=0;
        ingredientUnitsList.options.length = 0;
        Object.values(ingredientUnitsJSON).forEach((item) => {
          ingredientUnitsList.options[ingredientUnitsList.length] = new Option(item, item);
          i++;
        });

        // //permet de positionner la combo sur le bon champ
        // ingredientUnitsList.selectedIndex = 0;
      }
    });
  }

}

export {ingredientUnitSelect};

