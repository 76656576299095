import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import 'select2';

export default class extends Controller {
  static targets = ["year", "container", "pagination"];

  connect() {
    console.log("connexion")
    $(this.yearTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  updateYear(e) {
    const selected_year = this.yearTarget.options[this.yearTarget.options.selectedIndex].value
    const current_url = window.location.href
    var fetch_url

    // Vérification si l'url a déjà un params.
    current_url.includes('?') ? fetch_url = `movement_details?link=inventory_movements&year=${selected_year}` : `${current_url}` + `?year=${selected_year}`

    fetch(fetch_url, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
    })
    .then(response => response.json())
    .then((data) => {

      // Insertion de la partial des mouvements
      this.containerTarget.innerHTML = data.result;
      // Insertion de la partial de la pagination
      this.paginationTarget.innerHTML = data.pagination;

      // Relance de l'event change du select2
      $(this.yearTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });

      const event = new CustomEvent('turbolinks:load');
      document.dispatchEvent(event);
    });
  }

}
