import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import { filterTableStockInventory, filterTableStockInventoryIndex } from "../components/filterTableFunction.js"

function totalSum(total, data) {
  return total + data;
}

export default class extends Controller {
  static targets = ["type", "category", "zone", "mvt", "stockValue"];

  connect() {
    $(this.typeTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    $(this.categoryTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    $(this.zoneTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

  }

  filterTable(e) {
    var table = document.getElementById("table");
    var elementType = this.typeTarget.options[this.typeTarget.selectedIndex].value.toLowerCase();
    var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
    var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
    filterTableStockInventory(table, elementType, elementCat, elementZone, 0, false)
  }

  filterTableIndex(e) {
    var table = document.getElementById("table");
    var elementType = this.typeTarget.options[this.typeTarget.selectedIndex].value.toLowerCase();
    var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
    var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
    filterTableStockInventoryIndex(table, elementType, elementCat, elementZone, 0, false)
    var values = document.querySelectorAll('.status-area.stock-value')
    var sum = []
    values.forEach((el) => {
      if (el.closest('tr').style.display != 'none') {
        sum.push(parseFloat(el.innerHTML))
      }
    })
    sum.reduce((sum, v) => sum + v, 0).toFixed(1) + " €"
    this.stockValueTarget.innerHTML = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 1,
    }).format(sum.reduce((sum, v) => sum + v, 0))
  }

  filterTableMvts(e) {
    var table = document.getElementById("table");
    var elementType = this.typeTarget.options[this.typeTarget.selectedIndex].value.toLowerCase();
    var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
    var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
    var elementMvt = this.mvtTarget.options[this.mvtTarget.selectedIndex].innerHTML.toLowerCase();
    filterTableStockInventory(table, elementType, elementCat, elementZone, elementMvt, 0, false)
  }
}
