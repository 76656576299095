function toggleValidation (el, nameDuplicate) {
	if (nameDuplicate) {
		el.classList.remove('is-valid');
		el.classList.add("is-invalid");
	} else {
		el.classList.remove('is-invalid')
		el.classList.add('is-valid')
	}
}

const formSalesValidates = () => {
	const form = document.querySelector(".new_sale") || document.querySelector(".edit_sale")

	if (form) {

		form.addEventListener('submit', (event) => {
			
			var keysList = [];
			var keysDuplicates = [];
			var refItem, descriptionItem, menuItem, categoryItem
			const regex = /[^0-9]/g;
			const saleItemRows = Array.from(document.querySelectorAll('.productrows')).filter(el => el.style.display != 'none');
			
			for(const saleItemRow of saleItemRows) {
				var rowId = saleItemRow.dataset.row.replace(regex, "")
				refItem = saleItemRow.querySelector(`#sale_sale_items_attributes_${rowId}_ref_item`)
				descriptionItem = saleItemRow.querySelector(`#sale_sale_items_attributes_${rowId}_description_item`)
				menuItem = saleItemRow.querySelector(`#sale_sale_items_attributes_${rowId}_menu`)
				categoryItem = saleItemRow.querySelector(`#sale_sale_items_attributes_${rowId}_category`)
				if (refItem.value !== "" || descriptionItem.value !== "") {
					var object = {
						name: refItem.value + descriptionItem.value + menuItem.value + categoryItem.value,
						element: [refItem, descriptionItem,	menuItem, categoryItem]
					}

					keysList.push(object)
					var array = Object.values(keysList.map(e => e.name))

					if(array.indexOf(object.name) !== array.lastIndexOf(object.name)) {
						keysDuplicates.push(object.name) 	
					}
				}
			}

			keysList.forEach((key)=> {
				for (const [k, el] of Object.entries(key.element)) {
					toggleValidation(el, keysDuplicates.includes(key.name))
				}
			})
			
			keysDuplicates.length > 0 ? event.preventDefault() : ''
	
			var saleForm = document.getElementById('sale-form-div');
			var salesDates = JSON.parse(saleForm.dataset.sales);
			var interval = document.getElementById('range_start')

			var checkfrom = Date.parse(interval.value.split('au')[0]) 
			var checkto = Date.parse(interval.value.split('au')[1])

			for (let i = 0; i < salesDates.length; i++) {
				var datefrom = Date.parse(salesDates[i]["from"])
				if(datefrom <= checkto && datefrom >= checkfrom) {
					event.preventDefault();
					event.stopPropagation();
					return alert("la période sélectionnée ne peut pas inclure des imports déjà effectués");
				}   
			}

		});
	}
}

export { formSalesValidates };
