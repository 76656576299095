const checkDoubleProductUnit = () => {

  const form = document.getElementById('new_product');
  if(form){
    form.addEventListener('submit', (event) => {

      var a = document.querySelectorAll('.product-units-rows');
      var array = [];
      var perChunk = 5;

      for (var i = 0; i < a.length; i++) {
        a[i].querySelectorAll('select, input').forEach(item => array.push(item.value.toLowerCase()));
      }

      var result = array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])

      var uniq = result.map(JSON.stringify).reverse().filter(function (e, i, a) {
        return a.indexOf(e, i+1) === -1;
      }).reverse().map(JSON.parse)

      if(result.length != uniq.length){
        //document.getElementById('errormessage').innerHTML = "Veuillez modifier ou supprimer la ligne en double !"
        alert("Veuillez modifier ou supprimer la ligne en double !");
        event.preventDefault();
        event.stopPropagation();
        return;
      } else {
        return true;
      }

    });
  }
}


export { checkDoubleProductUnit };
