import autoComplete from 'js-autocomplete';

const autocompleteSearch = function() {

  const uniquesearchswithquery = document.querySelectorAll(".search form")
  const searchswithquery = document.querySelectorAll(".search")
  const searchswoquery = document.querySelectorAll(".searchwoquery form");
  var searchs = []
  uniquesearchswithquery.forEach((searchwithquery)=>{
    searchs.push(searchwithquery);
  });
  searchswithquery.forEach((searchwithquery)=>{
    searchs.push(searchwithquery);
  });
  searchswoquery.forEach((searchwoquery)=>{
    searchs.push(searchwoquery);
  });

  if(searchs){
    searchs.forEach((item)=>{
      if (item) {
        var divId = item.firstElementChild.id;
        var inputId = item.lastElementChild.firstElementChild.id;
        var docid = document.getElementById(divId);
        //console.log(divId)
        //console.log(inputId)
        //console.log(docid)
        if (docid){
            var skills = JSON.parse(docid.dataset.skills);
            var searchInput = document.getElementById(inputId);
            //console.log(skills)
            //console.log(searchInput)
          if (skills && searchInput) {
            new autoComplete({
              selector: searchInput,
              minChars: 1,
              source: function(term, suggest){
                  term = term.toLowerCase();
                  const choices = skills;
                  const matches = [];
                  for (let i = 0; i < choices.length; i++)
                      if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
                  suggest(matches);
              },
            });
          }
        }
      }
    });
  };

};

export { autocompleteSearch };
