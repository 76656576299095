import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['form', 'input'];

  connect() {
    //console.log("controller order modal invoiced")
  }

  submit(e) {
    this.formTarget.submit()
  }
}
