import $ from 'jquery';

const ingredientElement = () => {
  const elementSelector = document.getElementById('ingredient_element_id');

  if (elementSelector){
    const elementsList = document.querySelectorAll('#ingredient_element option');

    // Syntaxe spécifique select_2 : a priori non;
    $(elementSelector).on('change', function (e) {
      // elementSelector.addEventListener('change', (event) => {
      //console.log("change");
      const element = elementSelector.options[elementSelector.selectedIndex]


      if (element.dataset.type) {
        const element_type = element.dataset.type;
        //console.log(element.innerText);
        //console.log(element_type);

        const hiddenElementType = document.getElementById('hidden-element_type');
        hiddenElementType.innerText = element_type;
        hiddenElementType.value = element_type;
      }
    });
  }
}

export {ingredientElement};

