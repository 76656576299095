const quaggaLoad = () => {
  //console.log("load");
  const element = document.querySelector(".btn-barcode");
  if (!element) return
    //console.log("load found");
  import(
    /* webpackChunkName: "quagga" */ "./quagga"
  ).then(({ quagga_exec }) => {
    //console.log("exec");
    quagga_exec()
  });
}

export  {quaggaLoad}
