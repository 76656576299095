const removeProductUnitVisible = () => {

const productUnit = document.querySelectorAll('.product-units-rows');

  productUnit.forEach((item) => {
    item.addEventListener('mouseenter', (event) => {
      event.preventDefault();

      if (document.querySelector('.remove-yes')){
        if(productUnit.length == 1) {
          document.querySelector('.remove-yes').style.visibility = "hidden";
        } else {
          document.querySelector('.remove-yes').style.visibility = "visible";
        }
      }
    });
  });
};

export {removeProductUnitVisible};
