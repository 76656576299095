import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['checkbox_with_deleted', 'form'];

  connect() {
  }

  send(e) {
    e.preventDefault();
    if (this.checkbox_with_deletedTarget.checked) {
    	var checkboxResult = true
    } else {
    	checkboxResult = false
    }
	  fetch('/sales_mapping', {
	    method: 'POST',
	    headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
	    body: JSON.stringify({sales_mapping: checkboxResult})
	  })
	  .then(response => response.json())
	  .then((data) => {
	  	this.formTarget.innerHTML = data.inserted_item;
	  	const event = new CustomEvent('turbolinks:load');
    	document.dispatchEvent(event);
	  });
  }
}
