

const displayRecipeUnitInForm = () => {

  const recipeUnitInput = document.getElementById('recipe_recipe_unit');


  if (recipeUnitInput){

    const formrecipeunits = document.querySelectorAll(".formrecipeunit");

    if (formrecipeunits){
      formrecipeunits.forEach((formrecipeunit) => {
        if (recipeUnitInput.value) {
          formrecipeunit.innerHTML = recipeUnitInput.value;
        } else {
          formrecipeunit.innerHTML = "kg";
        }
      });
    }

    recipeUnitInput.addEventListener('change', (event) => {
      event.preventDefault();
      // console.log(recipeUnitInput);

      const formrecipeunits = document.querySelectorAll(".formrecipeunit");
      // console.log(formrecipeunits);
      formrecipeunits.forEach((formrecipeunit) => {
      formrecipeunit.innerHTML = recipeUnitInput.value;
      });
    });
  }
}

export {displayRecipeUnitInForm};
