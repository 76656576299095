
const addRecipeDefOrderInForm = () => {

  // Partie initiale : 1 restaurant
  const recipeActiveSale = document.getElementById('recipe_active_sale');
  const otherRecipe = document.getElementById('recipe_other_recipe');
  const subrecipe = document.getElementById('recipe_subrecipe');
  const saleButton = document.getElementById('recipe_for_sale');
  const recipeOrdersIntro = document.getElementById('recipe-orders-intro');
  const recipeOrdersInstructions = document.getElementById('recipe-orders-instructions');
  const initialMultiple = document.getElementById('recipe_restaurant_recipes_attributes_0_plate');
  const initialSaleForm = document.getElementById('recipe-sales-information');
  const initialRecipeOrdersForm = document.getElementById('recipe-orders-information');

  // Partie 2 : multi-établissements
  const switchButtonmultiRest = document.getElementById('recipe-uniform-multi-restaurants');
  // form avec button pour switcher
  const switchFormMultiRest = document.getElementById('switch-to-multi-restaurants');

  // Partie initiale adaptée selon multi-établissements :
  const uniformMultiple = document.getElementById('recipe_uniform_plate');
  const uniformsaleForm = document.getElementById('recipe-input-uniform-multivente');
  const uniformrecipeOrdersForm = document.getElementById('recipe-input-uniform-multiorders');

  // Parties formulaire
  const multiRestContent = document.getElementById('display-multi-restaurants-recipe-form');
  // Liste de sous-blocs par item
  if (multiRestContent) {
    const multiSaleTitle = multiRestContent.querySelector('.title-vente');
    const multiOrderTitle = multiRestContent.querySelector('.title-order');

    const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');
    const multiOrderForms = multiRestContent.querySelectorAll('.input-multi-orders');
  }



  // Message customisé
  const message_Sale_And_Other_Recipe = "Commandes : Indiquez le nombre de recettes destinées directement à la vente et les recettes 'autres' (repas pour le personnel, recettes non facturées) pour les commandes par défaut de leurs ingrédients.";
  const message_Sale_And_Or_Other_With_Subrecipe = "Commandes : Ne pas indiquer le nombre de recettes intermédiaires. Elles seront prises en compte pour les commandes parmi les ingrédients des recettes finales.";
  const message_Only_Subrecipe = "Commandes : Les ingrédients des recettes intermédiaires seront prises en compte pour les commandes parmi les ingrédients des recettes finales.";
  // console.log(recipeActiveSale, otherRecipe, subrecipe, multiple, saleButton, recipeOrdersIntro);
  const message_for_intro = "Indiquez le(s) type(s) de recette pour afficher les informations de ventes et de commandes correspondantes."

  // console.log(saleButton.checked, otherRecipe.checked, subrecipe.checked);
  // console.log(recipeActiveSale, otherRecipe, subrecipe, multiple, saleButton, recipeOrdersIntro);
  if (otherRecipe && subrecipe && saleButton) {

    // 1. Récupération des bons éléments pour la partie form unique (1 restaurant) ou uniform form (plusieurs restaurants)
    // Obligé de récupérer ici les bonnes valeurs pour que le js ne retourne pas not defined pour les saleFom, orderForm
    // Valeurs mises à jour selon 1 restaurant ou plusieurs restaurants avec inputsuniformes
    let multiple = null;
    let saleForm = null;
    let recipeOrdersForm = null;
    let dislayType = null;

    // Si 1 seul restaurant
    if ( initialSaleForm != null && initialRecipeOrdersForm != null ) {
      multiple = initialMultiple;
      saleForm = initialSaleForm;
      recipeOrdersForm = initialRecipeOrdersForm;
      dislayType = "block";
    // Si plusieurs restaurants
    } else {
      multiple = uniformMultiple;
      saleForm = uniformsaleForm;
      recipeOrdersForm = uniformrecipeOrdersForm;
      dislayType = "flex";
    }

    // 2. Activation des display none sur la page selon les cheched ou non (sert sur l'edit) et mise à jour des messages
    if (saleForm) {
      if (saleButton.checked == false){
        saleForm.style.display = "none";
      }

      if (saleButton.checked == false && otherRecipe.checked == false){
        saleForm.style.display = "none";
        if(recipeOrdersForm){
          recipeOrdersForm.style.display = "none";
          // multiple.readOnly = true;
        }
        // Option Mutli-établissements
        if (switchFormMultiRest){
          switchFormMultiRest.style.display = "none";

          // Affichage de l'active_sale (uniquement multi-restaurants / 1 seu lrestaurant pas besoin déja dans la div)
          let active_sale_detail = document.getElementById("active_sale_detail");
          if (active_sale_detail){
            active_sale_detail.style.display = "none";
          }
        }
        if (subrecipe.checked == true){
          recipeOrdersIntro.innerText = message_Only_Subrecipe;
        }
      }
    }

    // 3. Mise à jour des messages customisés selon les cas (hors eventlistener)
    if (saleButton.checked == true && otherRecipe.checked == true){
      recipeOrdersInstructions.innerText = message_Sale_And_Other_Recipe;


      if (subrecipe.checked == true){
        recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;

      }
    }

    if (saleButton.checked == true || otherRecipe.checked == true){
      recipeOrdersIntro.innerText ="";
    }

    if (saleButton.checked == false && otherRecipe.checked == false && subrecipe.checked == false){
      recipeOrdersIntro.innerText = message_for_intro;
    }


    // 4. Actions sur l'eventlistener de SaleButtoncheck
    saleButton.addEventListener('change', (event) => {
        event.preventDefault();
        // console.log("salesbutton change");
        // console.log(saleButton.checked);
        // console.log(switchButtonmultiRest.checked);

        if (event.target.checked){

          // 4.1. Partie 1 restaurant / uniforme input
          // 4.1.1 Mise à jour des display
          recipeOrdersIntro.innerText = "";

          saleForm.style.display = dislayType;
          recipeOrdersForm.style.display = dislayType;

          // 4.1.2 enabled input
          multiple.disabled = false;
          multiple.readOnly = false;

          // 4.1.3 Gestion message
          if (otherRecipe.checked == true){
            recipeOrdersInstructions.innerText = message_Sale_And_Other_Recipe;
          }

          if (subrecipe.checked == true){
            if (otherRecipe.checked == true){
              recipeOrdersInstructions.innerText = message_Sale_And_Other_Recipe;
            } else {
              recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;
            }
          }

          //4.2. Partie multi-restaurants
          if (switchFormMultiRest){
            // 4.2.1 Affichage multi-établissements
            switchFormMultiRest.style.display = "block";
            multiRestContent.style.display = "block";

            // 4.2.2 Affichage partie uniforminput
            const uniformMultiSaleTitle = switchFormMultiRest.querySelector('.title-uniform-vente');
            uniformMultiSaleTitle.style.display = "flex";

            // enabled input
            const firstUniformInputPrice = document.getElementById("recipe_uniform_price");
            firstUniformInputPrice.disabled = false;

            // enabled input
            const firstUniformInputTva = document.getElementById("recipe_uniform_tva");
            firstUniformInputTva.disabled = false;
             // Replacement de la tva par défaut si null
            if (firstUniformInputTva.value == ""){
              console.log(firstUniformInputTva.value == "");
              firstUniformInputTva.selectedIndex = 3;
            }

            // Affichage de l'active_sale (uniquement multi-restaurants / 1 seul restaurant pas besoin déja dans la div)
            let active_sale_detail = document.getElementById("active_sale_detail");
            if (active_sale_detail){
              active_sale_detail.style.display = "flex";
            }

            // 4.2.3 Affichage partie multiinputs
            // Mise à jour des titres et inputs pour la partie sales
            const multiSaleTitle = multiRestContent.querySelector('.title-vente');
            const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');

            multiSaleForms.forEach((form) => {
              form.style.display = "flex";
            });

            multiSaleTitle.style.display = "block";

            // Débloquer les inputs selon les restaurants checkés en cas de plusieurs actions
            const disabledRestaurantLinkedInputs = (listOfRestaurantInputs, linkedInputName) => {
              listOfRestaurantInputs.forEach((input) => {
                let itemIndex = input.dataset.itemindex;
                let linkedInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_${linkedInputName}`);
                if (linkedInput) {
                  if (input.checked == true) {
                    linkedInput.disabled = false;
                  } else {
                    linkedInput.disabled = true;
                  }
                }
              });
            }

            const recipeRestaurantInputs = document.querySelectorAll('.recipe_multi_restaurants_id');
            disabledRestaurantLinkedInputs(recipeRestaurantInputs, "price_per_plate");
            disabledRestaurantLinkedInputs(recipeRestaurantInputs, "tva_plate");
            disabledRestaurantLinkedInputs(recipeRestaurantInputs, "plate");
          }

        } else {

          // 4.3. Partie ou checked est false
          // 4.3.1. Partie 1 restaurant - recipe uniform input
          saleForm.style.display = "none";

          // 4.3.2. Affichher ou non la partie OrderForm selon les cas
          if (otherRecipe.checked == false){
            recipeOrdersForm.style.display = "none";

            // disabled input
            multiple.disabled = true;

            if(subrecipe.checked == true) {
              recipeOrdersIntro.innerText = message_Only_Subrecipe;
            } else {
              recipeOrdersIntro.innerText = message_for_intro;
            }

          } else {
            recipeOrdersForm.style.display = dislayType;
            recipeOrdersInstructions.innerText = "";
            if(subrecipe.checked == true){
              recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;

            }
          }

          // 4.3.2. Partie multi-restaurants
          if (switchFormMultiRest){
          // Option Mutli-établissements + ajouter disabled avec autre js

          // Mise à jour des display et des inputs sur la partie ventes
          // switchFormMultiRest.style.display = "none";

          const uniformMultiSaleTitle = switchFormMultiRest.querySelector('.title-uniform-vente');
          uniformMultiSaleTitle.style.display = "none";

          // disabled input
          const firstUniformInputPrice = document.getElementById("recipe_uniform_price");
          firstUniformInputPrice.disabled = true;

          // disabled input
          const firstUniformInputTva = document.getElementById("recipe_uniform_tva");
          firstUniformInputTva.disabled = true;


          // Display none la partie vente - Multi-établissements
          const multiSaleTitle = multiRestContent.querySelector('.title-vente');
          const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');

          // Partie active_sale uniquement sur multi-restaurants
          let active_sale_detail = document.getElementById("active_sale_detail");
          if (active_sale_detail){
            active_sale_detail.style.display = "none";
            recipeActiveSale.checked = false;
          }

          multiSaleForms.forEach((form) => {
            form.style.display = "none";
          });
          multiSaleTitle.style.display = "none";

          // Bloquer les inputs
            const recipeSellingPriceInputs = document.querySelectorAll('.recipe_multi_selling_price');
            recipeSellingPriceInputs.forEach((input) => {
              input.disabled = true;
            });

            const recipeTvaPriceInputs = document.querySelectorAll('.recipe_multi_selling_tva');
            recipeTvaPriceInputs.forEach((input) => {
              input.disabled = true;
            });

          // Mise à jour des display et des inputs sur la partie orders (besoin seulement si checked = false)
          if (otherRecipe.checked == false){
            multiRestContent.style.display = "none";
            switchFormMultiRest.style.display = "none";
            // switchButtonmultiRest.checked = false;

            // Bloquer les inputs
            const recipeOrderPlateInputs = document.querySelectorAll('.recipe_multi_plate');
            recipeOrderPlateInputs.forEach((input) => {
              input.disabled = true;
            });

          }
        }
        // 4.5 Activation des actions sur otherRecipe pour commande et subrecipe pour message, selon le check de saleButton
        // Varabilisé pour que les conditions sur le message etc. soient bien prise en compte et reconnus en js après les actions sur sales
        subrecipeChecked(saleButton, otherRecipe, recipeOrdersForm, recipeOrdersIntro);
      }

    });

    // 5 Activation des actions sur otherRecipe pour commande et subrecipe pour message, selon le check de saleButton
    subrecipeChecked(saleButton, otherRecipe, recipeOrdersForm, recipeOrdersIntro);

    // 6. Bloquage lié à l'activesale désactivé pour le moment (bloquer ou non l'input)
    // if (recipeActiveSale) {
    //   recipeActiveSale.addEventListener('change', (event) => {
    //     if (switchButtonmultiRest.checked == false) {
    //       event.preventDefault();
    //       //console.log(subrecipe);
    //       // console.log(multiple);
    //       if (event.target.checked){
    //         multiple.readOnly = false;

    //       } else {
    //         if (recipeActiveSale.checked == false && otherRecipe.checked == false){
    //          multiple.readOnly = true;
    //         }
    //       }
    //     }
    //   });
    // }

    // Pour edit
    let active_sale_detail = document.getElementById("active_sale_detail");
    if (saleButton.checked == false) {
      if (recipeActiveSale) {
        if (active_sale_detail){
          active_sale_detail.style.display = "none";
          recipeActiveSale.checked = false;
        }
      }
    } else {
      if (recipeActiveSale) {
        if (active_sale_detail){
          active_sale_detail.style.display = "flex";
        }
      }

    }

    // 7. Débloquage de l'input multiple selon les cas (A relier en parallèle à la fonction subrecipechecked qui prend en compte d'autres actions)
    otherRecipe.addEventListener('change', (event) => {
      // Options mulit-établissements, autre cas géré dans l'autres + display géré dans subrecipechecked
      if (switchButtonmultiRest) {
        // if (switchButtonmultiRest.checked == false) {
        event.preventDefault();
        // console.log(switchButtonmultiRest.checked);
        // // console.log(multiple);
        if (event.target.checked){
          multiple.readOnly = false;
        } else {
          if (saleButton.checked == false){
            multiple.readOnly = true;
          }
        }
      }
    });

  }
}

// 8. Nouvelle fonction pour effectuer les bonnes actions et bien personnaliser le message principalement en fonction de subrecipe et OtherRecipe
const subrecipeChecked = (button_1, button_2, recipeOrdersForm, recipeOrdersIntro) => {
  // var status = "unchecked"
  const message_Sale_And_Other_Recipe = "Commandes : Indiquez le nombre de recettes destinées directement à la vente et les recettes 'autres' (repas pour le personnel, recettes non facturées) pour les commandes par défaut de leurs ingrédients.";
  const message_Sale_And_Or_Other_With_Subrecipe = "Commandes : Ne pas indiquer le nombre de recettes intermédiaires. Elles seront prises en compte pour les commandes parmi les ingrédients des recettes finales.";
  const message_Only_Subrecipe = "Commandes : Les ingrédients des recettes intermédiaires seront prises en compte pour les commandes parmi les ingrédients des recettes finales.";
  const message_for_intro = "Indiquez le(s) type(s) de recette pour afficher les informations de ventes et de commandes correspondantes."

  // Partie 2 : mutli-établissements
  const switchButtonmultiRest = document.getElementById('recipe-uniform-multi-restaurants');
  const switchFormMultiRest = document.getElementById('switch-to-multi-restaurants');
  const multiRestContent = document.getElementById('display-multi-restaurants-recipe-form');


  const subrecipe = document.getElementById('recipe_subrecipe');
  const recipeOrdersInstructions = document.getElementById('recipe-orders-instructions');


  // Rappel méthode enabled restaurant_recipes_inputs
  // Débloquer les inputs
  const disabledRestaurantLinkedInputs = (listOfRestaurantInputs, linkedInputName) => {
    listOfRestaurantInputs.forEach((input) => {
      let itemIndex = input.dataset.itemindex;
      let linkedInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_${linkedInputName}`);
      if (linkedInput) {
        if (input.checked == true) {
          linkedInput.disabled = false;
        } else {
          linkedInput.disabled = true;
        }
      }
    });
  }


  if (subrecipe) {

    // 8.1 Récupération entre 1 restauant / plusieurs restaurants des bons éléments pour saleform et orderform
    // Obligé de récupérer ici les bonnes valeurs pour que le js ne retourne pas not defined pour les saleFom, orderForm
    let multiple = null;
    let saleForm = null;
    let recipeOrdersForm = null;
    let dislayType = null;

    // Partie pour bien avoir le display sur la partie ordersForm
    const initialMultiple = document.getElementById('recipe_restaurant_recipes_attributes_0_plate');
    const initialSaleForm = document.getElementById('recipe-sales-information');
    const initialRecipeOrdersForm = document.getElementById('recipe-orders-information');

    // Partie initiale adaptée selon multi-établissements :
    const uniformMultiple = document.getElementById('recipe_uniform_plate');
    const uniformsaleForm = document.getElementById('recipe-input-uniform-multivente');
    const uniformrecipeOrdersForm = document.getElementById('recipe-input-uniform-multiorders');

    // bon display sur la partie orderForm et multiple (lié au button 2)
    if ( initialSaleForm != null && initialRecipeOrdersForm != null ) {
      multiple = initialMultiple;
      saleForm = initialSaleForm;
      recipeOrdersForm = initialRecipeOrdersForm;
      dislayType = "block";
    // Si plusieurs restaurants
    } else {
      multiple = uniformMultiple;
      saleForm = uniformsaleForm;
      recipeOrdersForm = uniformrecipeOrdersForm;
      dislayType = "flex";
    }

    // 8.2 Actions pour customiser le message sur le check de subrecipe
    subrecipe.addEventListener('change', (event) => {
        event.preventDefault();
        // console.log(withPortionSizeRadio.checked);
        if (event.target.checked){
          recipeOrdersIntro.innerText ="";
          if (button_1.checked == true && button_2.checked == false){

            recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;
            // var status = "checked";
          } else {
            if (button_1.checked == false && button_2.checked == true){
            recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;
          } else {
            if (button_1.checked == false && button_2.checked == false){
            recipeOrdersIntro.innerText = message_Only_Subrecipe;
            }
          }
        }
        } else {
          if (button_1.checked == true && button_2.checked == true){
            recipeOrdersInstructions.innerText = message_Sale_And_Other_Recipe;
          } else {
            recipeOrdersInstructions.innerText = "";
            recipeOrdersIntro.innerText = "";
          }
        }
    });

    // 8.3 Actions sur otherrecipe (hors input mutliple déja effectué au dessus)
    //button other_recipe.change
    button_2.addEventListener('change', (event) => {
      // console.log(switchButtonmultiRest.checked);
      event.preventDefault();
      if (event.target.checked){
        // console.log("other-recipe-check");
        // 8.3.1 Display la form pour 1 restaurant ou uniform-multi et disabled l'input
        recipeOrdersIntro.innerText ="";
        recipeOrdersForm.style.display = dislayType;
        multiple.disabled = false;

        // 8.3.2 Display et enabled les bons éléments sur le mutli-établissements
        // Option Mutli-établissements
        if (switchFormMultiRest){
          switchFormMultiRest.style.display = "block";
          // Les multi-restaurants
          multiRestContent.style.display = "block";
          const multiSaleTitle = multiRestContent.querySelector('.title-vente');
          const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente');

          const recipeRestaurantInputs = document.querySelectorAll('.recipe_multi_restaurants_id');
          disabledRestaurantLinkedInputs(recipeRestaurantInputs, "plate");

          // Afficher la partie vente selon les cas ou sales est checked ou non
          if (button_1.checked == true){
            // Mise à jour des titres et inputs pour la partie sales
            multiSaleForms.forEach((form) => {
              form.style.display = "flex";
            });
            multiSaleTitle.style.display = "block";
          } else {
            const uniformMultiSaleTitle = switchFormMultiRest.querySelector('.title-uniform-vente');
            uniformMultiSaleTitle.style.display = "none";

            multiSaleForms.forEach((form) => {
              form.style.display = "none";
            });
            multiSaleTitle.style.display = "none";
          }
        }

        // 8.3.3 Mise à jour du message
        if (button_1.checked == true && subrecipe.checked == false){
          recipeOrdersInstructions.innerText = message_Sale_And_Other_Recipe;

        } else {
          if (button_1.checked == false && subrecipe.checked == false){
            recipeOrdersInstructions.innerText = "";

          } else {
            if (button_1.checked == false && subrecipe.checked == true){
              recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;

            } else {
              if (button_1.checked == true && subrecipe.checked == true){
                recipeOrdersInstructions.innerText = message_Sale_And_Other_Recipe;
              }
            }
          }
        }
      } else {

        // 8.4 PArtie unchecked mise à jour des display et inputs selon si ventes est checké ou non
        if (button_1.checked == false){
          //   saleForm.style.display = "none"; // A voir correction.
          recipeOrdersForm.style.display = "none";
          multiple.disabled = true;

          if (switchFormMultiRest){
           // Partie multi-établissements avec button_sale checked false
            multiRestContent.style.display = "none";
            switchFormMultiRest.style.display = "none";
            // switchButtonmultiRest.checked = false;

            // bloquer les inputs
            const recipeOrderPlateInputs = document.querySelectorAll('.recipe_multi_plate');
            recipeOrderPlateInputs.forEach((input) => {
              input.disabled = true;
            });
          }

          // Message
          if (subrecipe.checked == true) {
          recipeOrdersIntro.innerText = message_Only_Subrecipe;
          } else {
            recipeOrdersIntro.innerText = message_for_intro;
          }

        } else {

          recipeOrdersForm.style.display = dislayType;
          recipeOrdersInstructions.innerText = "";

          if(subrecipe.checked == true){
            recipeOrdersInstructions.innerText = message_Sale_And_Or_Other_With_Subrecipe;
          }
        }
      }

    });
  }
}

export {addRecipeDefOrderInForm};


