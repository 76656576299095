import $ from 'jquery';


const restaurantRecipesSpecUpdate = () => {

  const restaurantRecipesContent = document.getElementById('restaurant_recipes_content');
  if (restaurantRecipesContent) {
    const switchButtonOptimalQuantity = document.getElementById('fulfill-optimal-quantity');
    const formOptimalQuantity = document.getElementById('form-optimal-quantity');

    switchButtonOptimalQuantity.addEventListener('change', (event) => {
      if (event.target.checked) {
        confirm("Etes-vous certain de vouloir remplir automatiquement les quantités de recettes à commander pour chacune des recettes selon les quantités initialement paramétrées ?");
        $(formOptimalQuantity).submit();
      } else {
        confirm("Etes vous certain de vouloir réinitialiser les quantités pour commande de chacune des recettes ? Les quantités renseignées seront remises à zéro.");
        $.ajax(`/restaurant_recipes/reinitialize_order_quantities`, {
          method: "GET"
        })
        const address = window.location.href.split("?")[0];
        window.location = address
      }

    });
  }

// console.log("restrecipe");

}


export {restaurantRecipesSpecUpdate};
