import $ from 'jquery';

const addRemoveSupplierCond = () => {

  const addButton = document.querySelector('.fa-plus-circle.plus-supplier');
  if (addButton){
    var nbLignes = document.querySelectorAll(".product_supplier_item_supplier_id")

    if(nbLignes.length > 1){
      var i = nbLignes.length
    }
    else {
      var i = 1
    }

    // Checkbox ne peut être activé que sur 1 ligne de supplier item
   const CheckboxCheck = () => {
      $("body").ready(function(){
        $("input:checkbox.check").change(function() {
           if($('.check').length > 1){
                $('.check').prop('checked',false);
                $(this).prop('checked',true);
                $(this).prop('value',1);
            }
        });
      });
    }

    CheckboxCheck();

    addButton.addEventListener('click', (event) => {
      event.preventDefault();
      const supplierCard = document.querySelector('.suppliers-card');
      const productSupplier = document.querySelector('.supplier-units-rows');
      const productSupplierLabel = document.querySelector('.product-suppliers label');

      supplierCard.insertAdjacentHTML('beforeend',
        '<div class="supplier-units-rows supplier-labeld">'
        +
        productSupplier.innerHTML.split('[0]').join(`[${i}]`)
        +
        '<i class="fas fa-minus-circle minus-supplier"></i>'
        +
        '</div>');

      // recopie le basis unit du supplier item précédent
      const listitems = document.querySelectorAll('.supplier-units-rows');
      var selectoutputs = listitems[listitems.length-2].querySelectorAll('select')
      var selectinputs = listitems[listitems.length-1].querySelectorAll('select')
      selectinputs[2].selectedIndex = selectoutputs[2].selectedIndex

      // Vide tous les inputs d'un nouveau product_unit lorsque l'on est en edits
      if(window.location.href.split("/").includes("edit"))
        {
          const newitem = document.querySelectorAll('.supplier-units-rows');
          const listinputs = newitem[newitem.length-1].querySelectorAll('input');
          listinputs.forEach(input => input.value = "");
          listinputs[3].checked = false;
          const listselects = newitem[newitem.length-1].querySelectorAll('select');
          listselects[0].selectedIndex = 0;
        }
      CheckboxCheck();
      i++

      // Supprimer le delete () qui apparait dans l'edit sur les lignes ajoutées précédemment via le "insertAdjacentHTML"
      var productSupplierAll = document.querySelectorAll('.supplier-units-rows');
      var lastRow = productSupplierAll[productSupplierAll.length - 1];
      var lastChild = lastRow.children[lastRow.childElementCount - 2];
      if(lastChild.className == "remove-supplier-item"){
        lastChild.remove();
      }

      const removeButton = document.querySelectorAll('.supplier-units-rows');

      removeButton.forEach((item) => {
        item.lastChild.addEventListener('click', (event) => {
          event.preventDefault();
            item.childNodes.forEach((child) => {
              if (child.className=="fas fa-minus-circle minus-supplier") {
                item.remove();
              }
            });
        });
      });
    });
  }

}

export {addRemoveSupplierCond};
