import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['button', 'datepicker', 'modal', 'reinit-date'];

  connect() {
  }

  clearandclose(e) {
    this.modalTarget.classList.remove('show');
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }

  reinitdate(e) {
  	const my_fp = this.datepickerTarget._flatpickr;
    my_fp.clear();
  }
}
