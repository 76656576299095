const categoryCollapse = () => {

  const collapsibleButton = document.querySelectorAll(".button-green-categories");
  collapsibleButton.forEach((item)=>{
    item.addEventListener("click", (event) => {
      document.querySelectorAll(`#collapsible-categories${item.id.split('-')[1]}`).forEach((content)=>{
          if (content.classList.value == "d-none") {
            content.classList.remove("d-none");
          } else {
            content.classList.toggle("d-none");
          }
        });
    });
  });

  const collapsibleButtonEye = document.querySelectorAll(".button-green-ticket");
  collapsibleButtonEye.forEach((item)=>{
    item.addEventListener("click", (event) => {
      document.querySelectorAll(`#collapsible-eyes${item.id.split('-')[1]}`).forEach((content)=>{
          if (content.classList.value == "ticket-hidden") {
            content.classList.remove("ticket-hidden");
          } else {
            content.classList.toggle("ticket-hidden");
          }
        });
    });
  });

}

export {categoryCollapse};
