import {totalshopcart} from "../components/compute_total_shopcart.js";
import $ from 'jquery';

const minusPlusButton = () => {

  //1. Changement en direct dans l'input par le user
  const inputs = document.querySelectorAll('.quantityinput');
  // console.log(inputs);

  // Indicateur qu'il faut arrondir les inputs
  inputs.forEach((input) => {
    let currentValue = parseFloat(input.value);
    if (Number.isInteger(currentValue) == false) {
      // console.log(Number.isInteger(currentValue));
      input.style.backgroundColor = "#fd101521";
    } else {
      input.style.backgroundColor = "#FCFCFC";
    }
  });

  inputs.forEach((input) => {
    input.addEventListener('change', (event) => {
        // console.log('change value');
        // console.log(event.target.value);
        let currentValue = parseFloat(event.target.value);
        input.value = currentValue.toFixed(1);
        updateQuantityandPrices(input, currentValue);

        // Modifications pour quantité optimale : suivi de l'event-listener
        // NOTE : ne fonctionnera pas si l'utilisateur recharge la page. Possible de mettre de l'ajax pour envoyer cartèitem quantité_modified = true
        input.dataset.eventchanged = "true"
        // Mise à jour indications arrondi
        if (Number.isInteger(currentValue) == false) {
          input.style.backgroundColor = "#fd101521";
        } else {
          input.style.backgroundColor = "#FCFCFC";
        }
      });
    });

  const minusButtons = document.querySelectorAll('#minusbutton');
  minusButtons.forEach((minusButton) => {

    minusButton.addEventListener('click', (event) => {
      const input = minusButton.nextElementSibling;

      let currentValue = parseFloat(input.value);
      if (Number.isInteger(currentValue)) {
        if (currentValue > 0) {
          currentValue = currentValue - 1;
        } else {
          currentValue = 0;
        }
      } else {
        currentValue = Math.floor(currentValue);
        input.style.backgroundColor = "#FCFCFC";
      }
      input.value = currentValue.toFixed(1);
      updateQuantityandPrices(input, currentValue);
      input.dataset.eventchanged = "true"
    });

  });

  const plusButtons = document.querySelectorAll('#plusbutton');
  plusButtons.forEach((plusButton) => {
    // console.log('coucou');
    plusButton.addEventListener('click', (event) => {
      // console.log('salut');
      const input = plusButton.previousElementSibling;
      // console.log(input);
      // const prixunitaireht = document.getElementById(`prixunitaireht-${input.dataset.itemid}`);
      // const prixtotalht = document.getElementById(`totalht-${input.dataset.itemid}`);
      // const valeurunitaireht = parseFloat(prixunitaireht.innerHTML);
      let currentValue = parseFloat(input.value);
      if (Number.isInteger(currentValue)) {
        currentValue = currentValue + 1;
      } else {
        currentValue = Math.ceil(currentValue);
        input.style.backgroundColor = "#FCFCFC";
      }
      input.value = currentValue.toFixed(1);
      updateQuantityandPrices(input, currentValue);
      input.dataset.eventchanged = "true"
    });
  });

    const updateQuantityandPrices = (input, currentValue) => {
      // 1. Mise à jour des quantités de conditionnement commandés et order_item.quantity en hidden
      const itemquantity = document.getElementById(`item-quantity-${input.dataset.itemid}`);
      itemquantity.innerHTML = currentValue.toFixed(1);
      const hiddenquantity = document.getElementById(`hidden-quantity-${input.dataset.itemid}`);
      hiddenquantity.value = currentValue.toFixed(2);

      // 2.Mise à jour de la quantité unitaire (au L / au kg)
      const quantityPerUnit = document.getElementById(`item-quantity-per-unit-${input.dataset.itemid}`);
      const supplieritemsize = parseFloat(document.getElementById(`supplieritemsize-${input.dataset.itemid}`).innerHTML);
      // console.log(document.getElementById(`supplieritemselect-${input.dataset.itemid}`)); // NE FONCTIONNA PAS - création d'un supplieritem size en hidden
      const quantityPerUnitValue = ( supplieritemsize * currentValue ).toFixed(3);
      quantityPerUnit.innerHTML = quantityPerUnitValue

      // 3.Mise à jour du sous-total (recalcul en fonction du prix au kg / L pour éviter les problèmes de conversion entre prix et conditionnement)
      const refprixunitaireht = document.getElementById(`refprixunitaireht-${input.dataset.itemid}`);
      const prixtotalht = document.getElementById(`totalht-${input.dataset.itemid}`);
      const valeurunitaireht = parseFloat(refprixunitaireht.dataset.refcost);
      const valeurtotalht = ( valeurunitaireht * quantityPerUnitValue ).toFixed(2);
      prixtotalht.innerHTML = valeurtotalht;

      // 4.Mise à jour du total
      totalshopcart();

      //5. ajax pour update des cart_items
      $.ajax( {
        url: `/cart_items/${input.dataset.itemid}`,
        method: "PATCH",
        dataType: 'json',
        data: {cart_item: {quantity: currentValue }},
        statusCode: {
          401: function() {
            // Actions souhaitées lorsque le statut est 401 ( manque des informations d'authentification valides)
            // console.log("Statut 401 - Requête non autorisée");
           window.location = "/cart_items";
          },
        },
        error: function(err) {
          //console.log(err);
        }
      })

    }
  }


export {minusPlusButton};
