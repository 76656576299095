import $ from 'jquery';

const buttonPhoto = () => {

  const photoProduct = document.querySelector('.product-photo');
  const photoRecipe = document.querySelector('.recipe-photo');

  const photoChange = () => {
    console.log("photo upload factored")
    $('#image_upload_id').on('change', function() {
      showImage(this);
      const photoIcon = document.querySelector('.photo-icon')
      if(photoIcon){
        photoIcon.style.display = "none";
      }
      const photoText = document.querySelector('.photo-text')
      if(photoText){
        photoText.style.display = "none";
      }
      const ImageDefault = document.getElementById("image_Y")
      if(ImageDefault){
        ImageDefault.style.display = "none";
      }
    })
  }

  if (photoProduct){
    photoProduct.addEventListener('click', (event) => {
        document.getElementById("image_upload_id").click();
    });
    photoChange();
  }

  if (photoRecipe){
    photoRecipe.addEventListener('click', (event) => {
        document.getElementById("image_upload_id").click();
    });
    photoChange();
  }

  function showImage(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('#your_preview_id')
          .attr('src', e.target.result)
          .width(200)
          .height(200);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

}

const buttonUploadFile = () => {

  const fileInput = document.querySelector('.file-upload input');
  const fileTypeCsv = document.querySelector('.supplier-csv')
  const fileTypeExcel = document.querySelector('.supplier-excel')
  const filenameForm = document.querySelector('.filename');
  const buttonClose = document.querySelector('.close');

  if (fileInput){
    fileInput.addEventListener('change', (event) => {
      event.preventDefault();
      const file = fileInput.files[0];
      if (! /^.+(\.xlsx)$/i.test(fileInput.value) && !fileTypeCsv) {
        alert("Le fichier sélectionné n'est pas au format .xlsx");
        return;
      }

      if (! /^.+(\.csv)$/i.test(fileInput.value)&& !fileTypeExcel) {
        alert("Le fichier sélectionné n'est pas au format .csv");
        return;
      }

      if(fileInput.files.length == 1){
        $(".filename").fadeOut(1);
        $(".btn-file").fadeOut(1);
      }

      const filename = file.name.length > 15 ? 
      `${file.name.substring(0, 15)}... ajouté !` : 
      `${file.name} ajouté !`;

      filenameForm.innerText = filename;

      $(".filename").fadeIn(3000);
      $(".btn-file").fadeIn(3000);
      
    });
  }

  if(buttonClose){
    buttonClose.addEventListener('click', (event) => {
      event.preventDefault();
      if(fileInput){
        if(fileInput.files.length == 1){
          $(".filename").fadeOut(1);
          $(".btn-file").fadeOut(1);
        }
      }
    });
  }
}

export {buttonPhoto, buttonUploadFile };