const totalorder = (order_id) => {

  const textToFloat = (subtotal) => {
    return parseFloat(subtotal.lastElementChild.innerText);
  }

  const totalorder = document.getElementById(`total-order-validate-${order_id}`);
  const totalOrderGap = document.getElementById(`total-order-gap-validate-${order_id}`);
  const totalinputorder = document.getElementById(`total-to-validate-${order_id}`);
  const totalGapInput = document.getElementById(`total-gap-to-validate-${order_id}`);
  const subtotalorders = document.querySelectorAll(`.subtotalorder-${order_id}`);
  const removeOrderItems = document.querySelectorAll(`.remove-order-item-${order_id}`);
  const subtotalOrdersDefault = document.querySelectorAll(`.subtotal-default-${order_id}`);
  const minOrder = document.getElementById(`min-order-validate-${order_id}`);
  const minOrderContent = document.getElementById(`min-order-content-${order_id}`);
  const gapOrders = document.querySelectorAll(`.gaporder-${order_id}`)

  var array = []
  subtotalorders.forEach((subtotalorder) => {
    if(removeOrderItems.length < 1){
      array.push(parseFloat(subtotalorder.lastElementChild.innerText))
    } else {
      if(subtotalorder.nextElementSibling.lastElementChild.childNodes[2].checked == true){
      } else {
      array.push(parseFloat(subtotalorder.lastElementChild.innerText))
      }
    }
   });

  var array2 = []
  subtotalOrdersDefault.forEach((subtotalOrderDefault) => {
    array2.push(parseFloat(subtotalOrderDefault.lastElementChild.innerText))
  });

  var arrayGapOrders = []
  gapOrders.forEach((gapOrder) => {
    arrayGapOrders.push(parseFloat(gapOrder.lastElementChild.innerText))
  });

  function myFunc(total, num) {
    return total + num;
  }

  if (subtotalorders.length > 1) {
    if(array.length < 1) {
      var total = 0;
    } else {
      var total = array.reduce(myFunc);
      if (subtotalOrdersDefault.length != 0) {
        var gap = arrayGapOrders.reduce(myFunc)
        if (gap != 0) {
          totalOrderGap.style.display = "block"
        } else {
          totalOrderGap.style.display = "none"
        }
      }
    }
    totalorder.lastElementChild.innerText = total.toFixed(2) + " €";
    if(minOrder){
      var value = total - parseFloat(minOrder.dataset.minOrder)
      minOrder.innerText = value.toFixed(2) + " €"
      if (value >= 0){
        minOrderContent.style.display = "none"
      } else {
        minOrderContent.style.display = "flex"
      }
    }
    if (subtotalOrdersDefault.length != 0) {
      totalOrderGap.lastElementChild.innerText = gap.toFixed(2) + " €";
      totalGapInput.value = gap
    }
    totalinputorder.value = total.toFixed(2) + " €";

  } else {
    if(array.length < 1) {
      var total = 0;  
    } else {
      var total = textToFloat(subtotalorders[0]);
      if (subtotalOrdersDefault.length != 0) {
        var gap = textToFloat(gapOrders[0]);
        if (gap != 0) {
          totalOrderGap.style.display = "block"
        } else {
          totalOrderGap.style.display = "none"
        }
      }
    }
    totalorder.lastElementChild.innerText = total.toFixed(2) + " €";
    if(minOrder){
      var value = total - parseFloat(minOrder.dataset.minOrder)
      minOrder.innerText = value.toFixed(2) + " €"
      if (value >= 0){
        minOrderContent.style.display = "none"
      } else {
        minOrderContent.style.display = "flex"
      }
    }
    if (subtotalOrdersDefault.length != 0) {
      totalOrderGap.lastElementChild.innerText = gap.toFixed(2) + " €";
      totalGapInput.value = gap
    }
    totalinputorder.value = total.toFixed(2) + " €";
  }

};


export {totalorder};
