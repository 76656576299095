const selectSaleElements = () => {
  var refPdv = document.querySelectorAll('.sale_sale_items_ref_item');
  if (refPdv.length == 0){
    var refPdv = document.querySelectorAll('.sale_sale_item_ref_item');
  }

  if (refPdv){
    refPdv.forEach((refPdvItem)=> { 
      $(refPdvItem).on('change', function (e) {
        var id = parseInt(refPdvItem.children[0].name.split(/\[(.*?)\]/)[3]);
        //console.log(id)
        const descriptionPdv = document.getElementById(`sale_sale_items_attributes_${id}_description_item`);
        const descriptionPdvOptions = descriptionPdv.options;
        const element = refPdvItem.children[0].options[refPdvItem.children[0].selectedIndex].dataset.id;
        const saleElementId = document.getElementById(`sale_element_id[${id}]`);

        if (element) {
          Array.prototype.forEach.call(descriptionPdvOptions, option =>{
            if(element == option.dataset.id) {
              descriptionPdv.selectedIndex = option.index;
              saleElementId.value = option.dataset.id;
            }
          })
        }
      });
    });
  }

  var descriptionPdv = document.querySelectorAll('.sale_sale_items_description_item');
  if (descriptionPdv.length == 0){
    var descriptionPdv = document.querySelectorAll('.sale_sale_item_description_item');
  }
 
  if (descriptionPdv){
    descriptionPdv.forEach((descriptionPdvItem)=> { 
      $(descriptionPdvItem).on('change', function (e) {
        var id = parseInt(descriptionPdvItem.children[0].name.split(/\[(.*?)\]/)[3]);
        //console.log(id)
        const refPdv = document.getElementById(`sale_sale_items_attributes_${id}_ref_item`);
        const refPdvOptions = refPdv.options;
        const element = descriptionPdvItem.children[0].options[descriptionPdvItem.children[0].selectedIndex].dataset.id;
        const saleElementId = document.getElementById(`sale_element_id[${id}]`);

        if (element) {
          Array.prototype.forEach.call(refPdvOptions, option =>{
            if(element == option.dataset.id) {
              refPdv.selectedIndex = option.index;
               saleElementId.value = option.dataset.id;
            }
          })
        }
      });
    });
  }
}

const createRow = () => {
  const saleItemList = document.querySelector('.list-sale-items');
  const saleItemRows = document.querySelectorAll('.productrows');
  //const saleItemRows = document.querySelectorAll('.productrows:not([style*="display: none"])')  // selectionner le lastrow non masqué ! 
  const saleItemLastRow = saleItemRows[saleItemRows.length-1];
  const saleForm = document.querySelector('.new_sale');

  const regex = /[^0-9]/g
  var rowNb = parseInt(saleItemLastRow.dataset.row.replace(regex, ""));
  //console.log(saleItemLastRow)
  //console.log(rowNb)

  saleItemList.insertAdjacentHTML('beforeend',
    saleItemLastRow.outerHTML.split(`_${rowNb}_`).join(`_${rowNb+1}_`).split(`[${rowNb}]`).join(`[${rowNb+1}]`));

  const newitem = document.querySelectorAll('.productrows');
  newitem[newitem.length-1].style.display = "";
  if(saleForm){
    newitem[newitem.length-1].lastElementChild.lastChild.style.display = "";
  }
  const listinputs = newitem[newitem.length-1].querySelectorAll('input[type=number]')
  listinputs.forEach(input => input.value = 0)
  const listselects = newitem[newitem.length-1].querySelectorAll('select')
  listselects.forEach(select => select.selectedIndex = 0)
  const listnewrefs = newitem[newitem.length-1].querySelectorAll('.text-center-new-ref')
  listnewrefs.forEach(input => input.innerHTML = '<span></span>  <span></span>')
  const saleitemids = newitem[newitem.length-1].querySelectorAll('.sale_sale_item_id input')
  saleitemids.forEach(input => input.value = "")
}

const addProductOrRecipe = (object, type) => {
  object.addEventListener('click', (event) => {
    const saleItems = document.querySelectorAll('.productrows')
    saleItems.forEach((row)=>{
      if(row.dataset.row != '[0]'){
        row.remove();
      }
    });

    const saleItemList = document.querySelector('.list-sale-items');
    const saleItem = document.querySelectorAll('.productrows')[0];

    if(type == "product") {
       var listSelectSaleElements = document.querySelectorAll("[data-type='RestaurantProduct']")
    } else if(type == "recipe") {
      var listSelectSaleElements = document.querySelectorAll("[data-type='RestaurantRecipe']")
    }
    for (let i = 0; i < listSelectSaleElements.length; i++) {       
      // rempli la 1er ligne
      if (i == 0) {
        var price = document.getElementById('sale_sale_items_attributes_0_unit_sale_item_price');
        const saleElementId = document.getElementById(`sale_element_id[0]`);                         
        const newitem = document.querySelectorAll('.productrows');
        newitem[newitem.length-1].style.display = "";
        newitem[newitem.length-1].lastElementChild.lastChild.style.display = "";
        newitem[0].lastElementChild.lastChild.style.display = "none";
        var selectoutputs = newitem[newitem.length-1].querySelectorAll('select');
        selectoutputs.forEach((output)=>{
          output.options.selectedIndex = listSelectSaleElements[i].index;
        });
        price.value = parseFloat(listSelectSaleElements[i].dataset.price).toFixed(2);
        saleElementId.value = listSelectSaleElements[i].dataset.id;

      }
      // Ajoute de nouvelles lignes
      if (i != 0){  
        saleItemList.insertAdjacentHTML('beforeend', saleItem.outerHTML.split('_0_').join(`_${i}_`).split('[0]').join(`[${i}]`));   
        var price = document.getElementById(`sale_sale_items_attributes_${i}_unit_sale_item_price`); 
        const saleElementId = document.getElementById(`sale_element_id[${i}]`);
        const newitem = document.querySelectorAll('.productrows');
        newitem[newitem.length-1].style.display = "";
        newitem[newitem.length-1].lastElementChild.lastChild.style.display = "";
        newitem[0].lastElementChild.lastChild.style.display = "none";
        var selectoutputs = newitem[newitem.length-1].querySelectorAll('select');
        selectoutputs.forEach((output)=>{
          output.options.selectedIndex = listSelectSaleElements[i].index;
        });
        price.value = parseFloat(listSelectSaleElements[i].dataset.price).toFixed(2);
        saleElementId.value = listSelectSaleElements[i].dataset.id;
      }
    }
    selectSaleElements();
  });
}

const addSaleItems = () => {
  const saleForm = document.querySelector('.new_sale');
  const saleEdit = document.querySelector('.edit_sale');
  const addButton = document.getElementById('add_cart_items');
  const addButtonx5 = document.getElementById('add_cart_itemsx5');


  if (saleForm || saleEdit){
    if(saleForm){
      const firstRemoveIcon = document.querySelectorAll('.productrows i')[0];
      firstRemoveIcon.style.display = "none";
    }
    if(addButton) {
      addButton.addEventListener('click', (event) => {
        event.preventDefault();
        // var nbLignes = document.querySelectorAll(".sale_sale_items_ref_item");
        // if (nbLignes.length == 0) {
        //   var nbLignes = document.querySelectorAll(".sale_sale_item_ref_item");
        // }

        // if( nbLignes.length > 1 ){ var i = nbLignes.length } else { var i = 1 }

        createRow();
        // i++
        selectSaleElements();
      });
    }

    if(addButtonx5) {
      addButtonx5.addEventListener('click', (event) => {
        event.preventDefault();
        // var nbLignes = document.querySelectorAll(".sale_sale_items_ref_item");
        // if (nbLignes.length == 0) {
        //   var nbLignes = document.querySelectorAll(".sale_sale_item_ref_item");
        // }
        // if( nbLignes.length > 1 ){ var i = nbLignes.length } else { var i = 1 }

        for (let step = 0; step < 5; step++) {
          createRow();
          // i++
          selectSaleElements();
        }
      });
    }
  
    if (saleForm) {
      const importAllButton = document.getElementById('import-all');

      importAllButton.addEventListener('click', (event) => {
        const saleItems = document.querySelectorAll('.productrows')
        saleItems.forEach((row)=>{
          if(row.dataset.row != '[0]'){
            row.remove();
          }
        });
        const saleItemList = document.querySelector('.list-sale-items');
        const saleItem = document.querySelectorAll('.productrows')[0];
        const listSelectSaleElements = document.getElementById('sale_sale_items_attributes_0_ref_item').options
        
        for (let i = 0; i < listSelectSaleElements.length; i++) {       
          // rempli la 1er ligne
          if (listSelectSaleElements[i].value == "") {
            const price = document.getElementById('sale_sale_items_attributes_0_unit_sale_item_price');
            const saleElementId = document.getElementById(`sale_element_id[0]`);                         
            const newitem = document.querySelectorAll('.productrows');
            newitem[newitem.length-1].style.display = "";
            newitem[newitem.length-1].lastElementChild.lastChild.style.display = "";
            var selectoutputs = newitem[newitem.length-1].querySelectorAll('select');
            selectoutputs.forEach((output)=>{
              output.options.selectedIndex = listSelectSaleElements[i+1].index;
            });
            price.value = parseFloat(listSelectSaleElements[i+1].dataset.price).toFixed(2)
            saleElementId.value = listSelectSaleElements[i+1].dataset.id
          }

          // Ajoute de nouvelles lignes
          if (listSelectSaleElements[i].value != "" && listSelectSaleElements[i+1] !== undefined){  
            saleItemList.insertAdjacentHTML('beforeend', saleItem.outerHTML.split('_0_').join(`_${i}_`).split('[0]').join(`[${i}]`));   
            var price = document.getElementById(`sale_sale_items_attributes_${i}_unit_sale_item_price`) 
            const saleElementId = document.getElementById(`sale_element_id[${i}]`);
            const newitem = document.querySelectorAll('.productrows');
            newitem[newitem.length-1].style.display = "";
            newitem[newitem.length-1].lastElementChild.lastChild.style.display = "";
            newitem[0].lastElementChild.lastChild.style.display = "none";
            var selectoutputs = newitem[newitem.length-1].querySelectorAll('select') 
            selectoutputs.forEach((output)=>{
              output.options.selectedIndex = listSelectSaleElements[i+1].index;
            });
            price.value = parseFloat(listSelectSaleElements[i+1].dataset.price).toFixed(2)
            saleElementId.value = listSelectSaleElements[i+1].dataset.id
          }
        }
        selectSaleElements();
      });
    
    const importProductButton = document.getElementById('import-product');
    const importRecipeButton = document.getElementById('import-recipe');
    addProductOrRecipe(importProductButton, "product");
    addProductOrRecipe(importRecipeButton, "recipe");
    }
  }
}

export {addSaleItems, selectSaleElements};


