import $ from 'jquery';


const addRestProdMultiplePricesToForm = () => {

// VARIABILISE en fonction du psu id ou de new
  const switchMultiForm = (multiRestContent, switchButtonmultiRestProd, multiSaleTitle, multiSaleForms, saleFormUniqueRest, switchFormMultiRestProd) => {
    if (switchButtonmultiRestProd && multiRestContent) {

      let itempsuid = switchButtonmultiRestProd.dataset.itempsuid

      // Méthode pour disabled un liste d'inputs
      const disabledListOfInput = (listOfInputs) => {
        listOfInputs.forEach((input) => {
          // console.log(input);
          input.disabled = true;
        });
      }

      // Méthode pour enabled et disabled les inputs liés selon que restaurantinput soit checké
      const disabledRestaurantLinkedInputs = (listOfRestaurantInputs, linkedInputName) => {
        listOfRestaurantInputs.forEach((input) => {
          let itemIndex = input.dataset.itemindex;
          const linkedInput = document.getElementById(`product_selling_unit_${itempsuid}_restaurant_products_attributes_${itemIndex}_${linkedInputName}`);
          // console.log(linkedInput);
          // console.log(input.checked);
          // console.log(itemIndex);
          if (linkedInput) {
            if (input.checked == true) {
              linkedInput.disabled = false;
            } else {
              linkedInput.disabled = true;
            }
          }
          // console.log(linkedInput);
        });
      }

      // Méthode pour injecter les valeurs aux linked inputs sur le check uniform
      const injectUniformValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
        if (uniformInput.value != "") {
          let uniformInputValue = uniformInput.value
          if (uniformInputValue >= 0) {
            listOfMultipleLinkedInputs.forEach((input) => {
              input.value = parseFloat(uniformInputValue);
            });
          }
        }
      }

      // Méthode pour désactiver les valeurs aux linked inputs sur le decheck uniform
      const removeformValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
        if (uniformInput.value != "") {
          let uniformInputValue = uniformInput.value
          if (uniformInputValue >= 0) {
            listOfMultipleLinkedInputs.forEach((input) => {
              input.value = "";
            });
          }
        }
      }

      // Méthode pour injecter les valeurs aux linked inputs SELECT sur le check uniform (TVA)
      const injectUniformSelectedValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
        if (uniformInput.value != "") {
          let uniformInputValue = uniformInput.value
          if (uniformInputValue >= 0) {
            listOfMultipleLinkedInputs.forEach((input) => {
              input.selectedIndex = uniformInput.selectedIndex;
            });
          }
        }
      }

      // Méthode pour désactiver les valeurs aux linked inputs SELECT sur le decheck uniform (TVA)
      const removeUniformSelectedValueinLinkedInputs = (uniformInput, listOfMultipleLinkedInputs) => {
        if (uniformInput.value != "") {
          let uniformInputValue = uniformInput.value
          if (uniformInputValue >= 0) {
            listOfMultipleLinkedInputs.forEach((input) => {
              input.selectedIndex = 3;
            });
          }
        }
      }

      // Méthode pour mettre à jour les prix ht à partir de l'uniform input
      const updateMultihtPrices = () => {
        const prodUniformHtPrice = document.getElementById(`${itempsuid}-uniformproducthtpricecompute`);
        restaurantInputsProd.forEach((input) => {
          let itemIndex = input.dataset.itemindex;
          let restprodhtprice = document.getElementById(`psu_${itempsuid}_restprodhtprice-${itemIndex}`);
          if (restprodhtprice) {
            if (input.checked == true) {
              restprodhtprice.innerHTML = prodUniformHtPrice.innerHTML;

            } else {
              restprodhtprice.innerHTML = `( - €HT )`;
            }
          }
        });
      }

      //1. Récupération des check restaurants_id
      const restaurantInputsProd = multiRestContent.querySelectorAll('.rest_prod_multi_restaurants_id');
      //2. Récupération des inputs 2.1 sales price et 2.2 tva et 2.3 orders
      const sellingPriceInputsProd = multiRestContent.querySelectorAll('.rest_prod_multi_selling_price');
      const sellingTvaInputsProd = multiRestContent.querySelectorAll('.rest_prod_multi_selling_tva');
      // const recipeOrderPlateInputs = document.querySelectorAll('.recipe_multi_plate');

      const uniformProdPriceInput = switchFormMultiRestProd.querySelector('.uniform_sale_price_field_prod');
      const uniformProdTvaInput = switchFormMultiRestProd.querySelector('.uniform_tva_items_select');
      // const recipeOrderPlateInputs = document.querySelectorAll('.recipe_multi_plate');



      // Préparation des inputs disabled ou non selon l'edit
      if (restaurantInputsProd) {

        //3. disabled les inputs si pas sur edit
        disabledRestaurantLinkedInputs(restaurantInputsProd, "price_per_item");
        disabledRestaurantLinkedInputs(restaurantInputsProd, "tva_item");

      } else {
        // total disabled
        disabledListOfInput(sellingPriceInputsProd);
        disabledListOfInput(sellingTvaInputsProd);
      }


      // 4. affichage sur sélection switch button selon les cas
      // Partie à compléter sur swith button pour afficher la partie multi-établissements et masquer celle uniforme
      switchButtonmultiRestProd.addEventListener('change', (event) => {
        console.log("switchButtonmultiRestProd change")

        //Ne doit fonctionner que si la partie vente directe ou autre checkées
        if (event.target.checked) {


          // checked tous les inputs de restaurant
          restaurantInputsProd.forEach((input) => {
            input.checked = true;
          });

          // Mise à jour des disabled (edit)
          disabledRestaurantLinkedInputs(restaurantInputsProd, "price_per_item");
          disabledRestaurantLinkedInputs(restaurantInputsProd, "tva_item");

          // Mise à jour des valeurs price
          injectUniformValueinLinkedInputs(uniformProdPriceInput, sellingPriceInputsProd);
          // Mise à jour des valeurs tva
          injectUniformSelectedValueinLinkedInputs(uniformProdTvaInput, sellingTvaInputsProd);
          // Mise à jour des htprices
          updateMultihtPrices();

          // Avec simple_form en :restaurant_recipe et pas restaurant_recipes, besoin de refixer la TVA par défaut seulement pour le CREATE 136
          if (itempsuid == "new") {
            let sellingTvaInputsToFix = multiRestContent.querySelectorAll('.rest_prod_multi_selling_tva');
            sellingTvaInputsToFix.forEach((input) => {
              if (input.value == "") {
                input.selectedIndex = 1;
              }
            });
          }

        } else {

          restaurantInputsProd.forEach((input) => {
            input.checked = false;
          });
          // obligé de les rappeler sinon ne les définit pas
          const sellingPriceInputsProd = multiRestContent.querySelectorAll('.rest_prod_multi_selling_price');
          const sellingTvaInputsProd = multiRestContent.querySelectorAll('.rest_prod_multi_selling_tva');
          // const recipeOrderPlateInputs = multiRestContent.querySelectorAll('.recipe_multi_plate');
          // console.log(multiRestContent);

          // réinitialiser valeur
          removeformValueinLinkedInputs(uniformProdPriceInput, sellingPriceInputsProd);
          removeUniformSelectedValueinLinkedInputs(uniformProdTvaInput, sellingTvaInputsProd);

          // total disabled
          disabledListOfInput(sellingPriceInputsProd);
          disabledListOfInput(sellingTvaInputsProd);

          // Mise à jour des htprices
          let multiHtPricesComputed = multiRestContent.querySelectorAll(".restprodhtpricecompute");
          // console.log(multiHtPricesComputed);
          if (multiHtPricesComputed) {
             multiHtPricesComputed.forEach((input) => {
              input.innerHTML = `( - €HT )`;
            });
          }
        }

      });

    // Ne pas passer direct par le if (switchButtonmultiRest.checked == true) obligé de le mettre dans l'eventlistener

    //5. Mise à jour des multiinputs en cas de changement d'inputs uniformes (si switchbutton est checked)
    // Ne pas passer direct par le if (switchButtonmultiRest.checked == true) obligé de le mettre dans l'eventlistener

    //5.1 priceuniforminput and htprice
    uniformProdPriceInput.addEventListener('change', (event) => {
      if ( switchButtonmultiRestProd.checked == true ) {

        sellingPriceInputsProd.forEach((input) => {
          if (uniformProdPriceInput.value != "") {
            console.log(uniformProdPriceInput.value);
            input.value = parseFloat(uniformProdPriceInput.value);
          } else {
            input.value = "";
          }
        });
        updateMultihtPrices();
      }
    });


    //5.2 Tvainput and htprice
    uniformProdTvaInput.addEventListener('change', (event) => {
      if ( switchButtonmultiRestProd.checked == true ) {
        sellingTvaInputsProd.forEach((input) => {
          // console.log(uniformInput.value);
          if (uniformProdTvaInput.value != "") {
            input.selectedIndex = uniformProdTvaInput.selectedIndex;
          } else {
            input.selectedIndex = 1;
          }
        });
        updateMultihtPrices();
      }
    });


    //6. Mise à jour du check sur l'uniform input à partir des multiinputs en cas de changement d'un des multiinputs (si switchbutton est checked)
    // Ne pas passer direct par le if (switchButtonmultiRest.checked == true) obligé de le mettre dans l'eventlistener


    // 6.0 Méthode pour tracker en cas de changement d'un multi-input avec le uniform check false si tous les inputs price, TVA, orders
    // sont alignés avec les uniform inputs, pour remettre un uniform check true
     const checkUniformProdWhenAlignedInputs = () => {
      let differentinputcount = 0

      // Partie price
      sellingPriceInputsProd.forEach((input) => {
        if (input.value == "" || input.value != uniformProdPriceInput.value ) {
          differentinputcount += 1;
        }
      });

      // Partie tva
      sellingTvaInputsProd.forEach((input) => {
        if (input.value == "" || input.selectedIndex != uniformProdTvaInput.selectedIndex ) {
          differentinputcount += 1;
        }
      });

      // conclusion: remettre input ou non en checked
      if ( differentinputcount == 0 ) {
        switchButtonmultiRestProd.checked = true;
        // console.log(differentinputcount);
      } else {
        switchButtonmultiRestProd.checked = false;
      }

    }

     //6.1 priceuniforminput
    sellingPriceInputsProd.forEach((input) => {
      input.addEventListener('change', (event) => {
        if ( switchButtonmultiRestProd.checked == true ) {
          if (input.Value != uniformProdPriceInput.value ) {
            switchButtonmultiRestProd.checked = false;
          }
        } else {
          checkUniformProdWhenAlignedInputs();
        }
      });
    });


    //6.2 tvaforminput
    sellingTvaInputsProd.forEach((input) => {

      input.addEventListener('change', (event) => {
        if ( switchButtonmultiRestProd.checked == true ) {
          if (input.selectedIndex != uniformProdTvaInput.selectedIndex ) {
            switchButtonmultiRestProd.checked = false;
          }
        } else {
          checkUniformProdWhenAlignedInputs();
        }
      });

    });

    //6.3 Si uniform == false réinitialisetion de la tva pour les multi_inputs


    //6.4 Si uniform == false et reste sur other_recipe recalcul si besoin de checker ou pas le bouton (plate vs. sellingprice)
    // condition supplémentaire : tous les restaurants doivent être checkés (sinon problème si tout est vide)
    let countRestaurantsChecked = 0;
     restaurantInputsProd.forEach((input) => {
        if (input.checked == true){
          countRestaurantsChecked += 1;
        }
      });

    if ( switchButtonmultiRestProd.checked == false && countRestaurantsChecked == restaurantInputsProd.length ) {
      checkUniformProdWhenAlignedInputs();
    }


    //7. affichage pour l'edit sur checked ou non du switch button selon les cas

    if (switchButtonmultiRestProd.checked == false){

          // Mise à jour de la tva sur uniforme
           //7.1 Mettre par défaut Tva à 0
            if (uniformProdTvaInput.value == "") {
              uniformProdTvaInput.selectedIndex = 1;
            }

          // Mise à jour de la tva sur multi-établissements
          sellingTvaInputsProd.forEach((input) => {
            if ( input.selectedIndex == 0 ) {
              input.selectedIndex = 1;
            }
          });

        // sinon mise à jour des valeurs
    } else {
      // Mise à jour valeur uniform
      uniformProdPriceInput.value = sellingPriceInputsProd[0].value;
      uniformProdTvaInput.selectedIndex = sellingTvaInputsProd[0].selectedIndex;

      // Mise à jour prix ht
      const prodUniformHtPrice = document.getElementById(`${itempsuid}-uniformproducthtpricecompute`);
      let multiHtPricesComputed = multiRestContent.querySelectorAll(".restprodhtpricecompute");
      prodUniformHtPrice.innerHTML = multiHtPricesComputed[0].innerHTML;
    }


    //8. Bouton pour dispaly uniform inputs
    const uniforminputsToDisplay = document.getElementById(`${itempsuid}-product-uniforminputs-to-display`);
    const buttonUniformDisplay = document.getElementById(`${itempsuid}-product-button-displayuniforminputs`);
    // console.log(uniforminputsToDisplay);
    // console.log(buttonUniformDisplay);
    if (buttonUniformDisplay && uniforminputsToDisplay) {
      //8.1 Partie edit
      if ( switchButtonmultiRestProd.checked == true ){
        uniforminputsToDisplay.style.display = "block";
      } else {
        uniforminputsToDisplay.style.display = "none";
      }

      //8.2 Partie envetlistener display
      buttonUniformDisplay.addEventListener('click', (event) => {
        if (uniforminputsToDisplay.style.display == "none"){
          uniforminputsToDisplay.style.display = "block";
        } else {
          uniforminputsToDisplay.style.display = "none";
        }
      });

    }


    //9. Permettre d'enlever le disabled sur la bonne ligne pour le restaurant checké selon les cas
      restaurantInputsProd.forEach((input) => {
        input.addEventListener('change', (event) => {
          let itemIndex = input.dataset.itemindex;
          const restSellingPriceInput = document.getElementById(`product_selling_unit_${itempsuid}_restaurant_products_attributes_${itemIndex}_price_per_item`);
          const restSellingTvaPriceInput = document.getElementById(`product_selling_unit_${itempsuid}_restaurant_products_attributes_${itemIndex}_tva_item`);
          // const recipeOrderPlateInput = document.getElementById(`recipe_restaurant_recipes_attributes_${itemIndex}_plate`);

          if (event.target.checked) {
            restSellingPriceInput.disabled = false;
            restSellingTvaPriceInput.disabled = false;
            // recipeOrderPlateInput.disabled = false;

           } else {
            restSellingPriceInput.disabled = true;
            restSellingTvaPriceInput.disabled = true;
            // recipeOrderPlateInput.disabled = true;
          }
        });
      });


    }
  }

  //Tout part des switch buttons s'il y a plusieurs restaurants. Un dataset est fixé pour retrouver le bon id (new pour create) et psu id pour edit
  const switchButtonmultiRestProds = document.querySelectorAll('.rest_product-uniform-multi-restaurants');
  // console.log(switchButtonmultiRestProds);
  if (switchButtonmultiRestProds && switchButtonmultiRestProds.length > 0) {

    switchButtonmultiRestProds.forEach((switchButtonmultiRestProd) => {

      // Récupération du psuid
      let psuIdIndex = switchButtonmultiRestProd.dataset.itempsuid;
      // console.log(psuIdIndex);
      // console.log(switchButtonmultiRestProd);
      // // Récupération du format multi-établissements
      const multiRestContent = document.getElementById(`${psuIdIndex}_display-multi-restaurants-product-form`);
      // Partie uniforme par établissement prix de vente et TVA
      const saleFormUniqueRest = document.getElementById(`${psuIdIndex}-product-input-uniform-multivente`);

      const switchFormMultiRestProd = document.getElementById(`${psuIdIndex}-switch-to-multi-restaurants-prod`);

      // console.log(multiRestContent, switchButtonmultiRestProd, saleFormUniqueRest, switchFormMultiRestProd);
      if (switchButtonmultiRestProd && multiRestContent) {
        // Liste de sous-blocs pour prix de vente et TVA
        const multiSaleTitle = multiRestContent.querySelector('.title-vente.prod');
        const multiSaleForms = multiRestContent.querySelectorAll('.input-multi-vente.prod');
        // console.log(multiRestContent, switchButtonmultiRestProd, multiSaleTitle, multiSaleForms, saleFormUniqueRest, switchFormMultiRestProd);
        switchMultiForm(multiRestContent, switchButtonmultiRestProd, multiSaleTitle, multiSaleForms, saleFormUniqueRest, switchFormMultiRestProd);
      }

    });
  }


}


export {addRestProdMultiplePricesToForm};
