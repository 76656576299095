const OrderCardResponsive = () => {

  if (window.matchMedia('(max-width: 569px)').matches) {
    $(".product-unwrap  > *").unwrap();
    $(".shopping-cart-unwrap > *").unwrap();
  }

}

export {OrderCardResponsive};
