import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { French } from "flatpickr/dist/l10n/fr.js";

  function compute(object) {
  	var sum = 0;
    object.forEach( (element) => {
      var destroyCheckbox = element.closest('tr').querySelector("input[type='checkbox']")
      if (destroyCheckbox == null || destroyCheckbox.checked == false) {
        sum += parseFloat(element.value)
      }
    });
    return sum
  }

  function empty(element){
    if(element.value == ""){
      element.value = 0;
    }
  }

  function toggleValidation(element, boolean) {
    if (boolean) {
      element.classList.add("is-invalid");
      return true;
    } else {
      element.classList.remove("is-invalid");
      return false
    }
  }

export default class extends Controller {
  static targets = ['start_date', 'end_date', 'compute', 'qty_sold', 'total_qty_sold', 'qty_free', 'total_qty_free', 'discount_ttc', 'total_discount_ttc', 'total_discount_ttc_input', 'ttc_amount', 'total_amount_ttc', 'total_amount_ttc_input', 'vat_amount', 'total_vat', 'total_vat_input', 'ref_item', 'description_item', 'price','trash', 'row', 'checkbox_with_errors', 'list', 'sort_by', 'pagination', 'sortable', 'reinit-date'];

  connect() {
    // if (this.element.baseURI.includes("edit")) {
    //   this.ref_itemTargets.forEach( (element) => {
    //     if (element.options[element.selectedIndex].innerText == "Code") {
    //       element.style.border = "1px solid red"
    //     }
    //   });
    //   this.description_itemTargets.forEach( (element) => {
    //     if (element.options[element.selectedIndex].innerText == "Libellé") {
    //       element.style.border = "1px solid red"
    //     }
    //   });
    // }
  }

  compute(e) {
    this.total_qty_soldTarget.innerHTML = compute(this.qty_soldTargets).toFixed(2) // Total des quantités vendues
    this.total_qty_freeTarget.innerHTML = compute(this.qty_freeTargets).toFixed(2) // Total des quantités gratuites
    this.total_discount_ttcTarget.innerHTML = compute(this.discount_ttcTargets).toFixed(2) // Total des réductions ttc
    this.total_discount_ttc_inputTarget.value = compute(this.discount_ttcTargets).toFixed(2) // pour l'input
    this.total_amount_ttcTarget.innerHTML = compute(this.ttc_amountTargets).toFixed(2)  // Total des montants ttc
    this.total_amount_ttc_inputTarget.value = compute(this.ttc_amountTargets).toFixed(2) // pour l'input
    this.total_vatTarget.innerHTML = compute(this.vat_amountTargets).toFixed(2) // Total de la tva
    this.total_vat_inputTarget.value = compute(this.vat_amountTargets).toFixed(2) // pour l'input
  }


  pricechange(e){
    this.priceTargets.forEach( (element) => {
      if(element.dataset.check == 0) {
        element.dataset.check = 1
      }
    });
  }

  tvachange(e){
    this.vat_amountTargets.forEach( (element) => {
      if(element.dataset.check == 0) {
        element.dataset.check = 1
      }
    });
  }

  refchange(e){
    this.priceTargets.forEach( (element) => {
      if(element.dataset.check == 1) {
        element.dataset.check = 0
      }
    });

    this.vat_amountTargets.forEach( (element) => {
      if(element.dataset.check == 1) {
        element.dataset.check = 0
      }
    });
  }

  update(e) {
    //e.preventDefault();
    const regex = /[^0-9]/g
    var rowNb = event.currentTarget.dataset.row.replace(regex, "");
    var importType = document.getElementById('sale-form-div').dataset.type
    var price = 0
    var tva = 0
    var priceForCumpute = 0
    var qtySoldForCumpute = 0
    var discount_ttcForCumpute = 0
    var ttc_amountForTva = 0

    this.ref_itemTargets.forEach( (element) => {
      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][ref_item]`)[0].name) {
        if (element.value != ''){
          toggleValidation(element, false)
        } else {
          toggleValidation(element, true)
        }
/*        if(element.style.border == "1px solid red") {
          element.style.border = "1px solid #1EDD88"
        }
*/      var currentTarget = element
        price = currentTarget.options[currentTarget.options.selectedIndex].dataset.price;
        tva = currentTarget.options[currentTarget.options.selectedIndex].dataset.tva;
      }
    });

    this.description_itemTargets.forEach( (element) => {
      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][description_item]`)[0].name) {
        if (element.value != ''){
          toggleValidation(element, false)
        } else {
          toggleValidation(element, true)
        }
        // if(element.style.border == "1px solid red") {
        //   element.style.border = "1px solid #1EDD88"
        // }
      }
    });

    this.priceTargets.forEach( (element) => {
      var ref = element.closest('tr').getElementsByClassName('text-center-new-ref')[0]
      if (ref) { var refNew = ref.children[0].textContent } else {var refNew = "" }
      var description = element.closest('tr').getElementsByClassName('text-center-new-ref')[0]
      if (description) { var descriptionNew = description.children[1].textContent } else {var descriptionNew = "" }
      var refDesc = refNew + descriptionNew

      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][unit_sale_item_price]`)[0].name) {
        if (element.value == 0 || element.value == '') {
          //console.log("price value = 0")
          if (isNaN(parseFloat(price).toFixed(2))) {
            //console.log("price value = 0 et NAN")
            priceForCumpute = 0
          } else {
            //console.log("price value = 0 et != NAN")
            element.value = parseFloat(price).toFixed(2);
            priceForCumpute = element.value
          }
        } else {
          if (parseFloat(element.value).toFixed(2) != parseFloat(price).toFixed(2) && element.dataset.check == 1) {
            //console.log("price value != 0 et price value != input et check = 1")
            priceForCumpute = element.value
          } else {
            //console.log("price value != 0 et price value = input et check != 1")
            if (refDesc == "" && importType != "Import API") {
              //console.log("new ref = vide et pas Import API")
              if (price == '') {
                priceForCumpute = 0
                element.value = 0
              } else {
                element.value = parseFloat(price).toFixed(2);
                priceForCumpute = element.value
              }
            } else {
              if ((refDesc == "" && importType == "Import API")) {
                //console.log("new ref = vide et Import API")
                priceForCumpute = element.value
              } else {
                //console.log("new ref != vide")
                priceForCumpute = element.value
              }
            }
          }
        }
      }
    });

    this.qty_soldTargets.forEach( (element) => {
      empty(element)
      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][qty_sold]`)[0].name) {
        qtySoldForCumpute = element.value
      }
    });

    this.qty_freeTargets.forEach( (element) => {
      empty(element)
    });

    this.discount_ttcTargets.forEach( (element) => {
      empty(element)
      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][discount_ttc_amount]`)[0].name) {
        discount_ttcForCumpute = element.value
      }
    });

    this.ttc_amountTargets.forEach( (element) => {
      empty(element)
      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][ttc_amount]`)[0].name) {
        element.value = (parseFloat(priceForCumpute) * qtySoldForCumpute - discount_ttcForCumpute).toFixed(2);
        ttc_amountForTva = element.value
      }
    });

    this.vat_amountTargets.forEach( (element) => {
      var ref = element.closest('tr').getElementsByClassName('text-center-new-ref')[0]
      if (ref) { var refNew = ref.children[0].textContent } else {var refNew = "" }
      var description = element.closest('tr').getElementsByClassName('text-center-new-ref')[0]
      if (description) { var descriptionNew = description.children[1].textContent } else {var descriptionNew = "" }
      var refDesc = refNew + descriptionNew

      if(element.name == document.getElementsByName(`sale[sale_items_attributes][${rowNb}][vat_amount]`)[0].name) {
        var tvaValue = ((ttc_amountForTva / (1 + parseFloat(tva) / 100)) * (parseFloat(tva)/ 100)).toFixed(2)
        if (element.value == 0 || element.value == '') {
          if (ttc_amountForTva == 0 && tva === undefined) {
            element.value = 0
          } else {
            if (tva === undefined) {
              element.value = element.value
            } else {
              if (isNaN(tvaValue)) {
                element.value = 0
              } else {
                element.value = tvaValue;
              }
            }
          }
        } else {
          if (tva === undefined) {
            element.value = element.value
          } else {
            if (parseFloat(element.value).toFixed(2) != tvaValue && element.dataset.check == 1) {
              element.value = element.value
            } else {
              if (refDesc == "") {
                if (isNaN(tvaValue)) {
                  element.value = 0
                } else {
                  element.value = tvaValue;
                }
              } else {
                element.value = element.value
              }

            }
          }
        }
      }
    });
  }

  remove(e) {
    e.preventDefault();
    const regex = /[^0-9]/g
    var rowNb = event.currentTarget.parentNode.dataset.row.replace(regex, "");
    const saleEdit = document.querySelector('.edit_sale');
    const saleNew = document.querySelector('.new_sale');
    var saleItemRows = document.querySelectorAll('.productrows')
    var totalRow = 1
    
    saleItemRows.forEach((row)=>{
        if(row.style.display == 'none') {
          totalRow += 1
        }
    });
    //console.log(saleItemRows.length)
    //console.log(totalRow)
    var remain = (saleItemRows.length) - totalRow
    //console.log(remain)

    if (saleEdit && remain > 0) {

      if (event.currentTarget.parentNode.getElementsByClassName('sale_sale_item_id').length != 0) {
        var newSaleId = event.currentTarget.parentNode.getElementsByClassName('sale_sale_item_id')[0].lastChild.value
      } else {
        var newSaleId = ""
      }

      if (event.currentTarget.children[1] !== undefined && newSaleId != "") {
        //console.log(event.currentTarget.parentNode)
        event.currentTarget.children[1].childNodes[2].checked = true
        event.currentTarget.parentNode.style.display = "none";
      } else {
         event.currentTarget.parentNode.remove();
      }
      this.total_qty_soldTarget.innerHTML = compute(this.qty_soldTargets).toFixed(2) // Total des quantités vendues
      this.total_qty_freeTarget.innerHTML = compute(this.qty_freeTargets).toFixed(2) // Total des quantités gratuites
      this.total_discount_ttcTarget.innerHTML = compute(this.discount_ttcTargets).toFixed(2) // Total des réductions ttc
      this.total_discount_ttc_inputTarget.value = compute(this.discount_ttcTargets).toFixed(2) // pour l'input
      this.total_amount_ttcTarget.innerHTML = compute(this.ttc_amountTargets).toFixed(2)  // Total des montants ttc
      this.total_amount_ttc_inputTarget.value = compute(this.ttc_amountTargets).toFixed(2) // pour l'input
      this.total_vatTarget.innerHTML = compute(this.vat_amountTargets).toFixed(2) // Total de la tva
      this.total_vat_inputTarget.value = compute(this.vat_amountTargets).toFixed(2) // pour l'input
    }

    if (saleNew && rowNb != 0) {

      if (event.currentTarget.parentNode.getElementsByClassName('sale_sale_item_id').length != 0) {
        var newSaleId = event.currentTarget.parentNode.getElementsByClassName('sale_sale_item_id')[0].lastChild.value
      } else {
        var newSaleId = ""
      }

      if (event.currentTarget.children[1] !== undefined && newSaleId != "") {
        //console.log(event.currentTarget.parentNode)
        event.currentTarget.children[1].childNodes[2].checked = true
        event.currentTarget.parentNode.style.display = "none";
      } else {
         event.currentTarget.parentNode.remove();
      }
      this.total_qty_soldTarget.innerHTML = compute(this.qty_soldTargets).toFixed(2) // Total des quantités vendues
      this.total_qty_freeTarget.innerHTML = compute(this.qty_freeTargets).toFixed(2) // Total des quantités gratuites
      this.total_discount_ttcTarget.innerHTML = compute(this.discount_ttcTargets).toFixed(2) // Total des réductions ttc
      this.total_discount_ttc_inputTarget.value = compute(this.discount_ttcTargets).toFixed(2) // pour l'input
      this.total_amount_ttcTarget.innerHTML = compute(this.ttc_amountTargets).toFixed(2)  // Total des montants ttc
      this.total_amount_ttc_inputTarget.value = compute(this.ttc_amountTargets).toFixed(2) // pour l'input
      this.total_vatTarget.innerHTML = compute(this.vat_amountTargets).toFixed(2) // Total de la tva
      this.total_vat_inputTarget.value = compute(this.vat_amountTargets).toFixed(2) // pour l'input
    }
  }

  date(e) {
    var start_date = this.start_dateTarget
    var end_date = this.end_dateTarget
    var start_date_inputs = document.querySelectorAll('.sale_start_date input')
    var end_date_inputs = document.querySelectorAll('.sale_end_date input')

    if (start_date.value == '' || end_date.value == ''){
      start_date_inputs.forEach(date => toggleValidation(date, true))
      end_date_inputs.forEach(date => toggleValidation(date, true))
      e.preventDefault();
      e.stopPropagation();
    } else {
      start_date_inputs.forEach(date => toggleValidation(date, false))
      end_date_inputs.forEach(date => toggleValidation(date, false))
    }
  }

  empty(e) {

    // validate si code est vide et que qty est renseigné
    var start_date = this.start_dateTarget
    var end_date = this.end_dateTarget
    var start_date_inputs = document.querySelectorAll('.sale_start_date input')
    var end_date_inputs = document.querySelectorAll('.sale_end_date input')

    if (start_date.value == '' || end_date.value == ''){
      start_date_inputs.forEach(date => toggleValidation(date, true))
      end_date_inputs.forEach(date => toggleValidation(date, true))
      e.preventDefault();
      e.stopPropagation();
    } else {
      start_date_inputs.forEach(date => toggleValidation(date, false))
      end_date_inputs.forEach(date => toggleValidation(date, false))

      this.rowTargets.forEach( (element) => {
        var sum = 0;
        var qtySold = element.getElementsByClassName("sale-item-qty-sold")[0]
        var qtyFree = element.getElementsByClassName("sale-item-qty-free")[0]
        var qty_value = qtySold.value + qtyFree.value
        var ttc = element.getElementsByClassName("sale-item-ttc-amount")[0].value
        var edit_sale = document.querySelector('.edit_sale')
        if (edit_sale) {
          var description = element.getElementsByClassName("sale_sale_item_description_item select")[0].children[0]
          var code = element.getElementsByClassName("sale_sale_item_ref_item select")[0].children[0]
        }
        else {
          var description = element.getElementsByClassName("sale_sale_items_description_item select")[0].children[0]
          var code = element.getElementsByClassName("sale_sale_items_ref_item select")[0].children[0]
        }

        var newRef = element.getElementsByClassName("text-center-new-ref")[0]

        if (newRef === undefined) {
          var newRef = ""
        } else {
          var newRef = newRef.innerText
        }

        sum += parseFloat(qty_value)
        sum += parseFloat(ttc)

        const rows = document.querySelectorAll('.productrows');

        if (rows.length > 1) {
          if ((qty_value == "" || qty_value == '0' || qty_value == '0.0') && code.value == '') {
            element.remove();
          }

          if (sum == 0 & newRef == "" & code.value == ''){
            element.remove();
          }
        }

        if (code.value == '' && (qty_value != "" || qty_value != '0' || qty_value != '0.0')) {
          if (edit_sale) {}
          else {
            toggleValidation(code, true)
            toggleValidation(description, true)
            e.preventDefault();
            e.stopPropagation();
          }
        }/*  else {
          toggleValidation(code, false)
          toggleValidation(description, false)
        } */

        if (code.value != '' && (qty_value == "" || qty_value == '0' || qty_value == '0.0')) {
          toggleValidation(qty, true)
          e.preventDefault();
          e.stopPropagation();
        } else {
          toggleValidation(qty, false)
        }

      });
    }
  }

  send(e) {
    e.preventDefault();
    let url = ""
    if (this.checkbox_with_errorsTarget.checked) {
      window.location.href = '/sales?sales_errors=true'
      var checkboxResult = true
    } else {
      window.location.href = '/sales?sales_errors=false'
      checkboxResult = false;
    }
    
    fetch(`/sales?sales_errors=${checkboxResult}`, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
      //body: JSON.stringify({sales_errors: checkboxResult})
    })
    .then(response => response.json())
    .then((data) => {
      this.listTarget.innerHTML = data.inserted_item;
      this.paginationTarget.innerHTML = data.pagination;
    });
  }

  sort(e) {
    e.preventDefault();
    const element = e.currentTarget.lastElementChild
    var sort_type = e.currentTarget.dataset.type;
    var direction = document.querySelector('.sales-index-table').dataset.direction;

    if (direction == 'asc'|| direction == '') {
      direction = 'desc'
    } else {
      direction = 'asc'
    }

    let url = ""
    if (window.location.href.includes('sales_errors=true')){
      url = `/sales?sales_errors=true&sales_sort=${sort_type}&direction=${direction}`
    } else {
      url = `/sales?sales_sort=${sort_type}&direction=${direction}`
    }

    fetch(url, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
    })
    .then(response => response.json())
    .then((data) => {
      this.listTarget.innerHTML = data.inserted_item;
      this.paginationTarget.innerHTML = data.pagination;
      document.querySelector('.sales-index-table').dataset.direction = direction;
    });

    this.sortableTargets.forEach((sortable)=>{
      sortable.firstElementChild.remove()
      sortable.insertAdjacentHTML('beforeend', '<i class="fas fa-sort"></i>')
    })

    //element.classList.add('sortable')

    if (direction == 'asc'|| direction == '') {
      element.firstElementChild.remove()
      element.insertAdjacentHTML('beforeend', '<i class="fas fa-sort-amount-up-alt"></i>')
    } else {
      element.firstElementChild.remove()
      element.insertAdjacentHTML('beforeend', '<i class="fas fa-sort-amount-down"></i>')
    }

  }

  reinitdate(e) {
    var start_date = this.start_dateTarget
    var end_date = this.end_dateTarget
    var start_date_inputs = document.querySelectorAll('.sale_start_date input')
    var end_date_inputs = document.querySelectorAll('.sale_end_date input')
    var instance = document.getElementById('range_start');
    start_date_inputs.forEach(date => date.value = "")
    end_date_inputs.forEach(date => date.value = "")

    const saleForm = document.getElementById('sale-form-div');
    const sales = JSON.parse(saleForm.dataset.sales);
    var calendar = flatpickr(instance, {
       "locale": French,
      plugins: [new rangePlugin({ input: "#range_end"})],
      dateFormat: "Y-m-d",
      maxDate: new Date(),
      altInput: true,
      altFormat: "d/m/Y",
      disable: sales,
      onChange: function (selectedDates, dateStr, instance) {
        var current = selectedDates[0];
        var lastDayOfMonth = new Date(current.getFullYear(), current.getMonth()+1, 0);
        instance.set("maxDate", lastDayOfMonth);
      },
    });


  }

}
