import { Controller } from "stimulus";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"


// Connects to data-controller="address-autocomplete"
export default class extends Controller {
  static values = { apiKey: String }

  static targets = ["address"]

  connect() {

    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      types: "place,postcode,locality,neighborhood,address",
      placeholder: "Adresse",
      //countries: 'fr'
    })

    this.geocoder.addTo(this.element)
    this.geocoder.on("result", event => this.#setInputValue(event))
    this.geocoder.on("clear", event => this.#clearInputValue(event))

    // si edit afficher la valeur
    if (window.location.href.split("/").includes("edit")){
      var inputs = document.querySelectorAll(".mapboxgl-ctrl-geocoder--input")
      var outputs = document.querySelectorAll('.autocomplete')
      var clearButtons = document.querySelectorAll(".mapboxgl-ctrl-geocoder--button")
      for(var i=0; i < inputs.length; i++){
        inputs[i].value = outputs[i].value
        if (outputs[i].value != "") {
          clearButtons[i].style.display = "block"
        }
      }
      $('form').on('keypress', e => {
        if (e.keyCode == 13) {
            return false;
        }
      });
    }

  }

  disconnect() {
    this.geocoder.onRemove()
  }

  #setInputValue(event) {
    this.addressTarget.value = event.result["place_name"]
  }

  #clearInputValue(event) {
    this.addressTarget.value = ""
  }
}
