import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import $ from 'jquery';
import 'select2';
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { colorMargin } from "../components/colorMargin.js";
import { French } from "flatpickr/dist/l10n/fr.js"
import { sortTable } from "../functions/sort.js"
require('flatpickr/dist/plugins/monthSelect/style.css')
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau.js';

Chart.defaults.global.plugins.colorschemes = {
  scheme: Tableau20,
  fillAlpha: 1,
  reverse: false,
  override: false
};

var barChart, pieChart, bubbleChart, dataPie, start_date, end_date
const monthNames = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];
let typology = "CATEGORIE"
const colors = new Array(12).fill('rgba(184, 237, 190, 0.2)')
const borders = new Array(12).fill('rgba(184, 237, 190)')

function dataBarTransform(salesData) {

  let ht_amount_by_month = new Array(12)
  let margin_by_month = new Array(12)
  salesData.forEach((data) => {
    ht_amount_by_month[data.month - 1] = Math.round(data.ht_amount)
    margin_by_month[data.month - 1] = Math.round(data.margin_amount / data.ht_amount * 100)
  })

  const dataset1 = { type: 'bar', label: 'CA', yAxisID: "y-axis-1", data: ht_amount_by_month, backgroundColor: colors, borderColor: borders, borderWidth: 1 }
  const dataset2 = { type: 'line', label: 'Marge', yAxisID: "y-axis-2", data: margin_by_month, fill: false, pointBackgroundColor: '#ffff', pointBorderColor: 'rgb(255, 166, 0)', borderColor: 'rgb(255, 166, 0)' }

  const data = {
    labels: monthNames,
    datasets: [dataset1, dataset2]
  };

  return data
}

function dataPieTransform(salesData) {

  var ht_amount_by_cat = [];
  var categoryNames = [];
  var margin = [];
  var result = [];
  salesData.reduce(function (res, value) {
    if (!res[value.category]) {
      res[value.category] = { category: value.category, ht: 0, margin: 0 };
      result.push(res[value.category])
    }
    res[value.category].ht += value.ht;
    res[value.category].margin += value.margin;
    return res;
  }, {});

  result.forEach((data) => {
    ht_amount_by_cat.push(Math.round(data.ht));
    margin.push((((data.margin / data.ht) * 100).toFixed(1)) / 100);
    categoryNames.push(data.category);
  })

  if (ht_amount_by_cat.length == 0) {
    ht_amount_by_cat = [1]
  }

  dataPie = {
    labels: categoryNames,
    datasets: [{
      data: ht_amount_by_cat,
    }]
  };

  return { margin: margin, dataPie: dataPie }
}

function pieChartDisplayNoneWithoutData(salesData) {
  const salesChartPie = document.getElementById("sales-pie")
  const noChart = document.querySelector(".chart-none");

  if (salesData.length == 0) {
    salesChartPie.style.display = "none";
    $('#chart-sales-pie').css("display", "none")
    noChart.style = "display: flex; align-items: center; justify-content: center;";
    return true
  } else {
    salesChartPie.style.display = "block";
    $('#chart-sales-pie').css("display", "block")
    noChart.style.display = "none";
    return false
  }
}

function dataBubbleTransform(salesData) {
  var result = [];

  salesData.reduce(function (res, value) {
    if (!res[value.category]) {
      res[value.category] = { category: value.category, ht: 0, margin: 0 };
      result.push(res[value.category])
    }
    res[value.category].ht += value.ht;
    res[value.category].margin += value.margin;
    return res;
  }, {});

  var bubbleData = result.map(item => {
    const object = {}
    object.data = [{
      x: Math.round(item.ht),
      y: (((item.margin / item.ht) * 100).toFixed(1)) / 100,
      r: 10,
      name: item.category
    }]
    object.label = item.category
    return object
  })

  return bubbleData
}

function bubbleChartDisplayNoneWithoutData(salesData) {
  const salesChartBubble = document.getElementById("sales-bubble")
  const noChart = document.querySelector(".chart-none-bubble");

  if (salesData.length == 0) {
    salesChartBubble.style.display = "none";
    $('#chart-sales-bubble').css("display", "none")
    noChart.style = "display: flex; align-items: center; justify-content: center;";
    return true
  } else {
    salesChartBubble.style.display = "block";
    $('#chart-sales-bubble').css("display", "block")
    noChart.style.display = "none";
    return false
  }
}

const flatpickrMonth = () => {
  const saleAnalysisDiv = document.getElementById('sale-analysis-div');
  const month = document.querySelector('#datepicker')
  if (month) {
    var dateCurrent = new Date();
    var dateConverted = dateCurrent.toISOString().split('T')[0]
    const salesData = JSON.parse(saleAnalysisDiv.dataset.salescat)
    const salesDataForBarChart = JSON.parse(saleAnalysisDiv.dataset.salesbarmonths)
    initBarChart(salesDataForBarChart, dateCurrent);
    initPieChart(salesData, dateCurrent);
    initBubbleChart(salesData, dateCurrent);

    flatpickr("#datepicker", {
      altInput: true,
      defaultDate: dateConverted,
      "locale": French,
      plugins: [new monthSelectPlugin({
        shorthand: true,
        altFormat: "F Y",
        dateFormat: "Y-m-d"
      })],
      onChange: function () {
        // var monthSelected = month.value.split("-")
        // var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, monthSelected[2])
        // //chartBarSalesByMonths(dateSelected);
        // const categories = document.querySelector('.select-category')
        // var category = categories.options[categories.selectedIndex].value
        // var elementType = categories.options[categories.selectedIndex].parentNode.label
        // if (category == "Toutes Catégories") {
        //   category = ""
        //   elementType = ""
        // }
        // fetchDataForGraphs("month", dateSelected, category, elementType)
        // fetchDataForListItems("month", dateSelected, category, elementType)
      }
    });
  }
}

const flatpickrPeriod = () => {
  const period = document.querySelector('#datepicker2')
  if (period) {
    const saleAnalysisDiv = document.getElementById('sale-analysis-div');
    const salesData = JSON.parse(saleAnalysisDiv.dataset.salesbarmonths);
    const saleAnalysisflatpickrdiv = document.getElementById('sale-analysis-flatpickr-div');
    const reservedDates = JSON.parse(saleAnalysisflatpickrdiv.dataset.salesreserveddates);

    flatpickr("#datepicker2", {
      altInput: true,
      "locale": French,
      dateFormat: "Y-m-d",
      mode: "range",
      altFormat: "d/m/Y",
      onClose: function (selectedDates, dateStr, instance) {
        const categories = document.querySelector('.select-category')
        var category = categories.options[categories.selectedIndex].value
        var elementType = categories.options[categories.selectedIndex].parentNode.label
        if (category == "Toutes Catégories") {
          category = ""
          elementType = ""
        }
        const inputByImport = document.querySelector('.input2+input')
        fetchDataForListItems("period", inputByImport.value, category, elementType)
        fetchDataForGraphs("period", inputByImport.value, category, elementType)
      },
      onDayCreate: function (dObj, dStr, fp, dayElem) {
        var d = new Date(dayElem.dateObj);
        var ts = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
        var date = new Date(ts).toISOString().substring(0, 10);

        // Mise en forme des dates d'import dans le calendrier.
        for (var i = 0; i < reservedDates.length; i++) {
          if (date === reservedDates[i].from && date === reservedDates[i].to) {
            dayElem.classList.add("check-day-only"); // jours unique
          }
          if (date === reservedDates[i].from) {
            dayElem.classList.add("check-in"); // début d'intervalle
          }
          if (date === reservedDates[i].to) {
            dayElem.classList.add("check-out"); // fin  d'intervalle
          }
          if (new Date(date) < new Date(reservedDates[i].to) && new Date(date) > new Date(reservedDates[i].from)) {
            dayElem.classList.add("check"); // jours dans l'intervalle
            dayElem.classList.add("flatpickr-disabled");
          }
        }
      },
    });

  }
}

const initBarChart = (salesData, dateSelected) => {
  const salesChart = document.getElementById("sales-bar")
  const year = dateSelected.getFullYear();

  barChart = new Chart(salesChart, {
    type: 'bar',
    data: dataBarTransform(salesData),
    options: {
      onResize: function (chart, size) {
        if (size.height == 400) {
          chart.scales['y-axis-1'].options.scaleLabel.display = false
          chart.scales['y-axis-2'].options.scaleLabel.display = false
          chart.options.legend.display = true;
          chart.options.legend.position = 'bottom'
        }
        chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          align: 'top',
          formatter: (value, context) => {
            if (context.datasetIndex == 1) {
              return `${value}%`
            }
          },
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            lineWidth: 0,
            drawBorder: true,
          },
        }],
        yAxes: [{
          ticks: { beginAtZero: true },
          id: "y-axis-1",
          position: 'left',
          type: 'linear',
          ticks: { beginAtZero: true },
          gridLines: { lineWidth: 0, drawBorder: true },
          scaleLabel: { display: true, labelString: "Chiffre d'affaires en €" }
        },
        {
          id: "y-axis-2",
          position: 'right',
          type: 'linear',
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return `${value} %`
            }
          },
          gridLines: { lineWidth: 0, drawBorder: true },
          scaleLabel: { display: true, labelString: 'Marge en %' }
        }]
      },
      title: {
        display: true,
        text: `CA NET (€HT) & MARGE (%) - ${year}`,
        padding: 30
      },
      layout: { padding: 5 },
      legend: { display: false },
    }
  });
}

const initPieChart = (salesData, dateSelected) => {
    // Affiche/Masque le graph avec message si aucune donnée
    pieChartDisplayNoneWithoutData(salesData)

    const salesChartPie = document.getElementById("sales-pie")
    const indexMonth = dateSelected.getMonth();
    const year = dateSelected.getUTCFullYear();
    const margin = dataPieTransform(salesData).margin
    const dataPie = dataPieTransform(salesData).dataPie

    pieChart = new Chart(salesChartPie, {
      type: 'pie',
      data: dataPie,
      options: {
        onResize: function (chart, size) {
          if (size.height == 400) {
            chart.options.legend.display = true;
            chart.options.legend.position = 'bottom'
          }
          chart.update();
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 10,
            formatter: (value, ctx) => {
              let sum = ctx.dataset.data.reduce((partialSum, a) => partialSum + a, 0);
              let percentage = (value * 100 / sum).toFixed(0) + "%";
              if ((value * 100 / sum).toFixed(0) > 5) {
                return [`${value} (${percentage})`]
              }
              return ""
            }
          },
        },
        title: {
          display: true,
          text: `CA PAR CATEGORIE (€HT) : ${monthNames[indexMonth] + ' ' + year}`,
          padding: 30
        },
        layout: { padding: { bottom: 30 } },
        legend: { display: true, position: 'right', padding: 20 },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function (tooltipItem, all) {
              return [
                all.labels[tooltipItem[0].index],
              ]
            },
            label: function (tooltipItem, all) {
              return [
                "Chiffre d'affaires" + ': ' + all.datasets[0].data[tooltipItem.index].toLocaleString() + "€",
                "Taux de marge" + ': ' + (margin[tooltipItem.index] * 100).toLocaleString() + "%",
              ]
            }
          }
        },
      }
    });
    return dataPie
}

const initBubbleChart = (salesData, dateSelected) => {
    // Affiche/Masque le graph avec message si aucune donnée
    bubbleChartDisplayNoneWithoutData(salesData)

    const salesChart = document.getElementById("sales-bubble")
    const chartsale = salesChart.getContext('2d')
    const POINT_X_PERCENT = '%'; // prefix for x values, eg '$'
    const POINT_X_EURO = '€ '; // postfix for x values, eg '%'
    const X_AXIS = "Chiffre d'affaires";
    const Y_AXIS = 'Taux de marge';
    const categories = document.querySelector('.select-category')
    var category = categories.options[categories.selectedIndex].value
    var elementType = categories.options[categories.selectedIndex].parentNode.label
    const indexMonth = dateSelected.getMonth();
    const year = dateSelected.getUTCFullYear();

    if (category != "Toutes Catégories") {
      typology = elementType == "Produits" ? "PRODUIT" : "RECETTE"
    }

    bubbleChart = new Chart(chartsale, {
      type: 'bubble',
      data: { datasets: dataBubbleTransform(salesData) },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        onResize: function (chart, size) {
          if (size.height < 400) {
            chart.options.legend.display = true;
            chart.options.legend.position = 'bottom'
          }
          chart.update();
        },
        title: {
          display: true,
          padding: 20,
          text: `CA (€HT) vs MARGE (%) PAR ${typology} : Top/Flop ${monthNames[indexMonth] + ' ' + year}`
        },
        layout: { padding: 20 },
        legend: { display: true, position: 'right' },
        scales: {
          xAxes: [{
            scaleLabel: { display: true, labelString: X_AXIS },
            ticks: {
              callback: function (value, index, values) {
                return value.toLocaleString() + POINT_X_EURO;
              }
            }
          }],
          yAxes: [{
            scaleLabel: { display: true, labelString: Y_AXIS },
            ticks: {
              callback: function (value, index, values) {
                return (value * 100).toLocaleString() + POINT_X_PERCENT;
              }
            }
          }]
        },
        plugins: { datalabels: { display: false } },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: function (tooltipItem, all) {
              return [
                all.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].name,
              ]
            },
            label: function (tooltipItem) {
              return [
                X_AXIS + ': ' + tooltipItem.xLabel.toLocaleString() + POINT_X_EURO,
                Y_AXIS + ': ' + (tooltipItem.yLabel * 100).toLocaleString() + POINT_X_PERCENT,
              ]
            }
          }
        },
      }
    });

}

function fetchDataForListItems(choice, date, category = "", elementType = "") {
  // fetch pour réinitialiser la liste des produits/recettes
  var url = ""

  if (choice == "period") {

    if (date.includes('au')) {
      var start = date.toString().split(' au ')[0];
      var end = date.toString().split(' au ')[1];
    } else {
      var start = date
      var end = date
    }

    if (date.length != 0) {
      if (date.includes('/')) {
        start = `${start.split('/')[2]}-${start.split('/')[1]}-${start.split('/')[0]}`
        end = `${end.split('/')[2]}-${end.split('/')[1]}-${end.split('/')[0]}`
      }
      var firstDayMonth = new Date(start);
      var lastDayMonth = new Date(end);
      var firstDayMonthConverted = firstDayMonth.toISOString().split('T')[0]
      var lastDayMonthConverted = lastDayMonth.toISOString().split('T')[0]
      url = `/sales_analysis?sales[choice]=${choice}&sales[start]=${firstDayMonthConverted}&sales[end]=${lastDayMonthConverted}&sales[date]=${firstDayMonthConverted}&sales[category]=${encodeURIComponent(category)}&sales[elementType]=${elementType}`
    } else {
      url = `/sales_analysis`
    }

  } else {
    var firstDayMonth = new Date(date.getFullYear(), date.getMonth(), "1");
    firstDayMonth.setHours(0, -firstDayMonth.getTimezoneOffset(), 0, 0);
    var lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDayMonth.setHours(0, -lastDayMonth.getTimezoneOffset(), 0, 0);
    var firstDayMonthConverted = firstDayMonth.toISOString().split('T')[0]
    var lastDayMonthConverted = lastDayMonth.toISOString().split('T')[0]
    url = `/sales_analysis?sales[choice]=${choice}&sales[start]=${firstDayMonthConverted}&sales[end]=${lastDayMonthConverted}&sales[category]=${encodeURIComponent(category)}&sales[elementType]=${elementType}`
  }

  const div = document.querySelector('.sale-item-analysis');
  const cards = document.getElementById('sales-card-infos');
  const token = document.querySelector('meta[name="csrf-token"]').content;

  if (url != "/sales_analysis") {
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Accept: "*/*;q=0.5, application/javascript", "X-CSRF-Token": token,
      },
    })
      .then(response => response.text())
      .then((html) => {
        cards.innerHTML = "";
        cards.innerHTML = html.split("html")[1];
        div.innerHTML = "";
        div.innerHTML = html.split("html")[2];
        colorMargin();
        sortTable();
      });
  }
}

function fetchDataForGraphs(choice, dateSelected, category = "", elementType = "") {

  let url = ""
  const token = document.querySelector('meta[name="csrf-token"]').content;
  const categories = document.querySelector('.select-category')
  category = categories.options[categories.selectedIndex].value

  if (category == "Toutes Catégories") {
    category = ""
  }

  /// Personalisation de l'URL en fonction de la sélection d'une période ou d'un mois
  if (choice == "month") {
    url = `/montforcharts?sales_cat_chart[choice]=${choice}&sales_cat_chart[dateSelected]=${dateSelected}&sales_cat_chart[category]=${encodeURIComponent(category)}&ales_cat_chart[elementType]=${elementType}`
  } else if (choice == "period") {
    var start = dateSelected.includes('au') ? dateSelected.toString().split(' au ')[0] : dateSelected;
    var end = dateSelected.includes('au') ? dateSelected.toString().split(' au ')[1] : dateSelected;
    if (start.includes('/')) {
      start = `${start.split('/')[2]}-${start.split('/')[1]}-${start.split('/')[0]}`
      end = `${end.split('/')[2]}-${end.split('/')[1]}-${end.split('/')[0]}`
    }
    url = `/selecteddates?sales[start]=${start}&sales[end]=${end}&sales[category]=${encodeURIComponent(category)}&sales[elementType]=${elementType}`
  }

  // Fetch pour mettre à jour les graphiques
  if (url != "/sales_analysis" && start != "") {
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Accept: "*/*;q=0.5, application/javascript", "X-CSRF-Token": token,
      },
    })
      .then(response => response.json())
      .then((data) => {

        /// Mise à jour du PieChart avec le mois/période sélectionné(e)
        if (!pieChartDisplayNoneWithoutData(data[1])) {
          const margin = dataPieTransform(data[1]).margin
          const dataPie = dataPieTransform(data[1]).dataPie

          if (choice == "month") {
            const indexMonth = dateSelected.getMonth();
            const year = dateSelected.getFullYear();
            pieChart.options.title.text = `CA PAR CATEGORIE (€HT) : ${monthNames[indexMonth] + ' ' + year}`
          } else {
            pieChart.options.title.text = `CA PAR CATEGORIE (€HT) : ${dateSelected}`
          }

          pieChart.options.tooltips.callbacks.label = function (tooltipItem, all) {
            return [
              "Chiffre d'affaires" + ': ' + all.datasets[0].data[tooltipItem.index].toLocaleString() + "€",
              "Taux de marge" + ': ' + (margin[tooltipItem.index] * 100).toLocaleString() + "%",
            ]
          }
          pieChart.data = dataPie
          pieChart.update()
        }

        /// Mise à jour du bubbleChart avec le mois/période sélectionné(e)
        if (!bubbleChartDisplayNoneWithoutData(data[1])) {

          if (category != "Toutes Catégories" && category != "") {
            typology = elementType == "Produits" ? "PRODUIT" : "RECETTE"
          }

          if (choice == "month") {
            const indexMonth = dateSelected.getMonth();
            const year = dateSelected.getFullYear();
            bubbleChart.options.title.text = `CA (€HT) vs MARGE (%) PAR ${typology} : Top/Flop ${monthNames[indexMonth] + " " + year}`
          } else {
            bubbleChart.options.title.text = `CA (€HT) vs MARGE (%) PAR ${typology} : Top/Flop ${dateSelected}`
          }

          bubbleChart.data.datasets = category == "" ? dataBubbleTransform(data[1]) : dataBubbleTransform(data[2])
          bubbleChart.update()
        }

        /// Mise à jour du barChart avec le mois/période sélectionné(e)
        const salesData = data[0]
        if (choice == "period") {
          var start_date = new Date(start.split('-')[0], start.split('-')[1] - 1, start.split('-')[2])
          barChart.options.title.text = `CA NET (€HT) & MARGE (%) - ${start_date.getFullYear()}`
          barChart.data = dataBarTransform(salesData)
          barChart.update()

        } else {
          const year = dateSelected.getFullYear();

          barChart.options.title.text = `CA NET (€HT) & MARGE (%) - ${year}`
          barChart.data = dataBarTransform(salesData)
          barChart.update()
        }
      });
  }
}

export default class extends Controller {
  static targets = ['category', 'select', 'month', 'period', 'cards'];

  connect() {

    $(this.categoryTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });

    $(this.categoryTarget).on('select2:unselecting', function (e) {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });

    flatpickrMonth();
    flatpickrPeriod();
  }

  choice(e) {
    const linkActiveText = e.target.dataset.choice;
    const inputByMonth = document.querySelector('.input+.input')
    const inputByImport = document.querySelector('.input2+input')
    inputByMonth.classList.toggle("date-input-none");
    this.monthTarget.classList.toggle("active");
    inputByImport.classList.toggle("date-input-none");
    this.periodTarget.classList.toggle("active");
    this.get_data(e);
  }

  get_data() {

    const saleAnalysisDiv = document.getElementById('sale-analysis-div');
    if (saleAnalysisDiv) {
      const inputByMonth = document.querySelector('.input+.input')
      const inputByImport = document.querySelector('.input2+input')
      const periodMonth = document.querySelector('#datepicker')
      const categories = document.querySelector('.select-category')
      var category = categories.options[categories.selectedIndex].value
      var elementType = categories.options[categories.selectedIndex].parentNode.label
      if (category == "Toutes Catégories") {
        category = ""
        elementType = ""
      }

      if (this.monthTarget.classList.contains('active')) {
        var monthSelected = periodMonth.value.split("-")
        var dateSelected = new Date(monthSelected[0], monthSelected[1] - 1, monthSelected[2])
        inputByMonth.classList.remove("date-input-none");
        inputByImport.classList.add("date-input-none");

        fetchDataForListItems("month", dateSelected, category, elementType)
        fetchDataForGraphs("month", dateSelected, category, elementType)
      }
    }
  }
}
