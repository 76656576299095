import $ from 'jquery';

const convertRestRecipesInCartItems = () => {
  const restaurantRecipesContainer = document.getElementById('restaurant_recipes_content');
  const productionsContainer = document.querySelector('.productions-container');

  if (restaurantRecipesContainer || productionsContainer) {
    const convertRoundedUnities = document.getElementById('rounded_converted_conditioning_units');
    const restaurantRecipesConvertConfirmModal = document.getElementById('convert_restaurant_recipes');
    const consumptionStock = document.getElementById('stock_used');


    restaurantRecipesConvertConfirmModal.addEventListener('click', (event) => {
      if (window.location.href.includes("?")) {
        window.location = window.location.href.split("?")[0] + '/convert_in_cart_items?' + window.location.href.split("?")[1] + `&round_units=${convertRoundedUnities.checked}&stock_used=${consumptionStock === null ? false : consumptionStock.checkedd}`
      } else {
        window.location = window.location.href + `/convert_in_cart_items?round_units=${convertRoundedUnities.checked}&stock_used=${consumptionStock === null ? false : consumptionStock.checked}`
      }
    });
  }

  if (productionsContainer) {
    const convertRoundedUnities = document.getElementById('rounded_converted_conditioning_units');
    const restaurantRecipesConvertConfirmModal = document.getElementById('convert_restaurant_recipes');
    const productionId = parseFloat(window.location.href.split('/')[window.location.href.split('/').length - 1])

    restaurantRecipesConvertConfirmModal.addEventListener('click', (event) => {
      if (convertRoundedUnities.checked == true) {
        window.location = `/restaurant_recipes/convert_in_cart_items?round_units=true&production_id=${productionId}`
      } else {
        window.location = `/restaurant_recipes/convert_in_cart_items?min_qty_to_used=true`
      }
    });
  }

}
export { convertRestRecipesInCartItems };
