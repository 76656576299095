const computeTotalRecipesCost = () => {
  const restaurantRecipesContent = document.getElementById('restaurant_recipes_content');
  if (restaurantRecipesContent) {

    // #1 total des coûts
    const totalrecipecart = document.getElementById(`total-recipe-cart`);
    const recapTotalrecipecart = document.getElementById(`recap-costs-for-cart_items`);
    const recipesubtotals = document.querySelectorAll(".recipe-order-cost");

    // Distinction sales vs. others
    const totalrecipecartsale = document.getElementById(`total-recipe-cart-sale`);
    const totalrecipecartother = document.getElementById(`total-recipe-cart-other`);

    let totalCost = 0;
    let directSaleRecipeTotalCost = 0;
    let otherRecipeTotalCost = 0;

    recipesubtotals.forEach((subtotal) => {
      if (subtotal.innerHTML != "-"){
        totalCost += parseFloat(subtotal.innerHTML);
        // console.log(parseFloat(subtotal.innerHTML.replace("total :")));
        if (subtotal.dataset.refrecipetype == "sale"){
          directSaleRecipeTotalCost += parseFloat(subtotal.innerHTML);
        } else {
          if(subtotal.dataset.refrecipetype == "other"){
            otherRecipeTotalCost += parseFloat(subtotal.innerHTML);
          }
        }
      }
    });
    totalrecipecart.innerHTML = totalCost.toFixed(2);
    recapTotalrecipecart.innerHTML = totalCost.toFixed(2);
    totalrecipecartsale.innerHTML = directSaleRecipeTotalCost.toFixed(2);
    totalrecipecartother.innerHTML = otherRecipeTotalCost.toFixed(2);

    // #2 MArge moyenne (retraitée des recettes sans marge)
    //#3 Total recettes
    const avgMarginRecipes = document.getElementById(`total-recipe-margin-cart`);
    const avgMarginRecipesWithOther = document.getElementById(`total-recipe-margin-cart-with-others`);
    const restRecipeQuantities = document.querySelectorAll(".restaurantrecipequantityinput");

    const recipesCount = document.getElementById(`recipes-count`);
    const recipesCountSale = document.getElementById(`recipes-count-sale`);
    const recipesCountOther = document.getElementById(`recipes-count-other`);
    const recipesCountForCartItems = document.getElementById(`recap-recipes-for-cart_items`);

    let total_sales = 0;
    let total_margin = 0;
    let avg_margin_value = 0;
    let avg_margin_value_with_other = 0;

    let recipe_count = 0;
    let recipe_count_sale = 0;
    let recipe_count_other = 0;

    restRecipeQuantities.forEach((input) => {

      if (input.value > 0) {
        const quantityValue = parseFloat(input.value)
        // Partie marge
        if (input.dataset.recipetotalsaleht > 0 && input.dataset.marginrate != 0) {;
          const totalSalesValue = parseFloat(input.dataset.recipetotalsaleht) * quantityValue;
          const totalMarginValue = parseFloat(input.dataset.marginrate) * totalSalesValue;

          total_sales += totalSalesValue;
          total_margin += totalMarginValue;
        }
        // Partie nombre de recettes
        recipe_count += quantityValue;
        if (input.dataset.refrecipetype == "sale"){
          recipe_count_sale += quantityValue;
        } else {
          if(input.dataset.refrecipetype == "other"){
            recipe_count_other += quantityValue;
          }
        }
      }
    });

    recipesCount.innerHTML = recipe_count.toFixed(0);
    recipesCountForCartItems.innerHTML = recipe_count.toFixed(0);
    recipesCountSale.innerHTML = recipe_count_sale.toFixed(0);
    recipesCountOther.innerHTML = recipe_count_other.toFixed(0);


    if(total_sales > 0) {
      avg_margin_value = total_margin / total_sales;
      avg_margin_value_with_other = ( 1 - (totalCost / total_sales) ) * 100
    }

    avgMarginRecipes.innerHTML = avg_margin_value.toFixed(1);
    avgMarginRecipesWithOther.innerHTML = avg_margin_value_with_other.toFixed(1);
  }
}


export {computeTotalRecipesCost};
