import $ from 'jquery';

const toggleNavbarButton = () => {

  $('.custom-toggler').click(function(){
      $('.navbar-collapse').collapse('hide');
  });

}

export {toggleNavbarButton};
