import {chartOrderStatus} from "../components/charts.js";
import $ from 'jquery';

const dashboard = () => {

  const links = document.querySelectorAll(".nav-link");

  if(links){
    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        const linkActiveText = link.dataset.date;
        const counters = document.querySelectorAll(".order-nb")
        counters.forEach((counter) => {
          if(counter.dataset.date == linkActiveText){
            counter.hidden = false
            counter.classList.add("selected")
          } else {
            counter.hidden = true
            counter.classList.remove("selected")
          }
        });
          $('#doughnut-chart-order').remove(); // this is my <canvas> element
          $('#chart').append('<canvas id="doughnut-chart-order"><canvas>');
          chartOrderStatus();
      });
    });
  }

}

export { dashboard };
