import $ from 'jquery';

const pillsNavUsers = () => {

  if (sessionStorage.tabUserOuvert) {
    $("#"+sessionStorage.tabUserOuvert).find("a").trigger("click");
  }
  $(".userstab").on("click", function() {
    sessionStorage.setItem("tabUserOuvert",$(this).attr("id"));
    // console.log(sessionStorage.getItem("tabOuvert"));
    // console.log("change");
  });

  const userParameters = document.getElementById('userparameters');
  if(userParameters){
    userParameters.addEventListener('click', (event) => {
      sessionStorage.removeItem('');

    });
  }


}


export {pillsNavUsers};
