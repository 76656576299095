import $ from 'jquery';

const productSellingUnitElement = () => {
  const elementSelectors = document.querySelectorAll(".psu-select");
  elementSelectors.forEach((elementSelector) => {
  // const elementSelector = document.getElementById('product_selling_unit_element_id');
  // console.log(elementSelector);
    if (elementSelector){
      $(elementSelector).on('change', function (e) {
        const psuId = elementSelector.dataset.psuId;
        const element = elementSelector.options[elementSelector.selectedIndex]
        // console.log(element);
        if (psuId == null){
          if (element.dataset.type) {
            // console.log(element.dataset.type)
            const element_type = element.dataset.type;
            const hiddenElementType = document.getElementById('hidden-element_type_new');
            // console.log(hiddenElementType);
            hiddenElementType.innerText = element_type;
            hiddenElementType.value = element_type;
          }
        } else {
          if (element.dataset.type) {
            // console.log(element.dataset.type)
            const element_type = element.dataset.type;
            const hiddenElementType = document.getElementById(`hidden-element_type-${psuId}`);
            hiddenElementType.innerText = element_type;
            hiddenElementType.value = element_type;
          }
        }
      });
    }
  });
}

export {productSellingUnitElement};
