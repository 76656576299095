


const addRecipeCaliberProtect = () => {

  const withPortionSizeRadio = document.getElementById('with_portion_size');
  const withNetSizeRadio = document.getElementById('recipe_recipe_caliber_with_net_size');
  const withVarRateRadio = document.getElementById('with_var_rate_field');
  const withGrossSizeRadio = document.getElementById('recipe_recipe_caliber_with_gross_size');

  const portionUnitInput = document.getElementById('recipe_recipe_portions_attributes_0_portion_unit');
  const portionsSizeInput = document.getElementById('recipe_recipe_portions_attributes_0_size');
  const recipeNetSize = document.getElementById('recipe_size');
  const recipeVarRate = document.getElementById('recipe_var_rate');

  if (withPortionSizeRadio && portionsSizeInput && withNetSizeRadio && recipeNetSize && withVarRateRadio && recipeVarRate && withGrossSizeRadio && portionUnitInput){

  recipeDisabledInputs(withPortionSizeRadio, portionsSizeInput, withNetSizeRadio, recipeNetSize, withVarRateRadio, recipeVarRate, withGrossSizeRadio, portionUnitInput);
  }
}

const recipeDisabledInputs = (button_1, input_1, button_2, input_2, button_3, input_3, button_4, input_portion_unit ) => {
  // const portionsNumber = document.getElementById('recipe_recipe_portions_attributes_0_quantity');
  // console.log(withPortionSizeRadio.checked);
  input_2.disabled = true;
  input_1.disabled = true;
  input_portion_unit.disabled = true;
  input_3.disabled = true;


    if (button_1.checked == false ){
      input_1.disabled = true;
      input_portion_unit.disabled = true;
    } else {
      input_1.disabled = false;
      input_portion_unit.disabled = false;
    }
    button_1.addEventListener('change', (event) => {
      event.preventDefault();
      // console.log("change radio");
      // console.log(withPortionSizeRadio.checked);
      if (event.target.checked){
        input_1.disabled = false;
        input_portion_unit.disabled = false;
        input_2.disabled = true;
        input_3.disabled = true;
      }
    });

    if (button_2.checked == false ){
      input_2.disabled = true;
    } else {
      input_2.disabled = false;
    }
    button_2.addEventListener('change', (event) => {
      event.preventDefault();
      // console.log("change radio");
      // console.log(withPortionSizeRadio.checked);
      if (event.target.checked){
        input_2.disabled = false;
        input_1.disabled = true;
        input_portion_unit.disabled = true;
        input_3.disabled = true;
      }

    });
    if (button_3.checked == false ){
      input_3.disabled = true;
    } else {
      input_3.disabled = false;
    }
    button_3.addEventListener('change', (event) => {
      event.preventDefault();
      // console.log("change radio");
      // console.log(withPortionSizeRadio.checked);
      if (event.target.checked){
        input_2.disabled = true;
        input_1.disabled = true;
        input_portion_unit.disabled = true;
        input_3.disabled = false;
      }
    });
    button_4.addEventListener('change', (event) => {
      event.preventDefault();
      // console.log("change radio");
      // console.log(withPortionSizeRadio.checked);
      if (event.target.checked){
        input_2.disabled = true;
        input_1.disabled = true;
        input_portion_unit.disabled = true;
        input_3.disabled = true;
      }
    });
}



export {addRecipeCaliberProtect};
