import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";
import { filterTableStockInventory } from "../components/filterTableFunction.js"
import { formatState } from "../plugins/init_select2.js"
import { negativeInputs } from "../components/negativeInputs.js"

const toggleValidation = (element) => {
  if (element.value == "" || element.value == 0) {
    element.classList.add("is-invalid");
    return true;
  } else {
    element.classList.remove("is-invalid");
    return false
  }
}

function compute(object) {
  var sum = 0;
  object.forEach( (element) => {
    sum += element.value == "" ? 0 : parseFloat(element.value)
  });
  return sum
}

function counterZero(object) {
  var counter = 0;
  object.forEach( (element) => {
    counter += element.value == "0" ? 1 : 0
  });
  return counter
}

function sortOptionByText(select, option, positionOptionFixed) {
  var optionNotSorted = $(select + ` option:${option}`);
  var soptions = $(select + ` option:not(:${option})`).sort(function(a, b) {
     return a.text == b.text ? 0 : a.text < b.text ? -1 : 1
  });
  if (positionOptionFixed == "first") {
    $(select).html(soptions).prepend(optionNotSorted); 
  } else {
    $(select).html(soptions).append(optionNotSorted); 
  }     
};

export default class extends Controller {

  static targets = ['type', 'category', 'form', 'inventoryDate', 'qtyStock', 'showModal', 'container', 'cancelButton', 'submitButton', 'modalStockUnit','selectPlace', 'namePrincipal','nameSecondary','selectStockUnit', 'stockUnitOptions', 'name', 'quantity', 'placeSubmitButton', 'formNewPlace', 'formNewStockUnit', 'zone', 'inventoryPlaceIds', 'productStockUnitDefault', 'movementType', 'stockUnitAlert', 'restaurantStockChoice', 'submitStockUnit'];

  connect() {
    if(this.hasTypeTarget) {
      $(this.typeTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });

      $(this.categoryTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });

      $(this.zoneTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });

      $(this.restaurantStockChoiceTarget).on('select2:select', function () {
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      });
      //sortOptionByText('.inventory_place', 'eq(1)', 'first')
    }
  }

  filterTable(e) {
    var table = document.getElementById("table");
    var elementType = this.typeTarget.options[this.typeTarget.selectedIndex].value.toLowerCase();
    var elementCat = this.categoryTarget.options[this.categoryTarget.selectedIndex].value.toLowerCase();
    var elementZone = this.zoneTarget.options[this.zoneTarget.selectedIndex].innerHTML.toLowerCase();
    var elementStock = this.restaurantStockChoiceTarget.options[this.restaurantStockChoiceTarget.selectedIndex].innerHTML.toLowerCase();
    filterTableStockInventory(table, elementType, elementCat, elementZone, elementStock, 0, false)
  }

  formValidates(e) {
    var totalcount = 0;
    var dateInputs = document.querySelectorAll('.inventory_movement_inventory_date input')
    var movementTypeSelect2 = document.querySelector('.inventory_movement_movement_type > .select2-container')
    var docType = document.querySelector('.manual-type')
    var qty_inputs = document.querySelectorAll('.qty-item input')
    

    if(toggleValidation(this.inventoryDateTarget)){
      totalcount += 1
      if(dateInputs[0].value == "") {
        toggleValidation(dateInputs[1])
      } else {
        dateInputs[1].style.border = "1px solid #1EDD88";
      }
    }
    
    if(movementTypeSelect2){
      if(this.movementTypeTarget.value == "") {
        movementTypeSelect2.style.border = "1px solid red";
        totalcount += 1
      } else {
        movementTypeSelect2.style.border = "none";
      }
    }

    $('#inventory_movement_movement_type_id').on('select2:select', function (e) {
      if (e.target.value != ""){
        movementTypeSelect2.style.border = "none"
      }
    });

    if (docType.innerHTML != "Inventaire" && docType.innerHTML != "Production Recette") {
      var movementType = this.movementTypeTarget.options[this.movementTypeTarget.selectedIndex].innerText.split(' >')[0]
      if (movementType == "Vente" || movementType == "Perte" || movementType == "Ajustement négatif"){
        qty_inputs.forEach((input)=> {
          var stockValue = input.closest('.productrows').getElementsByClassName('stock-value')[0].innerText
          var stockValue = stockValue == "" ? 0 : parseFloat(stockValue)
          var inputValue = input.value == "" ? 0 : parseFloat(input.value)
          if (inputValue > stockValue) {
            //console.log(`${stockValue} vs ${inputValue}`)
            document.querySelector('.alert-negative-stock ').style.display = 'inline-block';
            totalcount += 1
          }
        });
      }
    }

    if (docType.innerHTML == "Inventaire") {
      // Inventaire
      if(compute(qty_inputs) == 0 && counterZero(qty_inputs) == 0){
        totalcount += 1
        alert('Une quantité doit être renseignée au minumum pour 1 article')
      }
    } else {
      // Autres mouvements
      if(compute(qty_inputs) == 0){
        totalcount += 1
        alert('Une quantité doit être renseignée au minumum pour 1 article')
      }
    }

    if (totalcount > 0) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  
  changeDate(e){
    var dateInputs = document.querySelectorAll('.inventory_movement_inventory_date input')
    if (dateInputs.length == 0) {
      var dateInputs = document.querySelectorAll('.production_inventory_date input')
    }

    if(dateInputs[0].value == "") {
      toggleValidation(dateInputs[1])
    } else {
      toggleValidation(dateInputs[1])
    }
  }

  showModal(e) {
    e.preventDefault();
    var item_id = e.currentTarget.dataset.id
    var item_class = e.currentTarget.dataset.class
    var stockUnit = e.currentTarget.dataset.stockUnit
    var inventoryPlace = e.currentTarget.dataset.inventoryPlace
    var rowNb, qty;
    const regex = /[^0-9]/g
    if (e.currentTarget.closest('tr')) {
      rowNb = e.currentTarget.closest('tr').lastElementChild.previousElementSibling.children[0].id.replace(regex, "")
      qty = e.currentTarget.closest('tr').lastElementChild.previousElementSibling.children[0].value
    }

    const fetchShow = () => {

      //console.table([item_id, item_class, stockUnit, inventoryPlace, rowNb, qty])

      fetch(`/inventory_modal?id=${item_id}&class=${item_class}&modal=Show`, {
        method: 'GET',
        headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
      })
      .then(response => response.json())
      .then((data) => {
        this.containerTarget.insertAdjacentHTML('beforeend', data.inserted_item);
        $('#create_zone_stock_unit').modal('show'); 
        
        if (rowNb) {
          document.getElementById('create_zone_stock_unit').setAttribute('data-rownb', `${rowNb}`)
          document.getElementById('create_zone_stock_unit').setAttribute('data-qty', `${qty}`)
        }

        $('#create_zone_stock_unit').on('hidden.bs.modal', function () {
          $(this).remove();
        });

        $('.inventory_place_create').select2({
          placeholder: "Selectionner une zone",
          templateResult: formatState,
          theme: 'classic'
        });

        Array.apply(null, this.selectPlaceTarget.options).forEach((option)=>{
          if(option.value == inventoryPlace){
            $('.inventory_place_create').prop('selectedIndex', option.index).change();     
          }
        })
        
        //sortOptionByText('.inventory_place_create', 'last', 'last')

        $('.stock_unit_select').select2({
          placeholder: "Selectionner une unité",
          templateResult: formatState,
          theme: 'classic'
        });

        Array.apply(null, this.selectStockUnitTarget.options).forEach((option)=>{
          if(option.value == stockUnit){
            $('.stock_unit_select').prop('selectedIndex', option.index).change();     
          }
        })

        $(this.selectPlaceTarget).on('select2:select', function () {
          let e = new Event('change', { bubbles: true }) // fire a native event
          this.dispatchEvent(e);
        });

        $(this.selectStockUnitTarget).on('select2:select', function () {
          let event = new Event('change', { bubbles: true }) // fire a native event
          this.dispatchEvent(event);
        });
      });
    }


    // vérification si tous les ingrédients d'1 Recipe ont des stock units
    if (item_class == "Recipe") {
      fetch(`/ingredients_with_stock_units?recipe_id=${item_id}`, {
        method: 'GET',
        headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
      })
      .then(response => response.json())
      .then((data) => {
        //console.log(data.not_created)
        if(data.not_created.length > 0) {
          alert('Pour pouvoir créer une unité de stock pour votre recette, veuillez renseigner une unité de stock pour tous vos ingredients')
        } else {
          fetchShow()
        }
      });
    } 
    
    if (item_class == "Product" ) {
      fetchShow()
    }

  }

  editModal(e) {

    e.preventDefault();
    var item_id = e.currentTarget.dataset.id
    var item_class = e.currentTarget.dataset.class
    var stockUnit = e.currentTarget.dataset.stockUnit
    var inventoryPlace = e.currentTarget.dataset.inventoryPlace
    var rowNb, qty;
    const regex = /[^0-9]/g
    if (e.currentTarget.closest('tr')) {
      rowNb = e.currentTarget.closest('tr').lastElementChild.previousElementSibling.children[0].id.replace(regex, "")
      qty = e.currentTarget.closest('tr').lastElementChild.previousElementSibling.children[0].value
    }

    console.table([item_id, item_class, stockUnit, inventoryPlace])

    fetch(`/inventory_modal?id=${item_id}&class=${item_class}&modal=Edit`, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': csrfToken() },
    })
    .then(response => response.json())
    .then((data) => {
      this.containerTarget.insertAdjacentHTML('beforeend', data.inserted_item_edit);
      $('#edit_zone_stock_unit').modal('show'); 
      
      if (rowNb && qty) {
        document.getElementById('create_zone_stock_unit').setAttribute('data-rownb', `${rowNb}`)
        document.getElementById('create_zone_stock_unit').setAttribute('data-qty', `${qty}`)
      }

      $('#edit_zone_stock_unit').on('hidden.bs.modal', function () {
        $(this).remove();
      });
      negativeInputs();
    });
  }

  cancelModal(){
    this.modalStockUnitTarget.remove();
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open')
  }

  createNewPlace(e) {

    var newPlaceForm = document.querySelector('.new-place')
    this.namePrincipalTarget.value = ''
    this.nameSecondaryTarget.value = ''
    
    if(this.selectPlaceTarget.options[this.selectPlaceTarget.selectedIndex].value == "0") {
      newPlaceForm.style.display  = ""
      var form = document.querySelector('.new_inventory_place')
      const token = document.querySelector('meta[name="csrf-token"]').content;

      form.addEventListener('submit', (e) => {
        e.preventDefault();
        var totalcount = 0;
        if(toggleValidation(this.namePrincipalTarget)){
          totalcount += 1
          if(this.namePrincipalTarget.value == "") {
            toggleValidation(this.namePrincipalTarget)
          } else {
            this.namePrincipalTarget.style.border = "1px solid #1EDD88"
          }
        }

        if (totalcount > 0) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          fetch('/inventory_places', {
            method: 'POST',
            headers: { 'Accept': "application/json", 'X-CSRF-Token': token },
            body: new FormData(form)   
          })
          .then(response => response.json())
          .then((data) => {
            var name_principal = this.namePrincipalTarget.value
            var name_secondary = this.nameSecondaryTarget.value
            var inventoryPlaceId = data.id
            var restaurantParam = document.getElementById(`inventory_place_restaurant_id`);
            var restaurantId = restaurantParam.value;
            var productId = document.getElementById('create_zone_stock_unit').dataset.id
            newPlaceForm.style.display  = "none"
            var placeDescription = `${name_principal} > ${name_secondary}`
            if(name_secondary == ""){
              var placeDescription = name_principal
            }
            var newOption = new Option(placeDescription, inventoryPlaceId, false, true);
            $(".inventory_place_create option:last").before(newOption);
            sortOptionByText('.inventory_place_create', 'last', 'last')

            var newOption2 = new Option(placeDescription, inventoryPlaceId, false, false);
            $(".inventory_place option:last").after(newOption2);
            sortOptionByText('.inventory_place', 'eq(0)', 'first')

            this.formNewPlaceTarget.innerHTML = data.inserted_form
          });
          this.submitButtonTarget.disabled = false
        }

      })
    
    } else {
      newPlaceForm.style.display  = "none"
      this.submitButtonTarget.disabled = false
    }
  }

  createNewStockUnit(e) {

    this.submitButtonTarget.disabled = true
    var newStockUnitForm = document.querySelector('.new-stock-unit')
    this.nameTarget.value = ''
    this.quantityTarget.value = ''
    negativeInputs();
    if(this.selectStockUnitTarget.options[this.selectStockUnitTarget.selectedIndex].value == "0") {
      newStockUnitForm.style.display  = ""
      var form = document.querySelector('.new_stock_unit')
      const token = document.querySelector('meta[name="csrf-token"]').content;

      form.addEventListener('submit', (e) => {
        e.preventDefault();
        var totalcount = 0;
        if(toggleValidation(this.nameTarget)){
          totalcount += 1
          if(this.nameTarget.value == "") {
            toggleValidation(this.nameTarget)
          } else {
            this.nameTarget.style.border = "1px solid #1EDD88"
          }
        }

        if(toggleValidation(this.quantityTarget)){
          totalcount += 1
          if(this.quantityTarget.value == "") {
            toggleValidation(this.quantityTarget)
          } else {
            this.quantityTarget.style.border = "1px solid #1EDD88"
          }
        }

        if (totalcount > 0) {
          e.preventDefault();
          e.stopPropagation();
        } else {

          var selectElementId = document.getElementById('stock_unit_element_id')
          var elementValue = selectElementId.options[selectElementId.selectedIndex].innerText
          var elemenType = selectElementId.options[selectElementId.selectedIndex].dataset.type
          const hiddenElementType = document.getElementById('hidden-element_type_stock_unit');

          hiddenElementType.innerText = elemenType;
          hiddenElementType.value = elemenType;
          console.table([elementValue, elemenType])

          fetch('/stock_units', {
            method: 'POST',
            headers: { 'Accept': "application/json", 'X-CSRF-Token': token },
            body: new FormData(form)   
          })
          .then(response => response.json())
          .then((data) => {
            var name = this.nameTarget.value
            var quantity = parseFloat(this.quantityTarget.value).toFixed(1)
            var stockUnitId = data.stock_unit_id
            newStockUnitForm.style.display  = "none"
            var newOption = new Option(`${name[0].toUpperCase() + name.substring(1)} x ${quantity} ${elementValue}`, stockUnitId, false, true);
            $(".stock_unit_select option:last").before(newOption);
            this.formNewStockUnitTarget.innerHTML = data.inserted_form
            if(document.querySelector('.stock-unit-alert')) {
              this.stockUnitAlertTarget.remove()
            }
          });
        }
        this.submitButtonTarget.disabled = false
      })


    } else {
      newStockUnitForm.style.display  = "none"
      this.submitButtonTarget.disabled = false
    }
  }

  createPlaceAndStockUnit(){

    const token = document.querySelector('meta[name="csrf-token"]').content;
    var itemId = document.getElementById('create_zone_stock_unit').dataset.id
    var item_class = document.getElementById('create_zone_stock_unit').dataset.class
    var rowNb = document.getElementById('create_zone_stock_unit').dataset.rownb
    var qty = document.getElementById('create_zone_stock_unit').dataset.qty
    var inventoryPlace = this.selectPlaceTarget.options[this.selectPlaceTarget.selectedIndex].value
    var stockUnitId = this.selectStockUnitTarget.options[this.selectStockUnitTarget.selectedIndex].value
    var stockUnit = this.selectStockUnitTarget.options[this.selectStockUnitTarget.selectedIndex].innerText
    var baseUrl = window.location.href
    var url = `/modal_update_product_recipe?baseUrl=${baseUrl}&qty=${qty}&rownb=${rowNb}&id=${itemId}&class=${item_class}&stock_unit_id=${stockUnitId}&stock_unit=${encodeURIComponent(stockUnit)}&inventory_place=${inventoryPlace}`
 
    fetch(url, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': token },
    })
    .then(response => response.json())
    .then((data) => {

      var stock_unit_row = document.querySelector('.stock_unit-rows-show')
      if (stock_unit_row) {
        this.productStockUnitDefaultTarget.innerHTML = data.inserted_item_for_product
      } else {
        var a = document.querySelectorAll('.productrows')
        a.forEach((row)=>{
          if(row.dataset.id == itemId) {
            row.innerHTML = data.inserted_item
          }
        })
      }

      this.modalStockUnitTarget.remove();
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');

      negativeInputs();
    });

  }

  editPlaceAndStockUnit(e){

    const token = document.querySelector('meta[name="csrf-token"]').content;
    var itemId = document.getElementById('edit_zone_stock_unit').dataset.id
    var item_class = document.getElementById('edit_zone_stock_unit').dataset.class
    var rowNb = document.getElementById('edit_zone_stock_unit').dataset.rownb
    var qty = document.getElementById('edit_zone_stock_unit').dataset.qty
    if(document.getElementById('inventory_place_id')){
      var inventoryPlaceId = document.getElementById('inventory_place_id').value
    }
    var stockUnitId = document.getElementById('stock_unit_id').value
    if (document.getElementById('recipe_stock_unit_element_id')){
      var stockUnit = document.getElementById('recipe_stock_unit_element_id').options[document.getElementById('recipe_stock_unit_element_id').selectedIndex].innerText
    } else {
       var stockUnit = document.getElementById('stock_unit_id').innerText
    }
        
    if (document.getElementById('product_stock_unit_element_id')) {
      var elemenType = document.getElementById('product_stock_unit_element_id').options[document.getElementById('product_stock_unit_element_id').selectedIndex].dataset.type
    }
    else {
      var elemenType = document.getElementById('recipe_stock_unit_element_id').options[document.getElementById('recipe_stock_unit_element_id').selectedIndex].dataset.type
    }

    const hiddenElementType = document.getElementById('hidden-element_type_stock_unit');
    hiddenElementType.innerText = elemenType;
    hiddenElementType.value = elemenType;

    //console.table([stockUnitId, stockUnit, elemenType])
    
    if (document.querySelector('.edit_product')){
      var formInventoryPlace = document.querySelector('.edit_product')
    } else {
      var formInventoryPlace = document.querySelector('.edit_recipe')
    }
    var form = $(formInventoryPlace).serialize();
    var url = `/modal_update_product_recipe?${form}&id=${itemId}&class=${item_class}&stock_unit_id=${stockUnitId}&stock_unit=${encodeURIComponent(stockUnit)}`
 
    var totalcount = 0;

    fetch(url, {
      method: 'GET',
      headers: { 'Accept': "application/json", "Content-Type": "application/json", 'X-CSRF-Token': token },
    })
    .then(response => response.json())
    .then((data) => {

      var stock_unit_row = document.querySelector('.stock_unit-rows-show')
      if (stock_unit_row) {
        this.productStockUnitDefaultTarget.innerHTML = data.inserted_item_for_product
      } else {
        var productRows = document.querySelectorAll('.productrows')
              
        var inventoryPlaceChangedId, inventoryPlaceChangedValue;
        productRows.forEach((row)=>{
          if(row.dataset.id == itemId) {
            row.innerHTML = data.inserted_item
            inventoryPlaceChangedId = row.getElementsByClassName('inventory-place')[0].getAttribute("name");
            inventoryPlaceChangedValue = row.getElementsByClassName('inventory-place')[0].innerText;
          }
        })
        
        /// Change le libellé des zones de stockage si celui-ci a été modifié
        var inventoryPlaces = document.getElementsByName(`inventory-place-${inventoryPlaceId}`)
        if (inventoryPlaces.length > 1) {
          inventoryPlaces.forEach((item) => {
            if ( item.innerText != inventoryPlaceChangedValue ) {
              item.innerText = inventoryPlaceChangedValue
            }
          })
        }
      }

      this.modalStockUnitTarget.remove();
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
      negativeInputs();
      
    });
  }

}         
