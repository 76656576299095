import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

let minQty = document.querySelector('.qty_min_bu'), valueBefore

function createElement(name, options) {
    let element = document.createElement(name);
    for (let key in options) {
        element.setAttribute(key, options[key]);
    }
    return element;
}

export default class extends Controller {
    static targets = ['form', 'buttons', 'actionsButton', 'cancelButton', 'valid-button'];

    connect() {
    }

    showCancelAndValidButtons(e) {
        minQty = document.querySelector('.qty_min_bu')
        valueBefore = parseFloat(minQty.innerText)
        const inputAttributes = {
            type: 'number',
            min: '0',
            step: 'any',
            value: valueBefore,
            class: "form-control is-valid numeric float optional quantity_field",
            name: "restaurant_stock[min_qty_bu]",
            id: "restaurant_stock_min_qty_bu"
        }
        const inputMinQty = createElement("input", inputAttributes)
        minQty.innerHTML = ""
        minQty.append(inputMinQty)

        e.currentTarget.style.display = "none"
        this.actionsButtonTarget.style.display = "flex"
    }

    cancel(e) {
        document.querySelector('.fa-pencil-alt').style.display = "block"
        this.actionsButtonTarget.style.display = "none"
        minQty.innerHTML = `${parseFloat(valueBefore).toFixed(1)}`
    }

    update(e) {
        e.preventDefault()
        const restaurantId = e.currentTarget.dataset.id

        fetch(`/restaurant_stocks/${restaurantId}`, {
            method: 'PATCH',
            headers: { 'Accept': "application/json", 'X-CSRF-Token': csrfToken() },
            body: new FormData(this.formTarget)
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
                minQty.innerHTML = parseFloat(data.min_qty_bu).toFixed(1)
            });

        document.querySelector('.fa-pencil-alt').style.display = "block"
        this.actionsButtonTarget.style.display = "none"
    }
}
